import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Text,
    Image,
    Divider,
    Stack,
    Center,
    Flex,
    SimpleGrid,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
} from '@chakra-ui/react';
import { isObjectEmpty, copyToClipboard, stringIsNullOrEmpty, checkBrowser } from "../../../common/util";
import { Pages } from "../../../routes/page";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _userData = useSelector(state => state.authState.userData);
    const _PARAMS = _location?.state;
    const instruction = _PARAMS?.instruction;
    const _IMAGE_MAPPING = [
        {
            name: "MOBILE_RELOAD",
            imagePath: require('../../../assets/images/graphic-success-mobile.png')
        },
        {
            name: "GAME_RELOAD",
            imagePath: require('../../../assets/images/graphic-success-gamereload.png')
        },
        {
            name: "BILL_PAYMENT",
            imagePath: require('../../../assets/images/graphic-success-billpayment.png')
        },
        {
            name: "EWALLET_RELOAD",
            imagePath: require('../../../assets/images/graphic-success-ewallet.png')
        }
    ];

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back();
        }
    }, [])


    if (!checkBrowser()) {
        return (
            <>
                {
                    !stringIsNullOrEmpty(_PARAMS?.instruction) &&
                    <Accordion allowToggle mt={'-50px'} mb={5}>
                        <AccordionItem className="reload-success-accordion">
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>
                                    {t('INSTRUCTION')}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                {
                                    _PARAMS?.instruction?.includes('\n') ?
                                        _PARAMS?.instruction.split('\n').map(item => {
                                            return (
                                                <Text>{item}</Text>
                                            )
                                        }) :
                                        <Text>{_PARAMS?.instruction}</Text>
                                }
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                }
                <Card className="purple-box" borderRadius='20px'>
                    <CardBody style={{ alignSelf: 'center' }}>
                        <Flex align={'center'}>
                            <Text as='b' color='#f8c763' fontSize='md'>{t(_PARAMS?.headerTitle)}</Text>
                            {
                                !stringIsNullOrEmpty(_PARAMS?.toCopy) &&
                                <Image
                                    ml={5}
                                    boxSize={'35px'}
                                    src={require('../../../assets/images/svg/general-copy.svg').default}
                                    onClick={() => {
                                        copyToClipboard(_PARAMS?.toCopy, t('COPIED_SUCCESFULLY'))
                                    }} />
                            }
                        </Flex>
                    </CardBody>
                    <Divider className="divider-horizontal" />
                    <CardBody style={{ alignSelf: 'center' }}>
                        <Image boxSize={'150px'}
                            src={!stringIsNullOrEmpty(_IMAGE_MAPPING.find(i => i.name == _PARAMS?.contentImage)?.imagePath) ? _IMAGE_MAPPING.find(i => i.name == _PARAMS?.contentImage)?.imagePath : _PARAMS?.contentImage} />
                        <Text textAlign={'center'}>{t('YOU_VE_PAID')}</Text>
                        <Text textAlign='center' fontWeight='bold' fontSize={'xl'} color='#f8c763'>{_PARAMS.amount}</Text>
                    </CardBody>
                    <Divider className="divider-horizontal" />
                    <CardBody style={{ alignSelf: 'center' }}>
                        <SimpleGrid columns={2}>
                            {
                                _PARAMS.details.map((item, index) => {
                                    return (
                                        <>
                                            <Text fontSize={'sm'}>{t(item.label)} : </Text>
                                            <Text fontSize={'sm'} color='#f8c763'>{t(item.value)}</Text>
                                        </>
                                    )
                                })
                            }
                        </SimpleGrid>
                    </CardBody>
                </Card>
                <Stack className="bottom-sticky-button" bottom={'25px'}>
                    <Center>
                        <Image
                            boxSize={'50px'}
                            src={require('../../../assets/images/svg/general-close.svg').default}
                            onClick={() => _PARAMS?.backToLastPage ? _router.back() : _router.navigate(Pages._DEFAULT, true)} />
                    </Center>
                </Stack>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _PARAMS?.backToLastPage ? _router.back() : _router.navigate(Pages._EPAY, true)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t(_PARAMS?.headerTitle)}</Text>
                        </div>
                        <Card bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={15}>
                            <CardBody pb={24} paddingInline={8} paddingBlock={8}>
                                <div style={{ textAlign: 'center' }}>
                                    <Center mb={5}>
                                        <Image boxSize={28} bg={'white'} borderRadius={50} padding={2}
                                            src={!stringIsNullOrEmpty(_IMAGE_MAPPING.find(i => i.name == _PARAMS?.contentImage)?.imagePath) ? _IMAGE_MAPPING.find(i => i.name == _PARAMS?.contentImage)?.imagePath : _PARAMS?.contentImage} />
                                    </Center>
                                    <Text textAlign={'center'}>{t('YOU_VE_PAID')}</Text>
                                    <Text textAlign='center' fontWeight='bold' fontSize={'xl'} color='#f8c763'>{_PARAMS?.amount}</Text>
                                </div>
                                <CardBody bg={'#400164'} borderRadius={15} marginBlock={5} padding={8}>
                                    <SimpleGrid spacing={5} columns={2}>
                                        {
                                            _PARAMS?.details.map((item, index) => {
                                                return (
                                                    <>
                                                        <Text fontSize={'md'}>{t(item.label)} : </Text>
                                                        <Text fontSize={'md'}>{t(item.value)}
                                                            {
                                                                !stringIsNullOrEmpty(_PARAMS?.toCopy) && item.label == 'PIN' &&
                                                                <Image
                                                                    boxSize={8}
                                                                    display={'inline-block'}
                                                                    mb={'-12px'}
                                                                    ml={3}
                                                                    cursor={'pointer'}
                                                                    src={require('../../../assets/images/svg/general-copy.svg').default}
                                                                    onClick={() => {
                                                                        copyToClipboard(_PARAMS?.toCopy, t('COPIED_SUCCESFULLY'))
                                                                    }} />
                                                            }
                                                        </Text>
                                                    </>
                                                )
                                            })
                                        }
                                    </SimpleGrid>
                                </CardBody>
                                {
                                    !stringIsNullOrEmpty(_PARAMS?.instruction) &&
                                    <Accordion allowToggle>
                                        <AccordionItem bg={'#400164'} borderRadius={15} color={'white'} border={'none'}>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' paddingBlock={2} paddingInline={3}>
                                                    {t('INSTRUCTION')}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                            <AccordionPanel pl={8} pt={0}>
                                                {
                                                    _PARAMS?.instruction?.includes('\n') ?
                                                        _PARAMS?.instruction.split('\n').map(item => {
                                                            return (
                                                                <Text fontSize={'sm'}>{item}</Text>
                                                            )
                                                        }) :
                                                        <Text fontSize={'sm'}>{_PARAMS?.instruction}</Text>
                                                }
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                }
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}