import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Text, SimpleGrid, Button, Box, Stack, Flex, Center, AbsoluteCenter, Spinner, Image } from "@chakra-ui/react";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, isObjectEmpty } from "../../../common/util";
import { EpayIndexCategory, ApiKey } from "../../../common/constant";
import GeneralTopBar from "../../../component/general-top-bar";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _PARAMS = _location?.state;
    const [service, setService] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back()
        }
        else {
            setIsLoading(true);
            getServiceSubcategory();
        }
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getServiceSubcategory() {
        var responseJson = await ApiEngine.get(Routes._GET_SERVICE_SUBCATEGORY + '?categoryId=' + _PARAMS?.categoryId,
            { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setService(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }

        setIsLoading(false);
    }

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={_PARAMS?.title} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                {
                    isLoading ?
                        <AbsoluteCenter>
                            <Spinner color='white' />
                        </AbsoluteCenter> :
                        <SimpleGrid columns={3} spacing={10} mt={5}>
                            {
                                service.map((item, index) => {
                                    return (
                                        <Stack key={index} align={'center'}>
                                            <Button
                                                variant={'link'}
                                                alignItems={'center'}
                                                borderRadius={50}
                                                backgroundColor={'#C6D1E2'}
                                                borderWidth={2.5}
                                                borderColor={'#A0B1CB'}
                                                p={3}
                                                onClick={() => {
                                                    _router.navigate(Pages._RELOAD_DETAIL, { subCategory: item, title: _PARAMS?.title })
                                                }}>
                                                <Image boxSize={'35px'} src={item.img} />
                                            </Button>
                                            <Text textAlign={'center'}>{item.ssc_name}</Text>
                                        </Stack>
                                    )
                                })
                            }
                        </SimpleGrid>
                }
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t(_PARAMS?.title)}</Text>
                        </div>
                        {
                            isLoading ?
                                <AbsoluteCenter>
                                    <Spinner color='white' />
                                </AbsoluteCenter> :
                                <SimpleGrid columns={4} spacing={6} mt={3}>
                                    {
                                        service.map((item, index) => {
                                            return (
                                                <Stack key={index} align={'center'} bg={'linear-gradient(#ac4cfe,#7416ab)'} cursor={'pointer'} borderRadius={15} paddingBlock={5} width={'12rem'} borderWidth={2}
                                                    borderColor={'transparent'} _hover={{ border: "2px solid white" }} transitionDuration={'normal'}
                                                    onClick={() => {
                                                        _router.navigate(Pages._RELOAD_DETAIL, { subCategory: item, title: _PARAMS?.title })
                                                    }}>
                                                    <Button
                                                        variant={'link'}
                                                        alignItems={'center'}
                                                        borderRadius={50}
                                                        backgroundColor={'#C6D1E2'}
                                                        borderWidth={2.5}
                                                        borderColor={'#A0B1CB'}
                                                        p={3}
                                                        onClick={() => {
                                                            _router.navigate(Pages._RELOAD_DETAIL, { subCategory: item, title: _PARAMS?.title })
                                                        }}>
                                                        <Image boxSize={'35px'} src={item.img} />
                                                    </Button>
                                                    <Text textAlign={'center'}>{item.ssc_name}</Text>
                                                </Stack>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                        }
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}