import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Image,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Stack,
    Flex,
    Text,
    Spacer,
    SimpleGrid,
    Button
} from "@chakra-ui/react";
import CustomInput from "./input";
import moment from "moment";
import { stringIsNullOrEmpty, checkBrowser } from "../common/util";
import { _DATE_FILTER_OPTIONS, DateFilterKeys, ScreenWidth } from "../common/constant";
import { isBrowser } from "react-device-detect";

/// <summary>
/// Author: Juin
/// </summary>
export default ({
    isOpen,
    closeOnOverlayClick = true,
    onClose,
    applyDateFilter,
    setDateTitle,
    monthOnly = false,
    initiateLastMonth = false
}) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(initiateLastMonth ? moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD') : moment().format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(initiateLastMonth ? moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD') : moment().format('yyyy-MM-DD'));
    const [selectedOption, setSelectedOption] = useState(initiateLastMonth ? '' : DateFilterKeys._TODAY);
    const [prevDayCount, setPrevDayCount] = useState(0);
    const [nextDayCount, setNextDayCount] = useState(0);
    const MONTH_KEY = 'month';
    const WEEK_KEY = 'week';

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        switch (selectedOption) {
            case DateFilterKeys._LAST_MONTH:
            case DateFilterKeys._THIS_MONTH:
                getWeekOrMonthRange(
                    MONTH_KEY,
                    selectedOption === DateFilterKeys._LAST_MONTH
                );
                break;
            case DateFilterKeys._LAST_WEEK:
            case DateFilterKeys._THIS_WEEK:
                getWeekOrMonthRange(
                    WEEK_KEY,
                    selectedOption === DateFilterKeys._LAST_WEEK
                );
                break;
            case DateFilterKeys._YESTERDAY:
                subtractDate(1);
                break;
            case DateFilterKeys._TODAY:
                setStartDate(moment().format('yyyy-MM-DD'));
                setEndDate(moment().format('yyyy-MM-DD'));
                break;
            default:
                break;
        }
    }, [selectedOption]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (prevDayCount > 0) {
            subtractDate(prevDayCount, 'd');
        }
    }, [prevDayCount]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (nextDayCount > 0) {
            let newDate = moment().add(nextDayCount, 'd').format('yyyy-MM-DD');

            setStartDate(newDate);
            setEndDate(newDate);
        }
    }, [nextDayCount]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    function dateChangeHandler(option) {
        if (option === DateFilterKeys._PREV_DAY) {
            setPrevDayCount(prevDayCount + 1);
            setNextDayCount(0);
        } else if (option === DateFilterKeys._NEXT_DAY) {
            setNextDayCount(nextDayCount + 1);
            setPrevDayCount(0);
        } else {
            setNextDayCount(0);
            setPrevDayCount(0);
        }

        setSelectedOption(option);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function resetHandler() {
        setSelectedOption(DateFilterKeys._TODAY);
        setPrevDayCount(0);
        setNextDayCount(0);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function subtractDate(days) {
        let newDate = moment().subtract(days, 'd').format('yyyy-MM-DD');

        setStartDate(newDate);
        setEndDate(newDate);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function getWeekOrMonthRange(rangeType, getLast) {
        let count = 0;
        let key = `${rangeType}s`;
        let type = rangeType === WEEK_KEY ? 'isoWeek' : rangeType;

        if (getLast) {
            count += 1;
        }

        setStartDate(moment().subtract(count, key).startOf(type).format('yyyy-MM-DD'));
        setEndDate(moment().subtract(count, key).endOf(type).format('yyyy-MM-DD'));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function filterByDateHandler(startDate, endDate) {
        applyDateFilter({ startDate, endDate });
        onClose();
    }

    return (
        <Drawer placement={'bottom'} isOpen={isOpen} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent borderTopLeftRadius={'20px'} borderTopRightRadius={'20px'} maxW={checkBrowser() ? '55rem !important' : ''} left={'unset !important'} right={'unset !important'}>
                <DrawerHeader className="bottom-sheet-header">
                    {t('DATE_FILTER')}
                    <DrawerCloseButton pt={'0.35rem'}><Image boxSize={'25px'} src={require('../assets/images/svg/general-close.svg').default} /></DrawerCloseButton>
                </DrawerHeader>
                <DrawerBody className="bottom-sheet-body" style={{ paddingInline: '1rem' }}>
                    <Stack spacing={0} w={'full'}>
                        <Text fontSize={'sm'}>{t('DATE_RANGE')}</Text>
                        <Flex align={'center'}>
                            <CustomInput
                                editable={false}
                                style={{ width: '43vw' }}
                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                borderRadius='10px !important'
                                value={startDate}
                                max={endDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(e) => {
                                    let newValue = e.target.value;

                                    if (stringIsNullOrEmpty(newValue)) {
                                        newValue = moment().format('yyyy-MM-DD');
                                    }
                                    else if (moment(endDate) < moment(newValue)) {
                                        setEndDate(monthOnly ? moment(newValue).endOf('month').format('yyyy-MM-DD') : newValue);
                                    }

                                    setStartDate(monthOnly ? moment(newValue).startOf('month').format('yyyy-MM-DD') : newValue);
                                }}
                                type="date" />
                            <Spacer />
                            <Text pb={4}>-</Text>
                            <Spacer />
                            <CustomInput
                                style={{ width: '43vw' }}
                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                borderRadius='10px !important'
                                value={endDate}
                                onKeyDown={(e) => e.preventDefault()}
                                onChange={(e) => {
                                    let newValue = e.target.value;

                                    if (stringIsNullOrEmpty(newValue)) {
                                        newValue = moment().format('yyyy-MM-DD');
                                    }
                                    else if (moment(startDate) > moment(newValue)) {
                                        setStartDate(monthOnly ? moment(newValue).startOf('month').format('yyyy-MM-DD') : newValue);
                                    }

                                    setEndDate(monthOnly ? moment(newValue).endOf('month').format('yyyy-MM-DD') : newValue);
                                }}
                                type="date" />
                        </Flex>
                    </Stack>
                    {
                        !monthOnly &&
                        <SimpleGrid templateColumns='repeat(3, 1fr)' spacing={3} mb={5}>
                            {
                                _DATE_FILTER_OPTIONS.map((item, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            className={`amount-button ${item === selectedOption ? 'selected' : ''}`}
                                            style={{
                                                borderRadius: 0,
                                                borderWidth: item === selectedOption ? 2 : 0,
                                                padding: '0.75rem'
                                            }}
                                            variant={'link'}
                                            onClick={() => dateChangeHandler(item)}>
                                            <Text>{t(item)}</Text>
                                        </Button>
                                    )
                                })
                            }
                        </SimpleGrid>
                    }
                </DrawerBody>
                <Flex w={'100%'}>
                    <Button className="date-filter-reset-button" onClick={resetHandler}>
                        {t('RESET')}
                    </Button>
                    <Button className="date-filter-apply-button" onClick={() => {
                        filterByDateHandler(startDate, endDate);
                    }}>
                        {t('APPLY')}
                    </Button>
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}