import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Flex, Center, Select, HStack } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ValidationPattern, _AMOUNT_TYPE, _PAYMENT_DEVICE_TYPE, _WALLET_ACTIONS, TransactionType, STATUS_TYPE, _DEPOSIT_TYPE, DepositType, _WEB_VERSION } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isString, createFormBody, checkBrowser, stringIsNullOrEmpty } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import CustomInput from "../../../component/input";
import CustomPicker from "../../../component/custom-picker";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
import { TriangleDownIcon } from "@chakra-ui/icons";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, getValues, setValue, watch, reset } = useForm();
    const userData = useSelector((state) => state.authState.userData);
    const [telcoPickerVisible, setTelcoPickerVisible] = useState(false);
    const [telcoList, setTelcoList] = useState([]);
    const [rate, setRate] = useState('');
    const [selectedDepositType, setSelectedDepositType] = useState(DepositType._USERNAME);
    const [selectedAmount, setSelectedAmount] = useState();
    const [vendorPickerVisible, setVendorPickerVisible] = useState(false);
    const [playerPickerVisible, setPlayerPickerVisible] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [playerScore, setPlayerScore] = useState(0);
    const selectedPlayerIdWatch = watch('selectedPlayerId');

    /// <summary>
    /// Author: Juin
    /// </summary>
    const toggleVendorPicker = () => {
        setVendorPickerVisible(!vendorPickerVisible)
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const togglePlayerPicker = () => {
        if (playerList.length > 0) {
            setPlayerPickerVisible(!playerPickerVisible)
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        (async () => {
            if (selectedDepositType == DepositType._PLAYER_ID) {
                var responseJson = await ApiEngine.get(Routes._GET_USERINFO_URL + '?id=' + userData.guid + "&versionCode=" + _WEB_VERSION);

                if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY].vendors_player.length > 0) {
                    let responseVendorList = responseJson[ApiKey._API_DATA_KEY].vendors_player.filter(item => item.players.length > 0).map(item => (
                        { ...item, label: item.vendor_name, value: item.vendor_id }
                    ))
                    let responsePlayerList = responseJson[ApiKey._API_DATA_KEY].vendors_player.filter(item => item.players.length > 0)[0].players.map(item => (
                        { ...item, label: item.vendor_username, value: item.player_id }
                    ))

                    setVendorList(responseVendorList);
                    setPlayerList(responsePlayerList);
                    setValue('selectedVendor', responseVendorList[0]);
                    setValue('selectedPlayerId', responsePlayerList[0]?.players?.length > 0 ? responsePlayerList[0].players[0] : '');
                }
                else {
                    _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
                }
            }
            else {
                reset();
            }
        })();
    }, [selectedDepositType])

    useEffect(() => {
        if (selectedPlayerIdWatch) {
            (async function () {
                try {
                    let responseJson = await ApiEngine.get(`${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${selectedPlayerIdWatch.vendor_username}`,
                        { headers: { "skipLoading": true } });
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setPlayerScore(responseJson[ApiKey._API_DATA_KEY][0]['score']);
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                }
                catch (err) {
                    _dispatch(showCustomDialog({ success: false, content: err }));
                }
            })();
        }
    }, [selectedPlayerIdWatch]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (selectedDepositType == DepositType._PLAYER_ID && watch('selectedVendor')) {
            if (watch('selectedVendor').players.length > 0) {
                let newPlayerList = watch('selectedVendor').players.map(item => (
                    { ...item, label: item.vendor_username, value: item.player_id }
                ))

                setValue('selectedPlayerId', newPlayerList[0]);
                setPlayerList(newPlayerList);
            }
            else {
                setValue('selectedPlayerId', '');
                setPlayerList([]);
            }
        }
    }, [selectedDepositType, watch('selectedVendor')]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        if(selectedDepositType == DepositType._USERNAME){
            return (!stringIsNullOrEmpty(watch('amount')));
        }else{
            return (!stringIsNullOrEmpty(watch('selectedVendor') && !stringIsNullOrEmpty(watch('selectedPlayerId')) && !stringIsNullOrEmpty(watch('amount'))));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const onSubmitHandler = async (data) => {
        if (data.amount > 0) {
            try {
                let apiUrl = Routes._CREATE_TRANSACTION_REQUEST + "?amount=" + data.amount;

                if (selectedDepositType == DepositType._PLAYER_ID) {
                    apiUrl += "&playerId=" + data.selectedPlayerId["player_id"]
                }

                let responseJson = await ApiEngine.post(apiUrl)

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _router.navigate(Pages._QR_GENERATION, {
                        title: "QR_CODE_DEPOSIT",
                        amount: data.amount,
                        requestCode: responseJson['data'],
                    });
                }
                else {
                    _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
                }
            } catch (err) {
                _dispatch(showCustomDialog({ success: false, content: err }));
            }
        } else {
            _dispatch(showCustomDialog({ success: false, content: t("MIN_AMOUNT_RM_001") }));
        }

    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={t('QRCODE_DEPOSIT')} bgColor="linear-gradient(#a94ff1,#9e32dd)">
                    <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                </GeneralTopBar>
                <div className="header-margin-top inner-container">
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Text mb={4}>1. Select Deposit To</Text>
                        <HStack spacing={5} mb={6}>
                            {_DEPOSIT_TYPE.map((value, index) => {
                                return (
                                    <Button className={selectedDepositType == value.type ? 'qr-code-options-selected' : 'qr-code-options'}
                                        onClick={() => { setSelectedDepositType(value.type) }}>{t(value.type)}</Button>
                                )
                            })}
                        </HStack>
                        <Divider mb={6} className='divider-horizontal' />
                        {
                            selectedDepositType == DepositType._USERNAME &&
                            <>
                                <Controller
                                    control={control}
                                    name="username"
                                    defaultValue={userData.username}
                                    render={({ value, name }) => (
                                        <CustomInput
                                            isReadOnly
                                            id={name}
                                            value={value}
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            border='2px solid #a820ff !important'
                                            borderRadius='10px !important'
                                            label={`2. ${t('YOUR_USERNAME')}`}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={10}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label={`3. ${t('ENTER_AMOUNT')}`}
                                            placeholder={t('ENTER_AMOUNT')}
                                            inputType="number"
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            border='2px solid #e2a570 !important'
                                            borderRadius='10px !important'
                                            subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                            subLabelStyle={
                                                {
                                                    color: '#f8c763'
                                                }
                                            }
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                        min: 1
                                    }}
                                />
                                <Stack spacing={4} direction='row' align='center' justifyContent={['space-between', 'flex-start']}>
                                    {_AMOUNT_TYPE.map((value, index) => (
                                        <Button
                                            className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                            size='md'
                                            onClick={() => setValue('amount', value.amount)}
                                        >
                                            RM {value.amount}
                                        </Button>
                                    ))}
                                </Stack>

                                <Button
                                    className="grey-gradient-button btn-float-btm"
                                    type="submit">
                                    {t('GENERATE_QR')}
                                </Button>
                            </>
                        }
                        {
                            selectedDepositType == DepositType._PLAYER_ID &&
                            <>
                                <Button variant={'link'} w='full' onClick={() => setVendorPickerVisible(!vendorPickerVisible)}>

                                    <Controller
                                        control={control}
                                        name="selectedVendor"
                                        defaultValue={''}
                                        render={({ onChange, onBlur, value, name }) => (
                                            <CustomInput
                                                id={name}
                                                value={value.label}
                                                editable={false}
                                                errors={errors}
                                                label={'2. ' + t('SELECT_VENDOR')}
                                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                                border='2px solid #e2a570 !important'
                                                borderRadius='10px !important'
                                                icon={
                                                    <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                                }
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </Button>
                                <Button variant={'link'} w='full' onClick={() => { playerList.length > 0 && setPlayerPickerVisible(!playerPickerVisible); }}>
                                    <Controller
                                        control={control}
                                        name="selectedPlayerId"
                                        defaultValue={''}
                                        render={({ value, name }) => (
                                            <CustomInput
                                                isReadOnly
                                                id={name}
                                                value={value?.label}
                                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                                border='2px solid #e2a570 !important'
                                                borderRadius='10px !important'
                                                label={'3. ' + t('SELECT_PLAYER_ID')}
                                                placeholder={t('SELECT_BANK')}
                                                errors={errors}
                                                subLabel={
                                                    'RM ' +
                                                    numberWithCurrencyFormat(playerScore)
                                                }
                                                icon={
                                                    <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                                }
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </Button>
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={10}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            label={`3. ${t('ENTER_AMOUNT')}`}
                                            placeholder={t('ENTER_AMOUNT')}
                                            inputType="number"
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            border='2px solid #e2a570 !important'
                                            borderRadius='10px !important'
                                            subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                            subLabelStyle={
                                                {
                                                    color: '#f8c763'
                                                }
                                            }
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                        min: 1
                                    }}
                                />
                                <Stack spacing={4} pb={8} direction='row' align='center' justifyContent={['space-between', 'flex-start']}>
                                    {_AMOUNT_TYPE.map((value, index) => (
                                        <Button
                                            className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                            size='md'
                                            onClick={() => setValue('amount', value.amount)}
                                        >
                                            RM {value.amount}
                                        </Button>
                                    ))}
                                </Stack>
                                <Button
                                    className="grey-gradient-button btn-float-btm"
                                    type="submit">
                                    {t('GENERATE_QR')}
                                </Button>
                            </>
                        }
                    </form>
                    <CustomPicker
                        isVisible={vendorPickerVisible}
                        title={"SELECT_VENDOR"}
                        options={vendorList}
                        selectedOption={watch("selectedVendor")}
                        onSelect={(isSelected = true, value) => {
                            if (isSelected) {
                                setValue('selectedVendor', value)
                            }
                            setVendorPickerVisible(!vendorPickerVisible);
                        }}
                    />
                    <CustomPicker
                        isVisible={playerPickerVisible}
                        title={'SELECT_PLAYER_ID'}
                        options={playerList}
                        selectedOption={watch('selectedPlayerId')}
                        onSelect={(isSelected = true, value) => {
                            if (isSelected) {
                                setValue('selectedPlayerId', value);
                            }
                            setPlayerPickerVisible(!playerPickerVisible);
                        }} />
                </div>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('MOBILE_PIN_TOPUP')}</Text>
                        </div>
                        <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                        <div className="inner-container" style={{ marginTop: "2rem" }}>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Text mb={4}>{`1. ${t('SELECT_DEPOSIT_TO')}`}</Text>
                        <HStack spacing={5} mb={6}>
                            {_DEPOSIT_TYPE.map((value, index) => {
                                return (
                                    <Button className={selectedDepositType == value.type ? 'qr-code-options-selected' : 'qr-code-options'}
                                        onClick={() => { setSelectedDepositType(value.type) }}>{t(value.type)}</Button>
                                )
                            })}
                        </HStack>
                        <Divider mb={6} className='divider-horizontal' />
                        {
                            selectedDepositType == DepositType._USERNAME &&
                            <>
                                <Controller
                                    control={control}
                                    name="username"
                                    defaultValue={userData.username}
                                    render={({ value, name }) => (
                                        <CustomInput
                                            isReadOnly
                                            id={name}
                                            value={value}
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            border='2px solid #a820ff !important'
                                            borderRadius='10px !important'
                                            label={`2. ${t('YOUR_USERNAME')}`}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={10}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            bg='#2c3956 !important'
                                            color='white'
                                            boxShadow='none !important'
                                            borderRadius='10px !important'
                                            background="linear-gradient(#5a0089,#7c15bd) !important"
                                            transitionDuration='normal'
                                            _hover={{ border: '2px solid white !important' }}
                                            border="2px solid transparent !important"
                                            label={`1. ${t('ENTER_AMOUNT')}`}
                                            placeholder={t('ENTER_AMOUNT')}
                                            inputType="number"
                                            subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                            subLabelStyle={
                                                {
                                                    color: '#f8c763',
                                                    fontSize: 'sm'
                                                }
                                            }
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                        min: 1
                                    }}
                                />
                                <Stack spacing={4} direction='row' align='center' mb={10} justifyContent={['space-between', 'flex-start']}>
                                    {_AMOUNT_TYPE.map((value, index) => (
                                        <Button
                                            className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                            size='md'
                                            onClick={() => setValue('amount', value.amount)}
                                        >
                                            RM {value.amount}
                                        </Button>
                                    ))}
                                </Stack>
                            </>
                        }
                        {
                            selectedDepositType == DepositType._PLAYER_ID &&
                            <>
                                <Text fontSize={'sm'} mb={3}>1. {t('SELECT_VENDOR')}</Text>
                                        <Controller
                                            name="selectedVendor"
                                            control={control}
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <Select {...field} color={'white'} bg='linear-gradient(#5a0089,#7c15bd) !important' mb={7} height={50} fontSize={'sm'} _hover={{ border: "2px solid white" }} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}
                                                    fontWeight={'bold'} borderRadius={15} value={watch('selectedVendor')}
                                                    onChange={(e) => {
                                                        setValue('amount', '0.00');
                                                        setValue('selectedVendor', vendorList.find((item) => item.id == e.target.value));
                                                    }}
                                                    icon={<TriangleDownIcon fontSize={13} />}>
                                                    {vendorList.map((option) => (
                                                        <option key={option.value} value={option.value} style={{ background: '#6f0daa' }}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                <Text fontSize={'sm'} mb={3} >2. {t('SELECT_PLAYER_ID')}</Text>
                                        <Controller
                                            control={control}
                                            name="selectedPlayerId"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <Select {...field} color={'white'} bg='linear-gradient(#5a0089,#7c15bd) !important' mb={7} height={50} fontSize={'sm'} errors={errors} _hover={{ border: "2px solid white" }} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'}
                                                    fontWeight={'bold'} borderRadius={15} value={watch('selectedPlayerId')}
                                                    onChange={(e) => {
                                                        setValue('amount', '0.00');
                                                        setValue('selectedPlayerId', playerList.find((item) => item.value == e.target.value));
                                                    }}
                                                    icon={<TriangleDownIcon fontSize={13} />}>
                                                    {playerList.map((option) => (
                                                        <option key={option.value} value={option.value} style={{ background: "#6f0daa" }}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Select>
                                            )}
                                            rules={{ required: true }}
                                        />
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={10}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            bg='#2c3956 !important'
                                            color='white'
                                            boxShadow='none !important'
                                            borderRadius='10px !important'
                                            background="linear-gradient(#5a0089,#7c15bd) !important"
                                            transitionDuration='normal'
                                            _hover={{ border: '2px solid white !important' }}
                                            border="2px solid transparent !important"
                                            label={`1. ${t('ENTER_AMOUNT')}`}
                                            placeholder={t('ENTER_AMOUNT')}
                                            inputType="number"
                                            subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                            subLabelStyle={
                                                {
                                                    color: '#f8c763',
                                                    fontSize: 'sm'
                                                }
                                            }
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                        min: 1
                                    }}
                                />
                                <Stack spacing={4} direction='row' align='center' mb={10} justifyContent={['space-between', 'flex-start']}>
                                    {_AMOUNT_TYPE.map((value, index) => (
                                        <Button
                                            className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                            size='md'
                                            onClick={() => setValue('amount', value.amount)}
                                        >
                                            RM {value.amount}
                                        </Button>
                                    ))}
                                </Stack>                              
                                    </>
                                }
                                <Button
                                    mt={6}
                                    style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%)", color: "white", padding: "25px 0px" }} w='100%'
                                    type="submit">
                                    {t('GENERATE_QR')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}