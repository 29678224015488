import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../hooks/useRouter";
import { useDispatch } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import {
    Card,
    CardBody,
    Stack,
    Text,
    Image,
    Button,
    Spacer,
    Box,
    Divider,
    AbsoluteCenter,
    Flex,
    HStack
} from '@chakra-ui/react';
import CurrencyInput from 'react-currency-input-field';
import Routes from "../common/api_routes";
import ApiEngine from "../common/api_engine";
import { ApiKey, ValidationPattern, ConvertType, _DATETIME_FORMAT, ResultImageType } from "../common/constant";
import { showCustomDialog } from "../application/action/app_action";
import { numberWithCurrencyFormat, createFormBody, checkBrowser } from "../common/util";
import { Pages } from "../routes/page";
import moment from "moment";

/// <summary>
/// Author: Juin
/// </summary>
export default ({
    credit,
    label,
    playerId,
    playerName,
    getPlayerScore,
    score,
    vendorName,
    username,
    isRequiredNavigateBack
}) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    const { t } = useTranslation();
    const _CONVERT_TO_SCORE = "CONVERT_TO_SCORE"
    const _CONVERT_TO_WALLET = "CONVERT_TO_WALLET"
    const [convertState, setConvertState] = useState(_CONVERT_TO_SCORE)

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        setValue('amount', numberWithCurrencyFormat(credit));
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    function changeConvertState() {
        setValue('amount', numberWithCurrencyFormat(convertState === _CONVERT_TO_WALLET ? credit : score))
        setConvertState(convertState == _CONVERT_TO_WALLET ? _CONVERT_TO_SCORE : _CONVERT_TO_WALLET);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        let positiveAmount = Math.abs(data.amount);

        if (positiveAmount <= 0) {
            _dispatch(showCustomDialog({ success: false, content: "INCORRECT_INPUT_ERROR_MSG" }));
        }
        else {
            _dispatch(showCustomDialog({
                success: true,
                title: "CONFIRMATION",
                content: convertState === _CONVERT_TO_WALLET ? "CONVERT_TO_CREDIT_CONFIRMATION" : "CONVERT_TO_SCORES_CONFIRMATION",
                onConfirm: () => {
                    convertCreditScore(positiveAmount);
                },
                onCancel: () => { },
                horizontalBtn: false
            }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function convertCreditScore(amount) {
        try {
            let params = {
                'PlayerId': playerId,
                'Amount': amount,
                'TargetType': convertState === _CONVERT_TO_WALLET ? ConvertType._CREDIT : ConvertType._SCORE
            };

            let formBody = createFormBody(params);
            let responseJson = await ApiEngine.post(Routes._COVERT_SCORE_CREDIT, formBody);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let data = responseJson[ApiKey._API_DATA_KEY];
            let convertDetailsArr = [];

            if (convertState === _CONVERT_TO_SCORE) {
                convertDetailsArr.push({ label: 'FROM_USERNAME', value: username });
                convertDetailsArr.push({ label: 'TO_PLAYER_ID', value: playerName });
            }
            else {
                convertDetailsArr.push({ label: 'FROM_PLAYER_ID', value: playerName });
                convertDetailsArr.push({ label: 'TO_USERNAME', value: username });
            }

            if (isRequiredNavigateBack) {
                _router.back();
            }
            else {
                _router.navigate(Pages._CONVERT_SUCCESS, {
                    headerTitle: 'SUCCESSFULLY_CONVERTED',
                    contentImageType: ResultImageType._CONVERT,
                    contentTitle: 'YOU_VE_CONVERTED',
                    amount: '- RM ' + numberWithCurrencyFormat(amount),
                    details: [
                        ...convertDetailsArr,
                        { label: 'VENDOR', value: label },
                        { label: 'DATE_TIME', value: moment().format(_DATETIME_FORMAT) }
                    ]
                }, true);
            }

        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    if (!checkBrowser()) {
        return (
            <Card className="player-detail-box header-margin-top" borderRadius='20px' bg={'linear-gradient(#9c2edb,#7a1cb2)'} width={'100%'}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <CardBody>
                        <Text color={'#f8c763'} fontWeight={800} fontSize={'md'} mb={-2}>{`${t('FROM')} ${convertState == _CONVERT_TO_WALLET ? `${t("SCORE")} (${label})` : t('WALLET')}`}</Text>
                        <Text as='i'>{`${t('CONVERT_FROM')} ${convertState == _CONVERT_TO_WALLET ? `${t('PLAYER_ID')} : ${playerName}` : `${t('USERNAME')} : ${username}`}`}</Text>
                        <Box w='full' borderRadius={20} borderColor='#C6D1E2' bg={'linear-gradient(#400164,#9223d4)'} borderWidth={1} mt={5}>
                            <Controller
                                control={control}
                                name="amount"
                                defaultValue={numberWithCurrencyFormat(convertState == _CONVERT_TO_WALLET ? score : credit)}
                                render={({ onChange, value, name }) => (
                                    <Stack direction='row' paddingInline={3}>
                                        <Image
                                            boxSize={'35px'}
                                            src={convertState == _CONVERT_TO_WALLET ? require('../assets/images/topbar-icon-score.png') : require('../assets/images/topbar-icon-credit.png')} />
                                        <CurrencyInput
                                            style={{
                                                textAlign: 'end',
                                                backgroundColor: 'transparent',
                                                color: 'white',
                                                fontWeight: '500',
                                                fontSize: '1.2rem',
                                                width: '70%',
                                                outlineWidth: 0
                                            }}
                                            id={name}
                                            name={name}
                                            value={value}
                                            placeholder="0.00"
                                            prefix="-"
                                            decimalScale={2}
                                            autoComplete={false}
                                            onFocus={(e) => e.target.select()}
                                            onValueChange={(value, name) => {
                                                onChange(value?.replace('-', ''));
                                            }}
                                        />
                                        <Divider orientation='vertical' h='35px' />
                                        <Button variant='link'
                                            onClick={() => {
                                                setValue('amount', numberWithCurrencyFormat(convertState == _CONVERT_TO_WALLET ? score : credit));
                                            }}>
                                            <Text fontSize={'md'}>{t('MAX')}</Text>
                                        </Button>
                                    </Stack>
                                )}
                                rules={{
                                    required: true, minLength: 1, maxLength: 10, pattern: ValidationPattern._DECIMAL_REGEX,
                                    max: convertState == _CONVERT_TO_WALLET ? score : credit
                                }}
                            />
                        </Box>
                        {
                            errors && errors['amount'] &&
                            <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                        }
                    </CardBody>
                    <Box position='relative' paddingBlock={5}>
                        <Divider className="divider-vertical" h={'0 !important'} />
                        <AbsoluteCenter px='4'>
                            <Button
                                variant='link'
                                leftIcon={<Image boxSize='35px'
                                    transform={convertState == _CONVERT_TO_WALLET ? 'rotate(180deg)' : 'rotate(90deg)'}
                                    src={require('../assets/images//svg/general-convert.svg').default} />}
                                onClick={() => changeConvertState()} />
                        </AbsoluteCenter>
                    </Box>
                    <CardBody mb={3}>
                        <Text color={'#f8c763'} fontWeight={800} fontSize={'md'} mb={-2}>{t('TO')} {convertState == _CONVERT_TO_WALLET ? t('WALLET') : `${t('SCORE')} (${label})`}</Text>
                        <Text as='i'>{t('CONVERT_TO')} {convertState == _CONVERT_TO_WALLET ? `${t("USERNAME")} : ${username}` : `${t("PLAYER_ID")} : ${playerName}`}</Text>
                        <Box w='full' borderRadius={20} borderColor='#C6D1E2' bg={'linear-gradient(#400164,#9223d4)'} borderWidth={1} mt={5}>
                            <Flex paddingInline={3} align='center'>
                                <Image
                                    boxSize={'35px'}
                                    src={convertState == _CONVERT_TO_WALLET ? require('../assets/images/topbar-icon-credit.png') : require('../assets/images/topbar-icon-score.png')} />
                                <Spacer />
                                <Text as='b' fontSize='md'>
                                    {
                                        `+ ${watch("amount") > 0 ? watch("amount") : '0.00'}`
                                    }
                                </Text>
                            </Flex>
                        </Box>
                    </CardBody>
                    <Button
                        className="light-purple-button" paddingBlock={'1.5rem !important'}
                        style={{ borderRadius: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                        type="submit">
                        {t(convertState == _CONVERT_TO_WALLET ? 'CONVERT_SCORE' : 'CONVERT_WALLET')}
                    </Button>
                </form>
            </Card>
        )
    } else {
        return (
            <>
                <form onSubmit={handleSubmit(submitForm)} style={{ marginTop: "1.5rem" }}>
                    <Text color={'#f8c763'} fontWeight={700} fontSize={'md'} mb={-1}>{`${t('FROM')} ${convertState == _CONVERT_TO_WALLET ? `${t("EPOINT")} (${label})` : t('ECREDIT')}`}</Text>
                    <Text as='i'>{`${t('CONVERT_FROM')} ${convertState == _CONVERT_TO_WALLET ? `${t('PLAYER_ID')} : ${playerName}` : `${t('USERNAME')} : ${username}`}`}</Text>
                    <Box w='full' borderRadius={20} bg={'#400164'} mt={5} paddingBlock={2}>
                        <Controller
                            control={control}
                            name="amount"
                            defaultValue={numberWithCurrencyFormat(convertState == _CONVERT_TO_WALLET ? score : credit)}
                            render={({ onChange, value, name }) => (
                                <HStack paddingInline={3} pr={6}>
                                    <Image
                                        boxSize={'35px'}
                                        src={convertState == _CONVERT_TO_WALLET ? require('../assets/images/topbar-icon-score.png') : require('../assets/images/topbar-icon-credit.png')} />
                                    <Spacer />
                                    <CurrencyInput
                                        style={{
                                            textAlign: 'end',
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            fontWeight: '500',
                                            fontSize: '1rem',
                                            width: '70%',
                                            outlineWidth: 0
                                        }}
                                        id={name}
                                        name={name}
                                        value={value}
                                        placeholder="0.00"
                                        prefix="-"
                                        decimalScale={2}
                                        autoComplete={false}
                                        onFocus={(e) => e.target.select()}
                                        onValueChange={(value, name) => {
                                            onChange(value?.replace('-', ''));
                                        }}
                                    />
                                    <Divider orientation='vertical' h='35px' />
                                    <Button variant='link'
                                        onClick={() => {
                                            setValue('amount', numberWithCurrencyFormat(convertState == _CONVERT_TO_WALLET ? score : credit));
                                        }}>
                                        <Text fontSize={'md'}>{t('MAX')}</Text>
                                    </Button>
                                </HStack>
                            )}
                            rules={{
                                required: true, minLength: 1, maxLength: 10, pattern: ValidationPattern._DECIMAL_REGEX,
                                max: convertState == _CONVERT_TO_WALLET ? score : credit
                            }}
                        />
                    </Box>
                    {
                        errors && errors['amount'] &&
                        <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                    }
                    <HStack mt={6}>
                        <div>
                            <Text color={'#f8c763'} fontWeight={700} fontSize={'md'} mb={-1}>{t('TO')} {convertState == _CONVERT_TO_WALLET ? t('ECREDIT') : `${t('EPOINT')} (${label})`}</Text>
                            <Text as='i'>{t('CONVERT_TO')} {convertState == _CONVERT_TO_WALLET ? `${t("USERNAME")} : ${username}` : `${t("PLAYER_ID")} : ${playerName}`}</Text>
                        </div>
                        <Spacer />
                        <Button
                            variant='link'
                            leftIcon={<Image boxSize='40px'
                                transform={convertState == _CONVERT_TO_WALLET ? 'rotate(180deg)' : 'rotate(90deg)'}
                                src={require('../assets/images/svg/general-convert.svg').default} />}
                            onClick={() => changeConvertState()} />
                    </HStack>
                    <Box w='full' borderRadius={20} bg={'#400164'} mt={5} paddingBlock={2}>
                        <HStack paddingInline={3} pr={6}>
                            <Image
                                boxSize={'35px'}
                                src={convertState == _CONVERT_TO_WALLET ? require('../assets/images/topbar-icon-credit.png') : require('../assets/images/topbar-icon-score.png')} />
                            <Spacer />
                            <Text as='b' fontSize='md'>
                                {
                                    `+ ${watch("amount") > 0 ? watch("amount") : '0.00'}`
                                }
                            </Text>
                        </HStack>
                    </Box>
                    <Button
                        w={'100%'} mt={8} fontWeight={'500'} borderRadius={15} backgroundImage={"linear-gradient(#e5b823, #8d7216)"} color={'white'} paddingBlock={6}
                        type="submit">
                        {t(convertState == _CONVERT_TO_WALLET ? 'CONVERT_SCORE' : 'CONVERT_WALLET')}
                    </Button>
                </form>
            </>
        )
    }
}
