import React, { useEffect, useContext, useState } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../../../hooks/useRouter";
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Divider,
    Center,
    HStack,
    Card,
    CardBody,
    Input
} from '@chakra-ui/react';
import { Pages } from "../../../routes/page";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import { BG_TYPE, _LANGUAGE_OPTION, ValidationPattern, ApiKey } from "../../../common/constant";
import BottomSheet from "../../../component/bottom-sheet";
import { ConfigEnum } from "../../../common/config";
import { getMemberDetails } from "../../../application/action/auth_action";
import { checkBrowser } from "../../../common/util";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
import CustomInput from "../../../component/input";
import { showCustomDialog } from "../../../application/action/app_action";
import Dropzone from 'react-dropzone';
import Marquee from "react-fast-marquee";
import { useForm, Controller } from 'react-hook-form';
import { stringIsNullOrEmpty, removeSpecialChar } from "../../../common/util";
import { performEditProfile } from "../../../application/action/auth_action";
import { useOrientation } from 'react-use';
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _navigate = useNavigate();
    var _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [logoFile, setLogoFile] = useState();
    const [logoImage, setLogoImage] = useState();
    const [bankHolderName, setBankHolderName] = useState('');
    const [userBankList, setUserBankList] = useState([]);
    const { type } = useOrientation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector(state => state.authState.userData);
    const [showLanguageBox, setShowlanguageBox] = useState(false);
    const _BOTTOM_BUTTOM = [
        {
            icon: require('../../../assets/images/settings/edit.png'),
            title: 'EDIT_PROFILE'
        },
        {
            icon: require('../../../assets/images/settings/bank.png'),
            title: 'BANK_DETAILS'
        },
        {
            icon: require('../../../assets/images/settings/language.png'),
            title: 'LANGUAGE'
        },
        {
            icon: require('../../../assets/images/settings/info.png'),
            title: 'ABOUT_US'
        },
    ];

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);
        setLogoImage(userData?.profileImage);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('alias')));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function onChangeLanguage(language) {
        i18n.changeLanguage(language);
        localStorage.setItem(ConfigEnum._LANGUAGE, language);
        _dispatch(getMemberDetails());
    }

    /// <summary>
    /// Author : Juin
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            let newData = { alias: data.alias };

            if (logoFile) {
                newData['profileImage'] = logoFile;
            }

            _dispatch(performEditProfile(
                newData,
                () => _router.back(),
            ));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);
        getUserBankAccount();

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getUserBankAccount = async () => {
        try {
            let responseJson = await ApiEngine.get(`${Routes._GET_USER_BANK_ACCOUNTS}?userId=${userData.guid}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let userBanks = responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];
            setUserBankList(userBanks);

            if (userBanks.length > 0) {
                setBankHolderName(userBanks[0]['bankAccountHolderName']);
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const deleteUserBankAccount = async (userBankId) => {
        try {
            let responseJson = await ApiEngine.delete(`${Routes._GET_USER_BANK_ACCOUNT}/${userBankId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showCustomDialog({
                success: true,
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: () => {
                    getUserBankAccount();
                }
            }));
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function settingActionHandler(key) {
        switch (key) {
            case _BOTTOM_BUTTOM[0]['title']:
                _router.navigate(Pages._EDIT_PROFILE);
                break;
            case _BOTTOM_BUTTOM[1]['title']:
                _router.navigate(Pages._BANK_DETAIL);
                break;
            case _BOTTOM_BUTTOM[2]['title']:
                setShowlanguageBox(!showLanguageBox);
                break;
            case _BOTTOM_BUTTOM[3]['title']:
                _router.navigate(Pages._ABOUT_US);
                break;
            default:
                break;
        }
    }

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'SETTINGS'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                <Stack mt={3}>
                    {
                        _BOTTOM_BUTTOM.map((item, index) => {
                            return (
                                <>
                                    <Button key={index} h='3rem' variant={'link'} onClick={() => settingActionHandler(item.title)}>
                                        <Flex align={'center'} w='full' paddingInline={2}>
                                            <Image boxSize={'40px'} src={item.icon} mr={5} />
                                            <Text fontSize={'sm'}>{t(item.title)}</Text>
                                            <Spacer />
                                            <Image boxSize={'25px'} transform={'rotate(180deg)'} src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                                        </Flex>
                                    </Button>
                                    <Divider className="divider-horizontal" ml={-4} w={'120%'} />
                                </>
                            )
                        })
                    }
                    <BottomSheet
                        title={'LANGUAGE'}
                        isOpen={showLanguageBox}
                        onClose={() => setShowlanguageBox(!showLanguageBox)}
                        content={
                            <Stack>
                                {
                                    _LANGUAGE_OPTION.map((item, index) => {
                                        return (
                                            <>
                                                <Button key={index} variant={'link'} onClick={() => {
                                                    onChangeLanguage(item.value);
                                                }}>
                                                    <Flex align={'center'} w='full' h='2.5rem' paddingInline={5}>
                                                        <Text fontSize={'md'} as='b'>{t(item.label)}</Text>
                                                        <Spacer />
                                                        <img
                                                            style={{
                                                                width: '30px',
                                                                height: '30px',
                                                                opacity: localStorage.getItem(ConfigEnum._LANGUAGE) == item.value ? 1 : 0.3
                                                            }}
                                                            src={require('../../../assets/images/svg/general-selected.svg').default} />
                                                    </Flex>
                                                </Button>
                                                {
                                                    index + 1 != _LANGUAGE_OPTION.length &&
                                                    <Divider className="divider-horizontal" />
                                                }
                                            </>
                                        )
                                    })
                                }
                            </Stack>
                        } />
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "40rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('SETTINGS')}</Text>
                        </div>
                        <Text as='b' fontSize={'md'}>{t('EDIT_PROFILE')}</Text>
                        <Text color={'#C6D1E2'}>{t('UPDATE_YOUR_PROFILE_INFO_HERE')}</Text>
                        <Card mt={5} mb={8} bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={15} paddingInline={2}>
                            <CardBody>
                                <HStack mb={5}>
                                    <Text fontSize={'md'}>{t('USERNAME')}</Text>
                                    <Spacer />
                                    <Text fontSize={'md'}>{userData?.username}</Text>
                                </HStack>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <HStack spacing={7} mt={2} mb={8}>
                                        <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)} multiple={false}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone" style={{ width: "s20%" }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Image boxSize={20} src={stringIsNullOrEmpty(logoImage) ? require('../../../assets/images/topbar-user-defaultphoto.png') : logoImage} borderRadius={50} border={'2px solid #f8c763'} />
                                                    <Image boxSize={7} style={{ position: "absolute", top: '7.2rem', left: '5rem' }} src={require('../../../assets/images/settings/icon-camera.svg').default} />
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div style={{ width: "80%" }}>
                                            <Text fontSize={'md'} mb={3}>{t('DISPLAY_NAME')}</Text>
                                            <Controller
                                                control={control}
                                                name="alias"
                                                defaultValue={userData?.alias}
                                                render={({ onChange, value, name }) => (
                                                    <>
                                                        <Input
                                                            value={value}
                                                            id={name}
                                                            name={name}
                                                            onChange={(e) =>
                                                                onChange(removeSpecialChar(e.target.value))
                                                            }
                                                            color={'white'}
                                                            _hover={'none'}
                                                            borderRadius={15}
                                                            bg={'linear-gradient(#5a0089,#7c15bd)'}
                                                            borderColor={'transparent'}
                                                            transitionDuration={'normal'}
                                                            fontWeight={'bold'}
                                                            errors={errors}
                                                            patternErrorTxt={t('ALPHANUMERIC_PATTERN')}
                                                            isReadOnly={!stringIsNullOrEmpty(userData?.alias)} />
                                                    </>
                                                )}
                                                rules={{
                                                    required: true,
                                                    maxLength: 32,
                                                    pattern: ValidationPattern._ALPHANUMBERIC_REGEX
                                                }}
                                            />
                                        </div>
                                    </HStack>
                                    <Button
                                        w={'100%'} color={'white'} height={50} padding={'20px 140px'} borderRadius={15} mb={2} backgroundImage={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : ''} background={activeBtn() ? '' : '#131825'}
                                        type="submit">{t('SUBMIT')}</Button>
                                </form>
                            </CardBody>
                        </Card>
                        <Text as='b' fontSize={'md'}>{t('BANK_DETAILS')}</Text>
                        <Card mt={5} mb={8} bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={15} paddingInline={2}>
                            <CardBody>
                                <Text fontSize={'md'} mb={3}>{t('BENEFICIARY_NAME')}</Text>
                                <Input
                                    value={bankHolderName}
                                    color={'white'}
                                    borderRadius={15}
                                    bg={'linear-gradient(#5a0089,#7c15bd)'}
                                    borderColor={'transparent'}
                                    _hover={'none'}
                                    fontWeight={'bold'}
                                    errors={errors}
                                    isReadOnly={true} />
                                {
                                    !stringIsNullOrEmpty(bankHolderName) &&
                                    <Text mt={1} fontSize={'sm'} color={'red'}>{t('YOUR_BANK_ACCOUNT_INFORMATION_WILL_BE_STORED_WITH_US_SECURELY')}</Text>
                                }
                                <Stack mt={6} mb={6} spacing={6} maxH={'12rem'} overflow={'auto'}>
                                    {
                                        userBankList.length > 0 &&
                                        userBankList.map((item, index) => {
                                            return (
                                                <>
                                                    <HStack cursor={'pointer'}>
                                                        <HStack onClick={() => _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: bankHolderName, userBankId: item?.id, isNewUser: false })}>
                                                        <Image boxSize={'40px'} backgroundColor={'white'} borderRadius={50} src={item.bankLogo} mr={5} />
                                                        <div>
                                                            {
                                                                type !== 'landscape-primary' && item.bankName.length >= 20 ?
                                                                    <Marquee>
                                                                        <Text fontSize={'sm'}>{item.bankName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                                                                    </Marquee> :
                                                                    <Text fontSize={'sm'}>{t(item.bankName)}</Text>
                                                            }
                                                            <Text fontSize={'sm'} color={'#f8c763'}>{t(item.bankAccountNumber)}</Text>
                                                        </div>
                                                        </HStack>
                                                        <Spacer onClick={() => _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: bankHolderName, userBankId: item?.id, isNewUser: false })}/>
                                                        {
                                                            userBankList.length > 0 &&
                                                            <Button variant={'link'} onClick={() => {
                                                                _dispatch(
                                                                    showCustomDialog({
                                                                        title: t('ARE_YOU_SURE'),
                                                                        content: t("ARE_YOU_SURE_YOU_WANT_TO_DELETE", { bank: `${item.bankAccountNumber} (${item.bankName})` }),
                                                                        horizontalBtn: false,
                                                                        onConfirm: () => {
                                                                            deleteUserBankAccount(item?.id);
                                                                        },
                                                                        onCancel: () => { }
                                                                    })
                                                                );
                                                            }}>
                                                                <Image boxSize={'35px'} src={require('../../../assets/images/svg/general-delete.svg').default} />
                                                            </Button>
                                                        }
                                                        <img src={require('../../../assets/images/svg/general-icon-arrow.svg').default} onClick={() => _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: bankHolderName, userBankId: item?.id, isNewUser: false })} />
                                                    </HStack>
                                                </>
                                            )
                                        })
                                    }
                                </Stack>
                                <Button
                                    w={'100%'} color={'white'} padding={'23px 140px'} borderRadius={15} mb={2} backgroundImage={'linear-gradient(#e5b823, #8d7216)'}
                                    onClick={() => _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: userBankList.length > 0 ? userBankList[0]['bankAccountHolderName'] : '', isNewUser: false })}>
                                    {t('ADD_BANK_ACCOUNT')}
                                </Button>
                            </CardBody>
                        </Card>
                        <Text as='b' fontSize={'md'}>{t('LANGUAGE')}</Text>
                        <Card mt={5} mb={5} bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={15} paddingInline={2}>
                            <CardBody>
                                <HStack spacing={4} marginBlock={2}>
                                    {
                                        _LANGUAGE_OPTION.map((item, index) => {
                                            return (
                                                <Button w={'11rem'} color={'white'} fontWeight={'normal'} paddingBlock={7} bg={'#400164 '} borderRadius={'15'}
                                                    backgroundImage={localStorage.getItem(ConfigEnum._LANGUAGE) == item.value ? 'linear-gradient(#5a0089,#7c15bd,#5a0089)' : ''} onClick={() => onChangeLanguage(item.value)}>{item.label}</Button>
                                            )
                                        })
                                    }
                                </HStack>
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}