import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import {
    Box,
    Tabs,
    TabList,
    Tab,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { showCustomDialog } from "../../../../application/action/app_action";
import { stringIsNullOrEmpty } from "../../../../common/util";
import { ApiKey, BG_TYPE } from "../../../../common/constant";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PLAYER_ID = _location?.state?.playerId;
    const [packageList, setPackageList] = useState([]);
    const [tabRoutes, setTabRoutes] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState();

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PLAYER_ID)) {
            _router.back();
        }
        else {
            getPackageList();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (packageList) {
            if (tabRoutes.length === 0) {
                let tempRoutes = [];
                Object.keys(packageList).map(packageKey => {
                    tempRoutes.push({ key: packageKey, title: packageKey });
                });

                setTabRoutes(tempRoutes);

                if (tempRoutes.length > 0) {
                    setSelectedPackage(tempRoutes[0]['key']);
                }
            }
        }
    }, [packageList]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getPackageList() {
        try {
            var responseJson = await ApiEngine.get(`${Routes._GET_PACKAGE_LIST}?playerId=${_PLAYER_ID}`)

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson.Message ?? responseJson.message ?? responseJson.error ?? responseJson.title;
            }

            setPackageList(responseJson[ApiKey._API_DATA_KEY]);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderPackageInfo = ({ packageKey }) => {
        const _PACKAGE = packageList[packageKey];

        return (
            <>
                <TableContainer mb={5}>
                    <Table variant={'swc'}>
                        <Thead>
                            <Tr>
                                <Th borderLeftWidth={0} borderTopLeftRadius={10}>Prize</Th>
                                <Th>Big</Th>
                                <Th>Small</Th>
                                <Th>4A</Th>
                                <Th>4SB</Th>
                                <Th>4SC</Th>
                                <Th>4SD</Th>
                                <Th>4SE</Th>
                                <Th borderRightWidth={0} borderTopRightRadius={10}>4C</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>1st</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Big')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Small')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4A')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SB')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SC')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SD')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SE')?.firstPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '4C')?.firstPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>2nd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Big')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Small')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4A')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SB')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SC')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SD')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SE')?.secondPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '4C')?.secondPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>3rd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Big')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Small')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4A')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SB')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SC')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SD')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SE')?.thirdPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '4C')?.thirdPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>Special</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Big')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == 'Small')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4A')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SB')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SC')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SD')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '4SE')?.specialPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '4C')?.specialPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'} borderBottomLeftRadius={10} borderBottom={0}>Consolation</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == 'Big')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == 'Small')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '4A')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '4SB')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '4SC')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '4SD')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '4SE')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottomRightRadius={10} borderBottom={0} borderRight={0}>{_PACKAGE.find(p => p.betType == '4C')?.consolationPrize ?? '-'}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
                <TableContainer mb={5}>
                    <Table variant={'swc'}>
                        <Thead>
                            <Tr>
                                <Th borderLeftWidth={0} borderTopLeftRadius={10}>Prize</Th>
                                <Th>3A</Th>
                                <Th>3B</Th>
                                <Th>3C</Th>
                                <Th>3SB</Th>
                                <Th>3SC</Th>
                                <Th>3SD</Th>
                                <Th borderRightWidth={0} borderTopRightRadius={10}>3SE</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>1st</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3A')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3B')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3C')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SB')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SC')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SD')?.firstPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '3SE')?.firstPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>2nd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3A')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3B')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3C')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SB')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SC')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SD')?.secondPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '3SE')?.secondPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>3rd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3A')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3B')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3C')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SB')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SC')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SD')?.thirdPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '3SE')?.thirdPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>Special</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3A')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3B')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3C')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SB')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SC')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '3SD')?.specialPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '3SE')?.specialPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'} borderBottomLeftRadius={10} borderBottom={0}>Consolation</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '3A')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '3B')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '3C')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '3SB')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '3SC')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '3SD')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottomRightRadius={10} borderBottom={0} borderRight={0}>{_PACKAGE.find(p => p.betType == '3SE')?.consolationPrize ?? '-'}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
                <TableContainer mb={5}>
                    <Table variant={'swc'}>
                        <Thead>
                            <Tr>
                                <Th borderLeftWidth={0} borderTopLeftRadius={10}>Prize</Th>
                                <Th>2A</Th>
                                <Th>2B</Th>
                                <Th>2C</Th>
                                <Th>2SB</Th>
                                <Th>2SC</Th>
                                <Th>2SD</Th>
                                <Th borderRightWidth={0} borderTopRightRadius={10}>2SE</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>1st</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2A')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2B')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2C')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SB')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SC')?.firstPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SD')?.firstPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '2SE')?.firstPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>2nd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2A')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2B')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2C')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SB')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SC')?.secondPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SD')?.secondPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '2SE')?.secondPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>3rd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2A')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2B')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2C')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SB')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SC')?.thirdPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SD')?.thirdPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '2SE')?.thirdPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>Special</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2A')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2B')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2C')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SB')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SC')?.specialPrize ?? '-'}</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '2SD')?.specialPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '2SE')?.specialPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'} borderBottomLeftRadius={10} borderBottom={0}>Consolation</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '2A')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '2B')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '2C')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '2SB')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '2SC')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '2SD')?.consolationPrize ?? '-'}</Td>
                                <Td borderBottomRightRadius={10} borderBottom={0} borderRight={0}>{_PACKAGE.find(p => p.betType == '2SE')?.consolationPrize ?? '-'}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table variant={'swc'}>
                        <Thead>
                            <Tr>
                                <Th borderLeftWidth={0} borderTopLeftRadius={10}>Prize</Th>
                                <Th>5D</Th>
                                <Th borderRightWidth={0} borderTopRightRadius={10}>6D</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>1st</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '5D')?.firstPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '6D')?.firstPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>2nd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '5D')?.secondPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '6D')?.secondPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>3rd</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '5D')?.thirdPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '6D')?.thirdPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>4th</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '5D')?.forthPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '6D')?.forthPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'}>5th</Td>
                                <Td>{_PACKAGE.find(p => p.betType == '5D')?.fifthPrize ?? '-'}</Td>
                                <Td borderRight={0}>{_PACKAGE.find(p => p.betType == '6D')?.fifthPrize ?? '-'}</Td>
                            </Tr>
                            <Tr>
                                <Td backgroundColor={'#EBFFE3'} borderBottomLeftRadius={10} borderBottom={0}>6th</Td>
                                <Td borderBottom={0}>{_PACKAGE.find(p => p.betType == '5D')?.sixthPrize ?? '-'}</Td>
                                <Td borderBottomRightRadius={10} borderBottom={0} borderRight={0}>{_PACKAGE.find(p => p.betType == '6D')?.sixthPrize ?? '-'}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    return (
        <div>
            <GeneralTopBar title={'PACKAGE_LIST'} bgColor="#ebffe3" blackTitle titleAlignLeft useSimpleBackIcon />
            <Box marginInline={'-3.75vw'} bg={'white'} textAlign={'center'} paddingInline={1} mb={5}>
                <Tabs isFitted size='sm' variant='enclosed'>
                    <TabList>
                        {
                            tabRoutes.map((item) => {
                                return (
                                    <Tab
                                        p={2.5}
                                        fontWeight={'bold'}
                                        fontSize={'md'}
                                        color={'#B0B0B1'}
                                        _selected={{ color: '#08CD34', borderBottomWidth: 2, borderBottomColor: '#08CD34' }}
                                        onClick={() => setSelectedPackage(item.key)}>
                                        {t(item.title)}
                                    </Tab>
                                )
                            })
                        }
                    </TabList>
                </Tabs>
            </Box>
            {
                selectedPackage && <RenderPackageInfo packageKey={selectedPackage} />
            }
        </div>
    )
}