import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Button, AbsoluteCenter, Textarea } from "@chakra-ui/react";
import { PageSettings } from "../../../../routes/setting";
import { Pages } from "../../../../routes/page";
import { isObjectEmpty, share, stringIsNullOrEmpty } from "../../../../common/util";
import { BG_TYPE } from "../../../../common/constant";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _ORDER_RESPONSE = _location?.state?.orderResponse;
    const _PLAYER_ID = _location?.state?.playerId;

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_ORDER_RESPONSE) || stringIsNullOrEmpty(_PLAYER_ID)) {
            _router.back();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    return (
        <div className='body-content'>
            <AbsoluteCenter w={'80%'}>
                <img src={require('./assets/images/successful.svg').default} />
                <Text fontWeight={'bold'} fontSize={'md'} textAlign={'center'}>{t('ORDER_SUCCESSFULLY')}</Text>
                <Text fontSize={'sm'} textAlign={'center'}>{t('ORDER_SUCCESSFUL_DESCRIPTION')}</Text>
                <Button
                    size={'md'}
                    variant='solid'
                    bg='#08CD34'
                    borderRadius={50}
                    p={2}
                    position={'absolute'}
                    bottom={65}
                    right={5}
                    zIndex={99}
                    onClick={() => { share(_ORDER_RESPONSE.receipt); }}>
                    <img src={require('./assets/images/share.svg').default} />
                </Button>
                <Textarea bg={'#F5F4FA'} isReadOnly rows={10} value={'Order Response:\n\n' + _ORDER_RESPONSE.receipt}></Textarea>
                <Button
                    mt={2}
                    w='full'
                    variant='solid'
                    bg='white'
                    color='#08CD34'
                    borderRadius={20}
                    onClick={() => _router.navigate(Pages.SWC_ORDER_HISTORY, { playerId: _PLAYER_ID}, true)}>
                    {t('DONE')}
                </Button>
            </AbsoluteCenter>
        </div>
    )
}