import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import {
    Text,
    Image,
    Button,
    Center,
    Flex,
    Stack,
    HStack
} from '@chakra-ui/react';
import { ValidationPattern, ApiKey } from "../../../common/constant";
import { checkBrowser, stringIsNullOrEmpty } from "../../../common/util";
import { Pages } from "../../../routes/page";
import QrReader from 'react-qr-scanner'
import CustomInput from "../../../component/input";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { useNavigate } from "react-router";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue } = useForm();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _dispatch = useDispatch();
    const [isScanned, setIsScanned] = useState(false);
    const _DELAY_IN_MS = 500;

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function qrDetectedHandler(decodedQr) {
        if (!stringIsNullOrEmpty(decodedQr?.text) && !isScanned) {
            setIsScanned(true);
            getUser(decodedQr.text);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            getUser(data.phoneNo);
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err,
            }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getUser(data) {
        var responseJson = await ApiEngine.get(Routes._SCAN_MEMBER_QR + '?username=' + data);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._SEND_E_CREDIT_DETAIL, { data: responseJson[ApiKey._API_DATA_KEY] }, true);
        }
        else {
            _dispatch(showCustomDialog({
                success: false,
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: () => setIsScanned(false)
            }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div className="qr-wrapper">
                <QrReader
                    legacyMode={true}
                    constraints={{
                        video: { facingMode: "environment" }
                    }}
                    style={{ width: "100%", height: '170vw', objectFit: "cover" }}
                    delay={_DELAY_IN_MS}
                    onError={(err) => {
                        _dispatch(showCustomDialog({
                            success: false,
                            content: err
                        }));
                    }}
                    onScan={qrDetectedHandler}
                />
                <div className="qr-scanner-overlay">
                    <div className={`scan-area`}></div>
                    <Center>
                        <Stack style={{ position: 'absolute', top: '15vw' }}>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Flex>
                                    <Controller
                                        control={control}
                                        name="phoneNo"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                label='ENTER_MOBILE_NUMBER'
                                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                                border='2px solid #e2a570 !important'
                                                borderRadius='10px !important'
                                                inputType="tel"
                                                errors={errors}
                                                patternErrorTxt={t(
                                                    'PLEASE_FILL_VALID_PHONE_NO'
                                                )} />
                                        )}
                                        rules={{
                                            required: true,
                                            pattern:
                                                ValidationPattern._PHONE_NUMBER_REGEX
                                        }}
                                    />
                                    <Button type="submit" variant='link' alignSelf={'center'} ml={5}>
                                        <Image
                                            boxSize={'35px'}
                                            transform={'rotate(180deg)'}
                                            src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                                    </Button>
                                </Flex>
                            </form>
                            <Text textAlign={'center'} fontSize={'md'} fontWeight={'bold'}>{t('OR')}</Text>
                        </Stack>
                    </Center>
                    <Center>
                        <Image
                            boxSize={'50px'}
                            style={{ position: 'absolute', bottom: '10vw' }}
                            src={require('../../../assets/images/svg/general-close.svg').default}
                            onClick={() => _router.back()} />
                    </Center>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('SEND_CREDIT')}</Text>
                        </div>
                        <HStack spacing={8} mb={6}>
                            <Stack w={'20rem'}>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <Flex w={'100%'}>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    label='ENTER_MOBILE_NUMBER'
                                                    background="linear-gradient(#5a0089,#7c15bd) !important"
                                                     borderRadius="15px !important"
                                                    inputType="tel"
                                                    errors={errors}
                                                    patternErrorTxt={t(
                                                        'PLEASE_FILL_VALID_PHONE_NO'
                                                    )} />
                                            )}
                                            rules={{
                                                required: true,
                                                pattern:
                                                    ValidationPattern._PHONE_NUMBER_REGEX
                                            }}
                                        />
                                        <Button type="submit" variant='link' alignSelf={'center'} ml={3}>
                                            <Image
                                                boxSize={'30px'}
                                                transform={'rotate(180deg)'}
                                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                                        </Button>
                                    </Flex>
                                </form>
                            </Stack>
                            <Text textAlign={'center'} fontSize={'md'} fontWeight={'bold'}>{t('OR')}</Text>
                            <div style={{
                                width: '25rem',
                                background: 'linear-gradient(to right, #f8c763 4px, transparent 4px) 0 0, linear-gradient(to right, #f8c763 4px, transparent 4px) 0 100%, linear-gradient(to left, #f8c763 4px, transparent 4px) 100% 0, linear-gradient(to left, #f8c763 4px, transparent 4px) 100% 100%, linear-gradient(to bottom, #f8c763 4px, transparent 4px) 0 0, linear-gradient(to bottom, #f8c763 4px, transparent 4px) 100% 0, linear-gradient(to top, #f8c763 4px, transparent 4px) 0 100%, linear-gradient(to top, #f8c763 4px, transparent 4px) 100% 100%',
                                backgroundRepeat: 'no-repeat', backgroundSize: '20px 20px', padding:'10px'
                            }}>
                                <QrReader
                                    legacyMode={true}
                                    constraints={{
                                        video: { facingMode: "environment" }
                                    }}
                                    style={{ width: "100%", objectFit: "cover" }}
                                    delay={_DELAY_IN_MS}
                                    onError={(err) => {
                                        _dispatch(showCustomDialog({
                                            success: false,
                                            content: err
                                        }));
                                    }}
                                    onScan={qrDetectedHandler}
                                />
                            </div>
                        </HStack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}