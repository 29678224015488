import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    HStack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Center
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../hooks/useRouter";
import { showCustomDialog } from "../../application/action/app_action";
import GeneralTopBar from "../../component/general-top-bar";
import { ApiKey, BG_TYPE, ValidationPattern, _LANGUAGE_OPTION, _RECEIPT_WATERMARK, ScreenWidth } from "../../common/constant";
import { useForm, Controller } from 'react-hook-form';
import CustomInput from "../../component/input";
import ApiEngine from "../../common/api_engine";
import Routes from "../../common/api_routes";
import { checkBrowser, isObjectEmpty, stringIsNullOrEmpty } from "../../common/util";
import { isMobile } from "react-device-detect";
import { Pages } from "../../routes/page";
import Footer from "./footer";
import Header from "./header";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { control, handleSubmit, errors, setValue, clearErrors, watch } = useForm();
    const { i18n, t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const doPasswordMatch = (value) => {
        if (value) {
            clearErrors(["confirmNewPassword", "newPassword"]);
            return true;
        }

        return false;
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            window.scrollTo(0, 0);
            var responseJson = await ApiEngine.get(`${Routes._FORGET_PASSWORD_URL}?phonenumber=${data.phoneNo}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            if (responseJson[ApiKey._API_DATA_KEY] == null || isObjectEmpty(responseJson[ApiKey._API_DATA_KEY]) ||
                (Object.keys(responseJson[ApiKey._API_DATA_KEY]).length === 1 && responseJson[ApiKey._API_DATA_KEY].hasOwnProperty("whatsappLinks"))) {
                _dispatch(showCustomDialog({
                    success: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: "PASSWORD_ALREADY_RESET_CS",
                    confirmTxt: "CUSTOMER_SERVICE",
                    cancelTxt: "OK",
                    onConfirm: () => {
                        window.open(responseJson[ApiKey._API_DATA_KEY]['whatsappLinks'], "_blank")
                        _router.back();
                    },
                    onCancel: () => {
                        _router.back();
                    },
                    horizontalBtn: false
                }));
            }
            else {
                _dispatch(showCustomDialog({
                    success: false,
                    content: t('RESET_PASSWORD_ATTEMPT_CHARGE', { attempt: responseJson[ApiKey._API_DATA_KEY].attempt, charge: responseJson[ApiKey._API_DATA_KEY].charge }),
                    onConfirm: () => {
                        resetPasswordWithCharges(data.phoneNo);
                    },
                    onCancel: () => { },
                    horizontalBtn: false
                }));
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author : Juin
    /// </summary>
    async function resetPasswordWithCharges(phoneNumber) {
        try {
            var responseJson = await ApiEngine.get(`${Routes._FORGET_PASSWORD_URL}?phonenumber=${phoneNumber}&chargeConfirmation=true`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showCustomDialog({
                success: responseJson[ApiKey._API_SUCCESS_KEY],
                content: "PASSWORD_ALREADY_RESET_CS",
                confirmTxt: "CUSTOMER_SERVICE",
                cancelTxt: "OK",
                onConfirm: () => {
                    window.open(responseJson[ApiKey._API_DATA_KEY]['whatsappLinks'], "_blank")
                    _router.back();
                },
                onCancel: () => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _router.back();
                    }
                },
                horizontalBtn: false
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('phoneNo')));
    }

    if (!checkBrowser()) {
        return (
            <div style={{ width: "100%", paddingInline: "2rem", position: "absolute", margin: "auto", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Center w={'fit-content'} margin={'auto'} bg={'#a84df0'} padding={3} borderTopRadius={50}>
                    <Image zIndex={99} boxSize={16} objectFit='contain' src={require('../../assets/icon2.png')} alt='ePayPro' />
                </Center>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Card backgroundImage={'linear-gradient(#a84df0, #7415aa)'} borderRadius={30} marginTop={-50} paddingTop={30}>
                        <CardBody paddingInline={7}>
                            <Text color={'#f8c763'} mb={5} fontSize={'2xl'} fontWeight={'bold'}>{t('FORGOT_PASSWORD')}</Text>
                            <Controller
                                control={control}
                                name="phoneNo"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        inputMode='tel'
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        label='ENTER_MOBILE_NUMBER'
                                        placeHolder={t('ENTER_MOBILE_NUMBER')}
                                        inputType='tel'
                                        border="1px solid #f8c763 !important"
                                        borderRadius="10px !important"
                                        background="linear-gradient(#5b0e89,#7e14bf) !important"
                                        boxShadow="none !important"
                                        errors={errors}
                                        patternErrorTxt={t('PLEASE_FILL_VALID_PHONE_NO')} />
                                )}
                                rules={{ required: true, pattern: ValidationPattern._PHONE_NUMBER_REGEX }}
                            />
                        </CardBody>
                        <Divider color={'#9a46cd'} borderWidth={2} w={'none'} />
                        <CardFooter justify={'center'} marginBlock={3}>
                            <Text fontSize={'sm'} color={'app.grey'}>{t('ALREADY_HAVE_AN_ACCOUNT')}</Text>
                            <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._LOGIN, {}, true) }}><Text fontSize={'sm'} color={'#f8c763'} decoration='underline'>{t('SIGN_IN_NOW')}</Text></Button>
                        </CardFooter>
                    </Card>
                    <Center>
                        <Button type="submit" mt={12} bg={'#6715ac'} borderRadius={50} w={'fit-content'} padding={'30px 15px'} border={'3px solid #9e57d9'}>
                            <Image boxSize={8} src={require('../../assets/images/svg/icon-tick.svg').default} />
                        </Button>
                    </Center>
                </form>
            </div>
        )
    } else {
        return (
            <>

                <Center margin="100px auto 0 auto">
                    <HStack spacing='100px'>
                        <div>
                            <Image boxSize='250px' objectFit='contain' src={require('../../assets/icon2.png')} alt='ePayPro' />
                        </div>
                        <div>
                            <Text fontSize='2xl' color={'#f8c763'} fontWeight={'bold'} marginBottom={"5px"}>{t('FORGOT_PASSWORD?')}</Text>
                            <Text fontSize='md' color={"white"} marginBottom={"35px"}>{t('ENTER_YOUR_MOBILE_NUMBER_AND_WE_WILL_SEND_YOU_TEMP_PIN')}</Text>
                            <Card border="1px solid white" borderRadius="15px" background={'linear-gradient(#ac4cfe,#7416ab)'} padding="12px 5px" minWidth="480px">
                                <CardBody>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    inputMode='tel'
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    label='ENTER_MOBILE_NUMBER'
                                                    inputType='tel'
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    border="1px solid #f8c763 !important"
                                                    background="linear-gradient(#5a0089,#8015c3) !important"
                                                    marginBottom="10px"
                                                    errors={errors}
                                                    patternErrorTxt={t('PLEASE_FILL_VALID_PHONE_NO')} />
                                            )}
                                            rules={{ required: true, pattern: ValidationPattern._PHONE_NUMBER_REGEX }}
                                        />
                                        <Button style={activeBtn() ? { borderRadius: "10px", background: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#ba5fee", color: "white", padding: "25px 0px" }} w='100%' type='submit'>{t('RESET_PASSWORD')}</Button>
                                    </form>
                                </CardBody>
                                <CardFooter justify={'center'}>
                                    <Text color={'app.grey'}>{t('ALREADY_HAVE_AN_ACCOUNT')}</Text>
                                    <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._LOGIN) }}><Text color={'#f8c763'} decoration='underline' fontWeight='normal' >{t('LOGIN_NOW')}</Text></Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </HStack>
                </Center>
                <Footer />
            </>
        )
    }
}