import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PageSettings } from "../../routes/setting";
import {
    Text, Button, Center, HStack, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverArrow, useDisclosure, Grid, GridItem, Stack, Image, Spacer,
    Flex, Divider, Spinner
} from '@chakra-ui/react';
import { ValidationPattern, _LANGUAGE_OPTION, _RECEIPT_WATERMARK, Language, SessionKey, ScreenWidth } from "../../common/constant";
import { ConfigEnum } from "../../common/config";
import { useRouter } from "../../hooks/useRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { Pages } from "../../routes/page";
import { useDispatch, useSelector } from "react-redux";
import { isBrowser } from "react-device-detect";
import { stringIsNullOrEmpty, numberWithCurrencyFormat, checkBrowser } from "../../common/util";
import { getMemberDetails } from "../../application/action/auth_action";
import { showCustomDialog } from "../../application/action/app_action";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { i18n, t } = useTranslation();
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _navigate = useNavigate();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _USER_DATA = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: Juin
    /// </summary>
    function onChangeLanguage(language) {
        i18n.changeLanguage(language);
        localStorage.setItem(ConfigEnum._LANGUAGE, language);
        onClose();
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        retrieveMemberDetails();
        onChangeLanguage(localStorage.getItem(ConfigEnum._LANGUAGE) ?? Language._ENGLISH);
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const retrieveMemberDetails = async () => {
        try {
            setIsLoading(true);
            _dispatch(getMemberDetails());
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        finally {
            setIsLoading(false);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function InfoSection({ credit, reward, image, username, alias, experienceDetail }) {
        return (
            <HStack spacing={2} mr={'-12px'}>
                <HStack spacing={0.5} cursor={'pointer'} onClick={() => { _navigate(Pages._REWARD_HISTORY) }}>
                    <Image boxSize={9} src={require('../../assets/images/topbar-icon-gift.png')} />
                    <Stack spacing={0} textAlign={'left'}>
                        <Text wordBreak={window.innerWidth < 1050 && localStorage.getItem(ConfigEnum._LANGUAGE) == Language._BAHASA_MELAYU ? 'break-word' : ''} fontSize={'sm'} lineHeight={1}>{t('REWARD')}</Text>
                        <Text fontSize={'sm'} as='b'>{numberWithCurrencyFormat(reward ?? 0)}</Text>
                    </Stack>
                </HStack>
                <HStack spacing={0.5} mr={2}>
                    <Image boxSize={9} src={require('../../assets/images/topbar-icon-credit.png')} />
                    <Stack spacing={0} textAlign={'left'}>
                        <Text fontSize={'sm'} lineHeight={1}>{t('ECREDIT')}</Text>
                        <Text fontSize={'sm'} as='b'>{numberWithCurrencyFormat(credit ?? 0)}</Text>
                    </Stack>
                </HStack>
                <Flex>
                    <Button variant={'link'} mt={'-3px'} minW={'7.25rem'} onClick={() => {
                        _router.navigate(Pages._PROFILE);
                    }}>
                        <Flex>
                            <Stack align={'flex-end'} spacing={0}>
                                <Text fontSize={'sm'} fontWeight={'normal'}>{alias ?? '-'}</Text>
                                <Text fontSize={'sm'} as='b'>{username ?? '-'}</Text>
                            </Stack>
                            <div className="profile-picture" style={{ marginLeft: "8px" }}>
                                <Image className="rank-image" boxSize={'28px'} src={experienceDetail?.innerFrame} />
                                <Image className="profile-image" boxSize={'22px'} borderRadius={25} src={image ? image : require('../../assets/images/topbar-user-defaultphoto.png')} />
                                <Image className="badge-image" boxSize={'25px'} src={experienceDetail?.badgeFile} />
                                <span className="red-dot"></span>
                            </div>
                        </Flex>
                    </Button>
                </Flex>
                <Flex>
                    <Button disabled={isLoading} variant={'link'} minW={'2rem'} onClick={() => { retrieveMemberDetails(); }}>
                        {
                            isLoading ?
                                <Spinner color="white" size={'sm'} /> :
                                <Image boxSize={8} src={require('../../assets/images/topbar-icon-refresh.png')} />
                        }
                    </Button>
                    <Button variant={'link'} minW={'2rem'} onClick={() => {
                        _router.navigate(Pages._REWARD_TO_CREDIT, {
                            memberGuid: _USER_DATA?.guid,
                            currentWalletCredit: _USER_DATA?.credit
                        });
                    }}>
                        <Image boxSize={8} src={require('../../assets/images/topbar-icon-transfer.png')} />
                        {_USER_DATA.reward ? <span className="red-dot"></span> : ''}
                    </Button>
                </Flex>
            </HStack>
        )
    }

    // if (isBrowser && _location.pathname == Pages._REGISTER || _location.pathname == Pages._LOGIN || _location.pathname == Pages._FORGOT_PASSWORD) {
    if (!_IS_LOGGED_IN && checkBrowser()) {
        return (
            <>
                <Grid templateColumns={'repeat(1, 1fr)'}>
                    <GridItem>
                        <Popover
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}>
                            <PopoverTrigger>
                                <Button float={'right'} color={'white'} pt={'50px'} pr={'80px'}>
                                    {_LANGUAGE_OPTION.find(l => l.value == localStorage.getItem(ConfigEnum._LANGUAGE))?.prefix}
                                    <img src={require('../../assets/images/svg/white-arrow.svg').default} style={{ transform: 'rotate(90deg)', marginLeft: '5px' }} />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="app-popover" mr={'60px'} mt={'10px'} width={'max-content'}>
                                <PopoverArrow />
                                {
                                    _LANGUAGE_OPTION.map((item, index) => {
                                        if (item.value != localStorage.getItem(ConfigEnum._LANGUAGE)) {
                                            return (
                                                <PopoverHeader key={index} textAlign={'right'} onClick={() => onChangeLanguage(item.value)}>
                                                    <Text cursor={'pointer'}>{item.label}</Text>
                                                </PopoverHeader>
                                            )
                                        }
                                    })
                                }
                            </PopoverContent>
                        </Popover>
                    </GridItem>
                </Grid>
            </>
        )
    } else {
        return (
            <div style={{ width: "100%", position: "fixed", top: "0", zIndex: "99999", background: "linear-gradient(#ac4afd,#7416a9)", textAlign: "center" }}>
                <HStack paddingInline={6} paddingBlock={4} spacing={6} maxW={'100rem'} margin={'auto'}>
                    <Image boxSize={16} mr={window.innerWidth > 1050 && localStorage.getItem(ConfigEnum._LANGUAGE) != Language._BAHASA_MELAYU ? 2 : 0} src={require('../../assets/icon2.png')} />
                    <Text fontSize={'md'} as={'b'} cursor={'pointer'} onClick={() => _navigate(Pages._GAMES)}>{t('GAMES')}</Text>
                    <Text fontSize={'md'} as={'b'} cursor={'pointer'} onClick={() => _navigate(Pages._TRANSACTIONS)}>{t('TRANSACTION')}</Text>
                    <Text fontSize={'md'} as={'b'} cursor={'pointer'} onClick={() => _navigate(Pages._CHAT)}>{t('CHAT')}</Text>
                    <Text fontSize={'md'} as={'b'} cursor={'pointer'} onClick={() => _navigate(Pages._BONUS)}>{t('BONUS')}</Text>
                    <Text w={window.innerWidth < 1050 && localStorage.getItem(ConfigEnum._LANGUAGE) != Language._CHINESE ? 'min-content' : ''} textAlign={'left'} fontSize={'md'} as={'b'} cursor={'pointer'} onClick={() => _navigate(Pages._EPAY)}>{t('RELOAD_SERVICE')}</Text>
                    {localStorage.getItem(ConfigEnum._LANGUAGE) == Language._BAHASA_MELAYU && window.innerWidth < 1050 ? <></> : <Spacer w={0} />}
                    <InfoSection credit={_USER_DATA?.credit} reward={_USER_DATA?.reward}
                        username={_USER_DATA?.username}
                        alias={_USER_DATA?.alias}
                        image={_USER_DATA?.profileImage}
                        experienceDetail={_USER_DATA?.experienceDetail} />
                    <Popover
                        isOpen={isOpen}
                        onOpen={onOpen}
                        onClose={onClose}>
                        <PopoverTrigger>
                            <Button paddingInlineStart={3} paddingInlineEnd={3} color={'white'}>
                                {_LANGUAGE_OPTION.find(l => l.value == localStorage.getItem(ConfigEnum._LANGUAGE))?.prefix}
                                <img src={require('../../assets/images/svg/white-arrow.svg').default} style={{ transform: 'rotate(90deg)', marginLeft: '5px' }} />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="app-popover" width={'max-content'} transform={'none'}>
                            <PopoverArrow />
                            {
                                _LANGUAGE_OPTION.map((item, index) => {
                                    if (item.value != localStorage.getItem(ConfigEnum._LANGUAGE)) {
                                        return (
                                            <PopoverHeader key={index} textAlign={'right'} borderColor={'#f8c763'} onClick={() => onChangeLanguage(item.value)}>
                                                <Text cursor={'pointer'}>{item.label}</Text>
                                            </PopoverHeader>
                                        )
                                    }
                                })
                            }
                        </PopoverContent>
                    </Popover>
                </HStack>
            </div>
        )
    }
}