import React, { useEffect, createRef } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import {
    Card,
    CardBody,
    Text,
    Image,
    Divider,
    Center,
    Flex,
    SimpleGrid
} from '@chakra-ui/react';
import { checkBrowser, isObjectEmpty, share } from "../../../common/util";
import { Pages } from "../../../routes/page";
import { ResultImageType } from "../../../common/constant";
import { useScreenshot } from "use-react-screenshot";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    var _router = useRouter();
    var _location = useLocation();
    const _PARAMS = _location?.state;
    const ref = createRef(null);
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back();
        }
    }, [])

    if (!checkBrowser()) {
        return (
            <div className="display-center">
                <Card className="purple-box" borderRadius='20px' ref={ref}>
                    <CardBody style={{ alignSelf: 'center' }}>
                        <Flex align={'center'}>
                            {
                                _PARAMS?.canShare &&
                                <Image
                                    mr={5}
                                    boxSize={'35px'}
                                    src={require('../../../assets/images/share-btn.png')}
                                    onClick={() => {
                                        takeScreenShot(ref.current).then((image) => {
                                            share(t("TRANSFER_RECEIPT"), image);
                                        })
                                    }} />
                            }
                            <Text as='b' color='#f8c763' fontSize='md'>{t(_PARAMS?.headerTitle)}</Text>
                        </Flex>
                    </CardBody>
                    <Divider className="divider-horizontal" />
                    <CardBody style={{ alignSelf: 'center' }}>
                        <Image boxSize={'150px'}
                            src={_PARAMS?.contentImageType == ResultImageType._CONVERT ?
                                require('../../../assets/images/transaction/graphic-wallet-score-convert.png') :
                                ResultImageType._TRANSFER ? require('../../../assets/images/graphic-success-transfer-score.png') :
                                    require("../../../assets/images/transaction/graphic-transfer.png")} />
                        <Text textAlign={'center'}>{t(_PARAMS?.contentTitle)}</Text>
                        <Text textAlign='center' fontWeight='bold' fontSize={'xl'} color='#f8c763'>{_PARAMS.amount}</Text>
                    </CardBody>
                    <Divider className="divider-horizontal" />
                    <CardBody style={{ alignSelf: 'center' }}>
                        <SimpleGrid columns={2} spacing={2}>
                            {
                                _PARAMS.details.map((item, index) => {
                                    return (
                                        <>
                                            <Text fontSize={'sm'}>{t(item.label)} : </Text>
                                            <Text fontSize={'sm'} color='#f8c763'>{t(item.value)}</Text>
                                        </>
                                    )
                                })
                            }
                        </SimpleGrid>
                    </CardBody>
                </Card>
                <Center mt={10}>
                    <Image
                        boxSize={'50px'}
                        src={require('../../../assets/images/svg/general-close.svg').default}
                        onClick={() => _router.navigate(_PARAMS?.navigateToLastPage ?? Pages._DEFAULT, { ..._PARAMS?.prevParams }, true)} />
                </Center>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", minWidth: "25rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _router.navigate(_PARAMS?.navigateToLastPage ?? Pages._DEFAULT, { ..._PARAMS?.prevParams }, true)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={_PARAMS?.canShare ? 0 : 10} fontWeight={'bold'}>{t(_PARAMS?.headerTitle)}</Text>
                            {
                                _PARAMS?.canShare &&
                                <Image
                                display={'inline-block'}
                                float={'right'}
                                cursor={'pointer'}
                                    boxSize={8}
                                    src={require('../../../assets/images/share-btn.png')}
                                    onClick={() => {
                                        takeScreenShot(ref.current).then((image) => {
                                            share(t("TRANSFER_RECEIPT"), image);
                                        })
                                    }} />
                            }
                        </div>
                        <Card ref={ref} bg={'none'} boxShadow={0}>
                            <CardBody bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={15} pb={24} paddingInline={8} paddingBlock={8}>
                                <Center>
                                    <Image boxSize={'150px'}
                                        src={_PARAMS?.contentImageType == ResultImageType._CONVERT ?
                                            require('../../../assets/images/transaction/graphic-wallet-score-convert.png') :
                                            ResultImageType._TRANSFER ? require('../../../assets/images/graphic-success-transfer-score.png') :
                                                require("../../../assets/images/transaction/graphic-transfer.png")} />
                                </Center>
                                <Text textAlign={'center'}>{t(_PARAMS?.contentTitle)}</Text>
                                <Text textAlign='center' fontWeight='bold' fontSize={'xl'} color='#f8c763'>{_PARAMS.amount}</Text>
                                <CardBody bg={'#400164'} borderRadius={15} mt={5} padding={8}>
                                    <SimpleGrid columns={2} spacing={2}>
                                        {
                                            _PARAMS.details.map((item, index) => {
                                                return (
                                                    <>
                                                        <Text fontSize={'sm'}>{t(item.label)} : </Text>
                                                        <Text fontSize={'sm'} color='#f8c763'>{t(item.value)}</Text>
                                                    </>
                                                )
                                            })
                                        }
                                    </SimpleGrid>
                                </CardBody>
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}