import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { Text, Button, Box, Flex, Image, Spacer, Divider, Stack, AbsoluteCenter, Spinner, Card, CardHeader, CardBody, Center } from "@chakra-ui/react";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { ApiKey, _PAGE_SIZE, _EVENT_STATUS } from "../../../common/constant";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Pages } from "../../../routes/page";
import kogData from './kogData.json'
import { ConfigEnum } from "../../../common/config";
import { checkBrowser } from "../../../common/util";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const _INITIAL_PAGE_NO = 1;
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [totalCount, setTotalCount] = useState(0);
    const [event, setEvent] = useState([]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!checkBrowser()) {
            _navigate(Pages._DEFAULT);
        }

        retrieveEvents();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const retrieveEvents = async (pagedNo = _INITIAL_PAGE_NO) => {
        try {
            let responseJson = await ApiEngine.get(
                `${Routes._EVENT}?page=${pagedNo}&pageSize=${_PAGE_SIZE}`,
                {
                    headers: { skipLoading: true }
                }
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY];
                setTotalCount(data['totalCount']);

                if (
                    data &&
                    data['data'].length > 0 &&
                    pageNo > _INITIAL_PAGE_NO
                ) {
                    setEvent([...event, ...data['data']]);
                } else {
                    setEvent(data['data']);
                }
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderItem = ({ event, index }) => {
        const isInactive = [_EVENT_STATUS.PAUSE, _EVENT_STATUS.COMING_SOON].includes(event.status);
        const isClickable = [_EVENT_STATUS.PAUSE, _EVENT_STATUS.ON_GOING, _EVENT_STATUS.ENDED].includes(event.status);
        const BADGE_STYLES = {
            [_EVENT_STATUS.COMING_SOON]: {
                bgColor: '#E9FFE3',
                borderColor: '#8DE578',
                textColor: '#43763E',
                badgeText: 'COMING_SOON'
            },
            [_EVENT_STATUS.ON_GOING]: {
                bgColor: 'linear-gradient(#ba851a,#c39429,#e7c35c)',
                borderColor: '#ffcc41',
                textColor: 'white',
                badgeText: 'ON_GOING'
            },
            [_EVENT_STATUS.PAUSE]: {
                bgColor: '#6F99E0',
                borderColor: '#F5F8FD',
                textColor: '#232E46',
                badgeText: 'PAUSE'
            },
            [_EVENT_STATUS.ENDED]: {
                bgColor: 'linear-gradient(#ba1a1a,#c02323,#e35858)',
                borderColor: '#ff4141',
                textColor: '#fff',
                badgeText: 'ENDED'
            }
        };

        /// <summary>
        /// Author: Juin
        /// </summary>
        const onPress = () => {
            if (isClickable) {
                _router.navigate(Pages._EVENT_DETAILS, { eventId: event?.id });
            }
        };

        return (
            <Stack key={index} mb={5}>
                <Card className={!checkBrowser() ? "purple-box" : ''} borderRadius={20} opacity={isInactive ? 0.6 : 1} onClick={onPress} cursor={'pointer'}>
                    <CardHeader padding={'1rem 1.25rem'} background={'linear-gradient(#9158c4, #4d0689, #7537a9)'} borderTopLeftRadius={20} borderTopRightRadius={20}>
                        <Stack direction={'row'}>
                            <Button
                                disabled
                                size={'xs'}
                                borderRadius={20}
                                borderWidth={1}
                                borderColor={BADGE_STYLES[event.status].borderColor}
                                bg={BADGE_STYLES[event.status].bgColor}
                                color={BADGE_STYLES[event.status].textColor}>
                                {t(BADGE_STYLES[event.status].badgeText)}
                            </Button>
                            <Spacer />
                            <Text color={'#f8c763'}>{event.name}</Text>
                        </Stack>
                    </CardHeader>
                    <CardBody>
                        <Box borderRadius={20} borderWidth={2} borderColor={'white'}>
                            <Image src={event.bannerImage} borderRadius={20} />
                        </Box>
                    </CardBody>
                </Card>
            </Stack>
        )
    }

    if (!checkBrowser()) {
        return (
            <Stack className="body-content">
                <Box marginBlock={2} onClick={() => _router.navigate(Pages._SPIN_EVENT, { dataUrl: `https://lucky-spin.epay4game.com?token=${localStorage.getItem(ConfigEnum._BEARER_TOKEN)}` })}>
                    <Flex alignItems={'center'} gap={1}>
                        <Image boxSize={'35px'} src={require('./../../../assets/images/bottombar/menu-gold-spin.png')} />
                        <Text fontSize={'sm'} color={'#fff'} fontWeight={'750'} ml={1}>{t('LUCKY_SPIN')}</Text>
                        <Spacer />
                        <img style={{ width: '12.5px', height: '12.5px' }} src={require('./../..//../assets/images/svg/white-arrow.svg').default} />
                    </Flex>
                </Box>
                <Divider className="divider-horizontal-dark" marginBlock={'0 !important'} />
                <Box marginBlock={3}>
                    <Flex alignItems={'center'} gap={1}>
                        <Image boxSize={'35px'} src={require('./../../../assets/images/kog.png')} />
                        <Text fontSize={'sm'} color={'#fff'} fontWeight={'750'} ml={1}>{t('KING_OF_GAMBLE')}</Text>
                    </Flex>
                </Box>
                {
                    event.length > 0 ?
                        <div
                            id="scrollableDiv"
                            style={{
                                overflow: "auto",
                                marginBottom: '5rem'
                            }}>
                            <InfiniteScroll
                                style={{ display: "flex", flexDirection: "column" }}
                                dataLength={event.length}
                                next={() => {
                                    let page = pageNo + 1;
                                    setPageNo(page);
                                    retrieveEvents(page);
                                }}
                                hasMore={event.length < totalCount}
                                loader={<Spinner color="white" />}
                            >
                                {
                                    event.map((item, index) => {
                                        return (
                                            <RenderItem
                                                event={item}
                                                index={index}
                                            />
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div> :
                        <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("NO_EVENT_FOUND")}</Text>
                        </AbsoluteCenter>
                }
            </Stack>
        )
    } else {
        return (
            <>
                <Center mt={16} mb={10}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "50rem" }}>
                        <Center>
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'}>{t('BONUS')}</Text>
                        </Center>
                        <Stack className="body-content" spacing={5} mt={6}>
                            <Box bg={'linear-gradient(#ac4cfe,#7416ab)'} padding={4} borderRadius={16} cursor={'pointer'} onClick={() => _router.navigate(Pages._SPIN_EVENT, { dataUrl: `https://lucky-spin.epay4game.com?token=${localStorage.getItem(ConfigEnum._BEARER_TOKEN)}` })}>
                                <Flex alignItems={'center'} gap={2}>
                                    <Image boxSize={'35px'} src={require('./../../../assets/images/bottombar/menu-gold-spin.png')} />
                                    <Text fontSize={'sm'} color={'#fff'} fontWeight={'750'} ml={1}>{t('LUCKY_SPIN')}</Text>
                                    <Spacer />
                                    <img style={{ width: '12.5px', height: '12.5px' }} src={require('./../..//../assets/images/svg/white-arrow.svg').default} />
                                </Flex>
                            </Box>
                            <Box bg={'linear-gradient(#ac4cfe,#7416ab)'} padding={4} borderRadius={16}>
                                <Flex alignItems={'center'} gap={2} mb={6}>
                                    <Image boxSize={'35px'} src={require('./../../../assets/images/kog.png')} />
                                    <Text fontSize={'sm'} color={'#fff'} fontWeight={'750'} ml={1}>{t('KING_OF_GAMBLE')}</Text>
                                </Flex>
                                {
                                    event.length > 0 ?
                                        <div
                                            id="scrollableDiv">
                                            <InfiniteScroll
                                                style={{ display: "flex", flexDirection: "column" }}
                                                dataLength={event.length}
                                                next={() => {
                                                    let page = pageNo + 1;
                                                    setPageNo(page);
                                                    retrieveEvents(page);
                                                }}
                                                hasMore={event.length < totalCount}
                                                loader={<Spinner color="white" />}
                                            >
                                                {
                                                    event.map((item, index) => {
                                                        return (
                                                            <RenderItem
                                                                event={item}
                                                                index={index}
                                                            />
                                                        )
                                                    })
                                                }
                                            </InfiniteScroll>
                                        </div> :
                                        <Center>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("NO_EVENT_FOUND")}</Text>
                                        </Center>
                                }
                            </Box>
                        </Stack>
                    </div>
                </Center>
            </>
        )
    }
}