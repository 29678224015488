import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import {
    Stack,
    Text,
    Image,
    Flex,
    Box,
    Spacer,
    Divider,
    Button
} from '@chakra-ui/react';
import { PageSettings } from "../../../routes/setting";
import GeneralTopBar from "../../../component/general-top-bar";
import { ApiKey, BG_TYPE } from "../../../common/constant";
import CustomInput from "../../../component/input";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { showCustomDialog } from "../../../application/action/app_action";
import { Pages } from "../../../routes/page";
import Marquee from "react-fast-marquee";
import { stringIsNullOrEmpty } from "../../../common/util";
import { useOrientation } from 'react-use';

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const [bankHolderName, setBankHolderName] = useState('');
    const [userBankList, setUserBankList] = useState([]);
    const { type } = useOrientation();

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);
        getUserBankAccount();

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getUserBankAccount = async () => {
        try {
            let responseJson = await ApiEngine.get(`${Routes._GET_USER_BANK_ACCOUNTS}?userId=${userData.guid}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let userBanks = responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];
            setUserBankList(userBanks);

            if (userBanks.length > 0) {
                setBankHolderName(userBanks[0]['bankAccountHolderName']);
            }
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const deleteUserBankAccount = async (userBankId) => {
        try {
            let responseJson = await ApiEngine.delete(`${Routes._GET_USER_BANK_ACCOUNT}/${userBankId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showCustomDialog({
                success: true,
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: () => {
                    getUserBankAccount();
                }
            }));
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    return (
        <div>
            <GeneralTopBar title={'BANK_DETAILS'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
            <Stack paddingInline={5} mt={3}>
                <CustomInput
                    value={bankHolderName}
                    label='BENEFICIARY_NAME'
                    background='linear-gradient(#5a0089,#7c15bd) !important'
                    border='2px solid #e2a570 !important'
                    borderRadius='10px !important'
                    placeHolder={t('BENEFICIARY_NAME')}
                    isReadOnly={true} />
                {
                    !stringIsNullOrEmpty(bankHolderName) && 
                    <Text mt={-5} color={'red'}>{t('YOUR_BANK_ACCOUNT_INFORMATION_WILL_BE_STORED_WITH_US_SECURELY')}</Text>
                }
                <Divider className="app-divider-full-width" />
            </Stack>
            <Stack mt={3}>
                {
                    userBankList.length > 0 &&
                    userBankList.map((item, index) => {
                        return (
                            <>
                                <Flex key={index} h='3rem' w='full' position='relative'>
                                    <Button w='100%' variant={'link'} onClick={() => _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: bankHolderName, userBankId: item?.id, isNewUser: false })}>
                                        <Flex align={'center'} w='full' paddingInline={2}>
                                            <Image boxSize={'40px'} backgroundColor={'white'} borderRadius={50} src={item.bankLogo} mr={5} />
                                            <Stack align={'start'} w={'55vw'}>
                                                {
                                                    type !== 'landscape-primary' && item.bankName.length >= 20 ?
                                                        <Marquee>
                                                            <Text fontSize={'sm'}>{item.bankName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                                                        </Marquee> :
                                                        <Text fontSize={'sm'}>{t(item.bankName)}</Text>
                                                }
                                                <Text fontSize={'sm'} color={'#f8c763'}>{t(item.bankAccountNumber)}</Text>
                                            </Stack>
                                            <Spacer />
                                            <img src={require('../../../assets/images/svg/general-icon-arrow.svg').default} />
                                        </Flex>
                                    </Button>
                                    {
                                        userBankList.length > 0 &&
                                        <Button variant={'link'} position={'absolute'} right={'10vw'} bottom={['2vw', '0.5vw']} onClick={() => {
                                            _dispatch(
                                                showCustomDialog({
                                                    title: t('ARE_YOU_SURE'),
                                                    content: t("ARE_YOU_SURE_YOU_WANT_TO_DELETE", { bank: `${item.bankAccountNumber} (${item.bankName})` }),
                                                    horizontalBtn: false,
                                                    onConfirm: () => {
                                                        deleteUserBankAccount(item?.id);
                                                    },
                                                    onCancel: () => { }
                                                })
                                            );
                                        }}>
                                            <Image boxSize={'35px'} src={require('../../../assets/images/svg/general-delete.svg').default} />
                                        </Button>
                                    }
                                </Flex>
                                <Divider className="app-divider-full-width" />
                            </>
                        )
                    })
                }
            </Stack>
            <div className="bottom-sticky-button">
                <Button
                    className="green-button"
                    style={{ borderRadius: 0, height: '3rem' }}
                    onClick={() => _router.navigate(Pages._CREATE_OR_UPDATE_BANK, { bankAccountHolderName: userBankList.length > 0 ? userBankList[0]['bankAccountHolderName'] : '', isNewUser: false })}>
                    {t('ADD_BANK_ACCOUNT')}
                </Button>
            </div>
        </div>
    )
}