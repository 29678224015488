import React, { useEffect, useState } from 'react';
import { useRouter } from '../../../hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ApiEngine from '../../../common/api_engine';
import Routes from '../../../common/api_routes';
import { Pages } from '../../../routes/page';
import { showCustomDialog } from '../../../application/action/app_action';
import { ApiKey } from '../../../common/constant';
import { numberWithCurrencyFormat, createFormBody, checkBrowser } from '../../../common/util';
import PasswordModal from '../../../component/password-modal';
import { Stack, Image, Button, Text, Flex, SimpleGrid, Divider, HStack, CardBody, Card } from '@chakra-ui/react';

/// <summary>
/// Author: Juin
/// </summary>
const PinReload = ({ service, serviceDetail, type, memberGuid, successImage }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [selectedPin, setSelectedPin] = useState(serviceDetail.services[0]);
    const [errorMsg, setErrorMsg] = useState();
    const [authorizationModal, setAuthorizationModal] = useState({
        password: '',
        visible: false
    });

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function submitPayment(authorization) {
        let params = {
            'initialAmount': selectedPin.max_amount_cents,
            'amount': selectedPin.max_amount_cents,
            'serviceId': selectedPin.id,
            'type': type,
            'memberGuid': memberGuid,
            'deviceId': window.navigator.userAgent,
            'enableVerification': true,
            'password': authorization.password
        };

        var responseJson = await ApiEngine.post(Routes._EPAY_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._RELOAD_SUCCESS, {
                instruction: responseJson[ApiKey._API_DATA_KEY].details.replace('Instruction-\n', ''),
                toCopy: responseJson[ApiKey._API_DATA_KEY].pin,
                amount: 'RM ' + numberWithCurrencyFormat(selectedPin.max_denom),
                headerTitle: 'SUCCESSFULLY_PAID',
                contentImage: successImage,
                details: [
                    { label: 'SERVICE', value: selectedPin.service_name },
                    { label: 'TRANSACTION_NO', value: responseJson[ApiKey._API_DATA_KEY].trans_ref },
                    { label: 'PIN', value: responseJson[ApiKey._API_DATA_KEY].pin },
                    { label: 'EXPIRY_DATE', value: responseJson[ApiKey._API_DATA_KEY].pin_expiry }
                ]
            });
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <Flex align={'flex-end'} mb={5}>
                    <Button
                        variant={'link'}
                        alignItems={'center'}
                        borderRadius={50}
                        backgroundColor={'#C6D1E2'}
                        borderWidth={2.5}
                        borderColor={'#A0B1CB'}
                        p={3}
                        disabled >
                        <Image boxSize={'35px'} src={service.img} />
                    </Button>
                    <Text fontSize={'md'} ml={5}>{service.ssc_name}</Text>
                </Flex>
                <SimpleGrid columns={3} spacing={5}>
                    {
                        serviceDetail.services.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    className={`amount-button ${item.id == selectedPin?.id ? 'selected' : ''}`}
                                    onClick={() => setSelectedPin(item)}>
                                    {'RM ' + item.max_denom}
                                </Button>
                            )
                        })
                    }
                </SimpleGrid>
                {
                    errorMsg &&
                    <Text mt={3} color={'red'} fontSize={'sm'}>{errorMsg}</Text>
                }
                <Stack className="bottom-sticky-button">
                    <Divider className='app-divider-full-width-setting' />
                    <Flex
                        style={{
                            padding: '1rem',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                        <Stack spacing={0}>
                            <Text fontSize={'lg'}>{t('TOTAL_AMOUNT')}</Text>
                            <Flex align={'center'}>
                                <Image boxSize={'30px'} src={require('../../../assets/images/topbar-icon-credit.png')} />
                                <Text fontSize={'md'} color={'#f8c763'} fontWeight={'bold'}>{`RM ${numberWithCurrencyFormat(selectedPin.max_denom)}`}</Text>
                            </Flex>
                        </Stack>
                        <Button
                            className="grey-gradient-button"
                            style={{ borderRadius: '20px', width: '40%', marginLeft: '5vw' }}
                            onClick={() => {
                                if (_USER_DATA.credit > selectedPin.max_denom) {
                                    setAuthorizationModal({ ...authorizationModal, visible: true });
                                } else {
                                    setErrorMsg(t('INSUFFICIENT_CREDIT'));
                                }
                            }}>
                            {t('CONFIRM_PAYMENT')}
                        </Button>
                    </Flex>
                </Stack>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            window.scrollTo(0, 0);
                            submitPayment(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    } else {
        return (
            <>
                <Card>
                    <CardBody bg={'linear-gradient(#ac4cfe,#7416ab)'} pb={24} paddingInline={8} pt={5}>
                        <HStack spacing={5} mb={10}>
                            <Button
                                variant={'link'}
                                alignItems={'center'}
                                borderRadius={50}
                                backgroundColor={'#C6D1E2'}
                                borderWidth={2.5}
                                borderColor={'#A0B1CB'}
                                p={2}
                                disabled >
                                <Image boxSize={9} src={service.img} />
                            </Button>
                            <Text fontSize={'md'}>{service.ssc_name}</Text>
                        </HStack>
                        <SimpleGrid columns={3} spacing={5}>
                            {
                                serviceDetail.services.map((item, index) => {
                                    return (
                                        <Button
                                            paddingInline={12}
                                            paddingBlock={6}
                                            key={index}
                                            className={`amount-button ${item.id == selectedPin?.id ? 'selected' : ''}`}
                                            onClick={() => setSelectedPin(item)}>
                                            {'RM ' + item.max_denom}
                                        </Button>
                                    )
                                })
                            }
                        </SimpleGrid>
                        {
                            errorMsg &&
                            <Text mt={3} color={'red'}>{errorMsg}</Text>
                        }
                    </CardBody>
                </Card>
                <HStack spacing={5} justifyContent={'flex-end'} bg={'#400164'} paddingInline={8} paddingBlock={5} borderBottomRadius={15}>
                    <Stack spacing={0} textAlign={'right'}>
                        <Text fontSize={'md'}>{t('TOTAL_AMOUNT')}</Text>
                        <HStack spacing={0}>
                            <Image boxSize={'30px'} src={require('../../../assets/images/topbar-icon-credit.png')} />
                            <Text color={'#f8c763'} fontSize={'md'} fontWeight={'bold'}>{`RM ${numberWithCurrencyFormat(selectedPin.max_denom)}`}</Text>
                        </HStack>
                    </Stack>
                    <Button
                        fontWeight={'500'} fontSize={'sm'} borderRadius={10} backgroundImage={"linear-gradient(#e5b823, #8d7216)"} color={'white'} paddingBlock={6}
                        onClick={() => {
                            if (_USER_DATA.credit > selectedPin.max_denom) {
                                setAuthorizationModal({ ...authorizationModal, visible: true });
                            } else {
                                setErrorMsg(t('INSUFFICIENT_CREDIT'));
                            }
                        }}>
                        {t('CONFIRM_PAYMENT')}
                    </Button>
                </HStack>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            window.scrollTo(0, 0);
                            submitPayment(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    }
}

export default PinReload;