import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import { ApiKey } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, isObjectEmpty } from "../../../common/util";
import CustomConverter from "../../../component/custom-converter";
import { Text, Center, Image, Card, CardBody } from "@chakra-ui/react";
import { getMemberDetails } from "../../../application/action/auth_action";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    var _userData = useSelector(state => state.authState.userData);
    const _PARAMS = _location?.state;
    const selectedPlayer = _PARAMS?.selectedPlayer;
    const vendorPlayerId = _PARAMS?.selectedPlayer.vendorPlayerid;
    const playerId = _PARAMS?.selectedPlayer.id;
    const label = _PARAMS?.selectedVendor.label;
    const [score, setPlayerScore] = useState(selectedPlayer?.gameScore);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back();
        }
        else {
            _dispatch(getMemberDetails());
        }
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getPlayerScore() {
        try {
            var responseJson = await ApiEngine.get(`${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${vendorPlayerId}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw new (responseJson[ApiKey._API_MESSAGE_KEY]);
            }

            setPlayerScore(responseJson[ApiKey._API_DATA_KEY][0].wallet_balance);
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'WALLET_OR_SCORE_CONVERTER'} bgColor="linear-gradient(#a94ff1,#9e32dd)">
                    <ConversionTopBar amount={score} onRefresh={getPlayerScore} />
                </GeneralTopBar>
                <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CustomConverter
                        label={label}
                        playerId={playerId}
                        playerName={selectedPlayer.vendorPlayerid}
                        credit={_userData.credit}
                        score={score}
                        vendorName={selectedPlayer?.vendorName}
                        getPlayerScore={getPlayerScore}
                        username={_userData.username}
                        isRequiredNavigateBack={_PARAMS?.isRequiredNavigateBack ?? false}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('WALLET_OR_SCORE_CONVERTER')}</Text>
                        </div>
                        <Card bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={15}>
                            <CardBody>
                                <ConversionTopBar amount={score} onRefresh={getPlayerScore} />
                                <CustomConverter
                                    label={label}
                                    playerId={playerId}
                                    playerName={selectedPlayer.vendorPlayerid}
                                    credit={_userData.credit}
                                    score={score}
                                    vendorName={selectedPlayer?.vendorName}
                                    getPlayerScore={getPlayerScore}
                                    username={_userData.username}
                                    isRequiredNavigateBack={_PARAMS?.isRequiredNavigateBack ?? false}
                                />
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}