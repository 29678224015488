import React, { useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { PageSettings } from "../../routes/setting";
import {
    Stack, HStack, Text, Image, Spacer, Card, CardBody, CardFooter, Flex, Divider, Button, Center,
} from '@chakra-ui/react';
import CustomInput from "../../component/input";
import { useForm, Controller } from 'react-hook-form';
import { ValidationPattern, _LANGUAGE_OPTION, _RECEIPT_WATERMARK, ScreenWidth } from "../../common/constant";
import { useRouter } from "../../hooks/useRouter";
import { useLocation } from "react-router-dom";
import { Pages } from "../../routes/page";
import { useDispatch } from "react-redux";
import { setBusy, setIdle } from '../../application/action/app_action';
import { performLogin } from '../../application/action/auth_action';
import { showCustomDialog } from "../../application/action/app_action";
import { checkBrowser, isObjectEmpty, stringIsNullOrEmpty } from "../../common/util";
import { isMobile } from "react-device-detect";
import Footer from "./footer";
import Header from "./header";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { i18n, t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const _PARAMS = _location?.state;

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(_PARAMS)) {
            setValue('phoneNo', _PARAMS?.username);
        }
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            _dispatch(setBusy());
            window.scrollTo(0, 0);

            let loginResponse = await _dispatch(
                performLogin(data.phoneNo, data.password)
            );

            if (loginResponse) {
                _router.navigate(Pages._SECONDARY_PASSWORD_LOGIN, { ...loginResponse })
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('phoneNo')) && !stringIsNullOrEmpty(watch('password')));
    }

    if (!checkBrowser()) {
        return (
            <div style={{ width: "100%", paddingInline: "2rem", position: "absolute", margin: "auto", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Center w={'fit-content'} margin={'auto'} bg={'#a84df0'} padding={3} borderTopRadius={50}>
                    <Image zIndex={99} boxSize={16} objectFit='contain' src={require('../../assets/icon2.png')} alt='ePayPro' />
                </Center>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Card backgroundImage={'linear-gradient(#a84df0, #7415aa)'} borderRadius={30} marginTop={-50} paddingTop={30}>
                        <CardBody paddingInline={7}>
                            <Text color={'#f8c763'} mb={5} fontSize={'2xl'} fontWeight={'bold'}>Sign In</Text>
                            <Controller
                                control={control}
                                name="phoneNo"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        label='ENTER_MOBILE_NUMBER'
                                        inputType="tel"
                                        border="1px solid #f8c763 !important"
                                        borderRadius="10px !important"
                                        background="linear-gradient(#5b0e89,#7e14bf) !important"
                                        boxShadow="none !important"
                                        errors={errors}
                                        patternErrorTxt={t(
                                            'PLEASE_FILL_VALID_PHONE_NO'
                                        )} />
                                )}
                                rules={{
                                    required: true,
                                    pattern:
                                        ValidationPattern._PHONE_NUMBER_REGEX
                                }}
                            />
                            <Controller
                                control={control}
                                name="password"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(value) =>
                                            onChange(value)
                                        }
                                        value={value}
                                        errors={errors}
                                        label='ENTER_PASSWORD'
                                        border="1px solid #f8c763 !important"
                                        borderRadius="10px !important"
                                        background="linear-gradient(#5b0e89,#7e14bf) !important"
                                        boxShadow="none !important"
                                        subLabel='FORGOT_PASSWORD'
                                        subLabelStyle={{ color: '#f8c763', decoration: 'underline' }}
                                        subLabelAction={() => { _router.navigate(Pages._FORGOT_PASSWORD); }}
                                        inputMode='tel'
                                        isPassword />
                                )}
                                rules={{
                                    required: true,
                                    minLength: 6,
                                    maxLength: 6,
                                    pattern: ValidationPattern._DIGIT_REGEX
                                }}
                            />
                        </CardBody>
                        <Divider color={'#9a46cd'} borderWidth={2} w={'none'} />
                        <CardFooter justify={'center'} marginBlock={3}>
                            <Text fontSize={'sm'} color={'app.grey'}>{t('DONT_HAVE_AN_ACCOUNT')}</Text>
                            <Button ml='0.3rem' color={'app.blue'} variant='link' onClick={() => { _router.navigate(Pages._REGISTER) }}><Text fontSize={'sm'} color={'#f8c763'} decoration='underline'>{t('REGISTER_NOW')}</Text></Button>
                        </CardFooter>
                    </Card>
                    <Center>
                        <Button type="submit" mt={12} bg={'#6715ac'} borderRadius={50} w={'fit-content'} padding={'30px 15px'} border={'3px solid #9e57d9'}>
                            <Image boxSize={8} src={require('../../assets/images/svg/icon-tick.svg').default} />
                        </Button>
                    </Center>
                </form>
            </div>
        )
    }
    else {
        return (
            <>
                <Center margin="55px auto 0 auto">
                    <HStack spacing='100px'>
                        <div>
                            <Image boxSize='250px' objectFit='contain' src={require('../../assets/icon2.png')} alt='ePayPro' />
                        </div>
                        <div>
                            <Text fontSize='2xl' color={'#f8c763'} fontWeight={'bold'} marginBottom={"5px"}>{t('MEMBER_LOGIN')}</Text>
                            <Text fontSize='md' color={"white"} marginBottom={"35px"}>{t('HELLO_AND_WELCOME_PLEASE_LOG_IN')}</Text>
                            <Card borderRadius="15px" background={'linear-gradient(#ac4cfe,#7416ab)'} padding="12px 5px" minWidth="480px">
                                <CardBody>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(e) =>
                                                        onChange(e.currentTarget.value)
                                                    }
                                                    value={value}
                                                    label='ENTER_MOBILE_NUMBER'
                                                    inputType="tel"
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    border="1px solid #f8c763 !important"
                                                    background="linear-gradient(#5a0089,#8015c3) !important"
                                                    errors={errors}
                                                    patternErrorTxt={t(
                                                        'PLEASE_FILL_VALID_PHONE_NO'
                                                    )} />
                                            )}
                                            rules={{
                                                required: true,
                                                pattern:
                                                    ValidationPattern._PHONE_NUMBER_REGEX
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="password"
                                            defaultValue={''}
                                            render={({ onChange, value, name }) => (
                                                <CustomInput
                                                    id={name}
                                                    onChange={(value) =>
                                                        onChange(value)
                                                    }
                                                    value={value}
                                                    errors={errors}
                                                    label='ENTER_PASSWORD'
                                                    height="50px"
                                                    borderRadius="10px !important"
                                                    border="1px solid #f8c763 !important"
                                                    background="linear-gradient(#5a0089,#8015c3) !important"
                                                    subLabel='FORGOT_PASSWORD?'
                                                    subLabelStyle={{ color: '#f8c763', decoration: 'none', fontWeight: 'normal' }}
                                                    subLabelAction={() => { _router.navigate(Pages._FORGOT_PASSWORD); }}
                                                    inputMode='tel'
                                                    isPassword
                                                    isBrowser />
                                            )}
                                            rules={{
                                                required: true,
                                                minLength: 6,
                                                maxLength: 6,
                                                pattern: ValidationPattern._DIGIT_REGEX
                                            }}
                                        />
                                        <Button mt={4} style={activeBtn() ? { borderRadius: "10px", background: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "#ba5fee", color: "white", padding: "25px 0px" }} w='100%' type='submit'>{t('LOGIN')}</Button>
                                    </form>
                                </CardBody>
                                <CardFooter justify={'center'}>
                                    <Text color={'app.grey'}>{t('DONT_HAVE_AN_ACCOUNT')}</Text>
                                    <Button ml='0.3rem' variant='link' onClick={() => { _router.navigate(Pages._REGISTER) }}><Text color={'#f8c763'} decoration='underline' fontWeight='normal' >{t('REGISTER_NOW')}</Text></Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </HStack>
                </Center>
                <Footer />
            </>
        )
    }
}