import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import appState from '../application/reducer/app_reducer.js';
import authState from '../application/reducer/auth_reducer.js';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

/// <summary>
/// Author: Juin
/// </summary>
const reducers = combineReducers({
    appState,
    authState
});

/// <summary>
/// Author: Juin
/// </summary>
const persistConfig = {
    key: 'root',
    storage
};

/// <summary>
/// Author: Juin
/// </summary>
const persistedReducer = persistReducer(persistConfig, reducers);

/// <summary>
/// Author: Juin
/// </summary>
const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

export default store;