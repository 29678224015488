import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomInput from './input';
import CustomDialog from './dialog';
import { checkBrowser, isObjectEmpty } from '../common/util';
import { ValidationPattern } from '../common/constant';
import { Stack, Text } from '@chakra-ui/react';

/// <summary>
/// Author: Juin
/// </summary>
export default ({ title, content, authorization, onPasswordHandler }) => {
    const { handleSubmit, errors, control } = useForm();
    const { t } = useTranslation();
    const [enterPasswordModalVisible, setEnterPasswordModalVisible] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        (async () => {
            if (authorization.visible) {
                setEnterPasswordModalVisible(true);
            }
            else {
                setEnterPasswordModalVisible(false);
            }
        })();
    }, [authorization.visible]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const onPasswordSubmit = async (data) => {
        setEnterPasswordModalVisible(!enterPasswordModalVisible);
        setTimeout(function () {
            onPasswordHandler(true, { ...authorization, password: data.password });
        }, 500);
    };

    return (
        <CustomDialog
            disableHardwareBackPress={true}
            isManualHideAlert={true}
            isVisible={enterPasswordModalVisible}
            title={'ENTER_PASSWORD'}
            content={'ENTER_YOUR_PASSWORD_TO_PROCESS'}
            customContent={
                <Stack mt={3} paddingInline={4}>
                    <Controller
                        control={control}
                        name="password"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                inputMode='tel'
                                isPassword
                                onChange={value => onChange(value)}
                                value={value}
                                label={t('PASSWORD')}
                                background={checkBrowser()?'linear-gradient(#5a0089,#7c15bd) !important': 'linear-gradient(#5a0089,#7c15bd) !important'}
                                borderRadius='15px !important'
                                border={checkBrowser()?'2px solid transparent !important':'2px solid #e2a570 !important'}
                                _hover={{ border: '2px solid white !important' }} transitionDuration={'normal !improtant'}
                                errors={errors}
                                patternErrorTxt={t('PASSWORD_ERROR_TXT')}
                            />
                        )}
                        rules={{ required: true, minLength: 4, maxLength: 6, pattern: ValidationPattern._DIGIT_REGEX }}
                    />
                    {
                        authorization?.tac && (
                            <Text>{t('YOUR_TAC_IS')}: {authorization?.tac}</Text>
                        )
                    }
                </Stack>
            }
            onConfirm={handleSubmit(onPasswordSubmit)}
            onCancel={() => {
                setEnterPasswordModalVisible(!enterPasswordModalVisible);
                onPasswordHandler(false, { ...authorization });
            }}
            horizontalBtn={false}
        />
    )
}