import { stringIsNullOrEmpty, isObjectEmpty } from "../../common/util";
import ApiEngine from "../../common/api_engine";
import Routes from "../../common/api_routes";
import { ApiKey } from "../../common/constant";
import moment from "moment";

export const _APP_BUSY = "appBusy";
export const _APP_FREE = "appFree";
export const _SHOW_CUSTOM_DIALOG = 'SHOW_CUSTOM_DIALOG';
export const _SET_TEMP_CUSTOM_DIALOG_DATA = 'SET_TEMP_CUSTOM_DIALOG_DATA';
export const _DISPOSE_CUSTOM_DIALOG = 'DISPOSE_CUSTOM_DIALOG';
export const _SET_BUTTON_CLICK_TIME = 'SET_BUTTON_CLICK_TIME';
export const _CHECK_IN_SPIN_LOGIN = 'CHECK_IN_SPIN_LOGIN';
export const _SHOW_SIMPLE_DIALOG = 'SHOW_SIMPLE_DIALOG';
export const _DISMISS_SIMPLE_DIALOG = 'DISMISS_SIMPLE_DIALOG';
export const _SET_TEMP_SIMPLE_DIALOG_DATA = 'SET_TEMP_SIMPLE_DIALOG_DATA';
export const _SET_BONUS_DIALOG_DATA = 'SET_BONUS_DIALOG_DATA';
export const _CLEAR_BONUS_DIALOG_DATA = 'CLEAR_BONUS_DIALOG_DATA';

/// <summary>
/// Author: Juin
/// </summary>
export const setBusy = () => {
    return async dispatch => {
        dispatch({
            type: _APP_BUSY
        });
    }
}

/// <summary>
/// Author: Juin
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _APP_FREE
        });
    }
}

/// <summary>
/// Author: Juin
/// </summary>
export const showCustomDialog = ({
    success = true,
    headerTitle,
    title,
    content,
    customContent,
    onConfirm,
    onCancel,
    confirmTxt,
    cancelTxt,
    headerIcon,
    isManualHideAlert,
    horizontalBtn,
    disableHardwareBackPress = false,
    isPlainTheme = false,
    hideTitle = false,
    hideFooter = false,
    homeBanner
}) => {
    return async (dispatch, getState) => {
        const currentDialogData = getState().appState.customDialogData;

        dispatch({
            type: isObjectEmpty(currentDialogData) ? _SHOW_CUSTOM_DIALOG : _SET_TEMP_CUSTOM_DIALOG_DATA,
            data: {
                success: success,
                headerIcon: headerIcon,
                headerTitle: headerTitle,
                title: title,
                content: content,
                customContent: customContent,
                onConfirm: onConfirm,
                onCancel: onCancel,
                confirmTxt: confirmTxt,
                cancelTxt: cancelTxt,
                isManualHideAlert: isManualHideAlert,
                horizontalBtn: horizontalBtn,
                disableHardwareBackPress: disableHardwareBackPress,
                isPlainTheme: isPlainTheme,
                hideTitle: hideTitle,
                hideFooter: hideFooter,
                homeBanner: homeBanner
            }
        });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const disposeCustomDialog = () => {
    return async dispatch => {
        dispatch({ type: _DISPOSE_CUSTOM_DIALOG });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const setTempCustomDialogData = (data) => {
    return async dispatch => {
        dispatch({ type: _SET_TEMP_CUSTOM_DIALOG_DATA, data: data });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const setBonusDialogData = (data) => {
    return async dispatch => {
        dispatch({ type: _SET_BONUS_DIALOG_DATA, data: data });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const clearBonusDialogData = () => {
    return async dispatch => {
        dispatch({ type: _CLEAR_BONUS_DIALOG_DATA });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const setTempSimpleDialogData = (data) => {
    return async dispatch => {
        dispatch({ type: _SET_TEMP_SIMPLE_DIALOG_DATA, data: data });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const showSimpleDialog = ({ title, message, color = null, onConfirm = null, onCancel = null, cancelText = null, isManualHideAlert = false }) => {
    return async (dispatch, getState) => {
        const currentDialogData = getState().appState.simpleDialogData;

        dispatch({
            type: isObjectEmpty(currentDialogData) ? _SHOW_SIMPLE_DIALOG : _SET_TEMP_SIMPLE_DIALOG_DATA,
            data: {
                title: title,
                message: message,
                color: color,
                onConfirm: onConfirm,
                onCancel: onCancel,
                cancelText: cancelText,
                isManualHideAlert: isManualHideAlert
            }
        });
    }
}

/// <summary>
/// Author: Juin
/// </summary>
export const dismissSimpleDialog = () => {
    return dispatch => {
        dispatch({ type: _DISMISS_SIMPLE_DIALOG });
    }
}


/// <summary>
/// Author: Juin
/// </summary>
export const setButtonClickTime = (data) => {
    return async dispatch => {
        dispatch({ type: _SET_BUTTON_CLICK_TIME, data: data });
    }
};

/// <summary>
/// Author: Juin
/// </summary>
export const checkInSpinLogin = () => {
    return async (dispatch) => {
        try {
            let responseJson = await ApiEngine.post(Routes._CHECK_IN_SPIN_LOGIN, null, { headers: { "skipLoading": true } });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                dispatch({ type: _CHECK_IN_SPIN_LOGIN, data: moment().format('L') });
            }
        }
        catch (err) {
            throw new Error(err);
        }
    };
};