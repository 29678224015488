import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Stack, Button, Box, Flex, Center, Spacer, Table, TableContainer, Tr, Td } from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { showCustomDialog } from "../../../../application/action/app_action";
import { stringIsNullOrEmpty, createFormBody, getPoolColor, getPoolFontColor } from "../../../../common/util";
import { ApiKey, BG_TYPE } from "../../../../common/constant";
import moment from "moment";
import DatePicker from 'react-date-picker';
import { Carousel } from 'react-responsive-carousel';
import {
    MAGNUM_POOL_NAME,
    DAMACAI_POOL_NAME,
    TOTO_POOL_NAME,
    SINGAPORE_POOL_NAME,
    SABAH_POOL_NAME,
    SANDAKAN_POOL_NAME,
    CASH_SWEEP_POOL_NAME,
    GRAND_DRAGON_POOL_NAME,
    NINE_LOTTO_POOL_NAME,
    MAGNUM_POOL_RESULT_KEY,
    DAMACAI_POOL_RESULT_KEY,
    TOTO_POOL_RESULT_KEY,
    SINGAPORE_POOL_RESULT_KEY,
    SABAH_POOL_RESULT_KEY,
    SANDAKAN_POOL_RESULT_KEY,
    CASH_SWEEP_POOL_RESULT_KEY,
    GRAND_DRAGON_POOL_RESULT_KEY,
    NINE_LOTTO_POOL_RESULT_KEY
} from '../../../../common/constant';

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PLAYER_ID = _location?.state?.playerId;
    const [selectedDate, setSelectedDate] = useState('');
    const [result, setResult] = useState([]);
    const [activeResult, setActiveResult] = useState(0);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PLAYER_ID)) {
            _router.back();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        (async () => {
            try {
                var params = {
                    "playerId": _PLAYER_ID,
                    "date": "",
                    "mg": true,
                    "kd": true,
                    "tt": true,
                    "sg": true,
                    "sb": true,
                    "sn": true,
                    "sr": true,
                    "gd": true,
                    "nl": true
                };

                if (selectedDate) {
                    params['date'] = moment(selectedDate).format('YYYYMMDD');
                }

                let responseJson = await ApiEngine.post(Routes._SWC_GET_LOTTO_RESULT, createFormBody(params));

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                var updatedResults = [
                    { "name": MAGNUM_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][MAGNUM_POOL_RESULT_KEY] },
                    { "name": DAMACAI_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][DAMACAI_POOL_RESULT_KEY] },
                    { "name": TOTO_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][TOTO_POOL_RESULT_KEY] },
                    { "name": SINGAPORE_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][SINGAPORE_POOL_RESULT_KEY] },
                    { "name": SABAH_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][SABAH_POOL_RESULT_KEY] },
                    { "name": SANDAKAN_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][SANDAKAN_POOL_RESULT_KEY] },
                    { "name": CASH_SWEEP_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][CASH_SWEEP_POOL_RESULT_KEY] },
                    { "name": GRAND_DRAGON_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][GRAND_DRAGON_POOL_RESULT_KEY] },
                    { "name": NINE_LOTTO_POOL_NAME, ...responseJson[ApiKey._API_DATA_KEY][NINE_LOTTO_POOL_RESULT_KEY] },
                ]

                setActiveResult(0);
                setResult(updatedResults);
            }
            catch (err) {
                _dispatch(showCustomDialog({ success: false, content: err }));
            }
        })();
    }, [selectedDate]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getPoolImgByTxt = (txt) => {
        var poolImg;
        var str = txt.toUpperCase();

        if (str.indexOf('MAGNUM') > -1 || str.indexOf(MAGNUM_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-magnum.png');
        }
        else if (str.indexOf('DAMACAI') > -1 || str.indexOf(DAMACAI_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-damacai.png');
        }
        else if (str.indexOf('SINGAPORE') > -1 || str.indexOf(SINGAPORE_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-singapore.png');
        }
        else if (str.indexOf('SABAH') > -1 || str.indexOf(SABAH_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-sabah88.png');
        }
        else if (str.indexOf('SANDAKAN') > -1 || str.indexOf('STC') > -1 || str.indexOf(SANDAKAN_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-stc.png');
        }
        else if (str.indexOf('CASH') > -1 || str.indexOf(CASH_SWEEP_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-big-cash.png');
        }
        else if (str.indexOf('TOTO') > -1 || str.indexOf(TOTO_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-toto.png');
        }
        else if (str.indexOf('GD') > -1 || str.indexOf(GRAND_DRAGON_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-gd.png');
        }
        else if (str.indexOf('NINE') > -1 || str.indexOf(NINE_LOTTO_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-ninelotto.png');
        }

        return poolImg;
    }

    return (
        <div className='body-content'>
            <GeneralTopBar title={t('VIEW_RESULTS')} bgColor="#EBFFE3" useSimpleBackIcon blackTitle titleAlignLeft />
            <Flex paddingBlock={3} bg={'white'} marginInline={'-3.75vw'} align={'center'} paddingInline={4} mb={5}>
                <Text fontSize={'sm'} color={'#989898'} fontWeight={'500'} mr={5}>{t('DRAW_DATE')}</Text>
                <DatePicker
                    className={'result-date-picker'}
                    onChange={setSelectedDate}
                    value={selectedDate}
                    clearIcon={null}
                    maxDate={new Date()}
                />
                <Spacer />
                {
                    selectedDate &&
                    <Button bg='red' color='white' size={'xs'} onClick={() => setSelectedDate()}>{t('CLEAR')}</Button>
                }
            </Flex>
            <Carousel selectedItem={activeResult} renderIndicator={false} showArrows={false} showStatus={false} onChange={setActiveResult}>
                {
                    result.map((item, index) => {
                        return (
                            <Box key={index} bg={'white'} borderRadius={20} marginBottom={10}>
                                <Box borderTopLeftRadius={20} borderTopRadius={20} bg={getPoolColor(item.name)} p={5}>
                                    <Flex>
                                        <Stack spacing={0} align={'flex-start'} justify={'end'}>
                                            <Text color={getPoolFontColor(item.name)} fontWeight={'bold'} fontSize={'lg'}>{item.name}</Text>
                                            <Text color={getPoolFontColor(item.name)} fontWeight={'bold'}>{item.drawdate ? moment(item.drawdate, 'YYYYMMDD').format('DD/MM/YYYY (ddd)') : "DD/MM/YYYY"}</Text>
                                        </Stack>
                                        <Spacer />
                                        <Box borderRadius={30} bg={'white'} p={2.5}>
                                            <Center>
                                                <img style={{ width: '40px', height: '40px' }} src={getPoolImgByTxt(item.name)} />
                                            </Center>
                                        </Box>
                                    </Flex>
                                </Box>
                                <TableContainer>
                                    <Table size={'sm'}>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                <Text fontSize={'md'} color={'black'} as='b'>1st</Text>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_4d_1st ? item.p_4d_1st : "----"}</Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                <Text fontSize={'md'} color={'black'} as='b'>2nd</Text>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_4d_2nd ? item.p_4d_2nd : "----"}</Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                <Text fontSize={'md'} color={'black'} as='b'>3rd</Text>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_4d_3rd ? item.p_4d_3rd : "----"}</Text>
                                            </Td>
                                        </Tr>
                                    </Table>
                                </TableContainer>
                                <Stack p={2} bg={getPoolColor(item.name)}>
                                    <Text fontSize={'md'} color={getPoolFontColor(item.name)}>Special</Text>
                                </Stack>
                                <TableContainer>
                                    <Table size={'sm'}>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter0 ? item.p_starter0 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter1 ? item.p_starter1 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter2 ? item.p_starter2 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter3 ? item.p_starter3 : "----"}</Text>
                                            </Td>
                                            <Td>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter4 ? item.p_starter4 : "----"}</Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter5 ? item.p_starter5 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter6 ? item.p_starter6 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter7 ? item.p_starter7 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter8 ? item.p_starter8 : "----"}</Text>
                                            </Td>
                                            <Td>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter9 ? item.p_starter9 : "----"}</Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'} bg={getPoolColor(item.name)} opacity={0.3}>

                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter10 ? item.p_starter10 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter11 ? item.p_starter11 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_starter12 ? item.p_starter12 : "----"}</Text>
                                            </Td>
                                            <Td bg={getPoolColor(item.name)} opacity={0.3}>

                                            </Td>
                                        </Tr>
                                    </Table>
                                </TableContainer>
                                <Stack p={2} bg={getPoolColor(item.name)}>
                                    <Text fontSize={'md'} color={getPoolFontColor(item.name)}>Consolation</Text>
                                </Stack>
                                <TableContainer>
                                    <Table size={'sm'}>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation0 ? item.p_consolation0 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation1 ? item.p_consolation1 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation2 ? item.p_consolation2 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation3 ? item.p_consolation3 : "----"}</Text>
                                            </Td>
                                            <Td>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation4 ? item.p_consolation4 : "----"}</Text>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'} borderBottom={0}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation5 ? item.p_consolation5 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation6 ? item.p_consolation6 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation7 ? item.p_consolation7 : "----"}</Text>
                                            </Td>
                                            <Td borderRightWidth={1} borderRightColor={'gray.100'}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation8 ? item.p_consolation8 : "----"}</Text>
                                            </Td>
                                            <Td borderBottom={0}>
                                                <Text fontSize={'md'} color={'black'}>{item.p_consolation9 ? item.p_consolation9 : "----"}</Text>
                                            </Td>
                                        </Tr>
                                    </Table>
                                </TableContainer>
                                {
                                    item.name === TOTO_POOL_NAME &&
                                    <>
                                        <Stack p={2} bg={getPoolColor(item.name)}>
                                            <Text fontSize={'md'} color={getPoolFontColor(item.name)}>5D</Text>
                                        </Stack>
                                        <TableContainer>
                                            <Table size={'sm'}>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>1st</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_5d_1st ? item.p_5d_1st : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>4th</Text>
                                                    </Td>
                                                    <Td textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_5d_4th ? item.p_5d_4th : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>2nd</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_5d_2nd ? item.p_5d_2nd : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>5th</Text>
                                                    </Td>
                                                    <Td textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_5d_5th ? item.p_5d_5th : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>3rd</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_5d_3rd ? item.p_5d_3rd : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>6th</Text>
                                                    </Td>
                                                    <Td textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_5d_6th ? item.p_5d_6th : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                            </Table>
                                        </TableContainer>
                                    </>
                                }
                                {
                                    (item.name === TOTO_POOL_NAME || item.name === GRAND_DRAGON_POOL_NAME) &&
                                    <>
                                        <Stack p={2} bg={getPoolColor(item.name)}>
                                            <Text fontSize={'md'} color={getPoolFontColor(item.name)}>6D</Text>
                                        </Stack>
                                        <TableContainer>
                                            <Table size={'sm'}>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>1st</Text>
                                                    </Td>
                                                    <Td colSpan={5} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_1st ? item.p_6d_1st : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>2nd</Text>
                                                    </Td>
                                                    <Td colSpan={2} borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_2nd_a ? item.p_6d_2nd_a : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>or</Text>
                                                    </Td>
                                                    <Td colSpan={2} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_2nd_b ? item.p_6d_2nd_b : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>3rd</Text>
                                                    </Td>
                                                    <Td colSpan={2} borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_3rd_a ? item.p_6d_3rd_a : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>or</Text>
                                                    </Td>
                                                    <Td colSpan={2} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_3rd_b ? item.p_6d_3rd_b : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>4th</Text>
                                                    </Td>
                                                    <Td colSpan={2} borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_4th_a ? item.p_6d_4th_a : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>or</Text>
                                                    </Td>
                                                    <Td colSpan={2} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_4th_b ? item.p_6d_4th_b : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'} borderBottom={0}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>5th</Text>
                                                    </Td>
                                                    <Td colSpan={2} borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_5th_a ? item.p_6d_5th_a : "------"}</Text>
                                                    </Td>
                                                    <Td borderRightWidth={1} borderRightColor={'gray.100'} textAlign={'center'}>
                                                        <Text fontSize={'md'} color={'black'} as='b'>or</Text>
                                                    </Td>
                                                    <Td colSpan={2} textAlign={'center'} borderBottom={0}>
                                                        <Text fontSize={'md'} color={'black'}>{item.p_6d_5th_b ? item.p_6d_5th_b : "------"}</Text>
                                                    </Td>
                                                </Tr>
                                            </Table>
                                        </TableContainer>
                                    </>
                                }
                            </Box>
                        )
                    })
                }
            </Carousel>
            <Flex className="bottom-sticky-button" bg={'#08cd34'} justifyContent={'space-evenly'} paddingBlock={2.5}>
                {
                    result.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                borderRadius={30}
                                bg={'white'}
                                p={1.5}
                                borderWidth={2}
                                borderColor={activeResult == index ? getPoolColor(item.name) : 'white'}
                                onClick={() => setActiveResult(index)}>
                                <Center>
                                    <img style={{ width: '25px', height: '25px', filter: `grayscale(${activeResult == index ? '0%' : '100%'})` }} src={getPoolImgByTxt(item.name)} />
                                </Center>
                            </Box>
                        )
                    })
                }
            </Flex>
        </div>
    )
}