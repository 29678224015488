import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    AbsoluteCenter,
    Spinner,
    Flex,
    Divider,
    Spacer,
    Image,
    Center,
    HStack
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import { _PAGE_SIZE, ApiKey, _NOTIFICATION_TYPES } from "../../../common/constant";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import InfiniteScroll from "react-infinite-scroll-component";
import { showCustomDialog } from "../../../application/action/app_action";
import moment from "moment";
import { checkBrowser } from "../../../common/util";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    var _navigate = useNavigate();
    const _INITIAL_PAGE_NO = 1;
    const [historyList, setHistoryList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        retrieveHistoryList();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const retrieveHistoryList = async (pagedNo = _INITIAL_PAGE_NO) => {
        try {
            let responseJson = await ApiEngine.get(
                `${Routes._NOTIFICATION_HISTORY}?page=${pagedNo}&pageSize=${_PAGE_SIZE}`);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let data = responseJson[ApiKey._API_DATA_KEY];
            setTotalCount(data.totalCount);

            if (
                responseJson[ApiKey._API_DATA_KEY]['data'].length > 0 &&
                pagedNo > _INITIAL_PAGE_NO
            ) {
                setHistoryList([...historyList, ...data['data']]);
            } else {
                setHistoryList(data.data);
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderItem = ({ item, index }) => {
        let templateParams = JSON.parse(item.templateParam);

        if (!checkBrowser()) {
            return (
                <>
                    <Flex key={index} paddingInline={1.5} paddingBlock={4}>
                        <img style={{ width: '35px', height: '35px' }} src={item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ?
                            require('../../../assets/images/rankinghistory/up-experience.png') :
                            item.notificationTypeId == _NOTIFICATION_TYPES.EXP_DOWN ? require('../../../assets/images/rankinghistory/down-experience.png') : ''} />
                        {
                            [_NOTIFICATION_TYPES.EXP_UP, _NOTIFICATION_TYPES.EXP_DOWN].includes(item.notificationTypeId) ?
                                <>
                                    <Stack spacing={0} ml={2} mr={1}>
                                        <Text >{t(item.content)}</Text>
                                        <Text color={'#f8c763'}>{moment(item.createdTime).format('DD MMM YYYY, hh:mm A')}</Text>
                                    </Stack>
                                    <Spacer />
                                    <Stack spacing={0}>
                                        <Text color={item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ? '#27D918' : '#FF5555'}>{item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ? "+" : ""} {templateParams.experience} </Text>
                                        <Text textAlign={'center'} color={item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ? '#27D918' : '#FF5555'}>EXP</Text>
                                    </Stack>
                                </> :
                                <Text color={'#f8c763'}>{t(item.content)}</Text>
                        }
                    </Flex>
                    <Divider className="app-divider-full-width-setting" />
                </>
            )
        } else {
            return (
                <>
                    <HStack spacing={0} paddingInline={1} paddingBlock={4}>
                        <Image boxSize={'3.5rem'} src={item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ?
                            require('../../../assets/images/rankinghistory/up-experience.png') :
                            item.notificationTypeId == _NOTIFICATION_TYPES.EXP_DOWN ? require('../../../assets/images/rankinghistory/down-experience.png') : ''} />
                        {
                            [_NOTIFICATION_TYPES.EXP_UP, _NOTIFICATION_TYPES.EXP_DOWN].includes(item.notificationTypeId) ?
                                <>
                                    <Stack spacing={0} ml={2}>
                                        <Text>{t(item.content)}</Text>
                                        <Text fontSize={'sm'} color={'#f8c763'}>{moment(item.createdTime).format('DD MMM YYYY, hh:mm A')}</Text>
                                    </Stack>
                                    <Spacer />
                                    <HStack borderRadius={20} border={'1px solid #575b65'} paddingBlock={.5} paddingInline={3}>
                                        <Text color={item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ? '#27D918' : '#FF5555'}>{item.notificationTypeId == _NOTIFICATION_TYPES.EXP_UP ? "+" : ""} {templateParams.experience} EXP</Text>
                                    </HStack>
                                </> :
                                <Text color={'#f8c763'}>{t(item.content)}</Text>
                        }
                    </HStack>
                    <Divider className="divider-horizontal" /></>
            )
        }
    }

    if (!checkBrowser()) {
        return (
            <div className='body-content'>
                <GeneralTopBar title={'RANKING_HISTORY'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                <Stack mt={3}>
                    {
                        historyList.length > 0 ?
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: '100vh',
                                    overflow: "auto"
                                }}>
                                <InfiniteScroll
                                    style={{ display: "flex", flexDirection: "column" }}
                                    dataLength={historyList.length}
                                    next={() => {
                                        let page = pageNo + 1;
                                        setPageNo(page);
                                        retrieveHistoryList(page);
                                    }}
                                    hasMore={historyList.length < totalCount}
                                    loader={<Spinner color="white" />}
                                >
                                    {
                                        historyList.map((item, index) => {
                                            return (
                                                <RenderItem
                                                    item={item}
                                                    index={index}
                                                />
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                            </div> :
                            <AbsoluteCenter>
                                <Center><Image boxSize={'150px'} src={require('../../../assets/images/rankinghistory/emptyranking.png')} /></Center>
                                <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'}>{t("EMPTY_HISTORY_LIST")}</Text>
                                <Text textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("NO_RANKING_HISTORY_AVAILABLE_NOW")}</Text>
                            </AbsoluteCenter>
                    }
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "40rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('RANKING_HISTORY')}</Text>
                        </div>
                        <Stack mt={5}>
                            {
                                historyList.length > 0 ?
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            // height: '100vh',
                                            overflow: "auto"
                                        }}>
                                        <InfiniteScroll
                                            style={{ display: "flex", flexDirection: "column" }}
                                            dataLength={historyList.length}
                                            next={() => {
                                                let page = pageNo + 1;
                                                setPageNo(page);
                                                retrieveHistoryList(page);
                                            }}
                                            hasMore={historyList.length < totalCount}
                                            loader={<Spinner color="white" />}
                                        >
                                            {
                                                historyList.map((item, index) => {
                                                    return (
                                                        <RenderItem
                                                            item={item}
                                                            index={index}
                                                        />
                                                    )
                                                })
                                            }
                                        </InfiniteScroll>
                                    </div> :
                                    <div style={{ marginBottom: "2.3rem", marginTop: "1rem" }}>
                                        <Center><Image boxSize={'150px'} src={require('../../../assets/images/rankinghistory/emptyranking.png')} /></Center>
                                        <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'}>{t("EMPTY_HISTORY_LIST")}</Text>
                                        <Text textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("NO_RANKING_HISTORY_AVAILABLE_NOW")}</Text>
                                    </div>
                            }
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}