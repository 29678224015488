import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Card,
    CardBody,
    Center
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog, disposeCustomDialog } from "../../../application/action/app_action";
import GeneralTopBar from "../../../component/general-top-bar";
import { ApiKey, BG_TYPE, ValidationPattern } from "../../../common/constant";
import { checkBrowser, createFormBody, stringIsNullOrEmpty } from "../../../common/util";
import { useForm, Controller } from 'react-hook-form';
import CustomInput from "../../../component/input";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { performLogout } from "../../../application/action/auth_action";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { control, handleSubmit, errors, setValue, clearErrors, watch } = useForm();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        _context.toggleBg(BG_TYPE.black);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const doPasswordMatch = (value) => {
        if (value) {
            clearErrors(["confirmNewPassword", "newPassword"]);
            return true;
        }

        return false;
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('oldPassword')) && !stringIsNullOrEmpty(watch('newPassword')) && !stringIsNullOrEmpty(watch('confirmNewPassword')));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            window.scrollTo(0, 0);
            let errorMsg = '';
            let memberId = userData.guid;
            let formBody = createFormBody({ memberId, ...data });

            var responseJson = await ApiEngine.post(
                Routes._CHANGE_PASSWORD_URL,
                formBody
            );

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                errorMsg = responseJson[ApiKey._API_MESSAGE_KEY] ?? responseJson[ApiKey._API_ERROR_KEY] ?? "INTERNAL_SERVER_ERROR";
            }

            _dispatch(showCustomDialog({
                success: responseJson[ApiKey._API_SUCCESS_KEY],
                content: !responseJson[ApiKey._API_SUCCESS_KEY] ? errorMsg : "CHANGE_PASSWORD_SUCCESS_MSG",
                onConfirm: () => {
                    _dispatch(disposeCustomDialog());
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _dispatch(performLogout());
                    }
                },
                horizontalBtn: true
            }));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'CHANGE_PASSWORD'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                <Center mt={8} mb={3}>
                    <Image w={'100px'} h={'80px'} src={require('../../../assets/images/graphic-changepw.png')} />
                </Center>
                <Text textAlign={'center'} mb={5} color={'#C6D1E2'}>{t("NOTE")}: {t("PASSWORD_MUST_BE_6_DIGIT_NUMBERS")}</Text>
                <Stack paddingInline={5} className="display-center">
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Controller
                            control={control}
                            name="oldPassword"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    inputMode='tel'
                                    isPassword
                                    id={name}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                    value={value}
                                    label={`${t('OLD_PASSWORD')} / ${t('PIN_OUT')}`}
                                    background='linear-gradient(#5a0089,#7c15bd) !important'
                                    border='2px solid #e2a570 !important'
                                    borderRadius='10px !important'
                                    placeHolder={`${t('OLD_PASSWORD')} / ${t('PIN_OUT')}`}
                                    inputType='tel'
                                    errors={errors}
                                    patternErrorTxt="PASSWORD_MSG" />
                            )}
                            rules={{
                                required: true,
                                minLength: 4,
                                maxLength: 6,
                                pattern: ValidationPattern._DIGIT_REGEX
                            }}
                        />
                        <Controller
                            control={control}
                            name="newPassword"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    inputMode='tel'
                                    isPassword
                                    id={name}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                    value={value}
                                    label='NEW_PASSWORD'
                                    background='linear-gradient(#5a0089,#7c15bd) !important'
                                    border='2px solid #e2a570 !important'
                                    borderRadius='10px !important'
                                    placeHolder={t('NEW_PASSWORD')}
                                    errors={errors}
                                    patternErrorTxt="PASSWORD_MSG" />
                            )}
                            rules={{
                                required: true,
                                minLength: 6,
                                maxLength: 6,
                                pattern: ValidationPattern._DIGIT_REGEX
                            }}
                        />
                        <Controller
                            control={control}
                            name="confirmNewPassword"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    inputMode='tel'
                                    isPassword
                                    id={name}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                    value={value}
                                    background='linear-gradient(#5a0089,#7c15bd) !important'
                                    border='2px solid #e2a570 !important'
                                    borderRadius='10px !important'
                                    label='CONFIRM_NEW_PASSWORD'
                                    placeHolder={t('CONFIRM_NEW_PASSWORD')}
                                    inputType='tel'
                                    errors={errors}
                                    patternErrorTxt="PASSWORD_MSG" />
                            )}
                            rules={{
                                required: true,
                                minLength: 6,
                                maxLength: 6,
                                pattern: ValidationPattern._DIGIT_REGEX,
                                validate: {
                                    matchingPassword: value => doPasswordMatch(value === watch('newPassword')) || 'PASSWORD_NOT_MATCH_MSG'
                                }
                            }}
                        />
                        <Button mt={6} className="grey-gradient-button" type="submit">{t('SUBMIT')}</Button>
                    </form>
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", minWidth: "32rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "25px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={10}>{t('CHANGE_PASSWORD')}</Text>
                        </div>
                        <Text fontSize={'sm'} mb={4} color={'#C6D1E2'}>{t("NOTE")}: {t("PASSWORD_MUST_BE_6_DIGIT_NUMBERS")}</Text>
                        <Card bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={20}>
                            <CardBody>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <Controller
                                        control={control}
                                        name="oldPassword"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputMode='tel'
                                                isPassword
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                borderRadius="10px !important"
                                                paddingBlock="21px !important"
                                                background="linear-gradient(#5a0089,#7c15bd) !important"
                                                label={`${t('OLD_SECONDARY_PASSWORD')} / ${t('PIN_OUT')}`}
                                                inputType='tel'
                                                errors={errors}
                                                patternErrorTxt="PASSWORD_MSG" />
                                        )}
                                        rules={{
                                            required: true,
                                            minLength: 4,
                                            maxLength: 6,
                                            pattern: ValidationPattern._DIGIT_REGEX
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name="newPassword"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputMode='tel'
                                                isPassword
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                borderRadius="10px !important"
                                                paddingBlock="21px !important"
                                                background="linear-gradient(#5a0089,#7c15bd) !important"
                                                label='NEW_PASSWORD'
                                                errors={errors}
                                                patternErrorTxt="PASSWORD_MSG" />
                                        )}
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                            maxLength: 6,
                                            pattern: ValidationPattern._DIGIT_REGEX
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name="confirmNewPassword"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CustomInput
                                                inputMode='tel'
                                                isPassword
                                                id={name}
                                                onChange={(value) =>
                                                    onChange(value)
                                                }
                                                value={value}
                                                borderRadius="10px !important"
                                                paddingBlock="21px !important"
                                                background="linear-gradient(#5a0089,#7c15bd) !important"
                                                label='CONFIRM_NEW_PASSWORD'
                                                inputType='tel'
                                                errors={errors}
                                                patternErrorTxt="PASSWORD_MSG" />
                                        )}
                                        rules={{
                                            required: true,
                                            minLength: 6,
                                            maxLength: 6,
                                            pattern: ValidationPattern._DIGIT_REGEX,
                                            validate: {
                                                matchingPassword: value => doPasswordMatch(value === watch('newPassword')) || 'PASSWORD_NOT_MATCH_MSG'
                                            }
                                        }}
                                    />
                                    <Button mt={2}
                                        style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%)", color: "white", padding: "25px 0px" }} w='100%'
                                        type="submit">{t('UPDATE_PASSWORD')}</Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}