import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import _ROUTES from "./page";
import { _NON_AUTH_ROUTES, _AUTH_ROUTES, Pages } from "./page";
import { useRouter } from "./../hooks/useRouter";
import { checkBrowser } from "../common/util";

/// <summary>
/// Author: Juin
/// </summary>
const Content = () => {
  var _currentLocation = useLocation();
  var _router = useRouter();
  const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);

  /// <summary>
  /// Author: Juin
  /// </summary>
  useEffect(() => {
    if (_currentLocation) {
      window.scrollTo(0, 0);

      var currentRoute = (_IS_LOGGED_IN ? _AUTH_ROUTES : _NON_AUTH_ROUTES).find(
        (route) => _currentLocation.pathname == route.path
      );

      if (currentRoute == null) {
        //window.history.pushState({}, document.title, Pages._DEFAULT);
        if (checkBrowser()) {
          _router.navigate(Pages._GAMES);
        } else {
          _router.navigate(Pages._DEFAULT);
        }
      }
    }
  }, [_currentLocation, _IS_LOGGED_IN]);

  return (
    <Routes location={_currentLocation}>
      {(_IS_LOGGED_IN ? _AUTH_ROUTES : _NON_AUTH_ROUTES).map((route, index) => (
        <Route key={index} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps, null)(Content);
