import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Stack, Text, Image, Flex, Divider, Button, Spinner, Box } from '@chakra-ui/react';
import { useRouter } from "../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { getMemberDetails } from "../application/action/auth_action";
import { showCustomDialog } from "../application/action/app_action";
import { numberWithCurrencyFormat } from "../common/util";
import { Pages } from "../routes/page";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector(state => state.authState.userData);
    const [isLoading, setIsLoading] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        retrieveMemberDetails();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const retrieveMemberDetails = async () => {
        try {
            setIsLoading(true);
            _dispatch(getMemberDetails());
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        finally {
            setIsLoading(false);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function UserSection({ image, username, alias, experienceDetail }) {
        return (
            <Flex ml={[2, 2, 5]}>
                <Button variant={'link'} onClick={() => {
                    _router.navigate(Pages._PROFILE);
                }}>
                    <Flex>
                        <div className="profile-picture">
                            <Image className="rank-image" boxSize={'28px'} src={experienceDetail?.innerFrame} />
                            <Image className="profile-image" boxSize={'22px'} borderRadius={25} src={image ? image : require('../assets/images/topbar-user-defaultphoto.png')} />
                            <Image className="badge-image" boxSize={'25px'} src={experienceDetail?.badgeFile} />
                            <span className="red-dot"></span>
                        </div>
                        <Stack ml={1} align={'flex-start'} spacing={0}>
                            <Text fontWeight={'normal'}>{username ?? '-'}</Text>
                            <Text fontSize={['2xs', 'sm']} as='b'>{alias ?? '-'}</Text>
                        </Stack>
                    </Flex>
                </Button>
            </Flex>
        )
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function BalanceSection({ credit, reward }) {
        return (
            <Flex align={'center'}>
                <Stack ml={1} spacing={0}>
                    <Text fontSize={['2xs', 'sm']} textAlign={'center'}>{t('WALLET')}</Text>
                    <Flex align={'center'}>
                        <Box
                            position={'relative'}
                            borderColor={'#f8c763'}
                            bg={'linear-gradient(#400164,#9223d4)'}
                            width={'4.5rem'}
                            paddingInline={1}
                            borderWidth={'1px'}
                            borderRadius={'15px'}>
                            <Image
                                style={{
                                    position: 'absolute',
                                    left: '-10px',
                                    top: '-4px'
                                }}
                                boxSize={'25px'}
                                src={require('../assets/images/topbar-icon-credit.png')} />
                            <Text textAlign={'right'} fontWeight={'bold'}>{numberWithCurrencyFormat(credit ?? 0)}</Text>
                        </Box>
                    </Flex>
                </Stack>
                    <Flex ml={3} align={'center'} onClick={() => { _router.navigate(Pages._REWARD_HISTORY); }}>
                        <Stack ml={1} spacing={0}>
                            <Text fontSize={['2xs', 'sm']} textAlign={'center'}>{t('REWARD')}</Text>
                            <Flex align={'center'}>
                                <Box
                                    position={'relative'}
                                    borderColor={'#f8c763'}
                                    bg={'linear-gradient(#400164,#9223d4)'}
                                    width={'4.5rem'}
                                    paddingInline={1}
                                    borderWidth={'1px'}
                                    borderRadius={'15px'}>
                                    <Image
                                        style={{
                                            position: 'absolute',
                                            left: '-10px',
                                            top: '-4px'
                                        }}
                                        boxSize={'25px'}
                                        src={require('../assets/images/topbar-icon-gift.png')} />
                                    <Text textAlign={'right'} fontWeight={'bold'}>{numberWithCurrencyFormat(reward)}</Text>
                                </Box>
                            </Flex>
                        </Stack>
                    </Flex>
            </Flex>
        )
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function ActionSection() {
        return (
            <Flex paddingInline={2}>
                <Button minW={'fit-content'} disabled={isLoading} variant={'link'} onClick={() => { retrieveMemberDetails(); }}>
                    {
                        isLoading ?
                            <Spinner color="white" size={'sm'} /> :
                            <Image boxSize={7} src={require('../assets/images/topbar-icon-refresh.png')} />
                    }
                </Button>
                <Button minW={'fit-content'} variant={'link'} onClick={() => {
                    _router.navigate(Pages._REWARD_TO_CREDIT, {
                        memberGuid: _USER_DATA?.guid,
                        currentWalletCredit: _USER_DATA?.credit
                    });
                }}>
                    <Image boxSize={7} src={require('../assets/images/topbar-icon-transfer.png')} />
                </Button>
            </Flex>
        )
    }

    return (
        <Stack className="app-general-top-bar" bg={'linear-gradient(#ac4afd,#7416a9)'} paddingInline={'2px'}>
            <Stack className="stack-container">
                <Flex justify={'center'}>
                    <UserSection
                        username={_USER_DATA?.username}
                        alias={_USER_DATA?.alias}
                        image={_USER_DATA?.profileImage}
                        experienceDetail={_USER_DATA?.experienceDetail} />
                    <Flex flex="1" justify={'flex-end'}>
                        <BalanceSection credit={_USER_DATA?.credit} reward={_USER_DATA?.reward} />
                        <ActionSection />
                    </Flex>
                </Flex>
            </Stack>
        </Stack>
    )
}
