import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import { Stack, Flex, Text, Divider, Spinner, Spacer, AbsoluteCenter, Image, Button, Center, HStack } from "@chakra-ui/react";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import { ApiKey, _PAGE_SIZE } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat } from "../../../common/util";
import moment from "moment";
import DateFilter from "../../../component/date-filter";
import InfiniteScroll from 'react-infinite-scroll-component';
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _navigate = useNavigate();
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    const _INITIAL_PAGE_NO = 0;
    const [transaction, setTransaction] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
    const [showDatePicker, setShowDatePicker] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        getSpinHistory();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getSpinHistory(
        startedDate = startDate,
        endedDate = endDate,
        pagedNo = _INITIAL_PAGE_NO
    ) {
        var responseJson = await ApiEngine.get(
            Routes._GET_LUCKY_SPIN_HISTORY +
            '?StartDate=' +
            startedDate + ' 00:00:00' +
            '&EndDate=' +
            endedDate + ' 23:59:59' +
            '&PageSize=' +
            _PAGE_SIZE +
            '&StartCount=' +
            pagedNo
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTotalCount(responseJson[ApiKey._API_DATA_KEY].totalCount);

            if (
                responseJson[ApiKey._API_DATA_KEY]?.data.length > 0 &&
                pagedNo != _INITIAL_PAGE_NO
            ) {
                setTransaction([
                    ...transaction,
                    ...responseJson[ApiKey._API_DATA_KEY].data
                ]);
            } else {
                setTransaction(responseJson[ApiKey._API_DATA_KEY].data);
            }
        } else {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: responseJson[ApiKey._API_MESSAGE_KEY]
                })
            );
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderItem = ({ item, index }) => {
        if (!checkBrowser()) {
            return (
                <Stack key={index} style={{ height: '82px' }}>
                    <Flex align={'center'} w='full'>
                        <Image boxSize={'40px'} src={item.prizeImage ? item.prizeImage : require('../../../assets/images/topbar-icon-gift.png')} />
                        <Stack spacing={0} ml={2}>
                            <Text>{t(item.prizeName)}</Text>
                            <Text color={'#f8c763'}>
                                {moment(item.createdTime).format('DD MMM yyyy, hh:mm:ss a')}
                            </Text>
                        </Stack>
                        <Spacer />
                        <Text>{'+RM ' + numberWithCurrencyFormat(item.amount)}</Text>
                    </Flex>
                    <Divider className="divider-horizontal" />
                </Stack>
            )
        } else {
            return (
                <>
                    <HStack key={index} paddingInline={5} paddingBlock={4}>
                        <Image boxSize={10} src={item.prizeImage ? item.prizeImage : require('../../../assets/images/topbar-icon-gift.png')} />
                        <Stack spacing={0} ml={2}>
                            <Text>{t(item.prizeName)}</Text>
                            <Text color={'#f8c763'} fontSize={'sm'}>
                                {moment(item.createdTime).format('DD MMM yyyy, hh:mm:ss a')}
                            </Text>
                        </Stack>
                        <Spacer />
                        <Text>{'+RM ' + numberWithCurrencyFormat(item.amount)}</Text>
                    </HStack>
                    <Divider className="divider-horizontal" />
                </>
            )
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'SPIN_HISTORY'} bgColor="linear-gradient(#a94ff1,#9e32dd)" style={{ paddingTop: '1rem' }}>
                    <Flex paddingInline={6} paddingBlock={5} bg={'#1d1643'}>
                        <Text fontWeight={'semibold'} mr={3} onClick={() => setShowDatePicker(!showDatePicker)}>
                            {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                        </Text>
                        <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                    </Flex>
                </GeneralTopBar>
                <Stack className="header-margin-top">
                    {
                        transaction.length > 0 ?
                            <>
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        height: '100vh',
                                        overflow: "auto"
                                    }}>
                                    <InfiniteScroll
                                        style={{ display: "flex", flexDirection: "column" }}
                                        dataLength={transaction.length}
                                        next={() => {
                                            let page = (pageNo + 1) * _PAGE_SIZE;
                                            setPageNo(page);
                                            getSpinHistory(startDate, endDate, page);
                                        }}
                                        hasMore={transaction.length < totalCount}
                                        loader={<Spinner color="white" />}
                                    >
                                        {
                                            transaction.map((item, index) => {
                                                return (
                                                    <RenderItem
                                                        item={item}
                                                        index={index}
                                                    />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                </div>
                            </> :
                            <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                                <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("EMPTY_SPIN_HISTORY")}</Text>
                                <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("NO_SPIN_HISTORY_AVAILABLE_NOW")}</Text>
                            </AbsoluteCenter>
                    }
                </Stack>
                <DateFilter
                    isOpen={showDatePicker}
                    onClose={() => setShowDatePicker(!showDatePicker)}
                    closeOnOverlayClick={false}
                    applyDateFilter={(data) => {
                        let start = moment(data.startDate).format('yyyy-MM-DD');
                        let end = moment(data.endDate).format('yyyy-MM-DD');
                        setStartDate(start);
                        setEndDate(end);
                        getSpinHistory(start, end);
                    }}
                />
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "50rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('SPIN_HISTORY')}</Text>
                        </div>
                        <div style={{ background: "linear-gradient(#ac4cfe,#7416ab)", padding: "20px", borderRadius: "20px" }}>
                            <Flex mt={2.5} paddingInline={3}>
                                <Text fontWeight={'semibold'} mr={3} cursor={'pointer'} onClick={() => setShowDatePicker(!showDatePicker)}>
                                    {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                </Text>
                                <img src={require('../../../assets/images/svg/updown-white.svg').default} onClick={() => setShowDatePicker(!showDatePicker)} style={{ cursor: "pointer" }} />
                            </Flex>
                            <Stack mt={2}>
                                {
                                    transaction.length > 0 ?
                                        <>
                                            <div
                                                id="scrollableDiv"
                                                style={{
                                                    height: '23rem',
                                                    overflow: "auto",
                                                }}>
                                                <InfiniteScroll
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                    dataLength={transaction.length}
                                                    next={() => {
                                                        let page = (pageNo + 1) * _PAGE_SIZE;
                                                        setPageNo(page);
                                                        getSpinHistory(startDate, endDate, page);
                                                    }}
                                                    hasMore={transaction.length < totalCount}
                                                    loader={<Spinner color="white" />}
                                                >
                                                    {
                                                        transaction.map((item, index) => {
                                                            return (
                                                                <RenderItem
                                                                    item={item}
                                                                    index={index}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </InfiniteScroll>
                                            </div>
                                        </> :
                                        <div style={{ textAlign: "center", marginBottom: "3rem", marginTop: "2rem" }}>
                                            <Center>
                                                <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                            </Center>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("EMPTY_SPIN_HISTORY")}</Text>
                                            <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("NO_SPIN_HISTORY_AVAILABLE_NOW")}</Text>
                                        </div>
                                }
                            </Stack>
                            <DateFilter
                                isOpen={showDatePicker}
                                onClose={() => setShowDatePicker(!showDatePicker)}
                                closeOnOverlayClick={false}
                                applyDateFilter={(data) => {
                                    let start = moment(data.startDate).format('yyyy-MM-DD');
                                    let end = moment(data.endDate).format('yyyy-MM-DD');
                                    setStartDate(start);
                                    setEndDate(end);
                                    getSpinHistory(start, end);
                                }}
                            />
                        </div>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}