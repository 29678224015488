import { _WEB_VERSION } from './constant';
export const _BASE_URL = 'https://gateway.huat77.net'; // Live
//export const _BASE_URL = 'https://sapi.epay4game.com'; // Dev
export const _BASE_API_URL = 'https://api.huat77.net';
export const _RESOURCE_URL = 'http://www.epay4game.com';

const Routes = {
    // User Actions
    __BASE_URL: _BASE_URL,
    _IMG__BASE_URL: 'http://www.epay4game.com',
    _LOGIN_URL: _BASE_URL + '/oauth2/token',
    _LOGOUT_URL: _BASE_URL + '/api/logout',
    _REGISTER_URL: _BASE_URL + '/api/register',
    _RESET_MEMBER_PASSWORD_URL: _BASE_URL + '/Member/ResetMemberPassword',
    _CHANGE_PASSWORD_URL: `${_BASE_URL}/Member/ChangePassword`,
    _FORGET_PASSWORD_URL: _BASE_URL + '/api/resetmemberpassword',
    _CHECK_IN_SPIN_LOGIN: _BASE_URL + '/MemberPromotion/RewardSpinIfNeeded',
    _GET_AVAILABLE_SPIN: _BASE_URL + '/MemberPromotion/GetAvailableSpin',
    _PERFORM_LUCKY_SPIN: _BASE_URL + '/MemberPromotion/PerformSpin',
    _GET_LUCKY_SPIN_HISTORY: _BASE_URL + '/MemberPromotion/GetSpinHistory',
    _GET_VENDOR_LIST: `${_BASE_URL}/vendor`,
    _GET_VENDOR_CATEGORY_LIST: `${_BASE_URL}/vendor/category`,
    _GET_RANK_LIST: `${_BASE_URL}/Rank`,
    _GET_MEMBER_REFERRAL_LINK: `${_BASE_URL}/Member/referral-link`,
    _NOTIFICATION_HISTORY: `${_BASE_URL}/Member/notification-history`,
    _RESEND_SECONDARY_PASSWORD: `${_BASE_URL}/api/send-secondary-password`,
    _CHANGE_SECONDARY_PASSWORD: `${_BASE_URL}/api/change-secondary-password`,
    _GET_MXPAY_PROCESSING_FEE: `${_BASE_URL}/Member/GetMxPayProcessingFee`,

    // Mini Program - ePay
    _GET_USERINFO_URL: _BASE_URL + '/Member/GetUserInfo',
    _GET_PLAYER_DETAIL_URL: _BASE_URL + '/Player/GetPlayer',
    _RESET_PLAYER_PASSWORD: _BASE_URL + '/Player/ResetPlayerPassword',
    _GET_PLAYER_LIST: _BASE_URL + '/Player/GetMyPlayerList',
    _GET_PLAYER_DETAILS: _BASE_URL + '/Player/GetPlayerDetails',
    _DELETE_PLAYER: _BASE_URL + '/Player/DeletePlayer',
    _ADD_PLAYER: _BASE_URL + '/Player/CreatePlayer',
    _COVERT_SCORE_CREDIT: _BASE_URL + '/Player/ConvertCreditScore',
    _GET_PLAYER_ACCOUNTS: _BASE_URL + '/Player/GetPlayerAccounts',
    _TRANSFER_PLAYER_SCORE: _BASE_URL + '/Player/TransferScore',
    _GET_TRANSFER_HISTORY: _BASE_URL + '/Player/CreditScoreHistory',
    _GET_TRANSFER_HISTORY_DETAIL:
        _BASE_URL + '/Player/CreditScoreHistory/Detail',
    _CLAIM_REWARD_TO_CREDIT: _BASE_URL + '/Member/ClaimRewardToCredit',
    _GET_CLAIM_REWARD_HISTORY: _BASE_URL + '/Member/ClaimRewardHistory',
    _GET_PACKAGE_LIST: _BASE_URL + '/Player/GetPackage',
    _GET_PLAYER_LOGIN_URL: _BASE_URL + '/Player/GetLoginUrl',
    _VERIFY_PLAYER_EMAIL: _BASE_URL + '/Player/VerifyEmail',
    _GET_SCORE_OPTION: _BASE_URL + '/Player/GetSetScoreOption',
    _CREATE_TRANSACTION_REQUEST: _BASE_URL + '/Member/CreateTransactionRequest',
    _GET_TRANSACTION_REQUEST: _BASE_URL + '/Member/GetTransactionRequest',
    _IS_PENDING_REQUEST: _BASE_URL + '/Member/IsPendingRequest',
    _SETTLE_TRANSACTION_REQUEST: _BASE_URL + '/Shop/SettleTransactionRequest',
    _GET_MEMBER_SCORE_TRANSACTION:
        _BASE_URL + '/Member/GetMemberScoreTransaction',
    _GET_SCORE_TRANSACTION_DETAIL:
        _BASE_URL + '/Member/GetScoreTransactionDetail',
    _CREATE_OR_UPDATE_BIOMETRIC_DATA:
        _BASE_URL + '/Member/CreateOrUpdateBiometricData',
    _BULK_CONVERT_SCORE_TO_CREDIT: _BASE_URL + '/Player/bulk-transfer-credit',

    // eGHL
    _GET_SERVICE_CATEGORY: _BASE_URL + '/epay/services/category',
    _GET_SERVICE_SUBCATEGORY: _BASE_URL + '/epay/services/subcategory',
    _GET_SERVICE_DETAIL: _BASE_URL + '/epay/services/list',
    _EPAY_TRANSACTION: _BASE_API_URL + '/epay/transaction',
    _CREATE_TRANSACTION: _BASE_URL + '/TopUp/CreateTransaction',
    _CREATE_DUITNOW_TRANSACTION: _BASE_URL + '/TopUp/CreateDuitNowTransaction',
    _GET_CREDIT_TRANSACTION_LIST: _BASE_URL + '/TopUp/TransactionHistory',
    _GET_CREDIT_TRANSACTION_DETAIL: _BASE_URL + '/TopUp/TransactionDetail',
    _GET_EGHL_TRANSACTION_LIST: _BASE_URL + '/epay/transaction/list',
    _GET_EGHL_TRANSACTION_DETAIL: _BASE_URL + '/epay/transaction/detail',

    // Chat
    _GET_CUSTOMER_SERVICE_INFO: _BASE_URL + '/Member/GetCustomerServiceInfo',
    _INITIATE_LIVE_CHAT: _BASE_URL + '/Member/InitiateLiveChat',
    _INITIATE_LIVE_CHAT_CONFIGURATION: _BASE_URL + '/Member/InitiateLiveChatConfiguration',
    _LIVECHAT_REQUEST_WITHDRAWAL:
        _BASE_URL + '/Member/livechat-request-withdrawal',
    _GET_LAST_MESSAGE: _BASE_URL + '/Member/GetLastMemberMessages',
    _GET_MESSAGES: _BASE_URL + '/Member/GetMyMessages',
    _READ_MESSAGE: _BASE_URL + '/Member/ReadMessage',
    _DELETE_MESSAGE_URL: _BASE_URL + '/Member/DeleteMessages',

    // Settings
    _GET_MINI_PROGRAM_LIST: _BASE_URL + '/Member/GetMiniProgram',
    _SET_ENABLE_BIT: _BASE_URL + '/Member/UpdateMiniProgram',

    // Contact
    _MATCH_CONTACTS: _BASE_URL + '/Member/Contacts',

    _MEMBER_DETAILS: `${_BASE_URL}/Member/{id}?VersionCode={androidVersion}`,
    _EDIT_MEMBER: `${_BASE_API_URL}/Member/{id}`,
    _SCAN_MEMBER_QR: `${_BASE_URL}/Member/search-valid-member`,

    _GPAY88_CREATE_TRANSACTION: 'https://gateway.mygpay88.com',
    _MEMBER_TRANSFER_CREDIT: `${_BASE_URL}/Member/member-transfer`,

    // Downlines
    _MY_DOWNLINES_INFO: `${_BASE_URL}/Member/own-downline-detail`,
    _MY_DOWNLINES: `${_BASE_URL}/Member/search-downline`,
    _MY_UPLINE_INFO: `${_BASE_URL}/Member/upline-info`,
    _DOWNLINE_COMMISSION_REPORT: `${_BASE_URL}/Member/upline-commission`,

    _CANCEL_GPAY88_TRANSACTION: `${_BASE_URL}/TopUp/CancelTransaction`,
    _GET_MEMBER_TRANSFER_HISTORY: `${_BASE_URL}/Member/member-transfer-history`,

    _GET_ANNOUNCEMENT: `${_BASE_URL}/Data/GetAnnouncements`,

    // Feedback
    _GET_FEEDBACK_OPTIONS: `${_BASE_URL}/Data/feedbackOptions`,
    _POST_FEEDBACK: `${_BASE_URL}/Member/feedback`,

    // Kog
    _EVENT: `${_BASE_URL}/event`,
    _EVENT_DETAILS: `${_BASE_URL}/event/{id}`,
    _EVENT_KOG_RANKING: `${_BASE_URL}/kog`,
    _EVENT_USER_KOG_RANKING: `${_BASE_URL}/kog/{id}`,

    _MEMBER_INACTIVITY_ALERT: `${_BASE_URL}/Member/Inactivity-alert`,

    // Lobby
    _REQUEST_LOBBY_URL: `${_BASE_URL}/Lobby/{playerId}`,

    // Epay tutorial
    _GET_TUTORIAL: `https://tutorial.epay4game.com`,

    _VENDOR_BANNERS: `${_BASE_URL}/Data/banner?page=1&pageSize=10`,
    _HOMESCREEN_BANNERS: `${_BASE_URL}/Data/banner?bannerType=1`,

    _MEMBER_CONTACTS: `${_BASE_URL}/member/contact`,
    _GET_REWARD_HISTORY: _BASE_URL + '/report/GetRewardHistory',
    _GET_MERGED_TRANSACTION_HISTORY:
        _BASE_URL + '/report/GetMergedTransactionList',
    _GET_MERGED_CONVERT_HISTORY:
        _BASE_URL + '/report/GetMergedConvertTransactionList',

    _CREATE_EWALLET_TRANSACTION: _BASE_API_URL + '/mxpay',
    _EWALLET_TRANSACTION_DETAIL:
        _BASE_URL + '/member/ewallet-transaction-detail',

    _CHECK_IS_LOGGED_IN: `${_BASE_URL}/member/IsLoggedIn`,

    _VERIFY_LIVECHAT_TOKEN: `${_BASE_URL}/member/livechat-token`,

    _ONLINE_WITHDRAWAL: `${_BASE_URL}/transaction/online-withdrawal`,
    _ONLINE_WITHDRAWAL_DETAIL: `${_BASE_URL}/transaction/online-withdrawal/{id}`,
    _BANK_INFO: `${_BASE_URL}/transaction/online-withdrawal/info`,
    _CREATE_OR_UPDATE_BANK: `${_BASE_URL}/member/bank-account`,
    _GET_ACCOUNT_VERIFICATION: `${_BASE_URL}/api/init-liveness-check`,

    _INIT_WINPAY: `${_BASE_URL}/topup/win-pay/init`,
    _WINPAY_RATES: `${_BASE_URL}/topup/win-pay/rates`,

    _WINPAY_DETAILS: `${_BASE_URL}/topup/transaction-detail`,
    _CHECK_BONUS_STATUS: `${_BASE_URL}/api/bonus-unlock`,
    _DOWNLINE_WINLOSS_REPORT: `${_BASE_URL}/member/downline-winloss`,
    _GET_CUSTOMER_SERVICE_WITHOUT_LOGIN: `${_BASE_URL}/api/get-customer-service`,
    _GET_USER_BANK_ACCOUNTS: `${_BASE_URL}/member/bank-accounts`,
    _GET_USER_BANK_ACCOUNT: `${_BASE_URL}/member/bank-account`,
    _GET_BANK_LIST: `${_BASE_URL}/api/bank-list`,
    _CREATE_BANK_FOR_WELCOME_BONUS: `${_BASE_URL}/api/welcome-bonus-bank-account`,
    _CLAIM_WELCOME_BONUS: `${_BASE_URL}/member/welcome-bonus/claim`,
    _SWC_GET_PLAYER_DETAILS: _BASE_URL + "/Player/GetPlayerDetails",
    _SWC_GET_PLAYER_LIST: _BASE_URL + "/Player/GetMyPlayerList",
    _SWC_GET_LOTTO_RESULT: _BASE_URL + "/Player/GetLottoResult",
    _SWC_MAKE_ORDER: _BASE_URL + "/Player/MakeOrder",
    _SWC_CHECK_RECEIPTS: _BASE_URL + "/Player/CheckReceipts",
    _SWC_CANCEL_TICKET: _BASE_URL + "/Player/CancelOrder",
    _SWC_CHECK_STRIKE: _BASE_URL + "/Player/CheckStrike",

    _CHECK_IN: `${_BASE_URL}/member/third-party/check-in`,
    _GENERATE_OTP: `${_BASE_URL}/member/otp/generate`,

    // Anonymous api
    _ANONYMOUS_GET_VENDOR_CATEGORY: _BASE_URL + "/home/vendor/category",
    _ANONYMOUS_GET_VENDOR: _BASE_URL + "/home/vendor",
};

export default Routes;
