import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Text, Stack, Box, Flex, Card, CardBody, Spacer, Image, Spinner, Button, Divider, Center, AbsoluteCenter, HStack } from "@chakra-ui/react";
import GeneralTopBar from "../../../component/general-top-bar";
import { isObjectEmpty, numberWithCurrencyFormat, copyToClipboard, createFormBody, stringIsNullOrEmpty, share, checkBrowser } from "../../../common/util";
import { useRouter } from "../../../hooks/useRouter";
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Status, _BELOW_AMOUNT_TO_DELETE, ValidationPattern, ApiKey, ConvertType } from "../../../common/constant";
import QrCode from "../../../component/qr-code";
import { showCustomDialog } from "../../../application/action/app_action";
import CurrencyInput from 'react-currency-input-field';
import { getMemberDetails } from "../../../application/action/auth_action";
import axios from 'axios';
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _userData = useSelector((state) => state.authState.userData);
    const { control, handleSubmit, errors, setValue, watch, clearErrors } = useForm();
    const { t } = useTranslation();
    const _CONVERT_TO_SCORE = 'CONVERT_TO_SCORE';
    const _CONVERT_TO_CREDIT = 'CONVERT_TO_CREDIT';
    const _PARAMS = _location?.state;
    const vendorList = _PARAMS?.vendorList;
    const selectedVendor = _PARAMS?.selectedVendor;
    const [playerDetail, setPlayerDetail] = useState(_PARAMS?.playerDetail);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [convertState, setConvertState] = useState(_CONVERT_TO_SCORE);
    const [resetAmountBit, setResetAmountBit] = useState(true);
    const cancelTokenSource = axios.CancelToken.source();
    const _BOTTOM_ELEMENTS = [
        { title: 'PLAY_GAME', color: '#32CD32', textColor: 'white', borderTopLeft: true },
        { title: 'WITHDRAW', color: '#FFD700' },
        { title: 'TRANSFER', color: '#b2ffb2', borderTopRight: true }
    ];
    const _RESET_PASSWORD = "RESET_PASSWORD";
    const _DELETE_PLAYER = "DELETE_PLAYER";
    const _IS_IOS = navigator.userAgent.indexOf("like Mac") != -1;
    const [content, setContent] = useState();
    const [walletAmount, setWalletAmount] = useState(0);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back()
        }
        else {
            refreshCreditScore(false);
            setValue('amount', parseFloat(_userData.credit));
        }

        // Component unmount
        return () => {
            // Cancel ongoing request
            cancelTokenSource.cancel();
        }
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        resetInputAmount();
    }, [convertState, resetAmountBit])

    /// <summary>
    /// Author: Juin
    /// </summary>
    function resetInputAmount() {
        setValue('amount', parseFloat(convertState === _CONVERT_TO_SCORE ? _userData.credit : playerDetail.score ?? 0));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function changeConvertState() {
        let convertToScore = true;

        if (convertState == _CONVERT_TO_SCORE) {
            setConvertState(_CONVERT_TO_CREDIT);
            convertToScore = false;
        }
        else {
            setConvertState(_CONVERT_TO_SCORE);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getPlayerDetail() {
        try {
            let responseJson = await ApiEngine.get(`${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${playerDetail.username}`,
                {
                    cancelToken: cancelTokenSource.token,
                    headers: { "skipLoading": true }
                }
            );

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            const {
                score,
                disable,
                isSameRegion
            } = responseJson[ApiKey._API_DATA_KEY][0];

            setPlayerDetail({
                ...playerDetail,
                isSameRegion,
                score,
                status: disable ? Status._DISABLED : Status._ENABLED
            });

            if (convertState === _CONVERT_TO_CREDIT) {
                setValue('amount', numberWithCurrencyFormat(score ?? 0).toString());

                if (errors?.amount && score > 0) {
                    clearErrors('amount');
                }
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function refreshCreditScore(readPlayerScore, isResetRequired = false) {
        setIsLoadingDetail(true);
        await _dispatch(getMemberDetails()); // Refresh wallet credit

        // Get player score
        if (!selectedVendor.enableManualReadScore || readPlayerScore) {
            await getPlayerDetail();
        }

        if (isResetRequired) {
            setResetAmountBit(!resetAmountBit);
        }

        setIsLoadingDetail(false);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function bottomBtnActionHandler(key) {
        switch (key) {
            case _BOTTOM_ELEMENTS[0]['title']:
                if (playerDetail.score <= 0) {
                    _dispatch(
                        showCustomDialog({
                            title: 'INSUFFICIENT_BALANCE',
                            content: t('TRANSFER_DESCRIPTION') + '\n\n' + t('CURRENT_SCORES') + ' ('
                                + numberWithCurrencyFormat(playerDetail.score ?? 0) + ')',
                            confirmTxt: 'YES_TRANSFER_NOW',
                            onConfirm: () => {
                                _router.navigate(Pages._TRANSFER_SCORE, {
                                    vendorList: vendorList.map((item) => { return { ...item, vendor_id: item.id.toString() } }),
                                    selectedVendor: { ...selectedVendor, vendor_name: selectedVendor.gameName },
                                    selectedPlayer: { ...playerDetail, vendorPlayerid: playerDetail.username, gameScore: playerDetail.score },
                                    memberGuid: _userData.guid,
                                    from: Pages._PLAYER_DETAIL,
                                    prevParams: _PARAMS
                                });
                            },
                            cancelTxt: 'LAUNCH_APP_TXT',
                            onCancel: () => launchApp(),
                            horizontalBtn: false,
                        })
                    );
                }
                else {
                    launchApp();
                }
                break;
            case _BOTTOM_ELEMENTS[1]['title']:
                _router.navigate(Pages._WITHDRAW_E_POINT, {
                    userVendorList: vendorList.map((item) => { return { ...item, vendor_id: item.id.toString() } }),
                    selectedPlayer: playerDetail,
                    selectedVendor: selectedVendor
                });
                break;
            case _BOTTOM_ELEMENTS[2]['title']:
                _router.navigate(Pages._TRANSFER_SCORE, {
                    vendorList: vendorList.map((item) => { return { ...item, vendor_id: item.id.toString() } }),
                    selectedVendor: { ...selectedVendor, vendor_name: selectedVendor.gameName },
                    selectedPlayer: { ...playerDetail, vendorPlayerid: playerDetail.username, gameScore: playerDetail.score },
                    memberGuid: _userData.guid,
                    from: Pages._PLAYER_DETAIL,
                    prevParams: _PARAMS
                });
                break;
            default:
                break;
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function launchApp() {
        if (selectedVendor.hasLobby) {
            requestLobbyUrl(playerDetail.id);
        }
        else if (!playerDetail.appDeepLink && !playerDetail.appPackageName && !playerDetail.navigationKey && !playerDetail.appDownloadUrlAndroid && !playerDetail.appDownloadUrlIos) {
            requestWebviewLoginUrl(playerDetail.id);
        }
        else if (!_IS_IOS && playerDetail.appPackageName.indexOf("pussy888") > -1) {
            window.location = playerDetail.appPackageName;
            setTimeout(() => {
                openAppDownloadLink();
            }, 2000);
        }
        else if (playerDetail.appDeepLink) {
            window.location = playerDetail.appDeepLink;
            setTimeout(() => {
                openAppDownloadLink();
            }, 2000);
        }
        else if (playerDetail.appPackageName && !_IS_IOS) {
            window.location = `intent://${playerDetail.appPackageName}#Intent;package=${playerDetail.appPackageName};end"`
            setTimeout(() => {
                openAppDownloadLink();
            }, 2000);
        }
        else if (playerDetail.navigationKey) {
            let selectedMiniProgram = selectedVendor.miniProgram;

            if (!selectedMiniProgram || (selectedMiniProgram && !selectedMiniProgram.status)) {
                _dispatch(showCustomDialog({ success: false, content: t('MINI_PROGRAM_NOT_AVAILABLE') }));
            }
            else {
                let miniProgram = {
                    "vendorId": selectedMiniProgram.vendorId,
                    "programName": selectedMiniProgram.name,
                    "programThumbnail": selectedMiniProgram.logo,
                    "autoAddPlayer": selectedMiniProgram.autoAddPlayer,
                    "navigationPath": selectedMiniProgram.navigationKey
                }

                _router.navigate(Pages[miniProgram.navigationPath], { miniProgram: miniProgram, playerId: playerDetail.id, selectedVendor: selectedVendor });
            }
        }
        else {
            openAppDownloadLink();
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const requestLobbyUrl = async (playerId) => {
        try {
            let responseJson = await ApiEngine.get(Routes._REQUEST_LOBBY_URL.replace("{playerId}", playerId));

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                lauchWebView(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err.message }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const requestWebviewLoginUrl = async (playerId) => {
        try {
            var responseJson = await ApiEngine.get(Routes._GET_PLAYER_LOGIN_URL + "?playerId=" + playerId);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                lauchWebView(responseJson[ApiKey._API_DATA_KEY].loginUrl)
            }
            else {
                _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err.message }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const lauchWebView = async (url) => {
        try {
            _router.navigate(Pages._WEB_VIEW, { dataUrl: `${url}`, title: playerDetail?.vendorName })
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err.message }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function openAppDownloadLink(url = '') {
        if (stringIsNullOrEmpty(url)) {
            if (!_IS_IOS) {
                url = playerDetail.appDownloadUrlAndroid;
            }
            else {
                url = playerDetail.appDownloadUrlIos;
            }
        }

        const newWindow = window.open(url, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            alert('Pop-up blocked. Please allow pop-ups for this site.');
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function onShare(game, response) {
        try {
            if (response) {
                let shareMsg = "ePay Pro \n\n";
                shareMsg += response.member ? "Username: " + response.member + "\n" : "";
                shareMsg += "Game: " + game + "\n";
                shareMsg += response.vendor_username ? "ID: " + response.vendor_username + "\n" : "";
                shareMsg += response.password ? "Pw: " + response.password + "\n" : "";
                shareMsg += response.downloadUrlAndroid ? "Download URL (Android): " + response.downloadUrlAndroid + "\n" : "";
                shareMsg += response.downloadUrlIOS ? "Download URL (IOS): " + response.downloadUrlIOS + "\n" : "";
                shareMsg += "\nePay Pro \n\n";
                shareMsg += response.dateTime;

                share(shareMsg);
            }
        }
        catch (ex) {
            console.log(ex)
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function actionHandler(actionState) {
        _dispatch(showCustomDialog({
            success: true,
            title: actionState == _RESET_PASSWORD ? "RESET_PASSWORD" : "DISABLE_PLAYER",
            content: actionState == _RESET_PASSWORD ? "RESET_PLAYER_PASSWORD_CONFIRMATION" : "DISABLE_PLAYER_CONFIRMATION",
            onConfirm: async () => {
                try {
                    let responseJson;

                    if (actionState == _RESET_PASSWORD) {
                        let params = {
                            'Id': _userData.guid,
                            'PlayerId': playerDetail.id,
                            'VendorId': selectedVendor.id,
                        };

                        let formBody = createFormBody(params);
                        responseJson = await ApiEngine.post(Routes._RESET_PLAYER_PASSWORD, formBody);
                    }
                    else if (actionState == _DELETE_PLAYER) {
                        let params = {
                            'PlayerId': playerDetail.id,
                            'VendorId': selectedVendor.id,
                        };

                        let formBody = createFormBody(params);
                        responseJson = await ApiEngine.post(Routes._DELETE_PLAYER, formBody);
                    }

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        _dispatch(showCustomDialog({
                            success: true,
                            headerTitle: t('SUCCESS'),
                            customContent: (
                                actionState == _RESET_PASSWORD &&
                                <Center>
                                    <Stack>
                                        <Text>{t('RESET_PLAYER_PASSWORD_SUCCESS')}</Text>
                                        <Flex justify='center'>
                                            <Text>{t('PLAYER_ID') + ': ' + responseJson[ApiKey._API_DATA_KEY]['vendor_username']}</Text>
                                            <Button variant='link' onClick={() => {
                                                copyToClipboard(responseJson[ApiKey._API_DATA_KEY]['vendor_username'], t('COPIED_SUCCESFULLY'))
                                            }}>
                                                <Image boxSize='30px' src={require('../../../assets/images/copy.png')} />
                                            </Button>
                                        </Flex>
                                        <Flex justify='center'>
                                            <Text>{t('PASSWORD') + ': ' + responseJson[ApiKey._API_DATA_KEY]['password']}</Text>
                                            <Button variant='link' onClick={() => {
                                                copyToClipboard(responseJson[ApiKey._API_DATA_KEY]['password'], t('COPIED_SUCCESFULLY'))
                                            }}>
                                                <Image boxSize='30px' src={require('../../../assets/images/copy.png')} />
                                            </Button>
                                        </Flex>
                                        <Center>
                                            <Button
                                                className="grey-share-button"
                                                style={{ width: '40%' }}
                                                leftIcon={<Image boxSize='30px'
                                                    src={require('../../../assets/images/share-btn.png')} />}
                                                onClick={() => {
                                                    onShare(selectedVendor?.gameName, responseJson[ApiKey._API_DATA_KEY]);
                                                }}>
                                                <Text color={'#2A3247'}>{t('SHARE')}</Text>
                                            </Button>
                                        </Center>
                                    </Stack>
                                </Center>
                            ),
                            onConfirm: () => {
                                if (actionState == _DELETE_PLAYER) {
                                    _router.navigate(Pages._DEFAULT, {}, true);
                                }
                            }
                        }));
                    }
                    else {
                        throw responseJson[ApiKey._API_MESSAGE_KEY];
                    }
                } catch (err) {
                    _dispatch(showCustomDialog({ success: false, content: err }));
                }
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        if (data.amount <= 0) {
            _dispatch(showCustomDialog({ success: false, content: "INCORRECT_INPUT_ERROR_MSG" }));
        }
        else {
            _dispatch(showCustomDialog({
                success: true,
                title: "CONFIRMATION",
                content: convertState === _CONVERT_TO_SCORE ? "CONVERT_TO_SCORES_CONFIRMATION" : "CONVERT_TO_CREDIT_CONFIRMATION",
                onConfirm: () => {
                    convertCreditScore(data.amount);
                },
                onCancel: () => { },
                horizontalBtn: false
            }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function convertCreditScore(amount) {
        try {
            let params = {
                'PlayerId': playerDetail.id,
                'Amount': amount,
                'TargetType': convertState === _CONVERT_TO_CREDIT ? ConvertType._CREDIT : ConvertType._SCORE
            };

            let formBody = createFormBody(params);
            let responseJson = await ApiEngine.post(Routes._COVERT_SCORE_CREDIT, formBody);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                refreshCreditScore(true, true);
                _dispatch(showCustomDialog({ success: true, content: convertState === _CONVERT_TO_CREDIT ? "CONVERT_TO_CREDIT_SUCCESS" : "CONVERT_TO_SCORE_SUCCESS" }));
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div style={{ height: '107vh', display: 'flex', flexDirection: 'column' }}>
                <GeneralTopBar title={selectedVendor?.gameName} style={{ position: 'relative' }} />
                {/* <HStack>
                    <Button variant='link' onClick={() =>  _router.back()}>
                                <Image src={require('../../../assets/images/svg/general-icon-back.svg').default} boxSize={'30px'} objectFit='cover' />
                    </Button>
                    <Spacer />
                    <Text>{selectedVendor?.gameName}</Text>
                    <Spacer />
                </HStack> */}
                <Stack align={'center'} overflow={'auto'} height={'100%'} padding={'0px 15px'}>
                    <Card className="player-detail-box" borderRadius='20px' mb={2}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <CardBody>
                                <Flex gap={5}>
                                    <Flex align={'center'} flexDirection={'column'} pl={2} w={'100%'}>
                                        <Text mr={2}>{t('ECREDIT')}</Text>
                                        <Box
                                            position={'relative'}
                                            paddingInline={2}
                                            paddingBlock={.5}
                                            w={'80%'}
                                            borderColor={'#ac61e1'}
                                            bg={'linear-gradient(#3f0864,#5a1487)'}
                                            borderWidth={'1px'}
                                            borderRadius={'15px'}>
                                            <Image
                                                style={{
                                                    position: 'absolute',
                                                    left: '-10px',
                                                    top: '-5px'
                                                }}
                                                boxSize={'28px'}
                                                src={require('../../../assets/images/topbar-icon-credit.png')} />
                                            <Text float={'right'}>{numberWithCurrencyFormat(_userData.credit ?? 0)}</Text>
                                        </Box>
                                    </Flex>
                                    <Flex align={'center'} flexDirection={'column'} w={'100%'}>
                                        <Text mr={2}>{t('EPOINT')}</Text>
                                        <Box
                                            position={'relative'}
                                            paddingInline={2}
                                            paddingBlock={.5}
                                            w={'80%'}
                                            borderColor={'#ac61e1'}
                                            bg={'linear-gradient(#3f0864,#5a1487)'}
                                            borderWidth={'1px'}
                                            borderRadius={'15px'}>
                                            <Image
                                                style={{
                                                    position: 'absolute',
                                                    left: '-10px',
                                                    top: '-5px'
                                                }}
                                                boxSize={'28px'}
                                                src={require('../../../assets/images/topbar-icon-score.png')} />
                                            <Text float={'right'}>{numberWithCurrencyFormat(playerDetail.score ?? 0)}</Text>
                                        </Box>
                                    </Flex>
                                    {
                                        isLoadingDetail ?
                                            <Spinner color='white' /> :
                                            <Button variant='link' onClick={() => { refreshCreditScore(true); }}>
                                                <Image
                                                    boxSize={'30px'}
                                                    src={require('../../../assets/images/svg/topbar-icon-refresh.svg').default} />
                                            </Button>
                                    }
                                </Flex>
                            </CardBody>
                            <Divider borderColor={'#8b44ae'} borderBottomWidth={'3px'} />
                            <CardBody>
                                <Flex>
                                    <Stack justify={'center'} alignSelf={'flex-start'}>
                                        <Flex align='center' mb={-2}>
                                            <Text as='b' fontSize={'xl'} color='#f1bc6e'>{playerDetail.username}</Text>
                                            <Button variant='link' onClick={() => {
                                                copyToClipboard(playerDetail.username, t('COPIED_SUCCESFULLY'))
                                            }}>
                                                <Image boxSize='30px' src={require('../../../assets/images/svg/general-copy.svg').default} />
                                            </Button>
                                        </Flex>
                                        {
                                            playerDetail.status == Status._DISABLED ?
                                                <Flex>
                                                    <Text color='#959CA7'>{t('PLAYER_BLOCKED')}</Text>
                                                </Flex> :
                                                <Text>{t('MY_PLAYER_ID')}</Text>
                                        }
                                    </Stack>
                                    <Spacer />
                                    <QrCode value={playerDetail.username} />
                                </Flex>
                            </CardBody>
                            <Flex w='full'>
                                <Button
                                    isDisabled={isLoadingDetail || playerDetail.status == Status._DISABLED}
                                    className="purple-button"
                                    leftIcon={<Image boxSize='30px'
                                        src={require('../../../assets/images/svg/general-reset.svg').default} />}
                                    style={{ borderRadius: 0, borderRight: '1px solid #7d22b0' }}
                                    onClick={() => { actionHandler(_RESET_PASSWORD); }}>
                                    {t('RESET_PASSWORD')}
                                </Button>
                                <Button
                                    isDisabled={isLoadingDetail || (playerDetail.score != undefined && Number(playerDetail.score) > _BELOW_AMOUNT_TO_DELETE)}
                                    className="purple-button"
                                    leftIcon={<Image boxSize='30px'
                                        src={require('../../../assets/images/svg/general-delete.svg').default} />}
                                    style={{ borderRadius: 0, borderLeft: '1px solid #b75eec' }}
                                    onClick={() => { actionHandler(_DELETE_PLAYER); }}>
                                    {t('DELETE_PLAYER')}
                                </Button>
                            </Flex>
                            <CardBody>
                                <Stack>
                                    <Text fontWeight='700' mb={-2} fontSize={'sm'} color={'#f1bc6e'}>{convertState == _CONVERT_TO_SCORE ? t('FROM_ECREDIT') : `${t('FROM_EPOINT')} (${selectedVendor.gameName})`}</Text>
                                    <Text as='i' fontSize={'sm'}>{convertState == _CONVERT_TO_SCORE ? t('USERNAME') : t('PLAYER_ID')}: {convertState == _CONVERT_TO_SCORE ? _userData.username : playerDetail.username}</Text>
                                    <Box w='full' borderRadius={20} borderColor='#f1bc6e' borderWidth={1} bg={'linear-gradient(#5a008a,#8419c6)'}>
                                        <Controller
                                            control={control}
                                            name="amount"
                                            defaultValue={numberWithCurrencyFormat(convertState == _CONVERT_TO_SCORE ? _userData.credit : (playerDetail.score ?? 0))}
                                            render={({ onChange, value, name }) => (
                                                <Stack direction='row' paddingInline={3}>
                                                    <Image
                                                        boxSize={'35px'}
                                                        src={convertState == _CONVERT_TO_SCORE ? require('../../../assets/images/topbar-icon-credit.png') : require('../../../assets/images/topbar-icon-score.png')} />
                                                    <CurrencyInput
                                                        style={{
                                                            textAlign: 'end',
                                                            backgroundColor: 'transparent',
                                                            color: 'white',
                                                            fontWeight: '500',
                                                            fontSize: '1rem',
                                                            width: '70%',
                                                            outlineWidth: 0
                                                        }}
                                                        id={name}
                                                        name={name}
                                                        value={value}
                                                        placeholder="0.00"
                                                        allowNegativeValue
                                                        decimalScale={2}
                                                        autoComplete={false}
                                                        onFocus={(e) => e.target.select()}
                                                        onValueChange={(value, name) => {
                                                            onChange(value);
                                                        }}
                                                    />
                                                    <Divider orientation='vertical' h='35px' />
                                                    <Button
                                                        variant='link'
                                                        w={'20%'}
                                                        isDisabled={isLoadingDetail || playerDetail.status == Status._DISABLED}
                                                        onClick={() => {
                                                            setValue('amount', convertState == _CONVERT_TO_SCORE ? parseFloat(_userData.credit) : (playerDetail.score ?? 0));
                                                            setWalletAmount(convertState == _CONVERT_TO_SCORE ? parseFloat(_userData.credit) : (playerDetail.score ?? 0));
                                                        }}>
                                                        <Text fontSize={'sm'}>{t('MAX')}</Text>
                                                    </Button>
                                                </Stack>
                                            )}
                                            rules={{
                                                required: true, minLength: 1, maxLength: 10, pattern: ValidationPattern._DECIMAL_REGEX,
                                                max: convertState == _CONVERT_TO_SCORE ? _userData.credit : (playerDetail.score ?? 0)
                                            }}
                                        />
                                    </Box>
                                    {
                                        errors && errors['amount'] &&
                                        <Text color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                                    }
                                </Stack>
                            </CardBody>
                            <Box position='relative' paddingBlock={5} paddingInline={5}>
                                <Divider border={'1px solid #ab4be7'} />
                                <AbsoluteCenter px='4'>
                                    <Button
                                        variant='link'
                                        leftIcon={<Image boxSize='35px'
                                            transform={convertState == _CONVERT_TO_CREDIT ? 'rotate(180deg)' : ''}
                                            src={require('../../../assets/images/svg/general-convert.svg').default} />}
                                        onClick={() => changeConvertState()} />
                                </AbsoluteCenter>
                            </Box>
                            <CardBody pt={4} mb={2}>
                                <Stack>
                                    <Text fontSize={'sm'} fontWeight='700' mb={-2} color={'#f1bc6e'}>{convertState == _CONVERT_TO_CREDIT ? t('TO_ECREDIT') : `${t('TO_EPOINT')} (${selectedVendor.gameName})`}</Text>
                                    <Text fontSize={'sm'} as='i'>{convertState == _CONVERT_TO_CREDIT ? t('USERNAME') : t('PLAYER_ID')}: {convertState == _CONVERT_TO_CREDIT ? _userData.username : playerDetail.username}</Text>
                                    <Box w='full' borderRadius={20} borderColor='#f1bc6e' borderWidth={1} bg={'linear-gradient(#5a008a,#8419c6)'}>
                                        <Flex paddingInline={3} align='center'>
                                            <Image
                                                boxSize={'35px'}
                                                src={convertState == _CONVERT_TO_CREDIT ? require('../../../assets/images/topbar-icon-credit.png') : require('../../../assets/images/topbar-icon-score.png')} />
                                            <Spacer />
                                            <Text as='b' fontSize='md' pr={4}>
                                                {
                                                    numberWithCurrencyFormat(convertState == _CONVERT_TO_CREDIT ? (_userData.credit) : (playerDetail.score ?? 0))
                                                }
                                            </Text>
                                            <Divider orientation='vertical' h='35px' />
                                            <Spacer />
                                            <Text as='b' fontSize='md'>
                                                {
                                                    `+ ${watch("amount") > 0 ? watch("amount") : '0.00'}`
                                                }
                                            </Text>
                                        </Flex>
                                    </Box>
                                </Stack>
                            </CardBody>
                            <Button
                                className="purple-button"
                                style={{ borderRadius: 0, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                                type="submit">
                                {t('CONVERT')}
                            </Button>
                        </form>
                    </Card>
                </Stack>
                <Flex className="bottom-sticky-button">
                    {
                        _BOTTOM_ELEMENTS.map((key, index) => {
                            let isBtnDisabled = isLoadingDetail || playerDetail.status == Status._DISABLED;

                            return (
                                <Button
                                    key={index}
                                    w={'100%'}
                                    isDisabled={isBtnDisabled}
                                    style={{
                                        backgroundColor: key.color,
                                        width: '100%',
                                        borderRadius: 0,
                                        height: '3rem',
                                        borderTopLeftRadius: key.borderTopLeft ? '20px' : 0,
                                        borderTopRightRadius: key.borderTopRight ? '20px' : 0,
                                        color: key.textColor
                                    }}
                                    onClick={() => bottomBtnActionHandler(key.title)}>
                                    {t(key.title)}
                                </Button>
                            )
                        })
                    }
                </Flex>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{selectedVendor.gameName}</Text>
                        </div>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <HStack spacing={8} alignItems={'flex-start'}>
                                <div id="test" style={{ flexGrow: "0" }}>
                                    <Flex gap={4} mb={3}>
                                        <Flex align={'center'}>
                                            <Text fontSize={'sm'} mr={5}>{t('ECREDIT')}</Text>
                                            <Box
                                                position={'relative'}
                                                paddingInline={6}
                                                paddingBlock={.5}
                                                borderColor={'#f8c763'}
                                                borderWidth={'1px'}
                                                borderRadius={'15px'}
                                                bg={'linear-gradient(#3f0864,#5a1487)'}>
                                                <Image
                                                    style={{
                                                        position: 'absolute',
                                                        left: '-18px',
                                                        top: '-5px'
                                                    }}
                                                    boxSize={'35px'}
                                                    src={require('../../../assets/images/topbar-icon-credit.png')} />
                                                <Text fontSize={'sm'}>{numberWithCurrencyFormat(_userData.credit ?? 0)}</Text>
                                            </Box>
                                        </Flex>
                                        <Flex align={'center'}>
                                            <Text fontSize={'sm'} mr={5}>{t('EPOINT')}</Text>
                                            <Box
                                                position={'relative'}
                                                paddingInline={6}
                                                paddingBlock={.5}
                                                borderColor={'#f8c763'}
                                                borderWidth={'1px'}
                                                borderRadius={'15px'}
                                                bg={'linear-gradient(#3f0864,#5a1487)'}>
                                                <Image
                                                    style={{
                                                        position: 'absolute',
                                                        left: '-18px',
                                                        top: '-5px'
                                                    }}
                                                    boxSize={'35px'}
                                                    src={require('../../../assets/images/topbar-icon-score.png')} />
                                                <Text fontSize={'sm'}>{numberWithCurrencyFormat(playerDetail.score ?? 0)}</Text>
                                            </Box>
                                        </Flex>
                                        {
                                            isLoadingDetail ?
                                                <Spinner color='white' /> :
                                                <Button variant='link' onClick={() => { refreshCreditScore(true); }} >
                                                    <Image
                                                        boxSize={'30px'}
                                                        src={require('../../../assets/images/svg/topbar-icon-refresh.svg').default} />
                                                </Button>
                                        }
                                    </Flex>
                                    <Card background={"unset"} boxShadow={'none'}>
                                        <CardBody padding={"unset"} mb={6}>
                                            <Stack>
                                                <Text fontWeight='bold' mb={-2} color={'#f8c763'}>{convertState == _CONVERT_TO_SCORE ? t('FROM_ECREDIT') : `${t('FROM_EPOINT')} (${selectedVendor.gameName})`}</Text>
                                                <Text as='i' mb={3}>{convertState == _CONVERT_TO_SCORE ? t('USERNAME') : t('CONVERT_TO_PLAYER_ID')}: {convertState == _CONVERT_TO_SCORE ? _userData.username : playerDetail.username}</Text>
                                                <Box w='full' mb={3} borderRadius={20} bg={'linear-gradient(#5a008a,#8419c6)'} borderColor={'transparent'} borderWidth={2} _hover={{ border: "2px solid white" }} transitionDuration={'normal'}>
                                                    <Controller
                                                        control={control}
                                                        name="amount"
                                                        defaultValue={parseFloat(convertState == _CONVERT_TO_SCORE ? (_userData.credit) : (playerDetail.score ?? 0))}
                                                        render={({ onChange, value, name }) => (
                                                            <Stack spacing={3} direction='row' paddingBlock={2} paddingInline={4}>
                                                                <Image
                                                                    boxSize={'35px'}
                                                                    src={convertState == _CONVERT_TO_SCORE ? require('../../../assets/images/topbar-icon-credit.png') : require('../../../assets/images/topbar-icon-score.png')} />
                                                                <CurrencyInput
                                                                    style={{
                                                                        textAlign: 'end',
                                                                        backgroundColor: 'transparent',
                                                                        color: 'white',
                                                                        fontWeight: '500',
                                                                        fontSize: '0.875rem',
                                                                        width: '100%',
                                                                    }}
                                                                    id={name}
                                                                    name={name}
                                                                    value={value} ///here
                                                                    placeholder="0.00"
                                                                    allowNegativeValue
                                                                    decimalScale={2}
                                                                    autoComplete={false}
                                                                    onFocus={(e) => e.target.select()}
                                                                    onValueChange={(value, name) => {
                                                                        onChange(value);
                                                                    }}
                                                                />
                                                                <Divider orientation='vertical' h='35px' color={"white"} />
                                                                <Button
                                                                    variant='link'
                                                                    isDisabled={isLoadingDetail || playerDetail.status == Status._DISABLED}
                                                                    onClick={() => {
                                                                        setValue('amount', convertState == _CONVERT_TO_SCORE ? parseFloat(_userData.credit) : (playerDetail.score ?? 0));
                                                                        setWalletAmount(convertState == _CONVERT_TO_SCORE ? parseFloat(_userData.credit) : (playerDetail.score ?? 0));
                                                                    }}>
                                                                    <Text fontSize={'sm'}>{t('MAX')}</Text>
                                                                </Button>
                                                            </Stack>
                                                        )}
                                                        rules={{
                                                            required: true, minLength: 1, maxLength: 10, pattern: ValidationPattern._DECIMAL_REGEX,
                                                            max: convertState == _CONVERT_TO_SCORE ? parseFloat(_userData.credit) : (playerDetail.score ?? 0)
                                                        }}
                                                    />
                                                </Box>
                                                {
                                                    errors && errors['amount'] &&
                                                    <Text mt={-3} mb={-5} color={'red'}>{t('AMOUNT_ERROR_TXT')}</Text>
                                                }
                                            </Stack>
                                        </CardBody>
                                        <CardBody padding={"unset"}>
                                            <Stack>
                                                <HStack mb={3}>
                                                    <div>
                                                        <Text fontWeight='bold' mb={0} color={'#f8c763'}>{convertState == _CONVERT_TO_CREDIT ? t('TO_ECREDIT') : `${t('TO_EPOINT')} (${selectedVendor.gameName})`}</Text>
                                                        <Text as='i'>{convertState == _CONVERT_TO_CREDIT ? t('USERNAME') : t('PLAYER_ID')}: {convertState == _CONVERT_TO_CREDIT ? _userData.username : playerDetail.username}</Text>
                                                    </div>
                                                    <Spacer />
                                                    <div>
                                                        <Button
                                                            variant='link'
                                                            leftIcon={<Image boxSize='40px'
                                                                transform={convertState == _CONVERT_TO_CREDIT ? 'rotate(90deg)' : 'rotate(90deg)'}
                                                                src={require('../../../assets/images/svg/general-convert.svg').default} />}
                                                            onClick={() => changeConvertState()} />
                                                    </div>
                                                </HStack>
                                                <Box w='full' mb={6} borderRadius={20} bg={'linear-gradient(#5a008a,#8419c6)'}>
                                                    <Flex paddingBlock={2} paddingInline={4} align='center'>
                                                        <Image
                                                            boxSize={'35px'} mr={1}
                                                            src={convertState == _CONVERT_TO_CREDIT ? require('../../../assets/images/topbar-icon-credit.png') : require('../../../assets/images/topbar-icon-score.png')} />
                                                        <Text as='b' fontSize='sm' w={'4rem'}>
                                                            {
                                                                numberWithCurrencyFormat(convertState == _CONVERT_TO_CREDIT ? (_userData.credit) : (playerDetail.score ?? 0))
                                                            }
                                                        </Text>
                                                        <Spacer />
                                                        <Divider orientation='vertical' h='35px' color={'white'} />
                                                        <Spacer />
                                                        <Text as='b' textAlign={'right'} fontSize='sm' w={'5rem'}>
                                                            {
                                                                `+ ${watch("amount") > 0 ? watch("amount") : '0.00'}`
                                                            }
                                                        </Text>
                                                    </Flex>
                                                </Box>
                                                <Button
                                                    style={{ borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "20px 0px" }}
                                                    type="submit">
                                                    {t('CONVERT')}
                                                </Button>
                                            </Stack>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div>
                                    <Card bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius='20px' >
                                        <CardBody>
                                            <HStack mb={210}>
                                                <div>
                                                    <Stack justify={'center'} mr={6}>
                                                        <Flex align='center' mb={-2}>
                                                            <Text as='b' fontSize={'xl'} fontWeight={'500'} color='white'>{playerDetail.username}</Text>
                                                            <Button variant='link' onClick={() => {
                                                                copyToClipboard(playerDetail.username, t('COPIED_SUCCESFULLY'))
                                                            }}>
                                                                <Image boxSize='25px' src={require('../../../assets/images/svg/general-copy.svg').default} />
                                                            </Button>
                                                        </Flex>
                                                        {
                                                            playerDetail.status == Status._DISABLED ?
                                                                <Flex>
                                                                    <Text fontSize={'sm'} color='#959CA7'>{t('PLAYER_BLOCKED')}</Text>
                                                                </Flex> :
                                                                <Text fontSize={'sm'} color={'#f8c763'}>{t('MY_PLAYER_ID')}</Text>
                                                        }
                                                        <HStack spacing={2}>
                                                            <Button
                                                                isDisabled={isLoadingDetail || playerDetail.status == Status._DISABLED}
                                                                leftIcon={<Image boxSize='30px'
                                                                    src={require('../../../assets/images/svg/general-reset.svg').default} />}
                                                                style={{ fontSize: '9pt', color: 'white', paddingInlineStart: '0' }}
                                                                onClick={() => { actionHandler(_RESET_PASSWORD); }}>
                                                                {t('RESET_PASSWORD')}
                                                            </Button>
                                                            <Button
                                                                isDisabled={isLoadingDetail || (playerDetail.score != undefined && Number(playerDetail.score) > _BELOW_AMOUNT_TO_DELETE)}
                                                                leftIcon={<Image boxSize='30px'
                                                                    src={require('../../../assets/images/svg/general-delete.svg').default} />}
                                                                style={{ fontSize: '9pt', color: 'white', paddingInlineStart: '0' }}
                                                                onClick={() => { actionHandler(_DELETE_PLAYER); }}>
                                                                {t('DELETE_PLAYER')}
                                                            </Button>
                                                        </HStack>
                                                    </Stack>
                                                </div>
                                                <div style={{ width: "70px", height: "70px" }}>
                                                    <QrCode value={playerDetail.username} />
                                                </div>
                                            </HStack>
                                            <HStack>
                                                {
                                                    _BOTTOM_ELEMENTS.map((key, index) => {
                                                        let isBtnDisabled = isLoadingDetail || playerDetail.status == Status._DISABLED;

                                                        return (
                                                            <Button
                                                                key={index}
                                                                w={'100%'}
                                                                isDisabled={isBtnDisabled}
                                                                style={{
                                                                    backgroundImage: key.backgroundImage,
                                                                    backgroundColor: key.color,
                                                                    width: '100%',
                                                                    borderRadius: 15,
                                                                    height: '3rem',
                                                                    color: key.textColor,
                                                                    fontSize: '9pt'
                                                                }}
                                                                onClick={() => bottomBtnActionHandler(key.title)}>
                                                                {t(key.title)}
                                                            </Button>
                                                        )
                                                    })
                                                }
                                            </HStack>
                                        </CardBody>
                                    </Card>
                                </div>
                            </HStack>
                        </form>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}