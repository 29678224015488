import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { Text, SimpleGrid, Button, Box, Center, HStack, Image } from "@chakra-ui/react";
import ApiEngine from "../../../common/api_engine";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, isObjectEmpty } from "../../../common/util";
import { EpayIndexCategory, ApiKey } from "../../../common/constant";
import { useNavigate } from "react-router";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [menuOption, setMenuOption] = useState([]);
    const _MENU_OPTION = [
        {
            name: "MOBILE_RELOAD",
            imagePath: require("../../../assets/images/epay/epay-mobilereload.png"),
            sc_code: EpayIndexCategory._MOBILE_RELOAD
        },
        {
            name: "GAME_RELOAD",
            imagePath: require("../../../assets/images/epay/epay-gamereload.png"),
            sc_code: EpayIndexCategory._GAME_RELOAD
        },
        {
            name: "BILL_PAYMENT",
            imagePath: require("../../../assets/images/epay/epay-billpayment.png"),
            sc_code: EpayIndexCategory._BILL_PAYMENT
        },
        {
            name: "EWALLET_RELOAD",
            imagePath: require("../../../assets/images/epay/epay-ewallet.png"),
            sc_code: EpayIndexCategory._EWALLET_RELOAD
        }
    ];
    const _MENU_OPTION_DESKTOP = [
        {
            name: "MOBILE_RELOAD",
            imagePath: require("../../../assets/images/epay/epay-icon-mobilereload.png"),
            sc_code: EpayIndexCategory._MOBILE_RELOAD
        },
        {
            name: "GAME_RELOAD",
            imagePath: require("../../../assets/images/epay/epay-icon-gamereload.png"),
            sc_code: EpayIndexCategory._GAME_RELOAD
        },
        {
            name: "BILL_PAYMENT",
            imagePath: require("../../../assets/images/epay/epay-icon-billpayment.png"),
            sc_code: EpayIndexCategory._BILL_PAYMENT
        },
        {
            name: "EWALLET_RELOAD",
            imagePath: require("../../../assets/images/epay/epay-icon-ewallet.png"),
            sc_code: EpayIndexCategory._EWALLET_RELOAD
        }
    ];

    /// <summary>
    /// Author: Juin & Juin
    /// </summary>
    useEffect(() => {
        if (!checkBrowser()) {
            _navigate(Pages._DEFAULT);
        }

        getServiceMenu();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getServiceMenu() {
        var responseJson = await ApiEngine.get(Routes._GET_SERVICE_CATEGORY, { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (!checkBrowser()) {
                let mergedMenu = responseJson[ApiKey._API_DATA_KEY].map(itm => ({
                    ..._MENU_OPTION.find((item) => (item.sc_code === itm.sc_code) && item),
                    ...itm
                }));
                setMenuOption(mergedMenu);
            } else {
                let mergedMenu = responseJson[ApiKey._API_DATA_KEY].map(itm => ({
                    ..._MENU_OPTION_DESKTOP.find((item) => (item.sc_code === itm.sc_code) && item),
                    ...itm
                }));
                setMenuOption(mergedMenu);
            }

        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div className='body-content' style={{ paddingBottom: '2rem' }}>
                <SimpleGrid columns={2} spacing={3} mt={3}>
                    {
                        menuOption.map((item, index) => {
                            return (
                                <Button
                                    key={index}
                                    variant={'link'}
                                    border={'4px solid #d7af48'} borderRadius={15}
                                    isDisabled={!isObjectEmpty(_USER_DATA.memberBonusInfo) ? _USER_DATA.memberBonusInfo.isLocked : false}
                                    onClick={() => { _router.navigate(Pages._RELOAD_CATEGORY, { categoryId: item.id, title: item.name }) }}>
                                    <Box className="app-game-button epay" backgroundImage={item.imagePath} backgroundSize={['cover', 'contain']} backgroundPosition={'center'}>
                                        <Box alignSelf={'flex-end'} bg={'linear-gradient(#a750e3,#9c2fdf,#800ac7)'} w={'full'} p={3} borderBottomRadius={10}>
                                            <Text fontSize={'sm'} color={'#f8c763'}>{t(item.name)}</Text>
                                        </Box>
                                    </Box>
                                </Button>
                            )
                        })
                    }
                </SimpleGrid>
                {
                    menuOption.length > 0 &&
                    <Button className="grey-gradient-button" mt={5} style={{ borderRadius: '15px' }} onClick={() => {
                        _router.navigate(Pages._EGHL_TRANSACTION_HISTORY);
                    }}>
                        {t('TRANSACTION_HISTORY')}
                    </Button>
                }
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", minWidth: "42rem" }}>
                        <Center mb={6}>
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'}>{t('RELOAD_SERVICE')}</Text>
                        </Center>
                        <SimpleGrid columns={2} spacing={6} mt={3} width={'fit-content'}>
                            {
                                menuOption.map((item, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            variant={'link'}
                                            isDisabled={!isObjectEmpty(_USER_DATA.memberBonusInfo) ? _USER_DATA.memberBonusInfo.isLocked : false}
                                            onClick={() => { _router.navigate(Pages._RELOAD_CATEGORY, { categoryId: item.id, title: item.name }) }}>
                                            <HStack w={'20rem'} bg={'linear-gradient(#ac4cfe,#7416ab)'} spacing={8} justifyContent={'center'} paddingBlock={6} borderRadius={15} borderWidth={2} 
                                            borderColor={'transparent'} _hover={{ border: "2px solid white" }} transitionDuration={'normal'}>
                                                <Image w={24} h={24} src={item.imagePath} />
                                                <Text>{t(item.name)}</Text>
                                            </HStack>
                                        </Button>
                                    )
                                })
                            }
                        </SimpleGrid>
                        {
                            menuOption.length > 0 &&
                            <Button w={'100%'} mt={8} fontWeight={'bold'} borderRadius={15} backgroundImage={"linear-gradient(#e5b823, #8d7216)"} color={'white'} paddingBlock={6}
                                onClick={() => {
                                    _router.navigate(Pages._EGHL_TRANSACTION_HISTORY);
                                }}>
                                {t('TRANSACTION_HISTORY')}
                            </Button>
                        }
                    </div>
                </Center>
            </>
        )
    }
}