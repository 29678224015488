import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Spinner, Flex } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ValidationPattern, _AMOUNT_TYPE, _RECEIPT_WATERMARK, TransactionTypeId, _DEPOSIT_TYPE } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isString, createFormBody, stringIsNullOrEmpty, isObjectEmpty } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import CustomInput from "../../../component/input";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector((state) => state.authState.userData);
    const _PARAMS = _location?.state;
    const _html = _PARAMS?.html;
    const _IS_DUITNOW = _PARAMS?.isDuitNow;
    const [proceedPayment, setProceedPayment] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_html)) {
            setProceedPayment(true);
        }
        else {
            _router.navigate(Pages._DEFAULT);
        }

        return () => {

        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const cancelHandler = () => {
        _dispatch(getMemberDetails());

        _dispatch(showCustomDialog({
            title: t("CONFIRMATION"),
            content: t("ARE_YOU_SURE_YOU_WANT_TO_LEAVE_YOU_WILL_LOSE_YOUR_DATA_IF_YOU_CONTINUE"),
            confirmTxt: t("CONFIRM_EXIT"),
            onConfirm: () => {
                _router.navigate(Pages._DEFAULT);
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
    }

    return (
        <>
            <GeneralTopBar onBack={() => cancelHandler()} title={t('EWALLET_DEPOSIT')} />
            <div>
                {
                    (proceedPayment && !stringIsNullOrEmpty(_html)) ?
                        <iframe className="eWalletIFrame" srcDoc={_html} />
                        :
                        <Flex direction='row' align={'center'} mt='10px'>
                            <Text fontSize='md'>Loading for redirection...</Text>
                            <Spinner size='sm' color="#78efa8" ml='10px' />
                        </Flex>
                }
            </div>
        </>
    )
}