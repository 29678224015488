import {
    _APP_BUSY,
    _APP_FREE,
    _SHOW_CUSTOM_DIALOG,
    _SET_TEMP_CUSTOM_DIALOG_DATA,
    _DISPOSE_CUSTOM_DIALOG,
    _SET_BUTTON_CLICK_TIME,
    _CHECK_IN_SPIN_LOGIN,
    _SHOW_SIMPLE_DIALOG,
    _DISMISS_SIMPLE_DIALOG,
    _SET_TEMP_SIMPLE_DIALOG_DATA,
    _SET_BONUS_DIALOG_DATA,
    _CLEAR_BONUS_DIALOG_DATA
} from "../action/app_action";

const _INITIAL_STATE = {
    customDialogData: {},
    tempCustomDialogData: {},
    appIsBusy: false,
    buttonClickTime: null,
    spinLoginCheckedDate: null,
    simpleDialogData: {},
    tempSimpleDialogData: {},
    bonusDialogData: {}
};

var _busyCount = 0;

/// <summary>
/// Author: Juin
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _APP_BUSY:
            _busyCount++;
            return {
                ...state,
                appIsBusy: true
            };
        case _APP_FREE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                appIsBusy: _busyCount != 0
            };
        case _DISPOSE_CUSTOM_DIALOG:
            return {
                ...state,
                customDialogData: {}
            };
        case _SHOW_CUSTOM_DIALOG:
            return {
                ...state,
                customDialogData: action.data
            };
        case _SET_TEMP_CUSTOM_DIALOG_DATA:
            return {
                ...state,
                tempCustomDialogData: action.data
            };
        case _SET_BUTTON_CLICK_TIME:
            return {
                ...state,
                buttonClickTime: action.data
            };
        case _CHECK_IN_SPIN_LOGIN:
            return {
                ...state,
                spinLoginCheckedDate: action.data
            };
        case _SHOW_SIMPLE_DIALOG:
            return {
                ...state,
                simpleDialogData: action.data
            };
        case _DISMISS_SIMPLE_DIALOG:
            return {
                ...state,
                simpleDialogData: {}
            };
        case _SET_TEMP_SIMPLE_DIALOG_DATA:
            return {
                ...state,
                tempSimpleDialogData: action.data
            };
        case _SET_BONUS_DIALOG_DATA:
            return {
                ...state,
                bonusDialogData: action.data
            };
        case _CLEAR_BONUS_DIALOG_DATA:
            return {
                ...state,
                bonusDialogData: {},
                customDialogData: {}
            };
        default:
            return state;
    }
};