import React, { useEffect, useState, useContext } from "react";
import { PageSettings } from "../../../routes/setting";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Flex,
    Button,
    Box,
    Spacer,
    Divider,
    Card,
    CardBody
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog } from "../../../application/action/app_action";
import GeneralTopBar from "../../../component/general-top-bar";
import { _RECEIPT_WATERMARK, _WEB_VERSION, _VERSION_BUILD_NUMBER, BG_TYPE, ValidationPattern } from "../../../common/constant";
import { stringIsNullOrEmpty, removeSpecialChar, checkBrowser } from "../../../common/util";
import Dropzone from 'react-dropzone';
import { useForm, Controller } from 'react-hook-form';
import CustomInput from "../../../component/input";
import { performEditProfile } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { control, handleSubmit, errors, setValue } = useForm();
    const { t } = useTranslation();
    const userData = useSelector(state => state.authState.userData);
    const [logoFile, setLogoFile] = useState();
    const [logoImage, setLogoImage] = useState();

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (checkBrowser()) {
            _router.navigate(Pages._SETTINGS);
        }

        _context.toggleBg(BG_TYPE.black);
        setLogoImage(userData?.profileImage);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, [])

    /// <summary>
    /// Author : Juin
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            let newData = { alias: data.alias };

            if (logoFile) {
                newData['profileImage'] = logoFile;
            }

            _dispatch(performEditProfile(
                newData,
                () => _router.back(),
            ));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
    }

    return (
        <div >
            <GeneralTopBar title={'EDIT_PROFILE'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
            <Stack mt={10} paddingInline={5} className="display-center">
                <form onSubmit={handleSubmit(submitForm)}>
                    <Stack h='15vh' alignItems={'center'} justify={'end'} mb={10}>
                        <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)} multiple={false}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Image boxSize={'100px'} border={'2px solid #f8c763'} src={stringIsNullOrEmpty(logoImage) ? require('../../../assets/images/topbar-user-defaultphoto.png') : logoImage} borderRadius={50} />
                                    <Image boxSize={'25px'} style={{ position: "absolute", top: '48vw', right: '37vw' }} src={require('../../../assets/images/settings/icon-camera.svg').default} />
                                </div>
                            )}
                        </Dropzone>
                    </Stack>
                    <Controller
                        control={control}
                        name="alias"
                        defaultValue={userData?.alias}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                onChange={(e) =>
                                    onChange(removeSpecialChar(e.target.value))
                                }
                                value={value}
                                label='DISPLAY_NAME'
                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                border='2px solid #e2a570 !important'
                                borderRadius='10px !important'
                                placeHolder={t('DISPLAY_NAME')}
                                errors={errors}
                                patternErrorTxt={t('ALPHANUMERIC_PATTERN')}
                                isReadOnly={!stringIsNullOrEmpty(userData?.alias)} />
                        )}
                        rules={{
                            required: true,
                            maxLength: 32,
                            pattern: ValidationPattern._ALPHANUMBERIC_REGEX
                        }}
                    />
                    <Controller
                        control={control}
                        name="alias"
                        defaultValue={userData?.username}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                onChange={(e) =>
                                    onChange(removeSpecialChar(e.target.value))
                                }
                                value={value}
                                label='USERNAME'
                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                border='2px solid #a820ff !important'
                                borderRadius='10px !important'
                                placeHolder={t('DISPLAY_NAME')}
                                errors={errors}
                                patternErrorTxt={t('ALPHANUMERIC_PATTERN')}
                                isReadOnly />
                        )}
                        rules={{
                            required: true,
                            maxLength: 32,
                            pattern: ValidationPattern._ALPHANUMBERIC_REGEX
                        }}
                    />
                    <Button mt={10} className="grey-gradient-button" type="submit">{t('SUBMIT')}</Button>
                </form>
            </Stack>
        </div >
    )
}