import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Flex, Spacer, Image, Divider, Spinner, Center } from "@chakra-ui/react";
import GeneralTopBar from "../../../component/general-top-bar";
import { checkBrowser } from "../../../common/util";
import Footer from "../../non-auth-pages/footer";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _location = useLocation();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { prizeList } = _location?.state;
    const PRIZE_MAPPING = {
        0: {
            svgKey: require('../../../assets/images/svg/first-prize.svg').default,
            text: '1st'
        },
        1: {
            svgKey: require('../../../assets/images/svg/second-prize.svg').default,
            text: '2nd'
        },
        2: {
            svgKey: require('../../../assets/images/svg/third-prize.svg').default,
            text: '3rd'
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'PRIZE'} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                {
                    prizeList && prizeList.map((prize, index) =>
                        <>
                            {index !== 0 && <Divider className="divider-horizontal" />}
                            <Flex justifyContent={'center'} alignItems={'center'} p={3}>
                                <Flex w={'15%'}>
                                    {
                                        index < 3 && <img src={PRIZE_MAPPING[index].svgKey} />
                                    }
                                    {
                                        index > 2 && (
                                            <Text>
                                                {index + 1 + 'th'}
                                            </Text>
                                        )
                                    }
                                </Flex>
                                <Spacer />
                                <Text w={'100%'} fontWeight={'bold'}>{prize.prizeName}</Text>
                                <Spacer />
                                <Image w={'30%'} boxSize={'35px'} src={prize.logo} />
                                <Spacer />
                                <Text w={'10%'} textAlign={'right'} fontWeight={'bold'}>x{prize.quantity}</Text>
                            </Flex>
                        </>
                    )
                }
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "45rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('PRIZE')}</Text>
                        </div>
                        {
                            prizeList && prizeList.map((prize, index) =>
                                <>
                                    {index !== 0 && <Divider className="app-divider-full-width-setting" />}
                                    <Flex justifyContent={'center'} alignItems={'center'} p={3} gap={3} paddingInline={5}>
                                        <Flex w={'10%'}>
                                            {
                                                index < 3 && <img src={PRIZE_MAPPING[index].svgKey} />
                                            }
                                            {
                                                index > 2 && (
                                                    <Text>
                                                        {index + 1 + 'th'}
                                                    </Text>
                                                )
                                            }
                                        </Flex>
                                        <Spacer />
                                        <Text w={'100%'} fontWeight={'bold'}>{prize.prizeName}</Text>
                                        <Spacer />
                                        <Image w={'30%'} boxSize={'35px'} src={prize.logo} />
                                        <Spacer />
                                        <Text w={'10%'} textAlign={'right'} fontWeight={'bold'}>x{prize.quantity}</Text>
                                    </Flex>
                                </>
                            )
                        }
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}