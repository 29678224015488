import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Flex, Center, Select } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ValidationPattern, _AMOUNT_TYPE, _PAYMENT_DEVICE_TYPE, _WALLET_ACTIONS, TransactionType, STATUS_TYPE } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isString, createFormBody, checkBrowser, stringIsNullOrEmpty } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import CustomInput from "../../../component/input";
import CustomPicker from "../../../component/custom-picker";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
import { TriangleDownIcon } from "@chakra-ui/icons";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector((state) => state.authState.userData);
    const [telcoPickerVisible, setTelcoPickerVisible] = useState(false);
    const [telcoList, setTelcoList] = useState([]);
    const [rate, setRate] = useState('');

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        getTelcoList();
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('reloadPinCode')) && !stringIsNullOrEmpty(watch('telCode')));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getTelcoList = async () => {
        try {
            let responseJson = await ApiEngine.get(Routes._WINPAY_RATES);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setTelcoList(
                responseJson[ApiKey._API_DATA_KEY]?.map((item) => ({
                    label: item?.typeName + ` (${item?.value}%)`,
                    value: item?.typeName,
                    rate: item?.value
                }))
            );
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const mobileTopupHandler = async (data) => {
        try {
            let raw = JSON.stringify({
                telcoCode: data?.telCode?.value,
                reloadPinCode: data?.reloadPinCode
            });
            console.log(raw);
            let responseJson = await ApiEngine.post(Routes._INIT_WINPAY, raw, {
                ...ApplicationJsonHeaderConfig
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _router.navigate(Pages._TRANSACTION_HISTORY, { type: _WALLET_ACTIONS.TRANSACTION }, true);

            _router.navigate(Pages._TRANSACTION_HISTORY_DETAIL, {
                type: TransactionType._MOBILE_PIN_TOPUP,
                status: STATUS_TYPE._PENDING_APPROVAL,
                transactionId: responseJson[ApiKey._API_DATA_KEY]?.id
            });
        } catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={t('MOBILE_PIN_TOPUP')} bgColor="linear-gradient(#a94ff1,#9e32dd)">
                    <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                </GeneralTopBar>
                <div className="header-margin-top inner-container">
                    <form onSubmit={handleSubmit(mobileTopupHandler)}>
                        <Button variant={'link'} w='full' onClick={() => setTelcoPickerVisible(!telcoPickerVisible)}>
                            <Controller
                                control={control}
                                name="telCode"
                                defaultValue={''}
                                render={({ value, name }) => (
                                    <CustomInput
                                        isReadOnly
                                        id={name}
                                        value={value?.label}
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        border='2px solid #e2a570 !important'
                                        borderRadius='10px !important'
                                        label={`1. ${t('TELCO_PROVIDER')}`}
                                        placeholder={t('SELECT_TELCO')}
                                        errors={errors}
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                )}
                                rules={{ required: true }}
                            />
                        </Button>
                        {rate && <Text color={'#f8c763'} fontSize={'sm'} mt={-4} mb={6} ml={1}>E.g: RM10 PIN x {rate}% Rate = {10 * rate / 100} e-Credit</Text>}
                        <Controller
                            control={control}
                            name="reloadPinCode"
                            defaultValue={''}
                            render={({ value, name, onChange }) => (
                                <CustomInput
                                    id={name}
                                    value={value}
                                    background='linear-gradient(#5a0089,#7c15bd) !important'
                                    border='2px solid #e2a570 !important'
                                    borderRadius='10px !important'
                                    label={`2. ${t('PIN_CODE')}`}
                                    placeholder={t('PIN_CODE')}
                                    errors={errors}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                />
                            )}
                            rules={{ required: true }}
                        />
                        <CustomPicker
                            showWhiteBg
                            isVisible={telcoPickerVisible}
                            title={'SELECT_TELCO'}
                            options={telcoList}
                            selectedOption={watch('telCode')}
                            onSelect={(isSelected = true, value) => {
                                if (isSelected) {
                                    setValue('telCode', value);
                                    setValue('rate', value?.rate + '%');
                                    setRate(value?.rate);
                                }

                                setTelcoPickerVisible(!telcoPickerVisible);
                            }}
                        />
                        <Button
                            className="grey-gradient-button btn-float-btm"
                            type="submit">
                            {t('RELOAD')}
                        </Button>
                    </form>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('MOBILE_PIN_TOPUP')}</Text>
                        </div>
                        <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                        <div className="inner-container" style={{ marginTop: "2rem" }}>
                            <form onSubmit={handleSubmit(mobileTopupHandler)}>
                                <Text fontSize={'md'} mb={3} >{`1. ${t('TELCO_PROVIDER')}`}</Text>
                                <Controller
                                    control={control}
                                    name="telCode"
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Select {...field} color={'white'} mb={7} bg={'linear-gradient(#5a0089,#7c15bd)'} height={10} fontSize={'md'} errors={errors} _hover={{ border: "2px solid white" }}
                                            borderRadius={10} borderColor='transparent' borderWidth={2} boxShadow='none !important'
                                            onChange={(e) => {
                                                setValue('telCode', telcoList.find((item) => item.value == e.target.value));
                                                setValue('rate', telcoList.find((item) => item.value == e.target.value).rate + '%');
                                                setRate(telcoList.find((item) => item.value == e.target.value).rate);
                                            }}
                                            icon={<TriangleDownIcon fontSize={13} />}>
                                            <option disabled selected style={{ background: "#6f0daa" }}>{t('SELECT_TELCO')}</option>
                                            {telcoList.map((option) => (
                                                <option key={option.value} value={option.value} style={{ background: "#6f0daa" }}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                    rules={{ required: true }}
                                />
                                {rate && <Text color={'#f8c763'} mt={-5} mb={7}>E.g: RM10 PIN x {rate}% Rate = {10 * rate / 100} e-Credit</Text>}
                                <Controller
                                    control={control}
                                    name="reloadPinCode"
                                    defaultValue={''}
                                    render={({ value, name, onChange }) => (
                                        <CustomInput
                                            id={name}
                                            value={value}
                                            borderRadius="10px !important"
                                            background="linear-gradient(#5a0089,#7c15bd) !important"
                                            transitionDuration='normal'
                                            _hover={{ border: '2px solid white !important' }}
                                            border="2px solid transparent !important"
                                            color='white'
                                            boxShadow='none !important'
                                            label={`2. ${t('PIN_CODE')}`}
                                            errors={errors}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                                <Button mt={3}
                                    style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%)", color: "white", padding: "25px 0px" }} w='100%'
                                    type="submit">
                                    {t('RELOAD')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}