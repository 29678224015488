import React, { useEffect, useState } from 'react';
import { useRouter } from '../../../hooks/useRouter';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ApiEngine from '../../../common/api_engine';
import Routes from '../../../common/api_routes';
import { Pages } from '../../../routes/page';
import { showCustomDialog } from '../../../application/action/app_action';
import { ApiKey, ValidationPattern } from '../../../common/constant';
import { numberWithCurrencyFormat, createFormBody, removeSpecialChar, stringIsNullOrEmpty, checkBrowser, getValidationMessage } from '../../../common/util';
import PasswordModal from '../../../component/password-modal';
import { Stack, Image, Button, Text, Flex, SimpleGrid, Divider, Box, Card, CardBody, HStack } from '@chakra-ui/react';
import CustomInput from '../../../component/input';
import CurrencyInput from 'react-currency-input-field';

/// <summary>
/// Author: Juin
/// </summary>
const InstantReload = ({ service, serviceDetail, type, memberGuid, successImage }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const { control, handleSubmit, errors, watch } = useForm();
    const { t } = useTranslation();
    const [errorMsg, setErrorMsg] = useState();
    const [selectedInstant, setSelectedInstant] = useState(serviceDetail.services[0]);
    const _REQUIRED_AMOUNT_FIELD = selectedInstant.max_denom != selectedInstant.min_denom;
    const _INPUT_TYPE_PHONE = 'phone_no';
    const _INPUT_TYPE_ACCOUNT = 'acc_no';
    const [authorizationModal, setAuthorizationModal] = useState({
        password: '',
        visible: false
    });

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        window.scrollTo(0, 0);
        setAuthorizationModal({ ...authorizationModal, visible: true });
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        if (_REQUIRED_AMOUNT_FIELD) {
            return (!stringIsNullOrEmpty(watch('accountNo')) && !stringIsNullOrEmpty(watch('amount')));
        } else {
            return (!stringIsNullOrEmpty(watch('accountNo')));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function submitPayment(authorization) {
        let params = {
            'amount': (_REQUIRED_AMOUNT_FIELD ? watch('amount') : selectedInstant.min_denom) * 100,
            'serviceId': selectedInstant.id,
            'type': type,
            'memberGuid': memberGuid,
            'deviceId': window.navigator.userAgent,
            'enableVerification': true,
            'accountNo': watch('accountNo'),
            'password': authorization.password
        };

        var responseJson = await ApiEngine.post(Routes._EPAY_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _router.navigate(Pages._RELOAD_SUCCESS, {
                instruction: responseJson[ApiKey._API_DATA_KEY].details.replace('Instruction-\n', ''),
                amount: _REQUIRED_AMOUNT_FIELD ? 'RM ' + numberWithCurrencyFormat(parseFloat(watch('amount')))
                    : 'RM ' + numberWithCurrencyFormat(selectedInstant.min_denom),
                headerTitle: 'SUCCESSFULLY_PAID',
                contentImage: successImage,
                details: [
                    { label: 'SERVICE', value: selectedInstant.service_name },
                    { label: 'TRANSACTION_NO', value: responseJson[ApiKey._API_DATA_KEY].trans_ref },
                    {
                        label: selectedInstant.input_type == _INPUT_TYPE_PHONE ? 'PHONE_NUMBER'
                            : 'ACCOUNT_NUMBER', value: watch('accountNo')
                    }
                ]
            });
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    if (!checkBrowser()) {
        return (
            <>
                <Flex align={'flex-end'} mb={5}>
                    <Button
                        variant={'link'}
                        alignItems={'center'}
                        borderRadius={50}
                        backgroundColor={'#C6D1E2'}
                        borderWidth={2.5}
                        borderColor={'#A0B1CB'}
                        p={3}
                        disabled >
                        <Image boxSize={'35px'} src={service.img} />
                    </Button>
                    <Text fontSize={'md'} ml={5}>{service.ssc_name}</Text>
                </Flex>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Controller
                        control={control}
                        name="accountNo"
                        defaultValue={''}
                        render={({ onChange, value, name }) => (
                            <CustomInput
                                id={name}
                                onChange={(e) =>
                                    onChange(removeSpecialChar(e.target.value))
                                }
                                value={value}
                                background='linear-gradient(#5a0089,#7c15bd) !important'
                                border='2px solid #e2a570 !important'
                                borderRadius='10px !important'
                                label={selectedInstant.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}
                                errors={errors}
                                patternErrorTxt={t(
                                    'PLEASE_FILL_VALID_PHONE_NO'
                                )} />
                        )}
                        rules={{
                            required: true
                        }}
                    />
                    {
                        _REQUIRED_AMOUNT_FIELD ?
                            <Stack spacing={0}>
                                <Text fontSize={'sm'}>{t('AMOUNT')}</Text>
                                <Box w='full' h='45px' background='linear-gradient(#5a0089,#7c15bd)' border='2px solid #e2a570' borderRadius='10px' mt={2}>
                                    <Controller
                                        control={control}
                                        name="amount"
                                        defaultValue={''}
                                        render={({ onChange, value, name }) => (
                                            <CurrencyInput
                                                style={{
                                                    textAlign: 'start',
                                                    backgroundColor: 'transparent',
                                                    color: 'white',
                                                    fontWeight: '500',
                                                    fontSize: '1rem',
                                                    width: '100%',
                                                    height: '100%',
                                                    paddingLeft: '10px'
                                                }}
                                                id={name}
                                                name={name}
                                                value={value}
                                                placeholder="0.00"
                                                allowNegativeValue
                                                decimalScale={2}
                                                autoComplete={false}
                                                onFocus={(e) => e.target.select()}
                                                onValueChange={(value, name) => {
                                                    onChange(value);
                                                }}
                                            />
                                        )}
                                        rules={{
                                            required: _REQUIRED_AMOUNT_FIELD,
                                            pattern: ValidationPattern._DECIMAL_REGEX,
                                            maxLength: 8,
                                            minLength: 1,
                                            min: selectedInstant.min_denom,
                                            max: selectedInstant.max_denom
                                        }}
                                    />
                                </Box>
                                {
                                    errors && errors['amount'] &&
                                    <Text color={'red'} mt={3} fontSize={'sm'}>{t('AMOUNT_ERROR_TXT')}</Text>
                                }
                            </Stack> :
                            <SimpleGrid columns={3} spacing={5}>
                                {
                                    serviceDetail.services.map((item, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                className={`amount-button ${item.id == selectedInstant.id ? 'selected' : ''}`}
                                                onClick={() => setSelectedInstant(item)}>
                                                {'RM ' + item.max_denom}
                                            </Button>
                                        )
                                    })
                                }
                            </SimpleGrid>
                    }
                    {
                        errorMsg &&
                        <Text mt={3} fontSize={'sm'} color={'red'}>{errorMsg}</Text>
                    }
                    <Stack className="bottom-sticky-button">
                        <Divider className='app-divider-full-width-setting' />
                        <Flex
                            style={{
                                padding: '1rem',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                            <Stack spacing={0}>
                                <Text fontSize={'lg'}>{t('TOTAL_AMOUNT')}</Text>
                                <Flex align={'center'}>
                                    <Image boxSize={'30px'} src={require('../../../assets/images/topbar-icon-credit.png')} />
                                    <Text fontSize={'md'} color={'#f8c763'} fontWeight={'bold'}>{`RM ${_REQUIRED_AMOUNT_FIELD ? stringIsNullOrEmpty(watch('amount')) ? '0.00' : numberWithCurrencyFormat(parseFloat(watch('amount')))
                                        : numberWithCurrencyFormat(selectedInstant.min_denom)}`}</Text>
                                </Flex>
                            </Stack>
                            <Button
                                type='submit'
                                className="grey-gradient-button"
                                style={{ borderRadius: '20px', width: '40%', marginLeft: '5vw' }}
                                onClick={() => {
                                    if (!_REQUIRED_AMOUNT_FIELD && _USER_DATA.credit < selectedInstant.max_denom) {
                                        setErrorMsg(t('INSUFFICIENT_CREDIT'));
                                    }
                                }}>
                                {t('CONFIRM_PAYMENT')}
                            </Button>
                        </Flex>
                    </Stack>
                </form>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            submitPayment(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    } else {
        return (
            <>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Card>
                        <CardBody bg={'linear-gradient(#ac4cfe,#7416ab)'} pb={24} paddingInline={8} pt={5}>
                            <HStack spacing={5} mb={10}>
                                <Button
                                    variant={'link'}
                                    alignItems={'center'}
                                    borderRadius={50}
                                    backgroundColor={'#C6D1E2'}
                                    borderWidth={2.5}
                                    borderColor={'#A0B1CB'}
                                    p={2}
                                    disabled >
                                    <Image boxSize={9} src={service.img} />
                                </Button>
                                <Text fontSize={'md'}>{service.ssc_name}</Text>
                            </HStack>
                            <Controller
                                control={control}
                                name="accountNo"
                                defaultValue={''}
                                render={({ onChange, value, name }) => (
                                    <CustomInput
                                        id={name}
                                        onChange={(e) =>
                                            onChange(removeSpecialChar(e.target.value))
                                        }
                                        value={value}
                                        borderRadius="15px !important"
                                        boxShadow="none !important"
                                        background="linear-gradient(#5a0089,#7c15bd) !important"
                                        label={selectedInstant.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}
                                        errors={errors}
                                        patternErrorTxt={t(
                                            'PLEASE_FILL_VALID_PHONE_NO'
                                        )} />
                                )}
                                rules={{
                                    required: true
                                }}
                            />
                            {
                                _REQUIRED_AMOUNT_FIELD ?
                                    <Stack spacing={0}>
                                        <Text fontSize={'md'}>{t('AMOUNT')}</Text>
                                        <Box w='full' h='43px' background={'linear-gradient(#5a0089,#7c15bd)'} borderRadius={15} borderColor='transparent' borderWidth={2} mt={2} _hover={{ border: "2px solid white" }} transitionDuration={'normal'}>
                                            <Controller
                                                control={control}
                                                name="amount"
                                                defaultValue={''}
                                                render={({ onChange, value, name }) => (
                                                    <CurrencyInput
                                                        style={{
                                                            textAlign: 'start',
                                                            color: 'white',
                                                            fontWeight: '500',
                                                            fontSize: '1rem',
                                                            width: '100%',
                                                            height: '100%',
                                                            paddingLeft: '15px',
                                                            borderRadius: '15px',
                                                            background: "transparent"
                                                        }}
                                                        id={name}
                                                        name={name}
                                                        value={value}
                                                        placeholder="0.00"
                                                        allowNegativeValue
                                                        decimalScale={2}
                                                        autoComplete={false}
                                                        onFocus={(e) => e.target.select()}
                                                        onValueChange={(value, name) => {
                                                            onChange(value);
                                                        }}
                                                    />
                                                )}
                                                rules={{
                                                    required: _REQUIRED_AMOUNT_FIELD,
                                                    pattern: ValidationPattern._DECIMAL_REGEX,
                                                    maxLength: 8,
                                                    minLength: 1,
                                                    min: selectedInstant.min_denom,
                                                    max: selectedInstant.max_denom
                                                }}
                                            />
                                        </Box>
                                        {
                                            errors && errors['amount'] &&
                                            <Text color={'red'} fontSize={'sm'} mt={2}>{t('AMOUNT_ERROR_TXT')}</Text>
                                        }
                                    </Stack> :
                                    <SimpleGrid columns={3} spacing={5}>
                                        {
                                            serviceDetail.services.map((item, index) => {
                                                return (
                                                    <Button
                                                        key={index}
                                                        paddingInline={12}
                                                        paddingBlock={6}
                                                        className={`amount-button ${item.id == selectedInstant.id ? 'selected' : ''}`}
                                                        onClick={() => setSelectedInstant(item)}>
                                                        {'RM ' + item.max_denom}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </SimpleGrid>
                            }
                            {
                                errorMsg &&
                                <Text mt={3} color={'red'}>{errorMsg}</Text>
                            }
                        </CardBody>
                    </Card>
                    <HStack spacing={5} justifyContent={'flex-end'} bg={'#400164'} paddingInline={8} paddingBlock={5} borderBottomRadius={15}>
                        <Stack spacing={0} textAlign={'right'}>
                            <Text fontSize={'md'}>{t('TOTAL_AMOUNT')}</Text>
                            <HStack spacing={0}>
                                <Image boxSize={'30px'} src={require('../../../assets/images/topbar-icon-credit.png')} />
                                <Text color={'#f8c763'} fontSize={'md'} fontWeight={'bold'}>{`RM ${_REQUIRED_AMOUNT_FIELD ? stringIsNullOrEmpty(watch('amount')) ? '0.00' : numberWithCurrencyFormat(parseFloat(watch('amount')))
                                    : numberWithCurrencyFormat(selectedInstant.min_denom)}`}</Text>
                            </HStack>
                        </Stack>
                        <Button
                            type='submit'
                            fontWeight={'500'} fontSize={'sm'} borderRadius={10} bg={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : 'linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%) '} color={'white'} paddingBlock={6}
                            onClick={() => {
                                if (!_REQUIRED_AMOUNT_FIELD && _USER_DATA.credit < selectedInstant.max_denom) {
                                    setErrorMsg(t('INSUFFICIENT_CREDIT'));
                                }
                            }}>
                            {t('CONFIRM_PAYMENT')}
                        </Button>
                    </HStack>
                </form>
                <PasswordModal
                    authorization={authorizationModal}
                    onPasswordHandler={(isDone = false, authorization) => {
                        if (isDone) {
                            window.scrollTo(0, 0);
                            submitPayment(authorization);
                        }

                        setAuthorizationModal({ ...authorization, visible: false });
                    }} />
            </>
        )
    }
}

export default InstantReload;