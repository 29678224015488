import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Spinner, Flex } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ValidationPattern, _AMOUNT_TYPE, _RECEIPT_WATERMARK, TransactionTypeId, _DEPOSIT_TYPE } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isString, createFormBody, stringIsNullOrEmpty, isObjectEmpty } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import CustomInput from "../../../component/input";
var md5 = require('md5');

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector((state) => state.authState.userData);
    const _PARAMS = _location?.state;
    const transaction = _PARAMS?.paymentGatewayTransaction;
    const _IS_DUITNOW = _PARAMS?.isDuitNow;
    const [proceedPayment, setProceedPayment] = useState(false);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (transaction) {
            setProceedPayment(true);
        }
        else {
            _router.navigate(Pages._DEFAULT);
        }

        return () => {

        };
    }, []);

    useEffect(() => {
        if (proceedPayment) {
            document.forms["paymentForm"].submit();
        }
    }, [proceedPayment]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const cancelHandler = async () => {
        cancelTransaction();
        return true;
    };

    const cancelTransaction = async () => {
        _dispatch(
            showCustomDialog({
                title: t('INCOMPLETE_TRANSACTION'),
                content: t(
                    'ARE_YOU_SURE_YOU_WANT_TO_LEAVE_YOU_WILL_LOSE_YOUR_DATA_IF_YOU_CONTINUE'
                ),
                confirmTxt: t('CONFIRM_EXIT'),
                onConfirm: async () => {
                    try {
                        let responseJson = await ApiEngine.post(
                            Routes._CANCEL_GPAY88_TRANSACTION +
                            `?transactionId=${transaction.transactionId
                            }&transactionTypeId=${_IS_DUITNOW
                                ? transaction.transactionTypeId ===
                                    TransactionTypeId._KINGPAY_DUITNOW
                                    ? TransactionTypeId._KINGPAY_DUITNOW
                                    : TransactionTypeId._TOP_UP_GPAY_DUITNOW
                                : TransactionTypeId._TOP_UP_GPAY
                            }`
                        );

                        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                            throw responseJson[ApiKey._API_MESSAGE_KEY];
                        }
                    } catch (err) {
                        // do nth
                    } finally {

                    }
                },
                onCancel: () => { },
                horizontalBtn: false
            })
        );
    };

    return (
        <>
            <GeneralTopBar title={t(_IS_DUITNOW ? 'DUITNOW' : 'GPAY88_DEPOSIT')} />
            <div>
                {
                    (proceedPayment && !isObjectEmpty(transaction)) ?
                        <div>
                            {
                                !_IS_DUITNOW ?
                                    <form name="paymentForm" method="POST" action={(!stringIsNullOrEmpty(transaction.baseUrl) ? transaction.baseUrl : Routes._GPAY88_CREATE_TRANSACTION) + '/Api/MakePayment'}>
                                        <input type="hidden" name="Code" value={transaction.apiCode} />
                                        <input type="hidden" name="Secret" value={transaction.apiSecret} />
                                        <input type="hidden" name="Signature" value={md5(transaction.apiCode + transaction.apiSecret + transaction.transactionId).toString().toUpperCase()} />
                                        <input type="hidden" name="RefNo" value={transaction.transactionId} />
                                        <input type="hidden" name="Amount" value={transaction.amount} />
                                        <input type="hidden" name="CallbackUrl" value={transaction.callbackUrl} />
                                        <input type="hidden" name="BackendUrl" value={transaction.responseUrl} />
                                        <input type="hidden" name="IpAddress" value={transaction.ipAddress} />
                                        <input type="hidden" name="Watermark" value={_RECEIPT_WATERMARK} />
                                    </form>
                                    :
                                    <form name="paymentForm" method="POST" action={`${transaction.baseUrl}/transaction/checkout`}>
                                        <input type="hidden" name="ClientCode" value={`${transaction.apiCode}`} />
                                        <input type="hidden" name="Signature" value={`${transaction.apiSecret}`} />
                                        <input type="hidden" name="TransactionId" value={`${transaction.transactionId}`} />
                                        <input type="hidden" name="Amount" value={`${transaction.amount}`} />
                                        <input type="hidden" name="Mode" value={`2`} />
                                        <input type="hidden" name="CallbackUrl" value={`${transaction.callbackUrl}`} />
                                        <input type="hidden" name="RedirectUrl" value={`${transaction.responseUrl}`} />
                                    </form>
                            }
                        </div>
                        :
                        <Flex direction='row' align={'center'} mt='10px'>
                            <Text fontSize='md'>Loading for redirection...</Text>
                            <Spinner size='sm' color="#78efa8" ml='10px' />
                        </Flex>
                }
            </div>
        </>
    )
}