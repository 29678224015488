import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Divider, Box, Flex, Spacer, SimpleGrid } from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { Pages } from "../../../../routes/page";
import { showCustomDialog } from "../../../../application/action/app_action";
import { isObjectEmpty, numberWithCurrencyFormat, share, stringIsNullOrEmpty, isTicketCancelled } from "../../../../common/util";
import { ApiKey, BG_TYPE, ACTIVE_STATUS, CANCELLED_STATUS, CANCELLED_STATUS_TXT } from "../../../../common/constant";
import moment from "moment";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const strikeDetail = _location?.state?.strikeDetail;

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(strikeDetail)) {
            _router.back();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    return (
        <div className='body-content'>
            <GeneralTopBar title={t('ORDER_ID') + ": " + strikeDetail?.conf_id} bgColor="#ebffe3" blackTitle titleAlignLeft useSimpleBackIcon />
            <Box
                backgroundColor={'#F5F4FA'}
                borderTopLeftRadius={30}
                borderTopRightRadius={30}
                marginInline={'-3.75vw'}
                overflowY={'hidden'}
                height={'110vh'}>
                <Flex
                    backgroundColor={'white'}
                    borderTopLeftRadius={30}
                    borderTopRightRadius={30}
                    p={5}>
                    <Text color={'#54595F'} fontSize={'sm'} fontWeight={'bold'}>{`${t('TOTAL_STRIKE')}:`}</Text>
                    <Spacer />
                    <Text color={'#22B956'} fontSize={'sm'} fontWeight={'bold'}>+RM {numberWithCurrencyFormat(parseFloat(strikeDetail?.groupsum))}</Text>
                </Flex>
                <Flex
                    backgroundColor={'white'}
                    p={5}>
                    <Text color={'#54595F'} fontSize={'sm'} fontWeight={'bold'}>{`${t('ORDER_NUMBER')}:`}</Text>
                    <Spacer />
                    <Text color={'#22B956'} fontSize={'sm'} fontWeight={'bold'}>{strikeDetail?.bet_number}</Text>
                </Flex>
                <SimpleGrid p={5} columns={2} spacing={10}>
                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('DRAW_DATE_TXT')}:</Text>
                    <Text fontSize={'sm'} color={'#54595F'}>{moment(strikeDetail.draw_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY (ddd)').toUpperCase()}</Text>
                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('STRIKE_DETAILS')}:</Text>
                    <Text fontSize={'sm'} color={'#54595F'}>{ }</Text>
                </SimpleGrid>
                {
                    strikeDetail?.details.map((detail, index) => {
                        return (
                            <>
                                <SimpleGrid p={5} columns={2} spacing={10}>
                                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('COMPANY')}:</Text>
                                    <Text fontSize={'sm'} color={'#54595F'}>{detail.company_name}</Text>
                                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('ORDER_TYPE_TXT')}:</Text>
                                    <Text fontSize={'sm'} color={'#54595F'}>{detail.bet_bsa}</Text>
                                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('ORDER_AMOUNT_TXT')}:</Text>
                                    <Text fontSize={'sm'} color={'#54595F'}>{numberWithCurrencyFormat(parseFloat(detail.bet_amount))}</Text>
                                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('PRIZE')}:</Text>
                                    <Text fontSize={'sm'} color={'#54595F'}>{detail.prize_name}</Text>
                                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('PRIZE_RATE')}:</Text>
                                    <Text fontSize={'sm'} color={'#54595F'}>{detail.prize_rate}</Text>
                                    <Text fontWeight={'bold'} fontSize={'sm'} color={'#54595F'}>{t('STRIKE_AMOUNT')}:</Text>
                                    <Text fontSize={'sm'} color={'#54595F'}>{numberWithCurrencyFormat(parseFloat(detail.strike_amount))}</Text>
                                </SimpleGrid>
                                {
                                    strikeDetail.details.length - 1 !== index &&
                                    <Divider />
                                }
                            </>
                        )
                    })
                }
            </Box>
        </div>
    )
}