import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../hooks/useRouter";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import { Stack, Flex, Text, Divider, Spinner, Spacer, AbsoluteCenter, Image, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Center, HStack } from "@chakra-ui/react";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import {
    ApiKey,
    _PAGE_SIZE,
    EGHLStatus,
    _WALLET_ACTIONS,
    MergedTransactionTypeId,
    TransactionHistoryStatus,
    _DATETIME_FORMAT,
    TransactionType,
    TransactionTypeId,
    OnlineWithdrawalStatus,
    TransactionCategory,
    Status,
    STATUS_TYPE,
    ScreenWidth
} from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isObjectEmpty, stringIsNullOrEmpty, checkBrowser } from "../../../common/util";
import moment from "moment";
import DateFilter from "../../../component/date-filter";
import InfiniteScroll from 'react-infinite-scroll-component';
import BottomSheet from "../../../component/bottom-sheet";
import { Pages } from "../../../routes/page";
import { isBrowser, isMobile } from "react-device-detect";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _location = useLocation();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _CLAIM_REWARD_APP = 'Claim Reward App';
    const _INITIAL_PAGE_NO = 1;
    const params = _location?.state;
    const [selectedTab, setSelectedTab] = useState(!isObjectEmpty(params) ? params.type : _WALLET_ACTIONS.TRANSACTION);
    const [convertData, setConvertData] = useState([]);
    const [withdrawData, setWithdrawData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [filterOptions, setFilterOptions] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf("month").format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showFilterBar, setShowFilterBar] = useState(false);
    const [requiredApiCalling, setRequiredApiCalling] = useState(false);
    const [optionType, setOptionType] = useState(
        selectedTab == _WALLET_ACTIONS.TRANSACTION
            ? TransactionType._ALL_TRANSACTION
            : TransactionType._ALL_CONVERT
    );
    const _TABS = [
        _WALLET_ACTIONS.TRANSACTION,
        _WALLET_ACTIONS.CONVERT,
        _WALLET_ACTIONS.WITHDRAWAL_REQUEST
    ];
    const withdrawFilterOptions = [
        {
            label: 'ALL',
            value: '',
            selected: true
        },
        {
            label: 'PENDING_APPROVAL',
            value: OnlineWithdrawalStatus._PENDING_APPROVAL,
            selected: false
        },
        {
            label: 'APPROVED',
            value: OnlineWithdrawalStatus._SUCCESS,
            selected: false
        },
        {
            label: 'REJECTED',
            value: OnlineWithdrawalStatus._REJECTED,
            selected: false
        }
    ];
    const convertFilterOptions = [
        {
            label: 'ALL_CONVERT',
            value: TransactionCategory._ALL,
            selected: true
        },
        {
            label: 'TRANSFER_SCORE',
            value: TransactionCategory._TRANSFER_SCORE,
            selected: false
        },
        {
            label: 'REWARD_TO_WALLET',
            value: TransactionCategory._REWARD_TO_WALLET,
            selected: false
        }
    ];
    const walletFilterOptions = [
        {
            label: 'ALL_TRANSACTION',
            value: TransactionCategory._ALL,
            selected: true
        },
        {
            label: 'GPAY88',
            value: TransactionCategory._GPAY88,
            selected: false
        },
        {
            label: 'EWALLET',
            value: TransactionCategory._EWALLET,
            selected: false
        },
        {
            label: 'SHOP_TOP_UP',
            value: TransactionCategory._SHOP_TOP_UP,
            selected: false
        },
        {
            label: 'SHOP_WITHDRAWAL',
            value: TransactionCategory._SHOP_WITHDRAWAL,
            selected: false
        },
        {
            label: 'TRANSFER_HISTORY',
            value: TransactionCategory._TRANSFER_HISTORY,
            selected: false
        },
        {
            label: 'MOBILE_PIN_TOPUP',
            value: TransactionCategory._MOBILE_PIN_TOPUP,
            selected: false
        }
    ];
    const _OPTIONS = {
        [TransactionType._ALL_CONVERT]: {
            route: Routes._GET_MERGED_CONVERT_HISTORY,
            type: _WALLET_ACTIONS.CONVERT,
            isDeposit: null
        },
        [TransactionType._TRANSFER_SCORE]: {
            route: Routes._GET_TRANSFER_HISTORY,
            type: _WALLET_ACTIONS.CONVERT,
            isDeposit: null
        },
        [TransactionType._REWARD_TO_WALLET]: {
            route: Routes._GET_CLAIM_REWARD_HISTORY,
            type: _WALLET_ACTIONS.CONVERT,
            isDeposit: null
        },
        [TransactionType._ALL_TRANSACTION]: {
            route: Routes._GET_MERGED_TRANSACTION_HISTORY,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: null
        },
        [TransactionType._GPAY88]: {
            route: Routes._GET_CREDIT_TRANSACTION_LIST,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: null
        },
        [TransactionType._EWALLET]: {
            route: Routes._GET_CREDIT_TRANSACTION_LIST,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: null
        },
        [TransactionType._SHOP_TOP_UP]: {
            route: Routes._GET_MEMBER_SCORE_TRANSACTION,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: true
        },
        [TransactionType._SHOP_WITHDRAWAL]: {
            route: Routes._GET_MEMBER_SCORE_TRANSACTION,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: false
        },
        [TransactionType._TRANSFER_HISTORY]: {
            route: Routes._GET_MEMBER_TRANSFER_HISTORY,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: null
        },
        [TransactionType._MOBILE_PIN_TOPUP]: {
            route: Routes._GET_CREDIT_TRANSACTION_LIST,
            type: _WALLET_ACTIONS.TRANSACTION,
            isDeposit: null
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        (async () => {
            // Filter by options
            setFilterOptions(
                selectedTab === _WALLET_ACTIONS.TRANSACTION
                    ? walletFilterOptions
                    : selectedTab === _WALLET_ACTIONS.CONVERT
                        ? convertFilterOptions
                        : withdrawFilterOptions
            );
        })();

    }, [selectedTab]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (selectedTab !== _WALLET_ACTIONS.WITHDRAWAL_REQUEST) {
            getAllTransactionHistories();
        } else {
            getWithdrawTransactions();
        }
    }, [optionType, startDate, endDate, selectedTab]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (requiredApiCalling) {
            if (selectedTab !== _WALLET_ACTIONS.WITHDRAWAL_REQUEST) {
                getAllTransactionHistories();
            } else {
                getWithdrawTransactions();
            }
        }
    }, [requiredApiCalling, selectedTab]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    const navigateToTransactionDetails = (item, type) => {
        let tempObj = {};
        let transactionId = item.id;
        let requestCode = item.requestCode;
        let customType = item.type;

        if (
            type === TransactionType._GPAY88 ||
            (type === TransactionType._ALL_TRANSACTION &&
                item.typeId == MergedTransactionTypeId._ONLINE_BANKING)
        ) {
            transactionId = item.transactionId;
            customType = TransactionType._GPAY88;
        } else if (
            type === TransactionType._EWALLET ||
            (type === TransactionType._ALL_TRANSACTION &&
                item.typeId == MergedTransactionTypeId._EWALLET)
        ) {
            transactionId = item.id;
            customType = TransactionType._EWALLET;
        } else if (
            type === TransactionType._SHOP_TOP_UP ||
            type === TransactionType._SHOP_WITHDRAWAL ||
            (type === TransactionType._ALL_TRANSACTION &&
                (item.typeId == MergedTransactionTypeId._SHOP_SET_CREDIT ||
                    item.typeId == MergedTransactionTypeId._SHOP_SET_SCORE ||
                    item.typeId == MergedTransactionTypeId._VENDING_MACHINE))
        ) {
            customType =
                type === TransactionType._ALL_TRANSACTION
                    ? item.amount > 0
                        ? TransactionType._SHOP_TOP_UP
                        : TransactionType._SHOP_WITHDRAWAL
                    : type;
            tempObj = {
                status:
                    item.status === Status._SUCCESS
                        ? STATUS_TYPE._SUCCESSFUL
                        : STATUS_TYPE._FAILED,
                type: customType,
                amount: item.amount,
                transactionId: transactionId,
                createDate: item.createdTime,
                requestCode
            };
        } else if (
            type === TransactionType._REWARD_TO_WALLET ||
            (type === TransactionType._ALL_CONVERT &&
                item.typeId == MergedTransactionTypeId._REWARD_TO_CREDIT)
        ) {
            customType = TransactionType._REWARD_TO_WALLET;
            tempObj = {
                status:
                    item.status === Status._SUCCESS
                        ? STATUS_TYPE._SUCCESSFUL
                        : STATUS_TYPE._FAILED,
                type: customType,
                amount: item.amount,
                createDate: item.createdTime,
                memberInitialCredit: item.memberInitialCredit ?? item.preCredit,
                memberFinalCredit: item.memberFinalCredit ?? item.postCredit,
                memberInitialRewardCredit:
                    item.memberInitialRewardCredit ?? item.preReward,
                memberFinalRewardCredit:
                    item.memberFinalRewardCredit ?? item.postReward,
                requestCode
            };
        } else if (
            type === TransactionType._TRANSFER_HISTORY ||
            (type === TransactionType._ALL_TRANSACTION &&
                item.typeId == MergedTransactionTypeId._SEND_CREDIT)
        ) {
            customType = item.isSender
                ? TransactionType._SEND_PAYMENT
                : TransactionType._RECEIVE_PAYMENT;
            tempObj = {
                status: STATUS_TYPE._SUCCESSFUL,
                type: customType,
                amount: item.amount,
                dateTime: item.createdTime,
                description: item.description,
                receive: item.isSender
                    ? item.receiverName ?? item.receiverUsername
                    : item.senderName ?? item.memberUsername,
                requestCode
            };
        } else if (
            type === TransactionType._WITHDRAW_CREDIT ||
            type === TransactionType._WITHDRAW_POINT
        ) {
            customType = type;
            tempObj = {
                status:
                    item.status === OnlineWithdrawalStatus._PENDING_APPROVAL
                        ? STATUS_TYPE._PENDING_APPROVAL
                        : item.status === OnlineWithdrawalStatus._SUCCESS
                            ? STATUS_TYPE._APPROVED
                            : STATUS_TYPE._REJECTED,
                type: type,
                transactionId: transactionId
            };
        } else if (type === TransactionType._MOBILE_PIN_TOPUP) {
            customType = type;
        }

        _router.navigate(Pages._TRANSACTION_HISTORY_DETAIL,
            !isObjectEmpty(tempObj)
                ? tempObj
                : {
                    status:
                        item.status === Status._SUCCESS
                            ? STATUS_TYPE._SUCCESSFUL
                            : STATUS_TYPE._FAILED,
                    type: customType,
                    transactionId: transactionId,
                    requestCode
                });
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getAllTransactionHistories = async () => {
        setIsLoading(true);

        try {
            let apiUrl =
                _OPTIONS[optionType].route +
                `?startDate=${startDate}&endDate=${endDate}&page=${pageNo}&pageSize=${_PAGE_SIZE}
                    &TransactionTypeId=${optionType === TransactionType._MOBILE_PIN_TOPUP
                    ? TransactionTypeId._MOBILE_PIN_TOPUP
                    : optionType == TransactionType._EWALLET
                        ? TransactionTypeId._EWALLET
                        : TransactionTypeId._TOP_UP_GPAY
                }`;

            if (_OPTIONS[optionType].isDeposit != null) {
                apiUrl += `&isDeposit=${_OPTIONS[optionType].isDeposit}`;
            }

            if (
                _OPTIONS[optionType].route ==
                Routes._GET_CREDIT_TRANSACTION_LIST
            ) {
                apiUrl += `&isV2=true`;
            }

            let responseJson = await ApiEngine.get(apiUrl, {
                headers: { skipLoading: true }
            });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let data = responseJson[ApiKey._API_DATA_KEY]['data'];

                setTotalCount(responseJson[ApiKey._API_DATA_KEY]['totalCount']);
                setTotalAmount(
                    responseJson[ApiKey._API_DATA_KEY]['totalAmount']
                );

                if (_OPTIONS[optionType].type == _WALLET_ACTIONS.TRANSACTION) {
                    if (
                        transactionData.length <
                        responseJson[ApiKey._API_DATA_KEY]['totalCount']
                    ) {
                        setTransactionData([...transactionData, ...data]);
                    } else {
                        setTransactionData(data);
                    }
                } else {
                    if (
                        convertData.length <
                        responseJson[ApiKey._API_DATA_KEY]['totalCount']
                    ) {
                        setConvertData([...convertData, ...data]);
                    } else {
                        setConvertData(data);
                    }
                }
            } else {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: responseJson[ApiKey._API_MESSAGE_KEY]
                    })
                );
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }

        setIsLoading(false);
        setRequiredApiCalling(false);
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getWithdrawTransactions = async () => {
        setIsLoading(true);

        try {
            let url = `${Routes._ONLINE_WITHDRAWAL}?page=${pageNo}&pageSize=${_PAGE_SIZE}`;

            if (withdrawFilterOptions.find((x) => x.label == optionType)) {
                url += `&status=${withdrawFilterOptions.find((x) => x.label == optionType)
                    .value
                    }`;
            }

            let responseJson = await ApiEngine.get(url, {
                headers: { skipLoading: true }
            });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let data = responseJson[ApiKey._API_DATA_KEY]['data'];

            setTotalCount(responseJson[ApiKey._API_DATA_KEY]['totalCount']);
            setTotalAmount(responseJson[ApiKey._API_DATA_KEY]['totalAmount']);

            if (pageNo === 1) {
                setWithdrawData([...data]);
            } else {
                setWithdrawData([...withdrawData, ...data]);
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        } finally {
            setIsLoading(false);
            setRequiredApiCalling(false);
        }
    };
    /// <summary>
    /// Author: Juin
    /// </summary>
    const returnTransactionIcon = (transactionTypeId, amount) => {
        let iconSrc = require('../../../assets/images/svg/general-plus.svg').default;
        let checkAmount = amount > 0;

        if (selectedTab == _WALLET_ACTIONS.TRANSACTION) {
            if (
                (optionType == TransactionType._ALL_TRANSACTION && transactionTypeId == MergedTransactionTypeId._SEND_CREDIT)
                ||
                (optionType == TransactionType._TRANSFER_HISTORY)
            ) {
                iconSrc = require('../../../assets/images/svg/general-transfer.svg').default;
            }
            else if (!checkAmount) {
                iconSrc = require('../../../assets/images/svg/general-minus.svg').default;
            }
        }
        else {

        }

        return iconSrc;
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const returnTitleString = (item) => {
        let transactionTypeId = item.typeId;
        let amount = item.amount;
        let isSender = item.isSender;
        let titleString = '';
        let checkAmount = amount > 0;

        if (selectedTab == _WALLET_ACTIONS.TRANSACTION) {
            if (
                (optionType == TransactionType._ALL_TRANSACTION && transactionTypeId == MergedTransactionTypeId._ONLINE_BANKING)
                ||
                (optionType == TransactionType._GPAY88)
            ) {
                titleString = t('GPAY88_DEPOSIT');
            }
            else if (
                (optionType == TransactionType._ALL_TRANSACTION && transactionTypeId == MergedTransactionTypeId._EWALLET)
                ||
                (optionType == TransactionType._EWALLET)
            ) {
                titleString = t('EWALLET_TOP_UP');
            }
            else if (
                (optionType == TransactionType._ALL_TRANSACTION && transactionTypeId == MergedTransactionTypeId._SEND_CREDIT)
                ||
                (optionType == TransactionType._TRANSFER_HISTORY)
            ) {
                titleString = isSender ? t('SEND_PAYMENT') : t('RECEIVE_PAYMENT');

                if (!stringIsNullOrEmpty(item.description)) {
                    titleString += "\n" + item.description;
                }
            }
            else if (
                (optionType == TransactionType._ALL_TRANSACTION && transactionTypeId == MergedTransactionTypeId._MOBILE_PIN_TOPUP)
                ||
                (optionType == TransactionType._MOBILE_PIN_TOPUP)
            ) {
                titleString = t('MOBILE_PIN_TOPUP');
            }
            else {
                titleString = checkAmount ? t('SHOP_TOP_UP') : t('SHOP_WITHDRAWAL');
            }
        }
        else if (selectedTab == _WALLET_ACTIONS.CONVERT) {
            let convertType = item.type;

            if (
                (convertType == TransactionType._CONVERT_TO_SCORE)
                ||
                (transactionTypeId == MergedTransactionTypeId._CONVERT_TO_SCORE)
            ) {
                titleString = t('CONVERT_TO_SCORE');
            }
            else if (
                (convertType == TransactionType._CONVERT_TO_CREDIT)
                ||
                (transactionTypeId == MergedTransactionTypeId._CONVERT_TO_CREDIT)
            ) {
                titleString = t('CONVERT_TO_CREDIT');
            }
            else if (
                (item.remark == _CLAIM_REWARD_APP)
                ||
                (transactionTypeId == MergedTransactionTypeId._REWARD_TO_CREDIT)
            ) {
                titleString = t('REWARD_TO_WALLET');
            }
            else {
                titleString = t(convertType);
            }
        }
        else {
            titleString = '';
        }

        return titleString;
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderWalletItem = ({ item, index }) => {
        let transactionStatus = optionType == TransactionType._TRANSFER_HISTORY ?
            TransactionHistoryStatus.SUCCESSFUL : item.status;

        return (
            <Stack className={checkBrowser() ? 'transaction-item-desktop' : 'transaction-item'}>
                <Button
                    key={index}
                    variant={'link'}
                    onClick={() => {
                        if (
                            (optionType == TransactionType._ALL_TRANSACTION && item.typeId == MergedTransactionTypeId._ONLINE_BANKING)
                            ||
                            (optionType == TransactionType._GPAY88)
                        ) {
                            navigateToTransactionDetails(item, TransactionType._GPAY88);
                        }
                        else if (
                            (optionType == TransactionType._ALL_TRANSACTION && item.typeId == MergedTransactionTypeId._EWALLET)
                            ||
                            (optionType == TransactionType._EWALLET)
                        ) {
                            navigateToTransactionDetails(item, TransactionType._EWALLET);
                        }
                        else if (
                            (optionType == TransactionType._ALL_TRANSACTION && item.typeId == MergedTransactionTypeId._SEND_CREDIT)
                            ||
                            (optionType == TransactionType._TRANSFER_HISTORY)
                        ) {
                            navigateToTransactionDetails(item, optionType);
                        }
                        else if (
                            (optionType == TransactionType._ALL_TRANSACTION && item.typeId == MergedTransactionTypeId._MOBILE_PIN_TOPUP)
                            ||
                            (optionType == TransactionType._MOBILE_PIN_TOPUP)
                        ) {
                            navigateToTransactionDetails(item, TransactionType._MOBILE_PIN_TOPUP);
                        }
                        else {
                            navigateToTransactionDetails(item, optionType);
                        }
                    }}
                >
                    <Flex gap={checkBrowser() ? 2 : 0} align={'center'} w='full'>
                        <img
                            className="transaction-icon"
                            boxSize={'35px'}
                            src={
                                returnTransactionIcon(item.typeId, item.amount)
                            }
                        />
                        <Stack spacing={0} ml={2} align={'flex-start'}>
                            <Text textAlign='left' whiteSpace={'pre-line'}>{returnTitleString(item)}</Text>
                            <Text fontSize={'sm'} fontWeight={'normal'} color={'#f8c763'}>{moment(item.createdTime).format(_DATETIME_FORMAT)}</Text>
                        </Stack>
                        <Spacer />
                        <Stack spacing={0} align={'flex-end'}>
                            <Text>{`${item.amount > 0 ? "+" : "-"} RM ${numberWithCurrencyFormat(Math.abs(item.amount))}`}</Text>
                            <Text color={transactionStatus == TransactionHistoryStatus.SUCCESSFUL ? '#27D918' : '#FF5555'}>{transactionStatus == TransactionHistoryStatus.SUCCESSFUL ? t("SUCCESSFUL") : t("FAILED")}</Text>
                        </Stack>
                        <img src={require('../../../assets/images/svg/general-icon-arrow.svg').default} />
                    </Flex>
                </Button>
                <Divider className="divider-horizontal" />
            </Stack>
        )
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderConvertItem = ({ item, index }) => {
        let transactionStatus = item.status;

        return (
            <Stack className={checkBrowser() ? 'transaction-item-desktop' : 'transaction-item'}>
                <Button
                    key={index}
                    variant={'link'}
                    onClick={() => {
                        navigateToTransactionDetails(item, optionType);
                    }}
                >
                    <Flex align={'center'} gap={checkBrowser() ? 2 : 0} w='full'>
                        <Stack spacing={0} ml={2} align={'flex-start'}>
                            <Text textAlign='left' whiteSpace={'pre-line'}>{returnTitleString(item)}</Text>
                            {
                                (
                                    (item.type == TransactionType._CONVERT_TO_SCORE)
                                    ||
                                    (item.typeId == MergedTransactionTypeId._CONVERT_TO_SCORE)
                                ) ?
                                    <Flex alignItems={'center'} mb={'5px'} mt={'5px'} >
                                        <Flex direction={'column'} alignItems={'flex-start'} className="convert-item">
                                            <Text color={'#f8c763'} fontWeight={'semibold'}>{t('EPAY_WALLET')}</Text>
                                        </Flex>
                                        <Image className="transaction-icon" ml={'10px'} mr={'10px'} boxSize={'35px'} src={require('../../../assets/images/svg/general-transfer.svg').default} />
                                        <Flex direction={'column'} alignItems={'flex-start'} className="convert-item">
                                            <Text color={'#f8c763'} fontWeight={'semibold'}>{item.vendorName}</Text>
                                            <Text color={'#fff'} fontWeight={'semibold'}>{item.vendorPlayerUsername}</Text>
                                        </Flex>
                                    </Flex>
                                    :
                                    (
                                        (item.type == TransactionType._CONVERT_TO_CREDIT)
                                        ||
                                        (item.typeId == MergedTransactionTypeId._CONVERT_TO_CREDIT)
                                    )
                                        ?
                                        <Flex alignItems={'center'} mb={'5px'} mt={'5px'} >
                                            <Flex direction={'column'} alignItems={'flex-start'} className="convert-item">
                                                <Text color={'#f8c763'} fontWeight={'semibold'}>{item.vendorName}</Text>
                                                <Text color={'#fff'} fontWeight={'semibold'}>{item.vendorPlayerUsername}</Text>
                                            </Flex>
                                            <Image className="transaction-icon" ml={'10px'} mr={'10px'} boxSize={'35px'} src={require('../../../assets/images/svg/general-transfer.svg').default} />
                                            <Flex direction={'column'} alignItems={'flex-start'} className="convert-item">
                                                <Text color={'#f8c763'} fontWeight={'semibold'}>{t('EPAY_WALLET')}</Text>
                                            </Flex>
                                        </Flex>
                                        :
                                        (
                                            (item.remark == _CLAIM_REWARD_APP)
                                            ||
                                            (item.typeId == MergedTransactionTypeId._REWARD_TO_CREDIT)
                                        )
                                            ?
                                            <></>
                                            :
                                            <Flex alignItems={'center'} mb={'5px'} mt={'5px'} >
                                                <Flex direction={'column'} alignItems={'flex-start'} className="convert-item">
                                                    <Text color={'#f8c763'} fontWeight={'semibold'}>{item.vendorName}</Text>
                                                    <Text color={'#fff'} fontWeight={'semibold'}>{item.vendorPlayerUsername}</Text>
                                                </Flex>
                                                <Image className="transaction-icon" ml={'10px'} mr={'10px'} boxSize={'35px'} src={require('../../../assets/images/svg/general-transfer.svg').default} />
                                                <Flex direction={'column'} alignItems={'flex-start'} className="convert-item">
                                                    <Text color={'#f8c763'} fontWeight={'semibold'}>{item.targetVendorName}</Text>
                                                    <Text color={'#fff'} fontWeight={'semibold'}>{item.targetVendorPlayerUsername}</Text>
                                                </Flex>
                                            </Flex>
                            }
                            <Text fontWeight={'normal'} color={'#f8c763'}>{moment(item.createdTime).format(_DATETIME_FORMAT)}</Text>
                        </Stack>
                        <Spacer />
                        <Stack spacing={0} align={'flex-end'}>
                            <Text>{`${item.amount > 0 ? "+" : "-"} RM ${numberWithCurrencyFormat(Math.abs(item.amount))}`}</Text>
                            <Text color={transactionStatus == TransactionHistoryStatus.SUCCESSFUL ? '#27D918' : '#FF5555'}>{transactionStatus == TransactionHistoryStatus.SUCCESSFUL ? t("SUCCESSFUL") : t("FAILED")}</Text>
                        </Stack>
                        <img src={require('../../../assets/images/svg/general-icon-arrow.svg').default} />
                    </Flex>
                </Button>
                <Divider className="divider-horizontal" />
            </Stack>
        )
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderWithdrawItem = ({ item, index }) => {
        let transactionStatus = item.status;

        return (
            <Stack className="transaction-item">
                <Button
                    key={index}
                    variant={'link'}
                    onClick={() => {
                        navigateToTransactionDetails(
                            item,
                            stringIsNullOrEmpty(item.playerId)
                                ? TransactionType._WITHDRAW_CREDIT
                                : TransactionType._WITHDRAW_POINT
                        )
                    }}
                >
                    <Flex align={'center'} w='full'>
                        <img
                            className="transaction-icon"
                            boxSize={'35px'}
                            src={require('../../../assets/images/svg/general-minus.svg').default}
                        />
                        <Stack spacing={0} ml={2} align={'flex-start'}>
                            <Text textAlign='left' whiteSpace={'pre-line'}>
                                {t('ONLINE_WITHDRAWAL')}{' '}
                                {`(${stringIsNullOrEmpty(item.playerId)
                                    ? t('CREDIT')
                                    : t('SCORES')
                                    })`}
                            </Text>
                            <Text color={'#f8c763'}>{moment(item.createdTime).format(_DATETIME_FORMAT)}</Text>
                        </Stack>
                        <Spacer />
                        <Stack spacing={0} align={'flex-end'}>
                            <Text>{`- RM ${numberWithCurrencyFormat(Math.abs(item?.amount))}`}</Text>
                            <Text color={transactionStatus == OnlineWithdrawalStatus._SUCCESS ? '#27D918' : transactionStatus == OnlineWithdrawalStatus._PENDING_APPROVAL ? '#CEA029' : '#FF5555'}>
                                {transactionStatus == OnlineWithdrawalStatus._SUCCESS ? t("APPROVED") : transactionStatus == OnlineWithdrawalStatus._PENDING_APPROVAL ? t("PENDING_APPROVAL") : t("REJECTED")}
                            </Text>
                        </Stack>
                        <img src={require('../../../assets/images/svg/general-icon-arrow.svg').default} />
                    </Flex>
                </Button>
                <Divider className="divider-horizontal" />
            </Stack>
        )
    }

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'TRANSACTION_HISTORY'} bgColor="linear-gradient(#a94ff1,#9e32dd)" style={{ height: '133px', paddingTop: '2.5rem' }}>
                    <Flex className="tab-container">
                        {
                            _TABS.map(i => (
                                <Button
                                    className={i == selectedTab ? "active" : ""}
                                    onClick={() => {
                                        setSelectedTab(i);
                                        setPageNo(_INITIAL_PAGE_NO);

                                        if (selectedTab != i) {
                                            if (
                                                i ===
                                                _WALLET_ACTIONS.WITHDRAWAL_REQUEST
                                            ) {
                                                getWithdrawTransactions();
                                            } else {
                                                setTransactionData([]);
                                                setConvertData([]);
                                                setOptionType(
                                                    i ===
                                                        _WALLET_ACTIONS.TRANSACTION
                                                        ? TransactionType._ALL_TRANSACTION
                                                        : TransactionType._ALL_CONVERT
                                                );
                                            }
                                        }
                                    }}
                                >
                                    {t(i)}
                                </Button>
                            ))
                        }
                    </Flex>
                    <Flex mt={'-8px'} paddingInline={6} paddingBlock={5} bg={'#1d1643'}>
                        {
                            selectedTab != _WALLET_ACTIONS.WITHDRAWAL_REQUEST &&
                            <>
                                <Text fontWeight={'semibold'} mr={3} onClick={() => setShowDatePicker(!showDatePicker)}>
                                    {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                </Text>
                                <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                            </>
                        }
                        <Spacer />
                        <Text fontWeight={'semibold'} mr={3} onClick={() => setShowFilterBar(!showFilterBar)}>
                            {t('FILTER_BY')}
                        </Text>
                        <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                    </Flex>
                </GeneralTopBar>
                <Stack className="header-margin-top" mt={'110px'}>
                    {
                        (selectedTab == _WALLET_ACTIONS.TRANSACTION
                            ? transactionData
                            : selectedTab == _WALLET_ACTIONS.CONVERT
                                ? convertData
                                : withdrawData
                        ).length > 0 ?
                            <>
                                {
                                    !isLoading &&
                                    optionType != TransactionType._TRANSFER_SCORE &&
                                    optionType != TransactionType._TRANSFER_HISTORY &&
                                    optionType != TransactionType._ALL_TRANSACTION &&
                                    optionType != TransactionType._ALL_CONVERT &&
                                    selectedTab != _WALLET_ACTIONS.WITHDRAWAL_REQUEST &&
                                    totalAmount &&
                                    <Text
                                        color={'app.blue'}
                                        mt={2}
                                        fontWeight={'600'}
                                    >
                                        {t('TOTAL_AMOUNT') +
                                            ' : RM ' +
                                            numberWithCurrencyFormat(totalAmount)}
                                    </Text>
                                }
                                <div
                                    id="scrollableDiv"
                                    style={{
                                        // height: '100vh',
                                        // overflow: "auto"
                                    }}>
                                    <InfiniteScroll
                                        style={{ display: "flex", flexDirection: "column" }}
                                        dataLength={
                                            (selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                ? transactionData
                                                : selectedTab == _WALLET_ACTIONS.CONVERT
                                                    ? convertData
                                                    : withdrawData
                                            ).length
                                        }
                                        next={() => {
                                            let page = pageNo + 1;
                                            setPageNo(page);
                                        }}
                                        hasMore={(selectedTab == _WALLET_ACTIONS.TRANSACTION
                                            ? transactionData
                                            : selectedTab == _WALLET_ACTIONS.CONVERT
                                                ? convertData
                                                : withdrawData
                                        ).length < totalCount}
                                        loader={<Spinner color="white" />}
                                    >
                                        {
                                            (selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                ? transactionData
                                                : selectedTab == _WALLET_ACTIONS.CONVERT
                                                    ? convertData
                                                    : withdrawData
                                            ).map((item, index) => {
                                                return (
                                                    selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                        ? <RenderWalletItem item={item} index={index} />
                                                        : selectedTab == _WALLET_ACTIONS.CONVERT
                                                            ? <RenderConvertItem item={item} index={index} />
                                                            : <RenderWithdrawItem item={item} index={index} />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                </div>
                            </> :
                            <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                                <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("NO_TRANSACTIONS")}</Text>
                                <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("YOU_HAVENT_MADE_ANY_TRANSACTIONS_YET")}</Text>
                            </AbsoluteCenter>
                    }
                </Stack>
                <DateFilter
                    isOpen={showDatePicker}
                    onClose={() => setShowDatePicker(!showDatePicker)}
                    closeOnOverlayClick={false}
                    applyDateFilter={(data) => {
                        if (
                            moment(data.startDate).format('yyyy-MM-DD') !=
                            startDate ||
                            moment(data.startDate).format('yyyy-MM-DD') != endDate
                        ) {
                            setTransactionData([]);
                            setConvertData([]);
                            setIsLoading(true);
                            setStartDate(
                                moment(data.startDate).format('yyyy-MM-DD')
                            );
                            setEndDate(moment(data.endDate).format('yyyy-MM-DD'));
                            setPageNo(_INITIAL_PAGE_NO);
                        }
                    }}
                />
                <BottomSheet
                    title={t('FILTER_BY')}
                    isOpen={showFilterBar}
                    onClose={() => setShowFilterBar(!showFilterBar)}
                    content={
                        <Stack>
                            {
                                filterOptions.map((item, index) => {
                                    return (
                                        <>
                                            <Button key={index} variant={'link'} onClick={() => { setOptionType(item.label); }}>
                                                <Flex align={'center'} w='full' h='2.5rem' paddingInline={5}>
                                                    <Text fontSize={'md'} as='b'>{t(item.label)}</Text>
                                                    <Spacer />
                                                    <img
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            opacity: optionType == item.label ? 1 : 0.3
                                                        }}
                                                        src={require('../../../assets/images/svg/general-selected.svg').default} />
                                                </Flex>
                                            </Button>
                                            {
                                                index + 1 != filterOptions.length &&
                                                <Divider className="app-divider-full-width-setting" />
                                            }
                                        </>
                                    )
                                })
                            }
                            <Flex w={'100%'}>
                                <Button className="date-filter-reset-button" onClick={() => setOptionType(filterOptions[0])}>
                                    {t('CLEAR_ALL')}
                                </Button>
                                <Button className="date-filter-apply-button" onClick={() => {
                                    setTransactionData([]);
                                    setConvertData([]);
                                    setWithdrawData([]);
                                    setPageNo(_INITIAL_PAGE_NO);
                                    setShowFilterBar(!showFilterBar);
                                    setIsLoading(true);
                                }}>
                                    {t('APPLY')}
                                </Button>
                            </Flex>
                        </Stack>
                    } />
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('TRANSACTION_HISTORY')}</Text>
                        </div>
                        <HStack bg={'transparent'} spacing={0} minW={'54rem'}>
                            {
                                _TABS.map(i => (
                                    <Button
                                        background={i == selectedTab ? '#ac4cfe' : '#7416ab'}
                                        color={'white'}
                                        fontWeight={400}
                                        borderTopRadius={15}
                                        borderBottomRadius={0}
                                        height={12}
                                        minW={'18rem'}
                                        // className={i == selectedTab ? "active" : ""}
                                        onClick={() => {
                                            setSelectedTab(i);
                                            setPageNo(_INITIAL_PAGE_NO);

                                            if (selectedTab != i) {
                                                if (
                                                    i ===
                                                    _WALLET_ACTIONS.WITHDRAWAL_REQUEST
                                                ) {
                                                    getWithdrawTransactions();
                                                } else {
                                                    setTransactionData([]);
                                                    setConvertData([]);
                                                    setOptionType(
                                                        i ===
                                                            _WALLET_ACTIONS.TRANSACTION
                                                            ? TransactionType._ALL_TRANSACTION
                                                            : TransactionType._ALL_CONVERT
                                                    );
                                                }
                                            }
                                        }}
                                    >
                                        {t(i)}
                                    </Button>
                                ))
                            }
                        </HStack>
                        <div style={{ background: "linear-gradient(#ac4cfe,#7416ab)", padding: "20px", height: "30rem", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                            <Flex mt={2.5} paddingInline={3}>
                                {
                                    selectedTab != _WALLET_ACTIONS.WITHDRAWAL_REQUEST &&
                                    <>
                                        <Text fontWeight={'semibold'} mr={3} cursor={'pointer'} onClick={() => setShowDatePicker(!showDatePicker)}>
                                            {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                        </Text>
                                        <img style={{ cursor: "pointer" }} onClick={() => setShowDatePicker(!showDatePicker)} src={require('../../../assets/images/svg/updown-white.svg').default} />
                                    </>
                                }
                                <Spacer />
                                <Text cursor={'pointer'} fontWeight={'semibold'} mr={3} onClick={() => setShowFilterBar(!showFilterBar)}>
                                    {t('FILTER_BY')}
                                </Text>
                                <img onClick={() => setShowFilterBar(!showFilterBar)} style={{ cursor: "pointer" }} src={require('../../../assets/images/svg/updown-white.svg').default} />
                            </Flex>
                            <Stack padding={5} spacing={.5}>
                                {
                                    (selectedTab == _WALLET_ACTIONS.TRANSACTION
                                        ? transactionData
                                        : selectedTab == _WALLET_ACTIONS.CONVERT
                                            ? convertData
                                            : withdrawData
                                    ).length > 0 ?
                                        <>
                                            {
                                                !isLoading &&
                                                optionType != TransactionType._TRANSFER_SCORE &&
                                                optionType != TransactionType._TRANSFER_HISTORY &&
                                                optionType != TransactionType._ALL_TRANSACTION &&
                                                optionType != TransactionType._ALL_CONVERT &&
                                                selectedTab != _WALLET_ACTIONS.WITHDRAWAL_REQUEST &&
                                                totalAmount &&
                                                <Text
                                                    color={'app.blue'}
                                                    mt={2}
                                                    fontWeight={'600'}
                                                >
                                                    {t('TOTAL_AMOUNT') +
                                                        ' : RM ' +
                                                        numberWithCurrencyFormat(totalAmount)}
                                                </Text>
                                            }
                                            <div
                                                id="scrollableDiv"
                                                style={{
                                                    height: '23rem',
                                                    overflow: "auto"
                                                }}>
                                                <InfiniteScroll
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                    dataLength={
                                                        (selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                            ? transactionData
                                                            : selectedTab == _WALLET_ACTIONS.CONVERT
                                                                ? convertData
                                                                : withdrawData
                                                        ).length
                                                    }
                                                    next={() => {
                                                        let page = pageNo + 1;
                                                        setPageNo(page);
                                                    }}
                                                    hasMore={(selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                        ? transactionData
                                                        : selectedTab == _WALLET_ACTIONS.CONVERT
                                                            ? convertData
                                                            : withdrawData
                                                    ).length < totalCount}
                                                    loader={<Spinner color="white" />}
                                                >
                                                    {
                                                        (selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                            ? transactionData
                                                            : selectedTab == _WALLET_ACTIONS.CONVERT
                                                                ? convertData
                                                                : withdrawData
                                                        ).map((item, index) => {
                                                            return (
                                                                selectedTab == _WALLET_ACTIONS.TRANSACTION
                                                                    ? <RenderWalletItem item={item} index={index} />
                                                                    : selectedTab == _WALLET_ACTIONS.CONVERT
                                                                        ? <RenderConvertItem item={item} index={index} />
                                                                        : <RenderWithdrawItem item={item} index={index} />
                                                            )
                                                        })
                                                    }
                                                </InfiniteScroll>
                                            </div>
                                        </> :
                                        <div style={{ textAlign: "center", padding: '3rem' }}>
                                            <Center>
                                                <Image boxSize={'150px'} textAlign={'center'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                            </Center>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("NO_TRANSACTIONS")}</Text>
                                            <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("YOU_HAVENT_MADE_ANY_TRANSACTIONS_YET")}</Text>
                                        </div>
                                }
                            </Stack>
                        </div>
                        <DateFilter
                            isOpen={showDatePicker}
                            onClose={() => setShowDatePicker(!showDatePicker)}
                            closeOnOverlayClick={false}
                            applyDateFilter={(data) => {
                                if (
                                    moment(data.startDate).format('yyyy-MM-DD') !=
                                    startDate ||
                                    moment(data.startDate).format('yyyy-MM-DD') != endDate
                                ) {
                                    setTransactionData([]);
                                    setConvertData([]);
                                    setIsLoading(true);
                                    setStartDate(
                                        moment(data.startDate).format('yyyy-MM-DD')
                                    );
                                    setEndDate(moment(data.endDate).format('yyyy-MM-DD'));
                                    setPageNo(_INITIAL_PAGE_NO);
                                }
                            }}
                        />
                        <BottomSheet
                            title={t('FILTER_BY')}
                            isOpen={showFilterBar}
                            onClose={() => setShowFilterBar(!showFilterBar)}
                            content={
                                <Stack>
                                    {
                                        filterOptions.map((item, index) => {
                                            return (
                                                <>
                                                    <Button key={index} variant={'link'} onClick={() => { setOptionType(item.label); }}>
                                                        <Flex align={'center'} w='full' h='2.5rem' paddingInline={5}>
                                                            <Text fontSize={'md'} as='b'>{t(item.label)}</Text>
                                                            <Spacer />
                                                            <img
                                                                style={{
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    opacity: optionType == item.label ? 1 : 0.3
                                                                }}
                                                                src={require('../../../assets/images/svg/general-selected.svg').default} />
                                                        </Flex>
                                                    </Button>
                                                    {
                                                        index + 1 != filterOptions.length &&
                                                        <Divider className="divider-horizontal" />
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                    <Flex w={'100%'}>
                                        <Button className="date-filter-reset-button" onClick={() => setOptionType(filterOptions[0])}>
                                            {t('CLEAR_ALL')}
                                        </Button>
                                        <Button className="date-filter-apply-button" onClick={() => {
                                            setTransactionData([]);
                                            setConvertData([]);
                                            setWithdrawData([]);
                                            setPageNo(_INITIAL_PAGE_NO);
                                            setShowFilterBar(!showFilterBar);
                                            setIsLoading(true);
                                        }}>
                                            {t('APPLY')}
                                        </Button>
                                    </Flex>
                                </Stack>
                            } />
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}