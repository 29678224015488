import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import ConversionTopBar from "../../../component/conversion-top-bar";
import { Stack, Image, Box, Button, Card, CardBody, Text, Divider, Flex, Center } from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import Routes from "../../../common/api_routes";
import ApiEngine, { ApplicationJsonHeaderConfig } from "../../../common/api_engine";
import { ApiKey, ValidationPattern, _AMOUNT_TYPE, _PAYMENT_DEVICE_TYPE } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, isString, createFormBody, checkBrowser, stringIsNullOrEmpty } from "../../../common/util";
import { getMemberDetails } from "../../../application/action/auth_action";
import { Pages } from "../../../routes/page";
import moment from "moment";
import CustomInput from "../../../component/input";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
var md5 = require('md5');

/// <summary>
/// Author: Juin
/// </summary>
export default ({ isDuitNow }) => {
    var _router = useRouter();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, watch } = useForm();
    const userData = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {

    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        _dispatch(showCustomDialog({
            success: true,
            title: "CONFIRMATION",
            content: t("ARE_YOU_SURE_WANT_TO_DEPOSIT_TO_WALLET", {
                amount: `RM ${watch('amount')}`
            }),
            onConfirm: () => {
                createTransactionHandler(data);
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('amount')));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const createTransactionHandler = async (data) => {
        try {
            let dataAmount = data.amount;

            if (isString(dataAmount)) {
                dataAmount = parseInt(data.amount);
            }

            let params = {
                Amount: dataAmount,
                DeviceType: _PAYMENT_DEVICE_TYPE
            };
            let formBody = createFormBody(params);
            let responseJson = await ApiEngine.post(
                isDuitNow
                    ? Routes._CREATE_DUITNOW_TRANSACTION
                    : Routes._CREATE_TRANSACTION,
                formBody
            );

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _router.navigate(Pages._PAYMENT_GATEWAY, {
                    paymentGatewayTransaction: responseJson[ApiKey._API_DATA_KEY],
                    isDuitNow: isDuitNow
                });
            } else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        } catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    };

    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={t(isDuitNow ? 'DUITNOW' : 'GPAY88_DEPOSIT')} bgColor="linear-gradient(#a94ff1,#9e32dd)">
                    <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                </GeneralTopBar>
                <div className="header-margin-top inner-container">
                    {isDuitNow && (
                        <Flex justifyContent={'flex-end'}>
                            <img
                                className="duitNowImg"
                                src={require('../../../assets/images/duitnowlogo.png')}
                            />
                        </Flex>
                    )}
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Controller
                            control={control}
                            name="amount"
                            defaultValue={10}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                    value={value}
                                    label={`1. ${t('ENTER_AMOUNT')}`}
                                    placeholder={t('ENTER_AMOUNT')}
                                    inputType="number"
                                    background='linear-gradient(#5a0089,#7c15bd) !important'
                                    border='2px solid #e2a570 !important'
                                    borderRadius='10px !important'
                                    subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                    subLabelStyle={
                                        {
                                            color: '#f8c763'
                                        }
                                    }
                                    errors={errors}
                                />
                            )}
                            rules={{
                                required: true,
                                min: 1
                            }}
                        />
                        <Stack spacing={4} direction='row' align='center' justifyContent={['space-between', 'flex-start']}>
                            {_AMOUNT_TYPE.map((value, index) => (
                                <Button
                                    className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                    size='md'
                                    onClick={() => setValue('amount', value.amount)}
                                >
                                    RM {value.amount}
                                </Button>
                            ))}
                        </Stack>
                        <Button
                            className="grey-gradient-button btn-float-btm"
                            type="submit">
                            {t('NEXT')}
                        </Button>
                    </form>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t(isDuitNow ? 'DUITNOW' : 'GPAY88_DEPOSIT')}</Text>
                        </div>
                        <ConversionTopBar credit={userData?.credit} amount={userData?.reward} isReward />
                        <div style={{ marginTop: "2rem" }}>
                            {isDuitNow && (
                                <Flex justifyContent={'flex-end'}>
                                    <img
                                        className="duitNowImg"
                                        src={require('../../../assets/images/duitnowlogo.png')}
                                    />
                                </Flex>
                            )}
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Controller
                                    control={control}
                                    name="amount"
                                    defaultValue={10}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            bg='#2c3956 !important'
                                            color='white'
                                            boxShadow='none !important'
                                            borderRadius='10px !important'
                                            background="linear-gradient(#5a0089,#7c15bd) !important"
                                            transitionDuration='normal'
                                            _hover={{ border: '2px solid white !important' }}
                                            border="2px solid transparent !important"
                                            label={`1. ${t('ENTER_AMOUNT')}`}
                                            placeholder={t('ENTER_AMOUNT')}
                                            inputType="number"
                                            subLabel={t('MIN_AMOUNT_REQUIRED_IS_RM10')}
                                            subLabelStyle={
                                                {
                                                    color: '#f8c763',
                                                    fontSize: 'sm'
                                                }
                                            }
                                            errors={errors}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                        min: 1
                                    }}
                                />
                                <Stack spacing={4} direction='row' align='center' mb={10} justifyContent={['space-between', 'flex-start']}>
                                    {_AMOUNT_TYPE.map((value, index) => (
                                        <Button
                                            className={`btn-outline ${watch('amount') == value.amount ? "active" : ""}`}
                                            size='md'
                                            onClick={() => setValue('amount', value.amount)}
                                        >
                                            RM {value.amount}
                                        </Button>
                                    ))}
                                </Stack>
                                <Button
                                    style={activeBtn() ? { borderRadius: "10px", backgroundImage: "linear-gradient(#e5b823, #8d7216)", color: "white", padding: "25px 0px" } : { borderRadius: "10px", background: "linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%)", color: "white", padding: "25px 0px" }} w='100%'
                                    type="submit">
                                    {t('NEXT')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}