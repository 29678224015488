import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouter } from "../../../hooks/useRouter";
import { checkBrowser, stringIsNullOrEmpty } from "../../../common/util";
import GeneralTopBar from "../../../component/general-top-bar";
import Iframe from 'react-iframe';
import { Button, HStack, Image, Spacer, Text } from "@chakra-ui/react";
import { Pages } from "../../../routes/page";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    const { t } = useTranslation();
    const { dataUrl } = _location?.state;

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(dataUrl)) {
            _router.back();
        }
    }, [])

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar title={'LUCKY_SPIN'} bgColor="linear-gradient(#a94ff1,#9e32dd)" rightComponent={<Button
                    className="grey-gradient-button"
                    style={{ width: '90%' }}
                    onClick={() => {
                        _router.navigate(Pages._SPIN_HISTORY);
                    }}>
                    {t('HISTORY')}
                </Button>} />
                <Iframe url={dataUrl} allow={'midi;encrypted-media;'} styles={{ overflowX: 'auto' }} className="lucky-spin-web-view-frame" />
            </div>
        )
    } else {
        return (
            <>
                <div style={{ width: "100%", position: "fixed", top: "0", zIndex: "99999", backgroundImage: "linear-gradient(to right, #7416ab, #ac4cfe, #7416ab)", textAlign: "center" }}>
                    <HStack paddingInline={6} paddingBlock={2} spacing={6} maxW={'100rem'} margin={'auto'}>
                        <Image
                            boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                            src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                        <Spacer />
                        <Text ml={6} textAlign={'center'} fontWeight={'bold'}>{t('LUCKY_SPIN')}</Text>
                        <Spacer />
                        <Button
                        borderRadius={15}
                        paddingBlock={'8px'}
                        height={'100%'}
                        fontSize={'sm'}
                        color={'white'}
                            bg={'linear-gradient(#400164,#7416ab, #400164)'}
                        onClick={() => {
                            _router.navigate(Pages._SPIN_HISTORY);
                        }}>{t('HISTORY')}</Button>
                    </HStack>
                </div>
                <Iframe url={dataUrl} allow={'midi;encrypted-media;'} styles={{ paddingTop: "3rem" }} className="lucky-spin-web-view-frame" />
            </>
        )
    }

}