import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { _STANDARD_MOBILE_VIEW_WIDTH_SIZE, BG_TYPE, Language, ApiKey, _LANGUAGE_OPTION, _CHECK_IN_COOLDOWN } from "./common/constant";
import { stringIsNullOrEmpty, isObjectEmpty, checkBrowser } from "./common/util";
import Loader from "./component/loader";
import Content from "./routes/content";
import { connect, useDispatch } from "react-redux";
import { useRouter } from "./hooks/useRouter";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { _NON_AUTH_ROUTES, _AUTH_ROUTES, Pages } from './routes/page';
import { PageSettings } from './routes/setting';
import CustomDialog from './component/dialog';
import { showCustomDialog, setTempCustomDialogData, checkInSpinLogin, showSimpleDialog, setTempSimpleDialogData } from './application/action/app_action';
import { ToastContainer } from 'react-toastify';
import { getMemberDetails } from './application/action/auth_action';
import { ConfigEnum } from './common/config';
import { Image, AbsoluteCenter, Stack, Center, Avatar } from '@chakra-ui/react';
import Routes, { _RESOURCE_URL } from './common/api_routes';
import ApiEngine from './common/api_engine';
import { Fab, Action } from 'react-tiny-fab';
import { isMobile, isBrowser } from 'react-device-detect';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import { toast } from 'react-toastify';
import Header from './pages/non-auth-pages/header';

/// <summary>
/// Author: Juin
/// </summary>
const App = () => {
    const { t, i18n } = useTranslation();
    var _router = useRouter();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _customDialogData = useSelector((state) => state.appState.customDialogData);
    var _tempCustomDialogData = useSelector((state) => state.appState.tempCustomDialogData);
    var _bonusDialogData = useSelector((state) => state.appState.bonusDialogData);
    var _simpleDialogData = useSelector((state) => state.appState.simpleDialogData);
    var _tempSimpleDialogData = useSelector((state) => state.appState.tempSimpleDialogData);
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _LAST_CHECK_IN = useSelector((state) => state.appState.spinLoginCheckedDate);
    const [bgType, setBgType] = useState(BG_TYPE.blur);
    const [customerSupport, setCustomerSupport] = useState([]);
    const [isAppInit, setIsAppInit] = useState(false);
    const withoutHeaderPaths = [Pages._DEFAULT, Pages._WEB_VIEW, Pages._SPIN_EVENT, Pages._ACCOUNT_VERIFICATION];
    const withHeaderPaths = [Pages._REGISTER, Pages._LOGIN, Pages._FORGOT_PASSWORD, Pages._CREATE_OR_UPDATE_BANK, Pages._SECONDARY_PASSWORD_LOGIN];
    const referral = new URLSearchParams(_location.search).get('referral');
    const [hasAngpau, setHasAngpau] = useState([]);
    const [angpauLinks, setAngpauLinks] = useState({});

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        (async () => {

            if (checkBrowser()) {
                window.scrollTo(0, 0);
            }

            if (stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE))) {
                localStorage.setItem(ConfigEnum._LANGUAGE, Language._ENGLISH);
            }

            if (referral) {
                localStorage.setItem(ConfigEnum._REFERRAL_CODE, referral);

                if (!_IS_LOGGED_IN) {
                    _router.navigate(Pages._REGISTER);
                }
            }

            // if (!_IS_LOGGED_IN) {
            //     let csArr = [];
            //     var responseJson = await ApiEngine.get(Routes._GET_CUSTOMER_SERVICE_WITHOUT_LOGIN, { headers: { "skipLoading": true } });

            //     if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            //         let csData = responseJson[ApiKey._API_DATA_KEY];

            //         if (!stringIsNullOrEmpty(csData)) {
            //             if (csData.includes(',')) {
            //                 csArr = csData.split(',');
            //             }
            //             else {
            //                 csArr.push(csData);
            //             }

            //             setCustomerSupport(csArr.map((item, index) => {
            //                 return {
            //                     text: `${t('CUSTOMER_SERVICE')} ${index + 1}`,
            //                     icon: require('./assets/images/female-telemarketer.png'),
            //                     name: item
            //                 }
            //             }));
            //         }
            //     }
            // }

            setIsAppInit(true);
        })();
    }, []);

    /// <summary>
    /// Author : Juin
    /// Edited: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            if (isAppInit && _IS_LOGGED_IN) {
                let today = moment().format('L');
                let lastRetrievedBannerTime = localStorage.getItem(ConfigEnum._HOME_BANNER_LAST_RETRIEVED);
                let lastRetrievedRankingTime = localStorage.getItem(ConfigEnum._HOME_RANKING_LAST_RETRIEVED);

                if (stringIsNullOrEmpty(lastRetrievedBannerTime) ||
                    lastRetrievedBannerTime != today) {
                    let bannerPopUp = [];
                    var responseJson = await ApiEngine.get(Routes._HOMESCREEN_BANNERS);

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        responseJson[ApiKey._API_DATA_KEY]?.data?.map(item =>
                            bannerPopUp.push({
                                image: _RESOURCE_URL + item.imageLocalizations.find(i => i.languageId)?.value,
                                cta: item?.cta
                            }));

                        localStorage.setItem(ConfigEnum._HOME_BANNER_LAST_RETRIEVED, today);

                        if (bannerPopUp.length > 0) {
                            _dispatch(showCustomDialog({
                                hideTitle: true,
                                isPlainTheme: true,
                                homeBanner: true,
                                content: '',
                                customContent: (
                                    <Carousel showArrows={false} showThumbs={false} showStatus={false} interval={3000} infiniteLoop autoPlay emulateTouch>
                                        {
                                            bannerPopUp.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Image src={item.image} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                )
                            }));
                        }
                    }
                }

                if (stringIsNullOrEmpty(lastRetrievedRankingTime) ||
                    lastRetrievedRankingTime != today) {
                    var levelUpDownResponse = await ApiEngine.get(Routes._CHECK_IS_LOGGED_IN);

                    if (levelUpDownResponse[ApiKey._API_SUCCESS_KEY] && levelUpDownResponse?.data != null && levelUpDownResponse?.data?.notification !== null) {
                        localStorage.setItem(ConfigEnum._HOME_RANKING_LAST_RETRIEVED, today);

                        let inactivityAlert =
                            levelUpDownResponse?.data?.inactivityAlertDetail;
                        let rankUpDownContent = levelUpDownResponse?.data?.notification?.content;

                        if (inactivityAlert?.dayLeft > 0) {
                            _dispatch(showCustomDialog({ success: false, content: t('DROP_RANK_IN', { days: inactivityAlert?.dayLeft }) }));

                        }

                        if (!stringIsNullOrEmpty(rankUpDownContent)) {
                            _dispatch(showCustomDialog({ success: true, content: rankUpDownContent, title: t('RANK_INFO') }));
                        }
                    }
                }

                if (stringIsNullOrEmpty(_LAST_CHECK_IN) || _LAST_CHECK_IN != today) {
                    try {
                        _dispatch(checkInSpinLogin());
                    }
                    catch (err) {
                        // do nothing
                    }
                }

                checkInUser();
            }
        })();
    }, [isAppInit, _IS_LOGGED_IN])

    /// <summary>
    /// Author : Juin
    /// </summary>
    useEffect(() => {
        if (
            isObjectEmpty(_customDialogData) &&
            !isObjectEmpty(_tempCustomDialogData)
        ) {
            _dispatch(showCustomDialog(_tempCustomDialogData));
            _dispatch(setTempCustomDialogData({}));
        }
    }, [_customDialogData, _tempCustomDialogData]);

    useEffect(() => {
        if (
            isObjectEmpty(_simpleDialogData) &&
            !isObjectEmpty(_tempSimpleDialogData)
        ) {
            _dispatch(showSimpleDialog(_simpleDialogData));
            _dispatch(setTempSimpleDialogData({}));
        }
    }, [_simpleDialogData, _tempSimpleDialogData]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(_bonusDialogData)) {
            _dispatch(showCustomDialog(_bonusDialogData));
        }
    }, [_bonusDialogData]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const checkInUser = async () => {
        try {
            let callApi = true;
            let lastCheckInDetails = await localStorage.getItem(ConfigEnum._LAST_CHECK_IN_ANGPOW_DATAILS);

            if (!stringIsNullOrEmpty(lastCheckInDetails)) {
                let prevCheckInParams = JSON.parse(lastCheckInDetails);

                // if (!prevCheckInParams?.isWinner && moment().diff(moment(prevCheckInParams?.checkInDateTime), 'minutes') < _CHECK_IN_COOLDOWN) {
                //     callApi = false;
                // }
                if (prevCheckInParams?.isWinner && !moment().startOf('day').isSame(moment(prevCheckInParams?.checkInDateTime).add(1, 'day').startOf('day'))) {
                    callApi = false;
                }
            }

            if (callApi) {
                var responseJson = await ApiEngine.post(Routes._CHECK_IN);

                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }

                setHasAngpau(responseJson[ApiKey._API_DATA_KEY]['programWinners'] ?? []);

                let checkInParams = {
                    isWinner: responseJson[ApiKey._API_DATA_KEY]['allProgramChecked'] ?? false,
                    checkInDateTime: moment().format('YYYY-MM-DD HH:mm:ss')
                };

                localStorage.setItem(
                    ConfigEnum._LAST_CHECK_IN_ANGPOW_DATAILS,
                    JSON.stringify(checkInParams)
                );
            }
        }
        catch (err) {
            // Do nothing
        }
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    function toggleBg(value) {
        setBgType(value);
    }

    return (
        <PageSettings.Provider value={{ toggleBg }}>
            <Loader />
            <CustomDialog
                isVisible={!isObjectEmpty(_customDialogData)}
                success={_customDialogData.success}
                headerTitle={_customDialogData.headerTitle}
                title={_customDialogData.title}
                content={_customDialogData.content}
                customContent={_customDialogData.customContent}
                onConfirm={_customDialogData.onConfirm}
                onCancel={_customDialogData.onCancel}
                confirmTxt={_customDialogData.confirmTxt}
                headerIcon={_customDialogData.headerIcon}
                cancelTxt={_customDialogData.cancelTxt}
                horizontalBtn={_customDialogData.horizontalBtn}
                disableHardwareBackPress={
                    _customDialogData.disableHardwareBackPress
                }
                isPlainTheme={_customDialogData.isPlainTheme}
                hideTitle={_customDialogData.hideTitle}
                hideFooter={_customDialogData.hideFooter}
                homeBanner={_customDialogData.homeBanner}
            />
            <ToastContainer style={{ zIndex: 9999999 }} />

            {!checkBrowser() &&
                <div className={'app'} style={_location.pathname == Pages._PLAYER_DETAIL ? { padding: '0' } : {}}>
                    <Content />
                </div>
            }

            {checkBrowser() &&
                // <div className={`desktop-app ${_location.pathname == Pages._LOGIN || _location.pathname == Pages._REGISTER || _location.pathname == Pages._FORGOT_PASSWORD ? 'bg-login' : ''}`}>
                <div className={'desktop-app'}>
                    {_IS_LOGGED_IN && !withoutHeaderPaths.includes(_location.pathname) && <Header />}
                    {!_IS_LOGGED_IN && withHeaderPaths.includes(_location.pathname) && <Header />}
                    <div style={_IS_LOGGED_IN && _location.pathname == Pages._GAMES && _location.pathname != Pages._WEB_VIEW ? { marginTop: "7rem" } : _IS_LOGGED_IN ? { marginTop: "12rem" } : {}}>
                        <Content />
                    </div>
                </div>
            }
            {
                _IS_LOGGED_IN && isAppInit && hasAngpau.length > 0 &&
                <div
                    style={{
                        position: 'fixed',
                        zIndex: 2,
                        right: checkBrowser() ? '2.5vw' : '1.5vw',
                        top: checkBrowser() ? '30vh' : '80vh'
                    }}
                    onClick={() => setAngpauLinks(hasAngpau[0])}>
                    <div style={{ position: 'relative' }}>
                        <Image
                            boxSize='75px'
                            objectFit='cover'
                            src={require('./assets/images/angpow_button.png')}
                            alt="ePay Pro AngPau" />
                        <span className="notification-dot">{hasAngpau.length}</span>
                    </div>
                </div >
            }
            {
                _IS_LOGGED_IN && isAppInit && !isObjectEmpty(angpauLinks) &&
                <div className='overlay-screen'>
                    <AbsoluteCenter>
                        <iframe
                            width={'350px'}
                            height={'350px'}
                            src={angpauLinks['h5Url']}
                            allowTransparency={true}
                        />
                        <Center mt={'10px'}>
                            <Image
                                boxSize={'50px'}
                                src={require('./assets/images/svg/general-close.svg').default}
                                onClick={() => {
                                    setHasAngpau(hasAngpau.filter(a => a.claimHistoryId != angpauLinks.claimHistoryId));
                                    setAngpauLinks({});
                                    setTimeout(() => {
                                        _dispatch(getMemberDetails());
                                    }, 3000)
                                }} />
                        </Center>
                    </AbsoluteCenter>
                </div >
            }
        </PageSettings.Provider >
    );
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps, null)(App);