import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { Box, Flex, Button, Image, Text, Spacer, Divider, Stack, Center, HStack, background, SimpleGrid } from "@chakra-ui/react";
import { Pages } from "../../../routes/page";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import { ApiKey, Language, _WALLET_ACTIONS, _WEB_VERSION, ScreenWidth } from "../../../common/constant";
import { ConfigEnum } from "../../../common/config";
import BottomSheet from "../../../component/bottom-sheet";
import CustomPicker from "../../../component/custom-picker";
import CustomInput from "../../../component/input";
import { stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, checkIsStateRangeValid, checkBrowser, copyToClipboard } from "../../../common/util";
import { showCustomDialog } from "../../../application/action/app_action";
import { getMemberDetails } from "../../../application/action/auth_action";
import moment from "moment";
import { useOrientation } from 'react-use';
import { isMobile } from "react-device-detect";
import Countdown, { zeroPad } from 'react-countdown';

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const _dispatch = useDispatch();
    const languageCode = localStorage.getItem(ConfigEnum._LANGUAGE) || Language._ENGLISH
    const [bottomPopUp, setBottomPopUp] = useState();
    const [bottomPopUpWithApi, setBottomPopUpWithApi] = useState();
    const [vendorPickerVisible, setVendorPickerVisible] = useState(false);
    const [playerPickerVisible, setPlayerPickerVisible] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState();
    const [selectedPlayer, setSelectedPlayer] = useState();
    const [otp, setOtp] = useState();
    const [otpExpiredIn, setOtpExpiredIn] = useState();
    const { type } = useOrientation();

    const TOP_UP_BOTTOM_SHEET_OPTIONS = [
        {
            title: _WALLET_ACTIONS.ONLINE_BANKING,
            image: require('../../../assets/images/transaction/deposit-gpay.png'),
            navigateTo: () => _router.navigate(Pages._ONLINE_BANKING_TOP_UP)
        },
        {
            title: _WALLET_ACTIONS.EWALLET,
            image: require('../../../assets/images/transaction/deposit-ewallet.png'),
            navigateTo: () => _router.navigate(_USER_DATA?.isDuitNowSupported ? Pages._DUITNOW_TOP_UP : Pages._E_WALLET_TOP_UP),
            isNew: _USER_DATA?.isDuitNowSupported,
            verificationCheck: true
        },
        {
            title: _WALLET_ACTIONS.QR_CODE,
            image: require('../../../assets/images/transaction/deposit-qrcode.png'),
            navigateTo: () => _router.navigate(Pages._QR_CODE_TOP_UP),
            isHide: true,
        },
        {
            title: _WALLET_ACTIONS.MOBILE_PIN_TOPUP,
            image: require('../../../assets/images/transaction/deposit-ewallet.png'),
            navigateTo: () => _router.navigate(Pages._MOBILE_PIN_TOP_UP),
            isNew: true
        }
    ];
    const WITHDRAW_BOTTOM_SHEET_OPTIONS = [
        {
            title: _WALLET_ACTIONS.WITHDRAW_WALLET,
            image: require('../../../assets/images/transaction/withdraw-wallet.png'),
            navigateTo: () => _router.navigate(Pages._WITHDRAW_E_CREDIT)
        },
        {
            title: _WALLET_ACTIONS.WITHDRAW_GAME_SCORE,
            image: require('../../../assets/images/transaction/withdraw-score.png'),
            navigateTo: () => _router.navigate(Pages._WITHDRAW_E_POINT)
        }
    ];
    const TRANSFER_BOTTOM_SHEET_OPTIONS = [
        {
            title: _WALLET_ACTIONS.SEND_CREDIT,
            image: require('../../../assets/images/transaction/transfer-payment.png'),
            navigateTo: () => _router.navigate(Pages._SEND_E_CREDIT),
            verificationCheck: true
        },
        {
            title: _WALLET_ACTIONS.RECEIVE_CREDIT,
            image: require('../../../assets/images/transaction/transfer-receive.png'),
            navigateTo: () => _router.navigate(Pages._MY_QR_CODE, { isMemberReferral: false }),
            verificationCheck: true
        }
    ];
    const TRANSACTION_OPTIONS = [
        {
            title: 'TOP_UP',
            image: require('../../../assets/images/transaction/wallet-deposit.png'),
            bottomSheetElement: TOP_UP_BOTTOM_SHEET_OPTIONS,
            // locationCheck: true
        },
        {
            title: 'WITHDRAW',
            image: require('../../../assets/images/transaction/wallet-withdraw-minus.png'),
            bottomSheetElement: WITHDRAW_BOTTOM_SHEET_OPTIONS
        },
        {
            title: 'TRANSFER',
            image: require('../../../assets/images/transaction/wallet-transfer.png'),
            bottomSheetElement: TRANSFER_BOTTOM_SHEET_OPTIONS,
            // locationCheck: true
        }
    ];
    const CONVERT_OPTIONS = [
        {
            title: 'ECREDIT_EPOINT_CONVERTER',
            image: require('../../../assets/images/transaction/convert-wallet-score.png'),
            requiredApiCall: true
        },
        {
            title: 'TRANSFER_EPOINT',
            image: require('../../../assets/images/transaction/convert-transferscore.png'),
            requiredApiCall: true
        },
        {
            title: 'REWARD_TO_ECREDIT',
            image: require('../../../assets/images/transaction/convert-rewardtowalletpng.png'),
            navigateTo: Pages._REWARD_TO_CREDIT,
            showIndicator: _USER_DATA?.reward >= 5
        }
    ];
    const _SECTION_COMPONENTS = [
        {
            title: 'TRANSACTION_ACTIONS',
            options: TRANSACTION_OPTIONS,
            helpAction: () => _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/transaction?lang=${languageCode}` }),
            checkIsLock: true,
            navigationKey: _WALLET_ACTIONS.TRANSACTION
        },
        {
            title: 'CONVERT_ACTIONS',
            options: CONVERT_OPTIONS,
            helpAction: () => _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/convert?lang=${languageCode}` }),
            checkIsLock: false,
            navigationKey: _WALLET_ACTIONS.CONVERT
        }
    ]

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!checkBrowser()) {
            _router.navigate(Pages._DEFAULT);
        }
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(bottomPopUpWithApi)) {
            setSelectedVendor();
            setSelectedPlayer();
        }
    }, [bottomPopUpWithApi]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(bottomPopUpWithApi) && selectedVendor) {
            if (selectedVendor?.players?.length > 0) {
                let newPlayerList = selectedVendor.players.map(
                    (item) => ({
                        ...item,
                        label: item.vendor_username,
                        value: item.player_id
                    })
                );

                setSelectedPlayer(newPlayerList[0]);
                setBottomPopUpWithApi({ ...bottomPopUpWithApi, playerList: [...newPlayerList] });
            } else {
                setSelectedPlayer();
                setBottomPopUpWithApi({ ...bottomPopUpWithApi, playerList: [] });
            }
        }
    }, [selectedVendor]);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getVendorAndPlayer(title) {
        var responseJson = await ApiEngine.get(
            Routes._GET_USERINFO_URL +
            '?id=' +
            _USER_DATA.guid +
            '&versionCode=' +
            _WEB_VERSION
        );

        if (
            responseJson[ApiKey._API_SUCCESS_KEY] &&
            responseJson[ApiKey._API_DATA_KEY].vendors_player.length > 0
        ) {
            let responseVendorList = responseJson[
                ApiKey._API_DATA_KEY
            ].vendors_player.map((item) => ({
                ...item,
                label: item.vendor_name,
                value: item.vendor_id
            }));
            let responsePlayerList = responseJson[
                ApiKey._API_DATA_KEY
            ].vendors_player[0].players.map((item) => ({
                ...item,
                label: item.vendor_username,
                value: item.player_id
            }));

            setSelectedVendor(responseVendorList[0]);
            setSelectedPlayer(responsePlayerList[0]);
            setBottomPopUpWithApi({ popUpTitle: title, vendorList: [...responseVendorList], playerList: [...responsePlayerList] });
        }
        else {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: responseJson[ApiKey._API_MESSAGE_KEY]
                })
            );
        }
    }

    /// <summary>
    /// Author: Juin & Juin
    /// </summary>
    const SectionComponent = ({
        title,
        options = [],
        tutorial,
        checkIsLock,
        navigationKey
    }) => {
        if (!checkBrowser()) {
            return (
                <Box className="transaction-box" w={['100%', '70%']}>
                    <Flex align={'center'}>
                        <Text color={'#ffd056'} fontSize={'sm'} as='b'>{t(title)}</Text>
                        <Button variant={'link'} onClick={tutorial}>
                            <Image
                                boxSize={'40px'}
                                src={require('../../../assets/images/svg/help-color.svg').default}
                            />
                        </Button>
                        <Spacer />
                        <Button
                            className="purple-share-button"
                            style={{ height: '100%', fontSize: 12, padding: '5px 20px' }}
                            onClick={() => {
                                _router.navigate(Pages._TRANSACTION_HISTORY, { type: navigationKey });
                            }}>
                            {t('HISTORY')}
                        </Button>
                    </Flex>
                    <Divider className="divider-horizontal" marginBlock={4} />
                    <Flex w='full'>
                        {
                            options.map((item, index) => {
                                return (
                                    <>
                                        {
                                            index == 1 &&
                                            <Divider className="divider-vertical" orientation="vertical" />
                                        }
                                        <Button variant={'link'} w='full' isDisabled={item.isBeta} onClick={async () => {
                                            if (checkIsLock && _USER_DATA?.memberBonusInfo?.isLocked) {
                                                _dispatch(showCustomDialog({
                                                    success: false,
                                                    title: t('TRANSACTION_FUNCTION_IS_LOCKED'),
                                                    content: t('PLEASE_HIT_EITHER_ONE_CONDITION_BELOW_TO_UNLOCK') + `\n${t('WHEN_ECREDIT')} [${numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMinThreshold)} / ${numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMaxThreshold)}]`,
                                                    onConfirm: () => {

                                                    },
                                                    cancelTxt: t('WHY'),
                                                    onCancel: () => _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/transaction/lock?lang=${languageCode}` }),
                                                    horizontalBtn: false
                                                }));
                                            }
                                            else {
                                                if (item?.bottomSheetElement) {
                                                    setBottomPopUp({ popUpTitle: item.title, content: [...item.bottomSheetElement] });
                                                }
                                                else if (item?.requiredApiCall) {
                                                    getVendorAndPlayer(item.title);
                                                }
                                                else {
                                                    _router.navigate(item.navigateTo);
                                                }
                                            }
                                        }}>
                                            <Stack align={'center'} spacing={0}>
                                                <Image boxSize={'65px'} src={item.image} />
                                                <Flex>
                                                    <Text whiteSpace={'break-spaces'} textAlign={'center'}>{t(item.title)}</Text>
                                                    {
                                                        item.isBeta &&
                                                        <Text whiteSpace={'break-spaces'} textAlign={'center'}>{` (${t('BETA')})`}</Text>
                                                    }
                                                    {
                                                        item.showIndicator &&
                                                        <span className="red-dot-2" />
                                                    }
                                                </Flex>

                                            </Stack>
                                        </Button>
                                        {
                                            index == 1 &&
                                            <Divider className="divider-vertical" orientation="vertical" />
                                        }
                                    </>
                                )
                            })
                        }
                    </Flex>
                </Box>
            )
        } else {
            return (
                <>
                    <Box border={'1px solid white'} padding={6} borderRadius={20} mb={12}>
                        <HStack spacing={0} mb={6}>
                            <Spacer />
                            <Text color={'white'} fontSize={'md'} mr={2} ml={20} as='b'>{t(title)}</Text>
                            <Button variant={'link'} onClick={tutorial}>
                                <Image
                                    boxSize={'40px'}
                                    src={require('../../../assets/images/svg/general-help-icon.svg').default}
                                />
                            </Button>
                            <Spacer />
                            <Button
                                bg={'linear-gradient(#ac4cfe,#7416ab)'} color={'white'}
                                borderRadius={20}
                                style={{ height: '100%', fontSize: 12, padding: '8px 20px' }}
                                onClick={() => {
                                    _router.navigate(Pages._TRANSACTION_HISTORY, { type: navigationKey });
                                }}>
                                {t('HISTORY')}
                            </Button>
                        </HStack>
                        <HStack justifyContent={'center'} spacing={8} mb={2}>
                            {
                                options.map((item, index) => {
                                    return (
                                        <>
                                            <Button variant={'link'} w={44} h={40} bg={'linear-gradient(#a94ff1,#9e32dd)'} borderRadius={20} isDisabled={item.isBeta} _disabled={{ background: "#5a0089", _hover: { background: "#5a0089", cursor: "not-allowed" } }} onClick={async () => {
                                                if (checkIsLock && _USER_DATA?.memberBonusInfo?.isLocked) {
                                                    _dispatch(showCustomDialog({
                                                        success: false,
                                                        title: t('TRANSACTION_FUNCTION_IS_LOCKED'),
                                                        content: t('PLEASE_HIT_EITHER_ONE_CONDITION_BELOW_TO_UNLOCK') + `\n${t('WHEN_ECREDIT')} [${numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMinThreshold)} / ${numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMaxThreshold)}]`,
                                                        onConfirm: () => {

                                                        },
                                                        cancelTxt: t('WHY'),
                                                        onCancel: () => _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/transaction/lock?lang=${languageCode}` }),
                                                        horizontalBtn: false
                                                    }));
                                                }
                                                else {
                                                    if (item?.bottomSheetElement) {
                                                        setBottomPopUp({ popUpTitle: item.title, content: [...item.bottomSheetElement] });
                                                    }
                                                    else if (item?.requiredApiCall) {
                                                        getVendorAndPlayer(item.title);
                                                    }
                                                    else {
                                                        _router.navigate(item.navigateTo);
                                                    }
                                                }
                                            }}>
                                                <Stack align={'center'}>
                                                    <Image boxSize={'100px'} src={item.image} />
                                                    <Flex>
                                                        <Text whiteSpace={'break-spaces'} textAlign={'center'}>{t(item.title)}</Text>
                                                        {
                                                            item.isBeta &&
                                                            <Text whiteSpace={'break-spaces'} textAlign={'center'}>{` (${t('BETA')})`}</Text>
                                                        }
                                                        {
                                                            item.showIndicator &&
                                                            <span className="red-dot-2" />
                                                        }
                                                    </Flex>
                                                </Stack>
                                            </Button>
                                        </>
                                    )
                                })
                            }
                        </HStack>
                    </Box>
                </>
            )
        }

    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function beginTransfer(transferAction) {
        try {
            if (stringIsNullOrEmpty(selectedPlayer)) {
                throw 'PLEASE_SELECT_A_PLAYER'
            }

            var responseJson = await ApiEngine.get(
                Routes._GET_PLAYER_DETAILS +
                '?id=' +
                selectedPlayer.player_id
            );

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            // Convert e-credit / e-point
            if (transferAction == CONVERT_OPTIONS[0]['title']) {
                _router.navigate(Pages._CONVERT_E_CREDIT_OR_E_POINT, {
                    selectedVendor: selectedVendor,
                    selectedPlayer: responseJson[ApiKey._API_DATA_KEY]
                });
            }
            // Transfer score
            else if (transferAction == CONVERT_OPTIONS[1]['title']) {
                _router.navigate(Pages._TRANSFER_SCORE, {
                    vendorList: bottomPopUpWithApi?.vendorList,
                    selectedVendor: selectedVendor,
                    selectedPlayer: responseJson[ApiKey._API_DATA_KEY],
                    memberGuid: _USER_DATA.guid
                });
            }
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function callBonusCheckApi() {
        try {
            var responseJson = await ApiEngine.post(Routes._CHECK_BONUS_STATUS);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(showCustomDialog({
                success: true,
                title: t('CONGRAT_TRANSACTION_ACTIONS_UNLOCK'),
                content: responseJson[ApiKey._API_MESSAGE_KEY],
                onConfirm: async () => {
                    _dispatch(getMemberDetails());
                },
            }));
        }
        catch (err) {
            localStorage.setItem(
                ConfigEnum._LAST_CHECK_BONUS_DATETIME,
                moment().format('YYYY-MM-DD HH:mm:ss')
            );
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function memberCheckBonusUnlock() {
        try {
            let lastCheckDateTime = localStorage.getItem(ConfigEnum._LAST_CHECK_BONUS_DATETIME);

            // check if user current credit exceed threshold or more than 10 minutes since last check
            if ((_USER_DATA?.credit <= _USER_DATA.memberBonusInfo.bonusMinThreshold || _USER_DATA?.credit >= _USER_DATA.memberBonusInfo.bonusMaxThreshold) ||
                (stringIsNullOrEmpty(lastCheckDateTime) || moment().diff(moment(lastCheckDateTime), 'minutes') >= 10)) {
                _dispatch(showCustomDialog({
                    success: true,
                    title: t('ARE_YOU_SURE'),
                    content: t('ARE_YOU_SURE_YOU_WANT_TO_CHECK_UNLOCK_STATUS_COOLDOWN_1HR'),
                    onConfirm: () => {
                        callBonusCheckApi();
                    },
                    onCancel: () => { },
                    horizontalBtn: false
                }));
            }
            else {
                throw t('CHECK_UNLOCK_STATUS_COOLDOWN_PLEASE_CHECK_AFTER', { time: (10 - moment().diff(moment(lastCheckDateTime), 'minutes')) });
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const TimerRenderer = ({ minutes, seconds }) => {
        return <Flex w='full' align={'center'} justifyContent={'center'} background={'#400164'} padding={'10px'}>
            <Text fontSize={'lg'} color={'app.blue'}>{zeroPad(minutes)}:{zeroPad(seconds)}</Text>
        </Flex>;
    };

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function generateOTP() {
        try {
            var responseJson = await ApiEngine.post(Routes._GENERATE_OTP);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            let expiredDateTime = responseJson[ApiKey._API_DATA_KEY]['createdDate'];
            setOtp(responseJson[ApiKey._API_DATA_KEY]['pin']);
            setOtpExpiredIn(moment(expiredDateTime).add(responseJson[ApiKey._API_DATA_KEY]['expireInMinutes'], 'minutes'));
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    if (!checkBrowser()) {
        return (
            <div className='body-content' style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    _SECTION_COMPONENTS.map((item, index) => {
                        return (
                            <SectionComponent
                                title={item.title}
                                options={item.options}
                                tutorial={item.helpAction}
                                checkIsLock={item.checkIsLock}
                                navigationKey={item.navigationKey} />
                        )
                    })
                }
                <Box className="transaction-box" w={['100%', '70%']} mb={8}>
                    <HStack spacing={0} mb={6}>
                        <Text color={'white'} fontSize={'md'} as='b' className="glowing-text">{t("PIN_OUT")}</Text>
                    </HStack>
                    <Text fontStyle='italic' mt={1.5}>{t('USE_THIS_PIN_TO_RESET_OLD_PASSWORD_OR_WITHDRAW')}</Text>
                    <Divider className="divider-horizontal" marginBlock={'1vw'} />
                    <Flex w='full' align={'center'} justifyContent={'center'}>
                        <Text fontSize={'xxx-large'} as='b' letterSpacing={6} onClick={() => {
                            if (!stringIsNullOrEmpty(otp)) {
                                copyToClipboard(otp, t('COPIED_SUCCESFULLY'));
                            }
                        }}>{!stringIsNullOrEmpty(otp) ? otp : '****'}</Text>
                    </Flex>
                    {
                        !stringIsNullOrEmpty(otp) &&
                        <Text fontStyle='italic' textAlign='center'>{t('TAP_THE_PIN_TO_COPY')}</Text>
                    }
                    <Divider className="divider-horizontal" marginBlock={'2vw'} />
                    {
                        stringIsNullOrEmpty(otpExpiredIn) ?
                            <Button
                                className="grey-gradient-button"
                                style={{ borderRadius: '10px' }}
                                onClick={() => { generateOTP(); }}>
                                {t('VIEW')}
                            </Button> :
                            <Countdown
                                date={otpExpiredIn}
                                renderer={TimerRenderer}
                                onComplete={() => {
                                    setOtpExpiredIn();
                                    setOtp();
                                }}
                            />
                    }
                </Box>
                {
                    !isObjectEmpty(_USER_DATA.memberBonusInfo) && _USER_DATA.memberBonusInfo.isLocked &&
                    <Box className="transaction-box" w={['100%', '70%']} mb={8}>
                        <Flex align={'center'}>
                            <Image
                                className="blink-effect"
                                boxSize={'25px'}
                                mr={2}
                                src={require('../../../assets/images/lock.png')}
                            />
                            <Text color={'#FCF55F'} fontSize={'sm'} as='b'>{t('TRANSACTION_FUNCTION_IS_LOCKED')}</Text>
                            <Button variant={'link'} onClick={() => _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/transaction/lock?lang=${languageCode}` })}>
                                <Image
                                    boxSize={'40px'}
                                    src={require('../../../assets/images/svg/help-color.svg').default}
                                />
                            </Button>
                            <Spacer />
                        </Flex>
                        <Text fontStyle='italic' mt={1.5}>{t('PLEASE_HIT_EITHER_ONE_CONDITION_BELOW_TO_UNLOCK')}</Text>
                        <Divider className="divider-horizontal" marginBlock={'4vw'} />
                        <Flex w='full' align={'center'}>
                            <Spacer />
                            <Stack align={'center'} spacing={0}>
                                <Text fontSize='md' as='b'>{numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMinThreshold)}</Text>
                                <Text>{t("WHEN_ECREDIT")}</Text>
                            </Stack>
                            <Spacer />
                            <Divider className="divider-vertical" orientation="vertical" />
                            <Spacer />
                            <Stack align={'center'} spacing={0}>
                                <Text fontSize='md' as='b'>{numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMaxThreshold)}</Text>
                                <Text>{t("WHEN_ECREDIT")}</Text>
                            </Stack>
                            <Spacer />
                        </Flex>
                        <Divider className="divider-horizontal" marginBlock={'3vw'} />
                        <Button
                            className="grey-gradient-button"
                            style={{ borderRadius: '10px' }}
                            onClick={() => { memberCheckBonusUnlock(); }}>
                            {t('UNLOCK')}
                        </Button>
                    </Box>
                }
                <BottomSheet
                    title={bottomPopUp?.popUpTitle}
                    color="#ffd056 !important"
                    isOpen={!isObjectEmpty(bottomPopUp)}
                    onClose={() => setBottomPopUp()}
                    content={
                        <SimpleGrid w='full' gridTemplateColumns={'repeat(2, minmax(0,1fr))'}>
                            {
                                bottomPopUp?.content?.map((item, index) => {
                                    return (
                                        <Button mb={5} variant={'link'} w='full' onClick={() => {
                                            if (_USER_DATA.isVerficationRequired && item.verificationCheck) {
                                                _dispatch(
                                                    showCustomDialog({
                                                        success: true,
                                                        title: t('ACCOUNT_VERIFICATION_REQUIRED'),
                                                        content: t('PLEASE_GO_TO_SETTING_SECURITY', { action: t(item.title) }),
                                                        confirmTxt: t('BRING_ME_THERE'),
                                                        onConfirm: () => {
                                                            _router.navigate(Pages._ACCOUNT_VERIFICATION)
                                                        },
                                                        cancelTxt: t('CANCEL'),
                                                        onCancel: () => { },
                                                        horizontalBtn: false
                                                    })
                                                );
                                            } else {
                                                item.navigateTo();
                                            }
                                        }}>
                                            <Stack align={'center'} position={'relative'}>
                                                {
                                                    item.isNew &&
                                                    <span className="newBadge">{t('NEW')}</span>
                                                }
                                                <Image boxSize={'80px'} style={{ background: 'linear-gradient(#4b0076,#761cb1)', borderRadius: '10px' }} src={item.image} />
                                                <Text whiteSpace={'break-spaces'} textAlign={'center'}>{t(item.title)}</Text>
                                            </Stack>
                                        </Button>
                                    )
                                })
                            }
                        </SimpleGrid>
                    }
                />
                <BottomSheet
                    title={bottomPopUpWithApi?.popUpTitle}
                    isOpen={!isObjectEmpty(bottomPopUpWithApi)}
                    onClose={() => setBottomPopUpWithApi()}
                    content={
                        <>
                            <Stack paddingInline={'1rem'}>
                                <Button variant={'link'} w='full' onClick={() => setVendorPickerVisible(!vendorPickerVisible)}>
                                    <CustomInput
                                        isReadOnly
                                        value={selectedVendor?.label}
                                        label='SELECT_VENDOR'
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        border='2px solid #e2a570 !important'
                                        borderRadius='10px !important'
                                        placeholder={t('SELECT_VENDOR')}
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                </Button>
                                <Button variant={'link'} w='full' onClick={() => setPlayerPickerVisible(!playerPickerVisible)}>
                                    <CustomInput
                                        isReadOnly
                                        value={selectedPlayer?.label ?? ''}
                                        label='SELECT_PLAYER_ID'
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        border='2px solid #e2a570 !important'
                                        borderRadius='10px !important'
                                        placeholder={t('SELECT_PLAYER_ID')}
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                </Button>
                            </Stack>
                            <Button bg={'linear-gradient(#490073,#2b0042)'} w={'100%'} color={'white'} style={{ borderRadius: 0, height: '3rem' }} onClick={() => { beginTransfer(bottomPopUpWithApi?.popUpTitle); }}>
                                {t('NEXT')}
                            </Button>
                            <CustomPicker
                                isVisible={vendorPickerVisible}
                                title={'SELECT_VENDOR'}
                                options={bottomPopUpWithApi?.vendorList}
                                selectedOption={selectedVendor}
                                onSelect={(isSelected = true, value) => {
                                    if (isSelected) {
                                        setSelectedVendor(value);
                                    }
                                    setVendorPickerVisible(!vendorPickerVisible);
                                }}
                            />
                            <CustomPicker
                                isVisible={playerPickerVisible}
                                title={'SELECT_PLAYER_ID'}
                                options={bottomPopUpWithApi?.playerList}
                                selectedOption={selectedPlayer}
                                onSelect={(isSelected = true, value) => {
                                    if (isSelected) {
                                        setSelectedPlayer(value);
                                    }
                                    setPlayerPickerVisible(!playerPickerVisible);
                                }}
                            />
                        </>
                    }
                />

            </div>
        )
    } else {
        return (
            <div className='body-content' style={{ marginTop: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    _SECTION_COMPONENTS.map((item, index) => {
                        return (
                            <SectionComponent
                                title={item.title}
                                options={item.options}
                                tutorial={item.helpAction}
                                checkIsLock={item.checkIsLock}
                                navigationKey={item.navigationKey} />
                        )
                    })
                }
                <Box border={'1px solid white'} padding={6} borderRadius={20} mb={12} width={'40rem'}>
                    <HStack spacing={0} mb={6} justifyContent={'center'}>
                        <Text color={'white'} fontSize={'x-large'} as='b' className="glowing-text">{t("PIN_OUT")}</Text>
                    </HStack>
                    <Text fontStyle='italic' mt={1.5}>{t('USE_THIS_PIN_TO_RESET_OLD_PASSWORD_OR_WITHDRAW')}</Text>
                    <Flex w='full' align={'center'} marginBlock={3} justifyContent={'center'}>
                        <Text fontSize={'3.5rem'} as='b' letterSpacing={6} onClick={() => {
                            if (!stringIsNullOrEmpty(otp)) {
                                copyToClipboard(otp, t('COPIED_SUCCESFULLY'));
                            }
                        }}>
                            {!stringIsNullOrEmpty(otp) ? otp : '****'}
                        </Text>
                    </Flex>
                    {
                        !stringIsNullOrEmpty(otp) &&
                        <Text fontStyle='italic' textAlign='center' mb={2}>{t('TAP_THE_PIN_TO_COPY')}</Text>
                    }
                    {
                        stringIsNullOrEmpty(otpExpiredIn) ?
                            <Button
                                w={'100%'} color={'white'} height={45} padding={'20px 140px'} borderRadius={15} backgroundImage={'linear-gradient(#e5b823, #8d7216)'}
                                onClick={() => { generateOTP(); }}>
                                {t('VIEW')}
                            </Button> :
                            <Countdown
                                date={otpExpiredIn}
                                renderer={TimerRenderer}
                                onComplete={() => {
                                    setOtpExpiredIn();
                                    setOtp();
                                }}
                            />
                    }
                </Box>
                {
                    !isObjectEmpty(_USER_DATA.memberBonusInfo) && _USER_DATA.memberBonusInfo.isLocked &&
                    <Box border={'1px solid white'} padding={6} borderRadius={20} mb={12} width={'40rem'}>
                        <Flex align={'center'}>
                            <Image
                                className="blink-effect"
                                boxSize={'25px'}
                                mr={2}
                                src={require('../../../assets/images/lock.png')}
                            />
                            <Text color={'#FCF55F'} fontSize={'sm'} as='b'>{t('TRANSACTION_FUNCTION_IS_LOCKED')}</Text>
                            <Button variant={'link'} onClick={() => _router.navigate(Pages._WEB_VIEW, { dataUrl: `${Routes._GET_TUTORIAL}/transaction/lock?lang=${languageCode}` })}>
                                <Image
                                    boxSize={'45px'}
                                    src={require('../../../assets/images/svg/help-color.svg').default}
                                />
                            </Button>
                            <Spacer />
                        </Flex>
                        <Text fontStyle='italic' mt={1.5}>{t('PLEASE_HIT_EITHER_ONE_CONDITION_BELOW_TO_UNLOCK')}</Text>
                        <Flex w='full' align={'center'} marginBlock={8}>
                            <Spacer />
                            <Stack align={'center'} spacing={0}>
                                <Text fontSize='lg' as='b'>{numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMinThreshold)}</Text>
                                <Text color='#f8c763'>{t("WHEN_ECREDIT")}</Text>
                            </Stack>
                            <Spacer />
                            <Divider className="app-divider-darker-bar-height" orientation="vertical" />
                            <Spacer />
                            <Stack align={'center'} spacing={0}>
                                <Text fontSize='lg' as='b'>{numberWithCurrencyFormat(_USER_DATA?.memberBonusInfo?.bonusMaxThreshold)}</Text>
                                <Text color='#f8c763'>{t("WHEN_ECREDIT")}</Text>
                            </Stack>
                            <Spacer />
                        </Flex>
                        <Button
                            w={'100%'} color={'white'} height={45} padding={'20px 140px'} borderRadius={15} backgroundImage={'linear-gradient(#e5b823, #8d7216)'}
                            onClick={() => { memberCheckBonusUnlock(); }}>
                            {t('UNLOCK')}
                        </Button>
                    </Box>
                }
                <BottomSheet
                    title={bottomPopUp?.popUpTitle}
                    isOpen={!isObjectEmpty(bottomPopUp)}
                    onClose={() => setBottomPopUp()}
                    content={
                        <Flex w='full' mb={8}>
                            {
                                bottomPopUp?.content?.map((item, index) => {
                                    return (
                                        <Button variant={'link'} w='full' onClick={() => {
                                            if (_USER_DATA.isVerficationRequired && item.verificationCheck) {
                                                _dispatch(
                                                    showCustomDialog({
                                                        success: true,
                                                        title: t('ACCOUNT_VERIFICATION_REQUIRED'),
                                                        content: t('PLEASE_GO_TO_SETTING_SECURITY', { action: t(item.title) }),
                                                        confirmTxt: t('BRING_ME_THERE'),
                                                        onConfirm: () => {
                                                            _router.navigate(Pages._ACCOUNT_VERIFICATION)
                                                        },
                                                        cancelTxt: t('CANCEL'),
                                                        onCancel: () => { },
                                                        horizontalBtn: false
                                                    })
                                                );
                                            } else {
                                                item.navigateTo();
                                            }
                                        }}>
                                            <Stack align={'center'} position={'relative'}>
                                                {
                                                    item.isNew &&
                                                    <span className="newBadge">{t('NEW')}</span>
                                                }
                                                <Image boxSize={'65px'} style={{ background: 'linear-gradient(#4b0076,#761cb1)', borderRadius: '10px' }} src={item.image} />
                                                <Text whiteSpace={'break-spaces'} textAlign={'center'}>{t(item.title)}</Text>
                                            </Stack>
                                        </Button>
                                    )
                                })
                            }
                        </Flex>
                    }
                />
                <BottomSheet
                    title={bottomPopUpWithApi?.popUpTitle}
                    isOpen={!isObjectEmpty(bottomPopUpWithApi)}
                    onClose={() => setBottomPopUpWithApi()}
                    content={
                        <>
                            <Stack paddingInline={'1rem'}>
                                <Button variant={'link'} w='full' onClick={() => setVendorPickerVisible(!vendorPickerVisible)}>
                                    <CustomInput
                                        isReadOnly
                                        value={selectedVendor?.label}
                                        color='white'
                                        borderRadius="10px !important"
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        label='SELECT_VENDOR'
                                        placeholder={t('SELECT_VENDOR')}
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                </Button>
                                <Button variant={'link'} w='full' onClick={() => setPlayerPickerVisible(!playerPickerVisible)}>
                                    <CustomInput
                                        isReadOnly
                                        value={selectedPlayer?.label ?? ''}
                                        color='white'
                                        borderRadius="10px !important"
                                        background='linear-gradient(#5a0089,#7c15bd) !important'
                                        label='SELECT_PLAYER_ID'
                                        placeholder={t('SELECT_PLAYER_ID')}
                                        icon={
                                            <img style={{ width: '0.75rem', height: '0.75rem' }} src={require('../../../assets/images/svg/general-updown-white.svg').default} />
                                        }
                                    />
                                </Button>
                            </Stack>
                            <Button mb={4} borderRadius={10} backgroundImage={'linear-gradient(#e5b823, #8d7216)'} color={'white'} marginInline={4} w={'-webkit-fill-available'} onClick={() => { beginTransfer(bottomPopUpWithApi?.popUpTitle); }}>
                                {t('NEXT')}
                            </Button>
                            <CustomPicker
                                isVisible={vendorPickerVisible}
                                title={'SELECT_VENDOR'}
                                options={bottomPopUpWithApi?.vendorList}
                                selectedOption={selectedVendor}
                                onSelect={(isSelected = true, value) => {
                                    if (isSelected) {
                                        setSelectedVendor(value);
                                    }
                                    setVendorPickerVisible(!vendorPickerVisible);
                                }}
                            />
                            <CustomPicker
                                isVisible={playerPickerVisible}
                                title={'SELECT_PLAYER_ID'}
                                options={bottomPopUpWithApi?.playerList}
                                selectedOption={selectedPlayer}
                                onSelect={(isSelected = true, value) => {
                                    if (isSelected) {
                                        setSelectedPlayer(value);
                                    }
                                    setPlayerPickerVisible(!playerPickerVisible);
                                }}
                            />
                        </>
                    }
                />
            </div>
        )
    }
}