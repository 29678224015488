import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Text,
    Image,
    Button,
    AbsoluteCenter,
    Center,
    SimpleGrid,
    Box,
    Flex,
    Card,
    CardBody,
    HStack,
    Spacer,
    Divider,
    Spinner
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import Routes from "../../../common/api_routes";
import { Pages } from "../../../routes/page";
import { Language, ApiKey, _PAGE_SIZE } from "../../../common/constant";
import { ConfigEnum } from "../../../common/config";
import ApiEngine from "../../../common/api_engine";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser } from "../../../common/util";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const languageCode = localStorage.getItem(ConfigEnum._LANGUAGE) || Language._ENGLISH;
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [newMembersCount, setNewMembersCount] = useState(0);
    const [directDownlinesCount, setDirectDownlinesCount] = useState(0);
    const [isRefreshingMemberInfo, setIsRefreshingMemberInfo] = useState(false);
    const [downlineList, setDownlineList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const _INITIAL_PAGE_NO = 1;

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        getMyDownlinesInfo();
        getMyDownlines();
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getMyDownlinesInfo = async () => {
        setIsRefreshingMemberInfo(true);
        var responseJson = await ApiEngine.get(Routes._MY_DOWNLINES_INFO, { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];

            setNewMembersCount(data.todayNewMemberCount);
            setDirectDownlinesCount(data.directDownlineCount);
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
        setIsRefreshingMemberInfo(false);
        setIsFirstLoad(false);
    }

    /// <summary>
    /// Author: Javacrypt
    /// </summary>
    const getMyDownlines = async () => {
        try {
            let apiUrl = Routes._MY_DOWNLINES + "?page=" + pageNo + "&pageSize=" + _PAGE_SIZE;

            let responseJson = await ApiEngine.get(apiUrl,
                { headers: { "skipLoading": true } });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {

                let data = responseJson[ApiKey._API_DATA_KEY];
                setTotalCount(data["totalCount"]);

                let tempArr = data["data"]

                if (responseJson[ApiKey._API_DATA_KEY]['data'].length > 0 && pageNo > _INITIAL_PAGE_NO) {
                    setDownlineList([...downlineList, ...tempArr]);
                }
                else {
                    setDownlineList(tempArr);
                }
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderItem = ({ item, index }) => {
        if (!checkBrowser()) {
            return (
                <>
                    <HStack key={index} paddingInline={5} paddingBlock={4} spacing={4}>
                        <Image boxSize={8} src={require('../../../assets/images/topbar-user-defaultphoto.png')} />
                        <Stack spacing={0}>
                            <Text>{item.alias}</Text>
                            <Text>{item.username}</Text>
                        </Stack>
                        <Spacer />
                        <Text>{moment(item.createdTime).format('DD MMM yyyy')}</Text>
                    </HStack>
                    <Divider className="divider-horizontal" />
                </>
            )
        } else {
            return (
                <>
                    <HStack key={index} paddingInline={5} paddingBlock={4} spacing={4}>
                        <Image boxSize={10} src={require('../../../assets/images/topbar-user-defaultphoto.png')} />
                        <Stack spacing={0}>
                            <Text>{item.alias}</Text>
                            <Text>{item.username}</Text>
                        </Stack>
                        <Spacer />
                        <Text>{moment(item.createdTime).format('DD MMM yyyy')}</Text>
                    </HStack>
                    <Divider className="divider-horizontal" />
                </>
            )
        }
    }

    if (!checkBrowser()) {
        return (
            <Stack>
                <GeneralTopBar title={`${t('DOWNLINES_MEMBER')} / ${t('REPORT')}`} bgColor="linear-gradient(#a94ff1,#9e32dd)" />
                <Center mt={5}>
                    <HStack borderRadius={15} padding={3} border={'2px solid #f8c763'} bg={'linear-gradient(#64009d,#7700bd,#64009d,#7700bd,#64009d)'}>
                        <Button
                            isLoading={isRefreshingMemberInfo}>
                            <Stack spacing={1}>
                                <Text fontSize={'xl'} fontWeight={'bold'}>{newMembersCount > 0 ? '+' : ''} {newMembersCount}</Text>
                                <Text color={'#f8c763'}>{t("NEW_MEMBER_TODAY")}</Text>
                            </Stack>
                        </Button>
                        <Divider className="divider-vertical" w={'fit-content'} />
                        <Button
                            isLoading={isRefreshingMemberInfo}>
                            <Stack spacing={1}>
                                <Text fontSize={'xl'} fontWeight={'bold'}>{directDownlinesCount}</Text>
                                <Text color={'#f8c763'}>{t("TOTAL_DIRECT_DOWNLINES")}</Text>
                            </Stack>
                        </Button>
                    </HStack>
                </Center>
                {
                    !isFirstLoad ? directDownlinesCount > 0 ?
                        <>
                            <Stack>
                                {
                                    downlineList.length > 0 ?
                                        <>
                                            <div
                                                id="scrollableDiv"
                                                style={{
                                                    overflow: "auto"
                                                }}>
                                                <InfiniteScroll
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                    dataLength={downlineList.length}
                                                    next={() => {
                                                        let page = (pageNo + 1) * _PAGE_SIZE;
                                                        setPageNo(page);
                                                        getMyDownlines();
                                                    }}
                                                    hasMore={downlineList.length < totalCount}
                                                    loader={<Spinner color="white" />}
                                                >
                                                    {
                                                        downlineList.map((item, index) => {
                                                            return (
                                                                <RenderItem
                                                                    item={item}
                                                                    index={index}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </InfiniteScroll>
                                            </div>
                                        </> :
                                        <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                                            <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("EMPTY_SPIN_HISTORY")}</Text>
                                            <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("NO_SPIN_HISTORY_AVAILABLE_NOW")}</Text>
                                        </AbsoluteCenter>
                                }
                            </Stack>
                        </>
                        :
                        <AbsoluteCenter textAlign={'center'} w={'full'}>
                            <Center>
                                <Image boxSize={'150px'} src={require("../../../assets/images/empty-downline.png")} />
                            </Center>
                            <Text fontSize={'md'} fontWeight={'bold'} color={'#f8c763'}>{t("NO_DOWNLINE_MEMBER")}</Text>
                            <Text fontSize={'sm'} fontStyle={'italic'}>{t("CURRENTLY_YOU_DONT_HAVE_ANY_DIRECT_DOWNLINE_YET")}</Text>
                        </AbsoluteCenter>
                        :
                        <></>
                }
            </Stack>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "40rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={7}>{`${t('DOWNLINES_MEMBER')} / ${t('REPORT')}`}</Text>
                        </div>
                        <Center mb={5}>
                            <HStack borderRadius={15} padding={3} border={'2px solid #f8c763'} bg={'linear-gradient(#64009d,#7700bd,#64009d,#7700bd,#64009d)'}>
                                <Button
                                    isLoading={isRefreshingMemberInfo}>
                                    <Stack spacing={1}>
                                        <Text fontSize={'xl'} fontWeight={'bold'}>{newMembersCount > 0 ? '+' : ''} {newMembersCount}</Text>
                                        <Text color={'#f8c763'}>{t("NEW_MEMBER_TODAY")}</Text>
                                    </Stack>
                                </Button>
                                <Divider className="divider-vertical" w={'fit-content'} />
                                <Button
                                    isLoading={isRefreshingMemberInfo}>
                                    <Stack spacing={1}>
                                        <Text fontSize={'xl'} fontWeight={'bold'}>{directDownlinesCount}</Text>
                                        <Text color={'#f8c763'}>{t("TOTAL_DIRECT_DOWNLINES")}</Text>
                                    </Stack>
                                </Button>
                            </HStack>
                        </Center>
                        {
                            !isFirstLoad ? directDownlinesCount > 0 ?
                                <>
                                    <Stack>
                                        {
                                            downlineList.length > 0 ?
                                                <>
                                                    <div
                                                        id="scrollableDiv"
                                                        style={{
                                                            overflow: "auto"
                                                        }}>
                                                        <InfiniteScroll
                                                            style={{ display: "flex", flexDirection: "column" }}
                                                            dataLength={downlineList.length}
                                                            next={() => {
                                                                let page = (pageNo + 1) * _PAGE_SIZE;
                                                                setPageNo(page);
                                                                getMyDownlines();
                                                            }}
                                                            hasMore={downlineList.length < totalCount}
                                                            loader={<Spinner color="white" />}
                                                        >
                                                            {
                                                                downlineList.map((item, index) => {
                                                                    return (
                                                                        <RenderItem
                                                                            item={item}
                                                                            index={index}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </InfiniteScroll>
                                                    </div>
                                                </> :
                                                <AbsoluteCenter textAlign={'-webkit-center'} w={'-webkit-max-content'}>
                                                    <Image boxSize={'150px'} src={require('../../../assets/images/transaction/empty-notransaction.png')} />
                                                    <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'#FFD055'}>{t("EMPTY_SPIN_HISTORY")}</Text>
                                                    <Text w={'full'} textAlign={'center'} fontWeight={'bold'} fontSize={'sm'}>{t("NO_SPIN_HISTORY_AVAILABLE_NOW")}</Text>
                                                </AbsoluteCenter>
                                        }
                                    </Stack>
                                </>
                                :
                                <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                                    <Center>
                                        <Image boxSize={'150px'} src={require("../../../assets/images/empty-downline.png")} />
                                    </Center>
                                    <Text color={'#f8c763'} fontSize={'md'} fontWeight={'bold'}>{t("NO_DOWNLINE_MEMBER")}</Text>
                                    <Text fontSize={'sm'} fontStyle={'italic'}>{t("CURRENTLY_YOU_DONT_HAVE_ANY_DIRECT_DOWNLINE_YET")}</Text>
                                </div>
                                :
                                <></>
                        }
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}
