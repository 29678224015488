import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../../hooks/useRouter";
import { Text, Stack, Button, Box, Flex, AbsoluteCenter, Image, Spinner, Spacer, Tabs, TabList, Tab, Divider } from "@chakra-ui/react";
import GeneralTopBar from "../../../../component/general-top-bar";
import { PageSettings } from "../../../../routes/setting";
import ApiEngine from "../../../../common/api_engine";
import Routes from "../../../../common/api_routes";
import { Pages } from "../../../../routes/page";
import { showCustomDialog } from "../../../../application/action/app_action";
import { isObjectEmpty, numberWithCurrencyFormat, share, stringIsNullOrEmpty, isTicketCancelled } from "../../../../common/util";
import {
    ApiKey,
    BG_TYPE,
    _PAGE_SIZE,
    MAGNUM_POOL_NAME,
    DAMACAI_POOL_NAME,
    TOTO_POOL_NAME,
    SINGAPORE_POOL_NAME,
    SABAH_POOL_NAME,
    SANDAKAN_POOL_NAME,
    CASH_SWEEP_POOL_NAME,
    GRAND_DRAGON_POOL_NAME,
    NINE_LOTTO_POOL_NAME
} from "../../../../common/constant";
import CustomPicker from "../../../../component/custom-picker";
import moment from "moment";
import DatePicker from 'react-date-picker';
import InfiniteScroll from 'react-infinite-scroll-component';

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _location = useLocation();
    const _PLAYER_ID = _location?.state?.playerId;
    const _STATUS = _location?.state?.status;
    const [selectedFromDate, setSelectedFromDate] = useState(moment().startOf('months'));
    const [selectedToDate, setSelectedToDate] = useState(moment());
    const [strikeHistory, setStrikeHistory] = useState([]);
    const _INITIAL_PAGE_NO = 1;
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [totalCount, setTotalCount] = useState(0);

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (stringIsNullOrEmpty(_PLAYER_ID)) {
            _router.back();
        }
        else {
            getStrikeHistory();
        }

        _context.toggleBg(BG_TYPE.swc);

        return () => {
            _context.toggleBg(BG_TYPE.blur);
        };
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getStrikeHistory(
        startedDate = selectedFromDate,
        endedDate = selectedToDate,
        pagedNo = _INITIAL_PAGE_NO
    ) {
        try {
            var params = JSON.stringify({
                "PlayerId": _PLAYER_ID,
                "DrawDateFrom": moment(startedDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                "DrawDateTo": moment(endedDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                "PageSize": pagedNo,
                "Page": _PAGE_SIZE
            });

            var response = await ApiEngine.post(Routes._SWC_CHECK_STRIKE, params, {
                headers: {
                    "content-type": ApiKey._API_APPLICATION_JSON
                }
            });

            if (!response[ApiKey._API_SUCCESS_KEY]) {
                throw response.Message ?? response.message ?? response.error ?? response.title;
            }

            setTotalCount(response[ApiKey._API_DATA_KEY]['last_page']);

            if (
                response[ApiKey._API_DATA_KEY] &&
                response[ApiKey._API_DATA_KEY].length > 0 &&
                pagedNo > _INITIAL_PAGE_NO
            ) {
                setStrikeHistory([
                    ...strikeHistory,
                    ...response[ApiKey._API_DATA_KEY]['records']
                ]);
            } else {
                setStrikeHistory(response[ApiKey._API_DATA_KEY]['records']);
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const getPoolImgByTxt = (txt) => {
        var poolImg;
        var str = txt.toUpperCase();

        if (str.indexOf('MAGNUM') > -1 || str.indexOf(MAGNUM_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-magnum.png');
        }
        else if (str.indexOf('DAMACAI') > -1 || str.indexOf(DAMACAI_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-damacai.png');
        }
        else if (str.indexOf('SINGAPORE') > -1 || str.indexOf(SINGAPORE_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-singapore.png');
        }
        else if (str.indexOf('SABAH') > -1 || str.indexOf(SABAH_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-sabah88.png');
        }
        else if (str.indexOf('SANDAKAN') > -1 || str.indexOf('STC') > -1 || str.indexOf(SANDAKAN_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-stc.png');
        }
        else if (str.indexOf('CASH') > -1 || str.indexOf(CASH_SWEEP_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-big-cash.png');
        }
        else if (str.indexOf('TOTO') > -1 || str.indexOf(TOTO_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-toto.png');
        }
        else if (str.indexOf('GD') > -1 || str.indexOf(GRAND_DRAGON_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-gd.png');
        }
        else if (str.indexOf('NINE') > -1 || str.indexOf(NINE_LOTTO_POOL_NAME.toUpperCase()) > -1) {
            poolImg = require('./assets/images/bet-logo-ninelotto.png');
        }

        return poolImg;
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderItem = ({ item, index }) => {
        return (
            <Button
                key={index}
                variant={'link'}
                mt={3}
                borderRadius={15}
                onClick={() => { }}>
                <Flex p={1.5} bg={'white'} borderRadius={15} align={'center'}>
                    <Stack spacing={0.5}>
                        <img style={{ width: '40px', height: '40px' }} src={getPoolImgByTxt(item.company)} />
                        <Text fontSize={'md'} fontWeight={'bold'}>{item.bet_number}</Text>
                    </Stack>
                    <Spacer />
                    <Stack spacing={0.5}>
                        <Text fontSize={'md'} fontWeight={'bold'}>{t('ORDER_ID')}: {item.conf_id}</Text>
                        <Text fontSize={'sm'}>{t('DRAW_DATE')}: {moment(item.draw_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM (ddd)').toUpperCase()}</Text>
                    </Stack>
                    <Spacer />
                    <Stack spacing={0.5}>
                        <Text fontSize={'md'} fontWeight={'bold'} color={'#22B956'}>+RM</Text>
                        <Text fontSize={'md'} fontWeight={'bold'} color={'#22B956'}>{numberWithCurrencyFormat(parseFloat(item.groupsum))}</Text>
                    </Stack>
                </Flex>
            </Button>
        )
    }

    return (
        <div className='body-content'>
            <GeneralTopBar title={'STRIKE_HISTORY'} bgColor="#ebffe3" blackTitle titleAlignLeft useSimpleBackIcon />
            <Box marginInline={'-3.75vw'} bg={'white'} p={1} textAlign={'center'}>
                <Text color={'black'} fontWeight={'bold'}>{t('DRAW_DATE')}</Text>
                <Flex align={'flex-end'}>
                    <Text color={'#989898'} fontWeight={'500'} mr={2}>{t('FROM')}</Text>
                    <DatePicker
                        className={'result-date-picker'}
                        onChange={setSelectedFromDate}
                        value={selectedFromDate}
                        clearIcon={null}
                    />
                    <Spacer />
                    <Text color={'#989898'} fontWeight={'500'} mr={2}>{t('TO')}</Text>
                    <DatePicker
                        className={'result-date-picker'}
                        onChange={setSelectedToDate}
                        value={selectedToDate}
                        clearIcon={null}
                    />
                    <Spacer />
                    <Button
                        size={'sm'}
                        variant='solid'
                        bg='#08CD34'
                        borderRadius={10}
                        color={'white'}
                        onClick={() => { }}>
                        {t('APPLY')}
                    </Button>
                </Flex>
            </Box>
            {
                strikeHistory.length == 0 ?
                    <AbsoluteCenter>
                        <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'} color={'black'}>{t("NO_RECORD_FOUND")}</Text>
                    </AbsoluteCenter> :
                    <div
                        id="scrollableDiv"
                        style={{
                            height: '80vh',
                            overflow: "auto"
                        }}>
                        <InfiniteScroll
                            style={{ display: "flex", flexDirection: "column" }}
                            dataLength={strikeHistory.length}
                            next={() => {
                                let page = pageNo + 1;
                                setPageNo(page);
                                getStrikeHistory(selectedFromDate, selectedToDate, page);
                            }}
                            hasMore={pageNo < totalCount}
                            loader={<Spinner color="white" />}
                        >
                            {
                                strikeHistory.map((item, index) => {
                                    return (
                                        <RenderItem
                                            item={item}
                                            index={index}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </div>
            }
        </div>
    )
}