import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Stack,
    Flex,
    Text,
    Button,
    Image,
    Spacer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    Tabs,
    TabList,
    Tab,
    Divider,
    SimpleGrid,
    Center,
    Box,
    Spinner,
    AbsoluteCenter,
    HStack
} from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import ApiEngine from "../../../common/api_engine";
import axios from 'axios';
import { ApiKey, _MAXIMUM_PLAYERS, Status, ScreenWidth } from "../../../common/constant";
import Routes from "../../../common/api_routes";
import { stringIsNullOrEmpty, isObjectEmpty, createFormBody, numberWithCurrencyFormat, copyToClipboard, share, checkIsStateRangeValid, checkBrowser } from "../../../common/util";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../hooks/useRouter";
import { showCustomDialog } from "../../../application/action/app_action";
import BottomSheet from "../../../component/bottom-sheet";
import { Pages } from "../../../routes/page";
import { isBrowser, isMobile } from "react-device-detect";
import Header from "../../non-auth-pages/header";
import { useLocation, useNavigate } from "react-router";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    var _userData = useSelector((state) => state.authState.userData);
    const { t } = useTranslation();
    const _ALL_CATEGORY = { id: -1, title: t('ALL') };
    const [vendorCategories, setVendorCategories] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [selectedVendorCategory, setSelectedVendorCategory] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isVendorListLoading, setIsVendorListLoading] = useState(true);
    const [isPlayerListLoading, setIsPlayerListLoading] = useState(false);
    const [players, setPlayers] = useState([]);
    const [playersQueue, setPlayersQueue] = useState([]);
    const [selectedPlayerUsername, setSelectedPlayerUsername] = useState(null);
    const [goToPlayerDetail, setGoToPlayerDetail] = useState(null);
    const [addPlayer, setAddPlayer] = useState(false);
    const cancelTokenSource = axios.CancelToken.source();

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (!checkBrowser()) {
            _navigate(Pages._DEFAULT);
        }        
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    if (checkBrowser()) {
        useEffect(() => {
            getVendorCategories();
            setSelectedVendor(_location.state);
        }, []);

        /// <summary>
        /// Author: Juin
        /// </summary>
        useEffect(() => {
            if (vendorCategories?.length) {
                getVendorList();
            }
        }, [vendorCategories]);

        /// <summary>
        /// Author: Juin
        /// </summary>
        useEffect(() => {
            if (!isFirstLoad) {
                getVendorList();
            }
        }, [selectedVendorCategory]);


        /// <summary>
        /// Author: Juin
        /// </summary>
        useEffect(() => {
            // If vendor not manual read score, queue players to get details
            if (!isObjectEmpty(selectedVendor)) {
                let vendorPlayers = selectedVendor.players;

                let tempVendorPlayers = vendorPlayers.map((item) => ({
                    ...item,
                    isSameRegion: true
                }));

                setPlayers(tempVendorPlayers);
                setPlayersQueue(vendorPlayers.map((i) => i.username));
            } else {
                setPlayers([]);
                setPlayersQueue([]);

                if (selectedPlayerUsername) {
                    // Cancel ongoing request
                    cancelTokenSource.cancel();
                    setSelectedPlayerUsername(null);
                }
            }
        }, [selectedVendor]);

        /// <summary>
        /// Author: Juin
        /// Navigate to player detail when new player is added
        /// </summary>
        useEffect(() => {
            if (goToPlayerDetail) {
                let playerDetail = players.find((i) => i.id == goToPlayerDetail);

                if (playerDetail) {
                    setAddPlayer(false);
                    _router.navigate(Pages._PLAYER_DETAIL, {
                        playerDetail: playerDetail,
                        selectedVendor: selectedVendor,
                        vendorList: vendorList,
                    })
                    setGoToPlayerDetail(null);
                }
            }
        }, [goToPlayerDetail, players.length]);

        /// <summary>
        /// Author: Juin
        /// </summary>
        async function onShare(game, response) {
            try {
                if (response) {
                    let shareMsg = 'ePay Pro \n\n';
                    shareMsg += response.member
                        ? 'Username: ' + response.member + '\n'
                        : '';
                    shareMsg += 'Game: ' + game + '\n';
                    shareMsg += response.vendor_username
                        ? 'ID: ' + response.vendor_username + '\n'
                        : '';
                    shareMsg += response.password
                        ? 'Pw: ' + response.password + '\n'
                        : '';
                    shareMsg += response.downloadUrlAndroid
                        ? 'Download URL (Android): ' +
                        response.downloadUrlAndroid +
                        '\n'
                        : '';
                    shareMsg += response.downloadUrlIOS
                        ? 'Download URL (IOS): ' + response.downloadUrlIOS + '\n'
                        : '';
                    shareMsg += '\nePay Pro \n\n';
                    shareMsg += response.createdDateTime;

                    share(shareMsg)
                }
            } catch (ex) {
                /// when user close the share option will come to here
            }
        }

        /// <summary>
        /// Author: Juin
        /// </summary>
        useEffect(() => {
            // If no player in queue selected, get the first item from queue to retrieve player details
            if (playersQueue.length > 0 && selectedPlayerUsername == null) {
                getPlayerDetail(playersQueue[0]);
            }
        }, [playersQueue.length, selectedPlayerUsername]);

        /// <summary>
        /// Author: Juin
        /// </summary>
        function queuePlayer(username) {
            let tempPlayersQueue = [...playersQueue];

            if (!tempPlayersQueue.includes(username)) {
                tempPlayersQueue.push(username);
                setPlayersQueue(tempPlayersQueue);
            }
        }

        /// <summary>
        /// Author: Juin
        /// </summary>
        async function addNewPlayer() {
            try {
                setAddPlayer(true);
                let params = {
                    Id: _userData.guid,
                    VendorId: selectedVendor.id
                };

                let formBody = createFormBody(params);
                let responseJson = await ApiEngine.post(
                    Routes._ADD_PLAYER,
                    formBody
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    _dispatch(
                        showCustomDialog({
                            success: true,
                            headerTitle: t('NEW_PLAYER_ADDED'),
                            customContent: (
                                <Center>
                                    <Stack>
                                        <Center>
                                            <Image boxSize='180px' mb={1} mt={1.5} src={require('../../../assets/images/graphic-newplayer.png')} />
                                        </Center>
                                        <Text mb={1} fontWeight={'bold'}>{t('YOU_VE_ADDED_PLAYER', { vendor: selectedVendor.name })}</Text>
                                        <Center>
                                            <Stack direction='row' align='center'>
                                                <Text>{t('PLAYER_ID') + ': ' + responseJson[ApiKey._API_DATA_KEY]['vendor_username']}</Text>
                                                <Button variant='link' onClick={() => {
                                                    copyToClipboard(responseJson[ApiKey._API_DATA_KEY]['vendor_username'], t('COPIED_SUCCESFULLY'))
                                                }}>
                                                    <Image boxSize='30px' src={require('../../../assets/images/svg/general-copy.svg').default} />
                                                </Button>
                                            </Stack>
                                        </Center>
                                        {
                                            !stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]['password']) &&
                                            <Center>
                                                <Stack direction='row' align='center' mb={3}>
                                                    <Text>{t('PASSWORD') + ': ' + responseJson[ApiKey._API_DATA_KEY]['password']}</Text>
                                                    <Button variant='link' onClick={() => {
                                                        copyToClipboard(responseJson[ApiKey._API_DATA_KEY]['password'], t('COPIED_SUCCESFULLY'))
                                                    }}>
                                                        <Image boxSize='30px' src={require('../../../assets/images/svg/general-copy.svg').default} />
                                                    </Button>
                                                </Stack>
                                            </Center>
                                        }
                                        <Center>
                                            <Button
                                            className="purple-share-button" bg={'linear-gradient(#b064e6,#8c2ed5,#7e27c3) !important'} border={'2px solid #ac5be9 !important'}
                                            leftIcon={<Image boxSize='18px'
                                                    src={require('../../../assets/images/svg/share.svg').default} />}
                                                onClick={() => {
                                                    onShare(selectedVendor?.gameName, responseJson[ApiKey._API_DATA_KEY]);
                                                }}>
                                                <Text>{t('SHARE')}</Text>
                                            </Button>
                                        </Center>
                                    </Stack>
                                </Center>
                            ),
                            confirmTxt: t('TRANSFER_AND_PLAY'),
                            onConfirm: () => {
                                setGoToPlayerDetail(
                                    responseJson[ApiKey._API_DATA_KEY]['player_id']
                                );
                            },
                            cancelTxt: t('BACK_TO_HOME'),
                            onCancel: () => {
                                setAddPlayer(false);
                                _navigate(Pages._GAMES);
                            },
                            horizontalBtn: true
                        })
                    );
                    getVendorList();
                } else {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }
            } catch (err) {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: err,
                        onConfirm: () => {
                            setAddPlayer(false);
                        }
                    })
                );
            }
        }

        /// <summary>
        /// Author: Juin
        /// </summary>
        const getVendorList = async () => {
            try {
                setIsVendorListLoading(true);
                let responseJson = await ApiEngine.get(
                    `${Routes._GET_VENDOR_LIST}${selectedVendorCategory != null
                        ? '?categoryId=' + selectedVendorCategory
                        : ''
                    }`,
                    { headers: { skipLoading: true } }
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let tempVendorList = responseJson[ApiKey._API_DATA_KEY]['data'];
                    setVendorList(tempVendorList);

                    if (selectedVendor) {
                        setSelectedVendor(
                            tempVendorList.find((i) => i.id == selectedVendor.id)
                        );
                    }

                    if (!selectedVendorCategory) {
                        let categoryId =
                            responseJson[ApiKey._API_DATA_KEY].overallInfo
                                ?.categoryId;

                        setSelectedVendorCategory(
                            (categoryId ? categoryId : _ALL_CATEGORY.id).toString()
                        );
                    }
                }
            } finally {
                setIsVendorListLoading(false);
                setIsFirstLoad(false);
            }
        };

        /// <summary>
        /// Author: Juin
        /// </summary>
        const getVendorCategories = async () => {
            try {
                var responseJson = await ApiEngine.get(
                    `${Routes._GET_VENDOR_CATEGORY_LIST}`,
                    { headers: { skipLoading: true } }
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let tempVendorCategoryList = responseJson[ApiKey._API_DATA_KEY][
                        'data'
                    ].map((i) => ({ id: i.id, title: i.translatedName }));
                    setVendorCategories([
                        { id: -1, title: t('ALL') },
                        ...tempVendorCategoryList
                    ]);
                }
            } catch (err) {
                _dispatch(
                    showCustomDialog({
                        success: false,
                        content: err
                    })
                );
            }
        };

        /// <summary>
        /// Author: Juin
        /// </summary>
        async function getPlayerDetail(username) {
            try {
                setSelectedPlayerUsername(username);
                setIsPlayerListLoading(true);
                let responseJson = await ApiEngine.get(
                    `${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${username}`,
                    {
                        cancelToken: cancelTokenSource.token,
                        headers: { skipLoading: true }
                    }
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    const { score, disable, isSameRegion } =
                        responseJson[ApiKey._API_DATA_KEY][0];
                    let tempPlayers = players;

                    // update player score
                    tempPlayers.find((i) => i.username == username)['score'] =
                        score;
                    tempPlayers.find((i) => i.username == username)['status'] =
                        disable ? Status._DISABLED : Status._ENABLED;
                    tempPlayers.find((i) => i.username == username)[
                        'isSameRegion'
                    ] = isSameRegion;
                    setPlayers(tempPlayers);
                }
            } finally {
                setPlayersQueue(playersQueue.filter((i) => i != username));
                setSelectedPlayerUsername(null);
                if (playersQueue.length == 1) {
                    setIsPlayerListLoading(false);
                }
            }
        }

        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 20px", borderRadius: "20px", width: "35rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            {/* <ChevronLeftIcon onClick={() => _navigate(-1)} boxSize={8} color={"black"} cursor={'pointer'} style={{ float: "left", backgroundImage: "linear-gradient(#c8d4e5 50%, #718ba6 50%)", borderRadius: "50px", backgroundRepeat: "no-repeat" }} /> */}
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={10} pt={1}>{selectedVendor?.gameName}</Text>
                        </div>
                        <Center mb={8}>
                            <Image boxSize={['70px', '80px', '110px']} src={selectedVendor?.imagePath} />
                        </Center>
                        <Stack spacing={2}>
                            {
                                players.length > 0 &&
                                players.map((item, index) => {
                                    return (
                                        <>
                                            <HStack spacing={1} bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={14} paddingInline={6} paddingBlock={4} cursor={'pointer'} >
                                                <HStack spacing={4} onClick={() => {
                                                    _router.navigate(Pages._PLAYER_DETAIL, {
                                                        playerDetail: item,
                                                        selectedVendor: selectedVendor,
                                                        vendorList: vendorList,
                                                    })
                                                }}>
                                                    {
                                                        item.status == Status._ENABLED ?
                                                            <Image boxSize={'35px'} src={require('../../../assets/images/svg/general-withdraw.svg').default} /> :
                                                            <img width={'35px'} src={require('../../../assets/images/svg/general-block.svg').default} />
                                                    }
                                                    <div>
                                                        <Text fontSize={['xs', 'sm']}>{`${index + 1}. ${t('PLAYER_ID')} :`}</Text>
                                                        <Text color={'#f8c763'} fontSize={'sm'}>{item.displayUsername}</Text>
                                                    </div>
                                                    <Spacer w={48} />
                                                    <Stack spacing={'0.2rem'}>
                                                        <Text textAlign={'center'} fontSize={['2xs', 'xs']}>{t('SCORE')}</Text>
                                                        <div>
                                                            <Box
                                                                position={'relative'}
                                                                paddingInline={8}
                                                                paddingBlock={.5}
                                                                borderColor={'#C6D1E2'}
                                                                borderWidth={'1px'}
                                                                borderRadius={'15px'}
                                                                bg={'linear-gradient(#3f0864,#5a1487)'}>
                                                                <Image
                                                                    style={{
                                                                        position: 'absolute',
                                                                        left: '-14px',
                                                                        top: '-4px'
                                                                    }}
                                                                    boxSize={'34px'}
                                                                    src={require('../../../assets/images/topbar-icon-score.png')} />
                                                                <Text>
                                                                    {item.score !=
                                                                        undefined
                                                                        ? numberWithCurrencyFormat(
                                                                            item.score
                                                                        )
                                                                        : '----'}</Text>
                                                            </Box>

                                                        </div>
                                                    </Stack>
                                                </HStack>
                                                {
                                                    playersQueue.includes(item.username) ?
                                                        <Spinner color='white' /> :
                                                        <Button ml={[1.5, 0.5]} variant={'link'} w={'10%'} pt={4} onClick={() =>
                                                            queuePlayer(
                                                                item.username
                                                            )
                                                        }>
                                                            <Image
                                                                boxSize={'30px'}
                                                                src={require('../../../assets/images/svg/general-refresh-purple.svg').default} />
                                                        </Button>
                                                }
                                            </HStack>
                                            <Flex w='100%' paddingInline={'1rem'} mb={2} key={index}>
                                                <Button variant={'link'} w={'90%'} onClick={() => {
                                                    _router.navigate(Pages._PLAYER_DETAIL, {
                                                        playerDetail: item,
                                                        selectedVendor: selectedVendor,
                                                        vendorList: vendorList,
                                                    })
                                                }}>
                                                </Button>
                                                <Spacer />
                                            </Flex>
                                        </>
                                    )
                                })
                            }
                            {
                                players.length < _MAXIMUM_PLAYERS &&
                                <Button mt={2} borderRadius={10} backgroundImage={'linear-gradient(#e5b823, #8d7216)'} color={'white'} padding={'25px 0px'} onClick={() => {
                                    _dispatch(
                                        showCustomDialog({
                                            success: true,
                                            title: t('ADD_PLAYER'),
                                            content:
                                                t('ADD_PLAYER_CONFIRMATION') +
                                                selectedVendor?.gameName +
                                                '?',
                                            confirmTxt: t('CONFIRM'),
                                            horizontalBtn: false,
                                            onConfirm: () => {
                                                addNewPlayer();
                                            },
                                            onCancel: () => { }
                                        })
                                    );
                                }}>
                                    {t('ADD_PLAYER')}
                                </Button>
                            }
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}