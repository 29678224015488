import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PageSettings } from "../../routes/setting";
import { Stack, Text, Image, Spacer, Card, CardBody, CardFooter, Flex, Divider, Button, Badge, Center } from '@chakra-ui/react';
import CustomInput from "../../component/input";
import { useForm, Controller } from 'react-hook-form';
import { ValidationPattern } from "../../common/constant";
import { useRouter } from "../../hooks/useRouter";
import { Pages } from "../../routes/page";
import { useDispatch } from "react-redux";
import ApiEngine from "../../common/api_engine";
import { showCustomDialog } from "../../application/action/app_action";
import GeneralTopBar from "../../component/general-top-bar";
import Countdown, { zeroPad } from 'react-countdown';
import Routes from "../../common/api_routes";
import { ApiKey, _LOGIN_METHOD } from "../../common/constant";
import { checkBrowser, createFormBody, isObjectEmpty, stringIsNullOrEmpty } from "../../common/util";
import { useLocation, useNavigate } from "react-router-dom";
import { performLogin } from "../../application/action/auth_action";
import Header from "./header";
import Footer from "./footer";

/// <summary>
/// Author: Juin
/// </summary>
export default () => {
    const { t } = useTranslation();
    const { control, handleSubmit, errors, watch } = useForm();
    var _context = useContext(PageSettings);
    var _router = useRouter();
    var _navigate = useNavigate();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const [canResend, setCanResend] = useState(true);
    const _EXPIRED_TIME_IN_SECONDS = 900000;
    const _PARAMS = _location?.state;

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back()
        }
        else {
            if (_PARAMS.method == _LOGIN_METHOD._REQUIRED_SECONDARY) {
                setCanResend(true);
            }
            else if (_PARAMS.method == _LOGIN_METHOD._REQUIRED_TAC) {
                setCanResend(false);
            }
        }
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    const TimerRenderer = ({ minutes, seconds }) => {
        return <span className="app-countdown-timer"><Text fontSize={'md'} color={'app.blue'}>{zeroPad(minutes)}:{zeroPad(seconds)}</Text></span>;
    };

    /// <summary>
    /// Author: Juin
    /// </summary>
    function activeBtn() {
        return (!stringIsNullOrEmpty(watch('secondaryPassword')));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function triggerResend() {
        _dispatch(showCustomDialog({
            success: true,
            title: "CONFIRMATION",
            content: "RESET_SECONDARY_PASSWORD",
            onConfirm: async () => {
                try {
                    var responseJson = await ApiEngine.post(Routes._RESEND_SECONDARY_PASSWORD, createFormBody({ UserId: _PARAMS.guid }));
                    let dialogContent = {
                        success: responseJson[ApiKey._API_SUCCESS_KEY],
                        content: responseJson[ApiKey._API_MESSAGE_KEY],
                        onConfirm: () => {
                            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                setCanResend(false);
                            }
                        },
                    };
                    
                    if (!stringIsNullOrEmpty(_PARAMS?.whatsappLinks) && _PARAMS?.whatsappLinks != 'null') {
                        dialogContent['content'] = "SECONDARY_ALREADY_RESET_CS";
                        dialogContent['confirmTxt'] = "CUSTOMER_SERVICE";
                        dialogContent['cancelTxt'] = "OK";
                        dialogContent['onConfirm'] = () => {
                            setCanResend(false);
                            window.open(_PARAMS?.whatsappLinks, "_blank");
                        };
                        dialogContent['onCancel'] = () => {
                            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                                setCanResend(false);
                            }
                        };
                        dialogContent['horizontalBtn'] = false;
                    }

                    _dispatch(showCustomDialog({ ...dialogContent }));
                } catch (err) {
                    _dispatch(showCustomDialog({ success: false, content: err }));
                }
            },
            onCancel: () => { },
            horizontalBtn: false
        }));
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const submitForm = async (data, e) => {
        try {
            _dispatch(performLogin(_PARAMS.username, _PARAMS.password, data.secondaryPassword));
        }
        catch (err) {
            _dispatch(showCustomDialog({
                success: false,
                content: err
            }));
        }
        finally {

        }
    }

    if (!checkBrowser()) {
        return (
            <div>
                <GeneralTopBar />
                <Stack align={'center'} spacing={4} mt={'55px'} position={'absolute'} top={'50%'} left={'50%'} transform={'translate(-50%, -50%)'} margin={0} w={'100%'} paddingInline={8}>
                    <Image src={require('../../assets/images/graphic-forgotpw.png')} objectFit={'cover'} boxSize={'125px'} />
                    <Text fontSize={'md'} fontWeight={'bold'} color={'#f8c763'}>{t('ENTER_YOUR_SECONDARY_PASSWORD')}</Text>
                    <Text textAlign={'center'} color={'#f8c763'} mb={2}>{t('SECONDARY_PASSWORD_DESCRIPTION')}</Text>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Controller
                            control={control}
                            name="secondaryPassword"
                            defaultValue={''}
                            render={({ onChange, value, name }) => (
                                <CustomInput
                                    id={name}
                                    onChange={(value) =>
                                        onChange(value)
                                    }
                                    value={value}
                                    border="1px solid #f8c763 !important"
                                        borderRadius="10px !important"
                                        background="linear-gradient(#5b0e89,#7e14bf) !important"
                                        boxShadow="none !important"
                                    errors={errors}
                                    label='SECONDARY_PASSWORD'
                                    inputMode='tel'
                                    isPassword />
                            )}
                            rules={{
                                required: true,
                                minLength: 6,
                                maxLength: 6,
                                pattern: ValidationPattern._DIGIT_REGEX
                            }}
                        />
                        <Button className="grey-gradient-button" type='submit'>{t('SUBMIT')}</Button>
                    </form>
                    {
                        canResend ?
                            <>
                                <Button className="grey-gradient-button" onClick={() => triggerResend()}>{t('RESET_SECONDARY_PASSWORD')}</Button>
                                <Text textAlign={'center'} color={'#f8c763'}>{t('SECONDARY_PASSWORD_SMS')}</Text>
                            </>
                            :
                            
                            <>
                                <Countdown
                                    date={Date.now() + _EXPIRED_TIME_IN_SECONDS}
                                    renderer={TimerRenderer}
                                    onComplete={() => setCanResend(!canResend)}
                                />
                                <Text textAlign={'center'} color={'#f8c763'}>{t('WE_HAVE_SENT_TAC_TO')}</Text>
                            </>
                    }
                </Stack>
            </div>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "33rem", background:'linear-gradient(#ac4cfe,#7416ab)' }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={7} fontWeight={'bold'}>{t('SECONDARY_PASSWORD')}</Text>
                        </div>
                        <Stack align={'center'} spacing={4} paddingInline={4} pb={2}>
                            <Image src={require('../../assets/images/graphic-forgotpw.png')} objectFit={'cover'} boxSize={'125px'} />
                            <Text fontSize={'md'} color={'#f8c763'}>{t('ENTER_YOUR_SECONDARY_PASSWORD')}</Text>
                            <Text textAlign={'center'} mb={2} color={'#f8c763'}>{t('SECONDARY_PASSWORD_DESCRIPTION')}</Text>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <Controller
                                    control={control}
                                    name="secondaryPassword"
                                    defaultValue={''}
                                    render={({ onChange, value, name }) => (
                                        <CustomInput
                                            id={name}
                                            onChange={(value) =>
                                                onChange(value)
                                            }
                                            value={value}
                                            borderRadius='15px !important'
                                            background='linear-gradient(#5a0089,#7c15bd) !important'
                                            height='100% !important'
                                            paddingBlock='0.5rem !important'
                                            errors={errors}
                                            label='SECONDARY_PASSWORD'
                                            inputMode='tel'
                                            isPassword />
                                    )}
                                    rules={{
                                        required: true,
                                        minLength: 6,
                                        maxLength: 6,
                                        pattern: ValidationPattern._DIGIT_REGEX
                                    }}
                                />
                                <Button 
                                width={'100%'} fontWeight={'500'} fontSize={'md'} borderRadius={25} bg={activeBtn() ? 'linear-gradient(#e5b823, #8d7216)' : 'linear-gradient(#bd67f0, #4c0177 40%, #6b239a 100%) '} color={'white'} paddingBlock={6}
                                type='submit'>{t('SUBMIT')}</Button>
                            </form>
                            {
                                canResend ?
                                    <Button 
                                    width={'100%'} fontWeight={'500'} fontSize={'md'} borderRadius={25} backgroundImage={'linear-gradient(#e5b823, #8d7216)'} color={'white'} paddingBlock={6}
                                    onClick={() => triggerResend()}>{t('RESET_SECONDARY_PASSWORD')}</Button> :
                                    <>
                                        <Countdown
                                            date={Date.now() + _EXPIRED_TIME_IN_SECONDS}
                                            renderer={TimerRenderer}
                                            onComplete={() => setCanResend(!canResend)}
                                        />
                                        <Text textAlign={'center'} color={'#f8c763'}>{t('SECONDARY_PASSWORD_SMS')}</Text>
                                    </>
                            }
                        </Stack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }

}