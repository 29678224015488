import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router";
import { useRouter } from "../../../hooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import {
    Text,
    Image,
    Spacer,
    Divider,
    Center,
    AbsoluteCenter,
    Flex,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    Box,
    Stack,
    Spinner,
    SimpleGrid,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    HStack,
    Card,
    CardBody,
} from '@chakra-ui/react';
import GeneralTopBar from "../../../component/general-top-bar";
import { checkBrowser, isObjectEmpty, numberWithCurrencyFormat } from "../../../common/util";
import { Pages } from "../../../routes/page";
import CurrencyInput from "react-currency-input-field";
import ApiEngine from "../../../common/api_engine";
import { ApiKey, ResultImageType, ScreenWidth } from "../../../common/constant";
import Routes from "../../../common/api_routes";
import { showCustomDialog, disposeCustomDialog } from "../../../application/action/app_action";
import { getMemberDetails } from "../../../application/action/auth_action";
import { useOrientation } from 'react-use';
import { isMobile } from "react-device-detect";
import Header from "../../non-auth-pages/header";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    const { t } = useTranslation();
    var _router = useRouter();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const userData = useSelector(state => state.authState.userData);
    const _PARAMS = _location?.state;
    const selectedVendor = _PARAMS?.selectedVendor;
    const selectedPlayer = _PARAMS?.selectedPlayer;
    const [transferScore, setTransferScore] = useState(0);
    const [vendorList, setVendorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingManualScore, setLoadingManualScore] = useState(false);
    const [isReadingScore, setIsReadingScore] = useState(false);
    const [walletAmount, setWalletAmount] = useState(0);
    const _POPOVER_CONTENT = [
        {
            icon: require('../../../assets/images/svg/manual.svg').default,
            description: 'USER_NEED_TO_TAP_ON_IT_TO_READ_SCORE'
        },
    ]
    const { type } = useOrientation();

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        if (isObjectEmpty(_PARAMS)) {
            _router.back();
        }
        else {
            setIsLoading(true);
            (async () => {
                let availableVendor = [];

                if (selectedPlayer) {
                    var responseJson = await ApiEngine.get(Routes._GET_PLAYER_ACCOUNTS + '?id=' + userData.guid +
                        '&playerId=' + selectedPlayer.id, { headers: { "skipLoading": true } });

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        availableVendor = responseJson[ApiKey._API_DATA_KEY]
                            .map(itm => ({
                                ...itm, expand: false, players: itm.players
                                    .map(item => ({
                                        ...item, transfer: item.wallet_balance.toString()
                                    }))
                            }));
                    }
                    else {
                        _dispatch(showCustomDialog({
                            success: false,
                            content: responseJson[ApiKey._API_MESSAGE_KEY],
                            disableHardwareBackPress: true,
                            onConfirm: () => {
                                _dispatch(disposeCustomDialog());
                                _router.back();
                            }
                        }));
                    }

                }
                else {
                    var responseJson = await ApiEngine.get(Routes._GET_PLAYER_ACCOUNTS + '?id=' + userData.guid,
                        { headers: { "skipLoading": true } });

                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        availableVendor = responseJson[ApiKey._API_DATA_KEY].filter(a => a.players.length > 0).map(itm => ({
                            ...itm, expand: false, players: itm.players
                                .map(item => ({
                                    ...item, transfer: item.wallet_balance.toString()
                                }))
                        }));
                    }
                    else {
                        _dispatch(showCustomDialog({
                            success: false,
                            content: responseJson[ApiKey._API_MESSAGE_KEY],
                            disableHardwareBackPress: true,
                            onConfirm: () => {
                                _dispatch(disposeCustomDialog());
                                _router.back();
                            }
                        }));
                    }

                }

                setTransferScore(availableVendor.reduce((a, b) => +a + +b.wallet_balance, 0));
                setVendorList(availableVendor);
                setIsLoading(false);
            })();
        }
    }, [])

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function refreshScore() {
        try {
            setIsReadingScore(true);
            if (selectedPlayer) {
                let responseJson = await ApiEngine.get(`${Routes._GET_PLAYER_DETAIL_URL}?vendorPlayerId=${selectedPlayer.vendorPlayerid}`,
                    { headers: { "skipLoading": true } });
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    selectedPlayer.gameScore = responseJson[ApiKey._API_DATA_KEY][0]['score'];
                }
                else {
                    throw responseJson[ApiKey._API_MESSAGE_KEY];
                }
            }
            else {
                _dispatch(getMemberDetails());
            }
        }
        catch (err) {
            _dispatch(showCustomDialog({ success: false, content: err }));
        }
        finally {
            setIsReadingScore(false);
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function loadManualReadScorePlayer(vendorId, playerId) {
        setLoadingManualScore(true);

        playerId.filter(item => item.vendor_username != null).map(async item => {
            var responseJson = await ApiEngine.get(Routes._GET_PLAYER_DETAILS + '?id=' + item.player_id, { headers: { "skipLoading": true } });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                updateTransferValue(vendorId, item.player_id, responseJson[ApiKey._API_DATA_KEY].gameScore, true);
            }
        });

        setLoadingManualScore(false);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    function updateTransferValue(vendorId, playerId, value, manualLoad = false) {
        let totalTransferScore = 0;
        let newVendorList = [...vendorList];

        if (vendorId !== null && playerId !== null) {
            let vendorIndex = vendorList.findIndex(obj => obj.vendor_id == vendorId);
            let playerIndex = newVendorList[vendorIndex].players.findIndex(obj => obj.player_id == playerId);

            if (manualLoad) {
                newVendorList[vendorIndex].players[playerIndex].wallet_balance = value;
                newVendorList[vendorIndex].wallet_balance = newVendorList[vendorIndex].players.reduce((a, b) => +a + +parseFloat(b.wallet_balance), 0);
            }

            if (value > newVendorList[vendorIndex].players[playerIndex].wallet_balance) {
                newVendorList[vendorIndex].players[playerIndex].transfer = newVendorList[vendorIndex].players[playerIndex].wallet_balance.toString();
            }
            else if (value < 0) {
                newVendorList[vendorIndex].players[playerIndex].transfer = '0';
            }
            else {
                newVendorList[vendorIndex].players[playerIndex].transfer = value.toString();
            }

            totalTransferScore += parseFloat(walletAmount);
            setVendorList(newVendorList);
        }
        else {
            totalTransferScore += parseFloat(value);
        }

        newVendorList.map(item => item.players.map(itm => { totalTransferScore += (parseFloat(itm.transfer) ? parseFloat(itm.transfer) : 0) }));
        setTransferScore(totalTransferScore);
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function setScore() {
        let apiUrl = '';
        // Filter out 0 players and player list transfer less than 0 
        let selectedVendorList = [...vendorList].filter(v => v.players.length > 0 && v.players.some(p => p.transfer > 0))
            .map(item => { return { ...item, players: item.players.filter(itm => itm.transfer > 0) } });

        // Form the params Vendors required data
        let vendorData = selectedVendorList.map(item => {
            return {
                VendorName: item.vendor_name,
                Players: item.players.map(itm => {
                    return {
                        PlayerId: itm.player_id,
                        Amount: parseFloat(itm.transfer),
                        Username: itm.vendor_username
                    }
                })
            }
        });

        // Actual data for api calling
        let params = {
            Vendors: vendorData
        };

        if (selectedPlayer) {
            apiUrl = Routes._TRANSFER_PLAYER_SCORE;
            params['TransferCreditDetail'] = {
                amount: parseFloat(walletAmount)
            }
            params['TargetPlayerId'] = selectedPlayer.id
        }
        else {
            apiUrl = Routes._BULK_CONVERT_SCORE_TO_CREDIT;
        }

        var responseJson = await ApiEngine.post(apiUrl, JSON.stringify(params), { headers: { 'content-type': ApiKey._API_APPLICATION_JSON } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let totalTransferScore = 0;
            let details = [];

            if (selectedPlayer) {
                totalTransferScore += parseFloat(walletAmount);
                details.push({ label: t('TO') + ' ' + selectedVendor.vendor_name, value: selectedPlayer.vendorPlayerid });
            }
            else {
                _dispatch(getMemberDetails());
            }

            responseJson[ApiKey._API_DATA_KEY].vendors.map(vendor => {
                vendor.players.map(item => { totalTransferScore += parseFloat(item.amount) });
                details.push({
                    label: t('FROM') + ' ' + vendor.vendorName,
                    value: vendor.players.filter(x => x.status).map(i => `${i.username} (${numberWithCurrencyFormat(i.amount)})`).join(`\n`)
                });
            });

            if (walletAmount > 0) {
                details.push({
                    label: t('FROM') + ' ' + t('WALLET'),
                    value: parseFloat(walletAmount).toFixed(2)
                });
            }

            _router.navigate(Pages._CONVERT_SUCCESS, {
                headerTitle: 'SUCCESSFULLY_TRANSFER',
                contentImageType: ResultImageType._SEND,
                contentTitle: 'YOU_VE_TRANSFER',
                amount: '+ RM ' + parseFloat(totalTransferScore).toFixed(2),
                details: details,
                navigateToLastPage: _PARAMS?.from ?? Pages._DEFAULT,
                prevParams: _PARAMS?.prevParams ?? {}
            }, true);
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    if (!checkBrowser()) {
        return (
            <Stack style={{ overflowX: 'hidden' }} marginInline={['-6vw', '-3vw']}>
                <GeneralTopBar title={selectedPlayer ? 'TRANSFER_SCORE' : 'SCORE_TO_WALLET'} bgColor="linear-gradient(#a94ff1,#9e32dd)" rightComponent={
                    <Popover>
                        <PopoverTrigger>
                            <Button variant='link' bg={'linear-gradient(#6b17af,#903bd3)'} borderRadius={50} paddingBlock={2} minW={'35px'}>
                                <img
                                    style={{
                                        width: '20px',
                                        height: '20px'
                                    }}
                                    src={require('../../../assets/images/svg/help.svg').default} />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="app-popover">
                            <PopoverArrow />
                            {
                                _POPOVER_CONTENT.map((item, index) => {
                                    return (
                                        <PopoverHeader>
                                            <Flex>
                                                <img width={'25px'} height={'25px'} src={item.icon} alt='EPAYPRO' />
                                                <Text noOfLines={3} ml={2}>{t(item.description)} </Text>
                                            </Flex>
                                        </PopoverHeader>
                                    )
                                })
                            }
                        </PopoverContent>
                    </Popover>
                } />
                <Box className="transfer-score-header">
                    <Center>
                        <Flex w={'fit-content'}>
                            {
                                type === 'landscape-primary' &&
                                <Spacer />
                            }
                            <Stack>
                                <Flex align='center'>
                                    <Image boxSize={'25px'} src={selectedPlayer ? require('../../../assets/images/topbar-icon-score.png') : require('../../../assets/images/topbar-icon-credit.png')} />
                                    <Text color='#f8c763' w={'10rem'} wordBreak={'break-word'} fontSize='md'>{selectedPlayer ? selectedPlayer.vendorPlayerid + ' (' + selectedVendor.vendor_name + ')' : t('CURRENT_WALLET')}</Text>
                                </Flex>
                                <Text fontSize='lg' fontWeight='bold' ml={2}>{numberWithCurrencyFormat(selectedPlayer ? selectedPlayer.gameScore ?? 0 : userData.credit)}</Text>
                            </Stack>
                            <Spacer />
                            <Stack w={'7rem'}>
                                <Flex align='center'>
                                    <Image boxSize={'25px'} src={selectedPlayer ? require('../../../assets/images/topbar-icon-score.png') : require('../../../assets/images/topbar-icon-credit.png')} />
                                    <Text color='#f8c763' fontSize='md'>{t(selectedPlayer ? 'SCORES' : 'WALLET')}</Text>
                                </Flex>
                                <Text fontSize='lg' fontWeight='bold' ml={2}>{'+ ' + numberWithCurrencyFormat(transferScore)}</Text>
                            </Stack>
                            <Spacer />
                            {
                                isReadingScore ?
                                    <Spinner color='white' /> :
                                    <Button variant='link' onClick={() => refreshScore()}>
                                        <Image
                                            boxSize={'35px'}
                                            src={require('../../../assets/images/svg/topbar-icon-refresh.svg').default} />
                                    </Button>
                            }
                            {
                                type === 'landscape-primary' &&
                                <Spacer />
                            }
                        </Flex>
                    </Center>
                    <Text textAlign={'center'} fontSize={'xs'} fontStyle={'italic'} marginBlock={2}>{t(selectedPlayer ? 'ENTER_AMOUNT_OF_OTHER_VENDORS_SCORE_TO_TRANSFER_TO_THIS_SCORE' :
                        'ENTER_AMOUNT_OF_OTHER_VENDORS_SCORE_TO_TRANSFER_TO_THIS_WALLET')}</Text>
                    <Divider borderColor={'#6d1ea2'} ml={'-1rem'} w={'110%'} />
                    <SimpleGrid marginBlock={3} columns={3} spacing={3}>
                        <Box>
                            <Text color='#C6D1E2' textAlign='center'>{t('VENDOR')}</Text>
                        </Box>
                        <Box>
                            <Text color='#C6D1E2' textAlign='center'>{t('CURRENT_SCORES')}</Text>
                        </Box>
                        <Box>
                            <Text color='#C6D1E2' textAlign='center'>{t('TRANSFER')}</Text>
                        </Box>
                    </SimpleGrid>
                    {
                        selectedPlayer &&
                        <SimpleGrid columns={3} spacing={3} className="transfer-score-e-credit-row">
                            <Flex justify={'center'}>
                                <Image
                                    boxSize={'22.5px'}
                                    src={require('../../../assets/images/topbar-icon-credit.png')}
                                />
                                <Text color='#f8c763' textAlign='center' fontSize={'sm'}>{t('WALLET')}</Text>
                            </Flex>
                            <Box>
                                <Text textAlign='center'>{numberWithCurrencyFormat(userData?.credit)}</Text>
                            </Box>
                            <Flex spacing={1} justify={'center'}>
                                <Button variant='link' onClick={() => {
                                    let value = (parseFloat(walletAmount) + 1).toFixed(2);

                                    if (userData.credit > value) {
                                        setWalletAmount(value);
                                        updateTransferValue(null, null, value);
                                    }
                                }}>
                                    <img width={25} height={25} src={require('../../../assets/images/svg/general-plus.svg').default} />
                                </Button>
                                <CurrencyInput
                                    style={{
                                        textAlign: 'center',
                                        backgroundColor: 'transparent',
                                        color: 'white',
                                        fontWeight: '500',
                                        fontSize: '0.8rem',
                                        width: '12.5vw',
                                        outlineWidth: 0
                                    }}
                                    value={walletAmount}
                                    placeholder="0.00"
                                    decimalScale={2}
                                    autoComplete={false}
                                    onFocus={(e) => e.target.select()}
                                    onValueChange={(value, name) => {
                                        if (!isLoading) {
                                            let newValue = value;

                                            if (newValue > userData.credit) {
                                                newValue = userData.credit;
                                            }

                                            setWalletAmount(newValue ?? 0);
                                            updateTransferValue(null, null, value);
                                        }
                                    }}
                                />
                                <Button variant='link' onClick={() => {
                                    let value = walletAmount - 1 > 0 ? (parseFloat(walletAmount) - 1).toFixed(2) : (0).toFixed(2);
                                    setWalletAmount(value);
                                    updateTransferValue(null, null, value);
                                }}>
                                    <img width={25} height={25} src={require('../../../assets/images/svg/general-minus.svg').default} />
                                </Button>
                            </Flex>
                        </SimpleGrid>
                    }
                </Box>
                <div className="transfer-score-vendor-container">
                    <Stack>
                        {
                            isLoading ?
                                <AbsoluteCenter>
                                    <Spinner color='white' />
                                </AbsoluteCenter> :
                                vendorList.map((item, index) => {
                                    return (
                                        <Accordion allowMultiple>
                                            <AccordionItem
                                                isDisabled={item.players.length == 0 || (!item.enableManualReadScore && item.wallet_balance <= 0)}
                                                borderTopWidth={0}
                                                borderBottomWidth={0.5}
                                                borderBottomColor={'#3D4B6E'}
                                            >
                                                {({ isExpanded }) => {
                                                    if (item.enableManualReadScore && isExpanded) {
                                                        loadManualReadScorePlayer(item.vendor_id, item.players);
                                                    }

                                                    return (
                                                        <>
                                                            <div style={isExpanded ? { background: '#2F4B7B' } : {}}>
                                                                <SimpleGrid columns={3} spacing={0} paddingInline={4}>
                                                                    <AccordionButton justifyContent={'center'}>
                                                                        <Text wordBreak={'break-word'}>{item.vendor_name}</Text>
                                                                        {
                                                                            item.enableManualReadScore &&
                                                                            <img className="blink-effect" style={{ marginLeft: '5px' }} src={require('../../../assets/images/svg/manual.svg').default} />
                                                                        }
                                                                    </AccordionButton>
                                                                    <AccordionButton justifyContent={'center'} >
                                                                        <Text>{item.wallet_balance.toFixed(2)}</Text>
                                                                    </AccordionButton>
                                                                    <AccordionButton justifyContent={'center'} >
                                                                        <Text>{item.players.reduce((a, b) => +a + +parseFloat(b.transfer), 0).toFixed(2)}</Text>
                                                                    </AccordionButton>
                                                                </SimpleGrid>
                                                            </div>
                                                            <AccordionPanel pb={0} backgroundColor='#1E283A' paddingInline={0}>
                                                                {
                                                                    item.players.map((player, idx) => {
                                                                        return (
                                                                            <SimpleGrid
                                                                                columns={3}
                                                                                spacing={3}
                                                                                textAlign={'center'}
                                                                                paddingBlock={3.5}
                                                                                paddingInline={4}
                                                                                borderBottomWidth={idx + 1 != item.players.length ? 0.5 : 0}
                                                                                borderColor={'#3D4B6E'}>
                                                                                <Box>
                                                                                    <Text wordBreak={'break-word'}>{player.vendor_username}</Text>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Text>{numberWithCurrencyFormat(player.wallet_balance)}</Text>
                                                                                </Box>
                                                                                <Flex justify={'center'} spacing={1}>
                                                                                    <Button variant='link' onClick={() => {
                                                                                        updateTransferValue(
                                                                                            item.vendor_id,
                                                                                            player.player_id,
                                                                                            ((parseFloat(player.transfer) + 1).toFixed(2)))
                                                                                    }}>
                                                                                        <img width={25} height={25} src={require('../../../assets/images/svg/general-plus.svg').default} />
                                                                                    </Button>
                                                                                    <CurrencyInput
                                                                                        style={{
                                                                                            textAlign: 'center',
                                                                                            backgroundColor: 'transparent',
                                                                                            color: 'white',
                                                                                            fontWeight: '500',
                                                                                            fontSize: '0.8rem',
                                                                                            width: '12.5vw',
                                                                                            outlineWidth: 0
                                                                                        }}
                                                                                        value={player.transfer}
                                                                                        placeholder="0.00"
                                                                                        decimalScale={2}
                                                                                        autoComplete={false}
                                                                                        onFocus={(e) => e.target.select()}
                                                                                        onValueChange={(value, name) => {
                                                                                            updateTransferValue(item.vendor_id, player.player_id, value ?? 0)
                                                                                        }}
                                                                                    />
                                                                                    <Button variant='link' onClick={() => {
                                                                                        updateTransferValue(
                                                                                            item.vendor_id,
                                                                                            player.player_id,
                                                                                            ((parseFloat(player.transfer) - 1).toFixed(2)))
                                                                                    }}>
                                                                                        <img width={25} height={25} src={require('../../../assets/images/svg/general-minus.svg').default} />
                                                                                    </Button>
                                                                                </Flex>
                                                                            </SimpleGrid>
                                                                        )
                                                                    })
                                                                }
                                                            </AccordionPanel>
                                                        </>
                                                    )
                                                }}
                                            </AccordionItem>
                                        </Accordion>
                                    )
                                })
                        }
                    </Stack>
                </div>
                <Flex className="bottom-sticky-button" style={{ background: 'linear-gradient(#a640e9,#8b27c7)', padding: '1rem', alignItems: 'center' }}>
                    <Stack spacing={0}>
                        <Text color={'#f8c763'}>{t('TOTAL_TRANSFER_AMOUNT')}</Text>
                        <Text fontSize={'2xl'} fontWeight={'bold'}><Text as='span' fontSize={'lg'} color={'#f8c763'}>+ </Text>{`${numberWithCurrencyFormat(transferScore)}`}</Text>
                    </Stack>
                    <Spacer />
                    <Button
                        className="grey-gradient-button"
                        style={{ borderRadius: '10px', width: '40%' }}
                        isDisabled={isLoading}
                        onClick={() => {
                            if (transferScore <= 0) {
                                _dispatch(showCustomDialog({
                                    success: false,
                                    content: 'THERE_IS_NO_SCORE_SET_TO_TRANSFER'
                                }));
                            }
                            else {
                                _dispatch(showCustomDialog({
                                    title: 'ARE_YOU_SURE',
                                    content: 'ARE_YOU_SURE_YOU_WANT_TO_TRANSFER_SCORE',
                                    confirmTxt: t("CONFIRM"),
                                    onConfirm: () => {
                                        setScore();
                                    },
                                    onCancel: () => { },
                                    horizontalBtn: false
                                }));
                            }
                        }}>
                        {t('CONFIRM')}
                    </Button>
                </Flex>
            </Stack>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "fit-content" }}>
                        <HStack textAlign={'center'} mb={'30px'}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Spacer />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} fontWeight={'bold'} mr={2}>{selectedPlayer ? t('TRANSFER_SCORE') : t('SCORE_TO_WALLET')}</Text>
                            <Button variant={'link'} display={'inline-block'} >
                                <Image
                                    boxSize={'38px'}
                                    src={require('../../../assets/images/svg/general-help-icon.svg').default}
                                />
                            </Button>
                            <Spacer />
                        </HStack>
                        <HStack spacing={8}>
                            <Card bg={'unset'} boxShadow={'none'}>
                                <CardBody maxW={'30rem'} padding={'none'}>
                                    <SimpleGrid columns={3} mb={4}>
                                        <Box>
                                            <Text color='#C6D1E2' textAlign='center'>{t('VENDOR')}</Text>
                                        </Box>
                                        <Box>
                                            <Text color='#C6D1E2' textAlign='center'>{t('CURRENT_SCORES')}</Text>
                                        </Box>
                                        <Box>
                                            <Text color='#C6D1E2' textAlign='center'>{t('TRANSFER')}</Text>
                                        </Box>
                                    </SimpleGrid>
                                    {
                                        selectedPlayer &&
                                        <SimpleGrid columns={3} mb={4}>
                                            <Flex justify={'center'}>
                                                <Image
                                                    boxSize={'23px'} mr={.5}
                                                    src={require('../../../assets/images/topbar-icon-credit.png')}
                                                />
                                                <Text color='#f8c763' textAlign='center' fontSize={'md'}>{t('ECREDIT')}</Text>
                                            </Flex>
                                            <Box>
                                                <Text textAlign='center'>{numberWithCurrencyFormat(userData?.credit)}</Text>
                                            </Box>
                                            <Flex spacing={1} justify={'center'} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'} _hover={{ border: '2px solid white', borderRadius: '20px' }}>
                                                <Button variant='link' onClick={() => {
                                                    let value = (parseFloat(walletAmount) + 1).toFixed(2);

                                                    if (userData.credit > value) {
                                                        setWalletAmount(value);
                                                        updateTransferValue(null, null, value);
                                                    }
                                                }}>
                                                    <img width={25} height={25} src={require('../../../assets/images/svg/general-plus.svg').default} />
                                                </Button>
                                                <CurrencyInput
                                                    style={{
                                                        textAlign: 'center',
                                                        backgroundColor: 'transparent',
                                                        color: 'white',
                                                        fontWeight: '500',
                                                        fontSize: '1rem',
                                                        width: '6.5vw',
                                                        outlineWidth: 0
                                                    }}
                                                    value={walletAmount}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    autoComplete={false}
                                                    onFocus={(e) => e.target.select()}
                                                    onValueChange={(value, name) => {
                                                        if (!isLoading) {
                                                            let newValue = value;

                                                            if (newValue > userData.credit) {
                                                                newValue = userData.credit;
                                                            }

                                                            setWalletAmount(newValue ?? 0);
                                                            updateTransferValue(null, null, newValue);
                                                        }
                                                    }}
                                                />
                                                <Button variant='link' onClick={() => {
                                                    let value = walletAmount - 1 > 0 ? (parseFloat(walletAmount) - 1).toFixed(2) : (0).toFixed(2);
                                                    setWalletAmount(value);
                                                    updateTransferValue(null, null, value);
                                                }}>
                                                    <img width={25} height={25} src={require('../../../assets/images/svg/general-minus.svg').default} />
                                                </Button>
                                            </Flex>
                                        </SimpleGrid>
                                    }
                                    <div style={{ background: "linear-gradient(#ac4cfe,#7416ab)", borderRadius: "20px", height: "25rem", overflow: "scroll" }}>
                                        <Stack>
                                            {
                                                isLoading ?
                                                    <AbsoluteCenter>
                                                        <Spinner color='white' />
                                                    </AbsoluteCenter> :
                                                    vendorList.map((item, index) => {
                                                        return (
                                                            <Accordion allowMultiple>
                                                                <AccordionItem
                                                                    isDisabled={item.players.length == 0 || (!item.enableManualReadScore && item.wallet_balance <= 0)}>
                                                                    {({ isExpanded }) => {
                                                                        if (item.enableManualReadScore && isExpanded) {
                                                                            loadManualReadScorePlayer(item.vendor_id, item.players);
                                                                        }

                                                                        return (
                                                                            <>
                                                                                <SimpleGrid columns={3} paddingBlock={1}>
                                                                                    <AccordionButton justifyContent={'center'} _hover={{ background: "none" }}>
                                                                                        <Text wordBreak={'break-word'}>{item.vendor_name}</Text>
                                                                                        {
                                                                                            item.enableManualReadScore &&
                                                                                            <img className="blink-effect" style={{ marginLeft: '5px' }} src={require('../../../assets/images/svg/manual.svg').default} />
                                                                                        }
                                                                                    </AccordionButton>
                                                                                    <AccordionButton justifyContent={'center'} _hover={{ background: "none" }}>
                                                                                        <Text>{item.wallet_balance.toFixed(2)}</Text>
                                                                                    </AccordionButton>
                                                                                    <AccordionButton justifyContent={'center'} _hover={{ background: "none" }}>
                                                                                        <Text>{item.players.reduce((a, b) => +a + +parseFloat(b.transfer), 0).toFixed(2)}</Text>
                                                                                    </AccordionButton>
                                                                                </SimpleGrid>
                                                                                <AccordionPanel paddingInlineStart={'none'} paddingInlineEnd={'none'} pb={2} bg='#5a0089'>
                                                                                    {
                                                                                        item.players.map((player, idx) => {
                                                                                            return (
                                                                                                <SimpleGrid
                                                                                                    columns={3}
                                                                                                    textAlign={'center'}
                                                                                                    paddingBlock={3.5}
                                                                                                    borderBottomWidth={idx + 1 != item.players.length ? 0.5 : 0}>
                                                                                                    <Box>
                                                                                                        <Text>{player.vendor_username}</Text>
                                                                                                    </Box>
                                                                                                    <Box>
                                                                                                        <Text>{numberWithCurrencyFormat(player.wallet_balance)}</Text>
                                                                                                    </Box>
                                                                                                    <Flex justify={'center'} spacing={1} borderWidth={2} borderColor={'transparent'} transitionDuration={'normal'} _hover={{ border: '2px solid white', borderRadius: '20px' }}>
                                                                                                        <Button variant='link' onClick={() => {
                                                                                                            if (player.wallet_balance > player.transfer) {
                                                                                                                updateTransferValue(
                                                                                                                    item.vendor_id,
                                                                                                                    player.player_id,
                                                                                                                    ((parseFloat(player.transfer) + 1).toFixed(2)))
                                                                                                            }
                                                                                                        }}>
                                                                                                            <img width={25} height={25} src={require('../../../assets/images/svg/general-plus.svg').default} />
                                                                                                        </Button>
                                                                                                        <CurrencyInput
                                                                                                            style={{
                                                                                                                textAlign: 'center',
                                                                                                                backgroundColor: 'transparent',
                                                                                                                color: 'white',
                                                                                                                fontWeight: '500',
                                                                                                                fontSize: '1rem',
                                                                                                                width: '6.5vw',
                                                                                                                outlineWidth: 0
                                                                                                            }}
                                                                                                            value={player.transfer}
                                                                                                            placeholder="0.00"
                                                                                                            decimalScale={2}
                                                                                                            autoComplete={false}
                                                                                                            onFocus={(e) => e.target.select()}
                                                                                                            onValueChange={(value, name) => {
                                                                                                                updateTransferValue(item.vendor_id, player.player_id, value ?? 0)
                                                                                                            }}
                                                                                                        />
                                                                                                        <Button variant='link' onClick={() => {
                                                                                                            updateTransferValue(
                                                                                                                item.vendor_id,
                                                                                                                player.player_id,
                                                                                                                player.transfer - 1 > 0 ? (parseFloat(player.transfer) - 1).toFixed(2) : (0).toFixed(2))
                                                                                                        }}>
                                                                                                            <img width={25} height={25} src={require('../../../assets/images/svg/general-minus.svg').default} />
                                                                                                        </Button>
                                                                                                    </Flex>
                                                                                                </SimpleGrid>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </AccordionPanel>
                                                                            </>
                                                                        )
                                                                    }}
                                                                </AccordionItem>
                                                            </Accordion>
                                                        )
                                                    })
                                            }
                                        </Stack>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card bg={'unset'} boxShadow={'none'} height={'30rem'}>
                                <CardBody bg={'linear-gradient(#ac4cfe,#7416ab)'} borderRadius={20} paddingInline={'2rem'} paddingBlock={8}>
                                    <Center mb={8}>
                                        <HStack spacing={8}>
                                            <div style={{ textAlign: "center" }}>
                                                <HStack>
                                                    <Image boxSize={'25px'} src={selectedPlayer ? require('../../../assets/images/topbar-icon-score.png') : require('../../../assets/images/topbar-icon-credit.png')} />
                                                    <Text color='#f8c763' fontSize='md'>{selectedPlayer ? selectedPlayer.vendorPlayerid + ' (' + selectedVendor.vendor_name + ')' : t('CURRENT_WALLET')}</Text>
                                                </HStack>
                                                <Text fontSize='xl' fontWeight='bold' ml={2}>{numberWithCurrencyFormat(selectedPlayer ? selectedPlayer.gameScore ?? 0 : userData.credit)}</Text>
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                                <HStack>
                                                    <Image boxSize={'25px'} src={selectedPlayer ? require('../../../assets/images/topbar-icon-score.png') : require('../../../assets/images/topbar-icon-credit.png')} />
                                                    <Text color='#f8c763' fontSize='md'>{t(selectedPlayer ? 'SCORES' : 'WALLET')}</Text>

                                                </HStack>
                                                <Text fontSize='xl' fontWeight='bold' ml={2} w={'5.2rem'}>{'+ ' + numberWithCurrencyFormat(transferScore)}</Text>
                                            </div>
                                            <div>
                                                {
                                                    isReadingScore ?
                                                        <Spinner color='white' /> :
                                                        <Button variant='link' onClick={() => refreshScore()}>
                                                            <Image
                                                                boxSize={'32px'}
                                                                src={require('../../../assets/images/svg/general-refresh-purple.svg').default} />
                                                        </Button>
                                                }
                                            </div>
                                        </HStack>
                                    </Center>
                                    <Text textAlign={'center'} color={'#f8c763'} fontSize={['2xs', 'sm']} fontStyle={'italic'}>{t(selectedPlayer ? 'ENTER_AMOUNT_OF_OTHER_VENDORS_SCORE_TO_TRANSFER_TO_THIS_SCORE' :
                                        'ENTER_AMOUNT_OF_OTHER_VENDORS_SCORE_TO_TRANSFER_TO_THIS_WALLET')}</Text>
                                    <HStack position={'absolute'} bottom={0} width={'-webkit-fill-available'} pr={8} pb={8}>
                                        <div>
                                            <Text color={'#f8c763'}>{t('TOTAL_TRANSFER_AMOUNT')}</Text>
                                            <Text fontSize={'2xl'} fontWeight={'bold'}><Text as='span' fontSize={'lg'} color={'#f8c763'}>+ </Text>{`${numberWithCurrencyFormat(transferScore)}`}</Text>
                                        </div>
                                        <Spacer />
                                        <Button
                                            backgroundImage={'linear-gradient(#e5b823, #8d7216)'}
                                            color={'white'}
                                            height={12}
                                            borderRadius={16}
                                            width={'40%'}
                                            isDisabled={isLoading}
                                            onClick={() => {
                                                if (transferScore <= 0) {
                                                    _dispatch(showCustomDialog({
                                                        success: false,
                                                        content: 'THERE_IS_NO_SCORE_SET_TO_TRANSFER'
                                                    }));
                                                }
                                                else {
                                                    _dispatch(showCustomDialog({
                                                        title: 'ARE_YOU_SURE',
                                                        content: 'ARE_YOU_SURE_YOU_WANT_TO_TRANSFER_SCORE',
                                                        confirmTxt: t("CONFIRM"),
                                                        onConfirm: () => {
                                                            setScore();
                                                        },
                                                        onCancel: () => { },
                                                        horizontalBtn: false
                                                    }));
                                                }
                                            }}>
                                            {t('CONFIRM')}
                                        </Button>
                                    </HStack>
                                </CardBody>
                            </Card>
                        </HStack>
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}