import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import GeneralTopBar from "../../../component/general-top-bar";
import { Stack, Flex, Text, Divider, Spinner, Spacer, AbsoluteCenter, Image, Center } from "@chakra-ui/react";
import Routes from "../../../common/api_routes";
import ApiEngine from "../../../common/api_engine";
import { ApiKey, _PAGE_SIZE, TransactionHistoryStatus } from "../../../common/constant";
import { showCustomDialog } from "../../../application/action/app_action";
import { checkBrowser, numberWithCurrencyFormat } from "../../../common/util";
import moment from "moment";
import DateFilter from "../../../component/date-filter";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from "react-router";
import Footer from "../../non-auth-pages/footer";

/// <summary>
/// Author: Juin
/// </summary>
export default ({ }) => {
    var _dispatch = useDispatch();
    var _navigate = useNavigate();
    const { t } = useTranslation();
    const _INITIAL_PAGE_NO = 1;
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [transaction, setTransaction] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(_INITIAL_PAGE_NO);
    const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));

    /// <summary>
    /// Author: Juin
    /// </summary>
    useEffect(() => {
        getRewardHistory();
    }, []);

    /// <summary>
    /// Author: Juin
    /// </summary>
    async function getRewardHistory(
        startedDate = startDate,
        endedDate = endDate,
        pagedNo = _INITIAL_PAGE_NO) {

        var responseJson = await ApiEngine.get(Routes._GET_REWARD_HISTORY
            + "?startDate=" + startedDate + ' 00:00:00' + "&endDate=" + endedDate + ' 23:59:59'
            + "&page=" + pagedNo + "&pageSize=" + _PAGE_SIZE);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTotalCount(responseJson[ApiKey._API_DATA_KEY].totalCount);

            if (responseJson[ApiKey._API_DATA_KEY] && responseJson[ApiKey._API_DATA_KEY].data.length > 0
                && pagedNo > _INITIAL_PAGE_NO) {
                setTransaction([...transaction, ...responseJson[ApiKey._API_DATA_KEY].data]);
            }
            else {
                setTransaction(responseJson[ApiKey._API_DATA_KEY].data);
            }
        }
        else {
            _dispatch(showCustomDialog({ success: false, content: responseJson[ApiKey._API_MESSAGE_KEY] }));
        }
    }

    /// <summary>
    /// Author: Juin
    /// </summary>
    const RenderItem = ({ item, index }) => {
        return (
            <>
                <Flex key={index} paddingInline={1.5} paddingBlock={4} gap={2} alignItems={'center'}>
                    <img width={'30px'} height={'30px'} src={parseFloat(item.reward) > 0 ? require('../../../assets/images/svg/general-plus.svg').default : require('../../../assets/images/svg/general-minus.svg').default} />
                    <Stack spacing={0} ml={2}>
                        <Text>{t(item.type)}</Text>
                        <Text color={'#f8c763'}>{moment(item.createdTime).format('DD MMM yyyy, hh:mm:ss a')}</Text>
                    </Stack>
                    <Spacer />
                    <Stack spacing={0} align={'flex-end'}>
                        <Text>{numberWithCurrencyFormat(item.reward)}</Text>
                        <Text color={item.status == TransactionHistoryStatus.SUCCESSFUL ? '#27D918' : '#FF5555'}>{item.status == TransactionHistoryStatus.SUCCESSFUL ? t("SUCCESSFUL") : t("FAILED")}</Text>
                    </Stack>
                </Flex>
                <Divider className="divider-horizontal" />
            </>
        )
    }


    if (!checkBrowser()) {
        return (
            <>
                <GeneralTopBar title={'REWARD_HISTORY'} bgColor="linear-gradient(#a94ff1,#9e32dd)" style={{ paddingTop: '1rem' }}>
                    <Flex paddingInline={6} paddingBlock={5} bg={'#1d1643'}>
                        <Text fontWeight={'semibold'} mr={3} ml={2} onClick={() => setShowDatePicker(!showDatePicker)}>
                            {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                        </Text>
                        <img src={require('../../../assets/images/svg/updown-white.svg').default} />
                    </Flex>
                </GeneralTopBar>
                <Stack mt={16}>
                    {
                        transaction.length > 0 ?
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: '100vh',
                                    overflow: "auto"
                                }}>
                                <InfiniteScroll
                                    style={{ display: "flex", flexDirection: "column" }}
                                    dataLength={transaction.length}
                                    next={() => {
                                        let page = pageNo + 1;
                                        setPageNo(page);
                                        getRewardHistory(startDate, endDate, page);
                                    }}
                                    hasMore={transaction.length < totalCount}
                                    loader={<Spinner color="white" />}
                                >
                                    {
                                        transaction.map((item, index) => {
                                            return (
                                                <RenderItem
                                                    item={item}
                                                    index={index}
                                                />
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                            </div> :
                            <AbsoluteCenter>
                                <Image boxSize={'150px'} src={require('../../../assets/images/empty-prize.png')} />
                                <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'}>{t("NO_REWARD_HISTORY")}</Text>
                            </AbsoluteCenter>
                    }
                </Stack>
                <DateFilter
                    isOpen={showDatePicker}
                    onClose={() => setShowDatePicker(!showDatePicker)}
                    closeOnOverlayClick={false}
                    applyDateFilter={(data) => {
                        let start = moment(data.startDate).format('yyyy-MM-DD');
                        let end = moment(data.endDate).format('yyyy-MM-DD');
                        setStartDate(start);
                        setEndDate(end);
                        getRewardHistory(start, end);
                    }}
                />
            </>
        )
    } else {
        return (
            <>
                <Center mt={16}>
                    <div style={{ margin: "0px 50px", border: "1px solid #d7d7d8", padding: " 25px", borderRadius: "20px", width: "50rem" }}>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>
                            <Image
                                boxSize={7} float={'left'} cursor={'pointer'} onClick={() => _navigate(-1)}
                                src={require('../../../assets/images/svg/general-icon-back.svg').default} />
                            <Text fontSize={'lg'} color={'white'} display={'inline-block'} mr={10} fontWeight={'bold'}>{t('REWARD_HISTORY')}</Text>
                        </div>
                        <div style={{ background: "linear-gradient(#ac4cfe,#7416ab)", padding: "20px", borderRadius: "20px" }}>
                            <Flex mt={2.5}>
                                <Text fontWeight={'semibold'} mr={3} ml={2} cursor={'pointer'} onClick={() => setShowDatePicker(!showDatePicker)}>
                                    {`${moment(startDate).format('DD MMM yyyy')} - ${moment(endDate).format('DD MMM yyyy')}`}
                                </Text>
                                <img style={{ cursor: "pointer" }} onClick={() => setShowDatePicker(!showDatePicker)} src={require('../../../assets/images/svg/updown-white.svg').default} />
                            </Flex>
                            <Stack paddingInline={5} mt={4}>
                                {
                                    transaction.length > 0 ?
                                        <div
                                            id="scrollableDiv"
                                            style={{
                                                height: '23rem',
                                                overflow: "auto"
                                            }}>
                                            <InfiniteScroll
                                                style={{ display: "flex", flexDirection: "column" }}
                                                dataLength={transaction.length}
                                                next={() => {
                                                    let page = pageNo + 1;
                                                    setPageNo(page);
                                                    getRewardHistory(startDate, endDate, page);
                                                }}
                                                hasMore={transaction.length < totalCount}
                                                loader={<Spinner color="white" />}
                                            >
                                                {
                                                    transaction.map((item, index) => {
                                                        return (
                                                            <RenderItem
                                                                item={item}
                                                                index={index}
                                                            />
                                                        )
                                                    })
                                                }
                                            </InfiniteScroll>
                                        </div> :
                                        <div style={{ textAlign: "center", paddingBlock: "5rem" }}>
                                            <Center>
                                                <Image boxSize={'150px'} src={require('../../../assets/images/empty-prize.png')} />
                                            </Center>
                                            <Text textAlign={'center'} fontWeight={'bold'} fontSize={'md'}>{t("NO_REWARD_HISTORY")}</Text>
                                        </div>
                                }
                            </Stack>
                        </div>
                        <DateFilter
                            isOpen={showDatePicker}
                            onClose={() => setShowDatePicker(!showDatePicker)}
                            closeOnOverlayClick={false}
                            applyDateFilter={(data) => {
                                let start = moment(data.startDate).format('yyyy-MM-DD');
                                let end = moment(data.endDate).format('yyyy-MM-DD');
                                setStartDate(start);
                                setEndDate(end);
                                getRewardHistory(start, end);
                            }}
                        />
                    </div>
                </Center>
                <Footer />
            </>
        )
    }
}